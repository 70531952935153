import React, {Component} from "react";
import {Col, Divider, Row, Space, Spin, Form, Radio, Checkbox, Input, Card} from "antd";
import {activityApiService} from "../../services/amartaVip/ActivityApiService";
import Cookies from "universal-cookie";
import TextArea from "antd/es/input/TextArea";
import CurrencyInput from "react-currency-input-field";

class DetailCustomerVehicleSurvey extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            chosenAppCode: "",
            processingFetch: false,
            dataApplication: {},

            interiorSatisfied: "",
            interiorDissatisfied: "",
            exteriorSatisfied: "",
            exteriorDissatisfied: "",
            surveyRecommended: "",
            surveyLuxury: null,
            surveyFeminine: null,
            surveyUserAge: null,
            surveyRoadField: null,
            surveyBusinessUsage: null,
            surveyTechnology: null,
            testDriveResult: "",
            vehicleUsage: null,
            purchasePlan: null,
            purchaseMethod: null,
            downPayment: 0,
            tenor: 0,
            installmentAmount: 0,

            countCheckedBuyFactor: 0,
            checkBoxInterior: false,
            checkBoxExterior: false,
            checkBoxFeature: false,
            checkBoxBrand: false,
            checkBoxFuelConsumption: false,
            checkBoxPrice: false,
            checkBoxPerform: false,
            checkBoxComfort: false,
            checkBoxDurable: false,
            checkBoxAfterSales: false,
            checkBoxCabinRelief: false,
            checkBoxSparePart: false,
            checkBoxTechnology: false,
            checkBoxSecurity: false,
            checkBoxOther: false,
            checkBoxOtherText: "",

            countCheckedInfo: 0,
            checkBoxInfoTv: false,
            checkBoxInfoShoppingMall: false,
            checkBoxInfoOfficialWebsite: false,
            checkBoxInfoInternet: false,
            checkBoxInfoPhone: false,
            checkBoxInfoMagazine: false,
            checkBoxInfoSocialMedia: false,
            checkBoxInfoExhibition: false,
            checkBoxInfoBillboard: false,
            checkBoxInfoRelatives: false,
            checkBoxInfoClub: false,
            checkBoxInfoDealer: false,
            checkBoxInfoOther: false,
            checkBoxInfoOtherText: ""
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            chosenAppCode: this.props.chosenAppCode,
        });
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.time !== prevProps.time) || (this.props.chosenAppCode !== prevProps.chosenAppCode)) {
            await this.promisedSetState({
                chosenAppCode: this.props.chosenAppCode,
            });
            await this.fetchData();
        }
    }

    fetchData = async () => {
        await this.promisedSetState({
            processingFetch: true,
            dataApplication: {},
        });

        try {
            await activityApiService.setToken(this.state.token);
            const fetchResult = await activityApiService.getActivityList({
                activityType: "customersurvey",
                code: this.state.chosenAppCode,
            });

            const finalData = fetchResult?.data?.data[0];

            await this.promisedSetState({
                processingFetch: false,
                dataApplication: finalData,

                interiorSatisfied: finalData?.interior_evaluation?.interior_satisfied,
                interiorDissatisfied: finalData?.interior_evaluation?.interior_dissatisfied,
                exteriorSatisfied: finalData?.exterior_evaluation?.exterior_satisfied,
                exteriorDissatisfied: finalData?.exterior_evaluation?.exterior_dissatisfied,
                testDriveResult: finalData?.test_drive_experience,
                surveyRecommended: finalData?.desire_to_recommend,

                surveyLuxury: finalData?.opinion_evaluation?.luxury,
                surveyFeminine: finalData?.opinion_evaluation?.feminine,
                surveyUserAge: finalData?.opinion_evaluation?.user_age,
                surveyRoadField: finalData?.opinion_evaluation?.road_field,
                surveyBusinessUsage: finalData?.opinion_evaluation?.business_usage,
                surveyTechnology: finalData?.opinion_evaluation?.technology,

                vehicleUsage: finalData?.vehicle_usage,
                purchasePlan: finalData?.purchase_plan,
                purchaseMethod: finalData?.purchase_method,
                downPayment: finalData?.down_payment,
                tenor: finalData?.tenor,
                installmentAmount: finalData?.installment_amount,

                countCheckedBuyFactor: 0,
                checkBoxInterior: finalData?.consider_buying_factor?.interior ?? false,
                checkBoxExterior: finalData?.consider_buying_factor?.exterior ?? false,
                checkBoxFeature: finalData?.consider_buying_factor?.feature ?? false,
                checkBoxBrand: finalData?.consider_buying_factor?.brand ?? false,
                checkBoxFuelConsumption: finalData?.consider_buying_factor?.fuel_consumption ?? false,
                checkBoxPrice: finalData?.consider_buying_factor?.price ?? false,
                checkBoxPerform: finalData?.consider_buying_factor?.perform ?? false,
                checkBoxComfort: finalData?.consider_buying_factor?.comfort ?? false,
                checkBoxDurable: finalData?.consider_buying_factor?.durable ?? false,
                checkBoxAfterSales: finalData?.consider_buying_factor?.after_sales ?? false,
                checkBoxCabinRelief: finalData?.consider_buying_factor?.cabin_relief ?? false,
                checkBoxSparePart: finalData?.consider_buying_factor?.spare_part ?? false,
                checkBoxTechnology: finalData?.consider_buying_factor?.technology ?? false,
                checkBoxSecurity: finalData?.consider_buying_factor?.security ?? false,
                checkBoxOther: finalData?.consider_buying_factor?.other ?? false,
                checkBoxOtherText: finalData?.consider_buying_factor?.other_text ?? "",

                countCheckedInfo: 0,
                checkBoxInfoTv: finalData?.source_of_information?.tv ?? false,
                checkBoxInfoShoppingMall: finalData?.source_of_information?.shopping_mall ?? false,
                checkBoxInfoOfficialWebsite: finalData?.source_of_information?.official_website ?? false,
                checkBoxInfoInternet: finalData?.source_of_information?.internet ?? false,
                checkBoxInfoPhone: finalData?.source_of_information?.phone ?? false,
                checkBoxInfoMagazine: finalData?.source_of_information?.magazine ?? false,
                checkBoxInfoSocialMedia: finalData?.source_of_information?.social_media ?? false,
                checkBoxInfoExhibition: finalData?.source_of_information?.exhibition ?? false,
                checkBoxInfoBillboard: finalData?.source_of_information?.billboard ?? false,
                checkBoxInfoRelatives: finalData?.source_of_information?.relatives ?? false,
                checkBoxInfoClub: finalData?.source_of_information?.club ?? false,
                checkBoxInfoDealer: finalData?.source_of_information?.dealer ?? false,
                checkBoxInfoOther: finalData?.source_of_information?.other ?? false,
                checkBoxInfoOtherText: finalData?.source_of_information?.other_text ?? "",
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetch: false,
                dataApplication: {},
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        const surveyOptions = [{value: 1, label: "Sederhana"}, {value: 2, label: ""}, {value: 3, label: ""}, {value: 4, label: ""}, {value: 5, label: "Mewah"}];
        const surveyOptions2 = [{value: 1, label: "Maskulin"}, {value: 2, label: ""}, {value: 3, label: ""}, {value: 4, label: ""}, {value: 5, label: "Feminim"}];
        const surveyOptions3 = [{value: 1, label: "Muda"}, {value: 2, label: ""}, {value: 3, label: ""}, {value: 4, label: ""}, {value: 5, label: "Dewasa"}];
        const surveyOptions5 = [{value: 1, label: "Perkotaan"}, {value: 2, label: ""}, {value: 3, label: ""}, {value: 4, label: ""}, {value: 5, label: "Off Road"}];
        const surveyOptions6 = [{value: 1, label: "Rumah"}, {value: 2, label: ""}, {value: 3, label: ""}, {value: 4, label: ""}, {value: 5, label: "Bisnis"}];
        const surveyOptions7 = [{value: 1, label: "Low Technology"}, {value: 2, label: ""}, {value: 3, label: ""}, {value: 4, label: ""}, {value: 5, label: "High Technology"}];

        return (
            <React.Fragment>
                <div style={this.state.processingFetch ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={!this.state.processingFetch ? {} : {display: 'none'}}>
                    <Row className={`p-5 mt-3`}>
                        <div className={`pt-3 detail-product-title`}>{this.state?.dataApplication?.vehicle?.market_name?.toLowerCase()?.replace(/\b\w/g, (s: string) => s.toUpperCase())}</div>
                    </Row>

                    <Divider className={`pt-5`} orientation="left">Pengalaman Setelah TestDrive</Divider>
                    <Row>
                        <Col className={`p-3`} xxl={{span: 12}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Pengalaman anda setelah melakukan testDrive</i>
                                <TextArea
                                    rows={3} value={this.state.testDriveResult}
                                    placeholder="Deskripsi pengalaman anda setelah melakukan testDrive"
                                />
                            </Form.Item>
                        </Col>
                        <Col className={`p-3`} xxl={{span: 12}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Setelah melakukan testDrive, apakah anda ingin merekomendasikan kendaraan ini?</i><br/>
                                <Radio.Group value={this.state.surveyRecommended}>
                                    <Space direction="vertical">
                                        <Radio value={"impossible"}>Tidak Mungkin</Radio>
                                        <Radio value={"not_sure"}>Tidak Yakin</Radio>
                                        <Radio value={"maybe"}>Mungkin</Radio>
                                        <Radio value={"recommend"}>Sangat Mungkin Merekomendasikan</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider orientation="left">Evaluasi Kendaraan</Divider>
                    <Row className={`mt-20`}>
                        <Col className={`p-3`} xxl={{span: 12}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <Divider orientation="left" plain>Interior</Divider>
                            <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Hal yang anda suka dari Interior kendaraan</i>
                                <TextArea
                                    rows={3} value={this.state.interiorSatisfied}
                                    placeholder="Deskripsi Kepuasan anda terhadap Interior kendaraan"
                                />
                            </Form.Item>
                            <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Hal yang anda tidak suka dari Interior kendaraan</i>
                                <TextArea
                                    rows={3} value={this.state.interiorDissatisfied}
                                    placeholder="Bagian mana pada Interior yang membuat anda tidak puas / tidak suka."
                                />
                            </Form.Item>
                            <Divider orientation="left" plain>Exterior</Divider>
                            <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Hal yang anda suka dari Exterior kendaraan</i>
                                <TextArea
                                    rows={3} value={this.state.exteriorSatisfied}
                                    placeholder="Deskripsi Kepuasan anda terhadap Exterior kendaraan"
                                />
                            </Form.Item>
                            <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Hal yang anda tidak suka dari Exterior kendaraan</i>
                                <TextArea
                                    showCount maxLength={500} rows={3} value={this.state.exteriorDissatisfied}
                                    placeholder="Bagian mana pada Exterior yang membuat anda tidak puas / tidak suka."
                                />
                            </Form.Item>
                        </Col>
                        <Col className={`p-3`} xxl={{span: 12}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <Divider orientation="left" plain>Survey Keseluruhan</Divider>
                            <table style={{width: "100%"}}>
                                <thead>
                                <tr style={{backgroundColor: "#e1e1e1", padding: 10}}>
                                    {surveyOptions.map((option) => (
                                        <td style={{width: "20%", textAlign: "center", padding: 10}}>{option.value}</td>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    {surveyOptions.map((option) => (
                                        <td style={{paddingTop: 5, paddingBottom: 5}} key={option.value}>
                                            <div className={`text-small-grey-nopadding`} style={{textAlign: "center", paddingBottom: (option.label) ? 5 : 20}}>{option.label}</div>
                                            <div style={{textAlign: "center"}}>
                                                <Radio.Group value={this.state.surveyLuxury}>
                                                    <Radio value={option.value}/>
                                                </Radio.Group>
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    {surveyOptions2.map((option) => (
                                        <td style={{paddingTop: 5, paddingBottom: 5}} key={option.value}>
                                            <div className={`text-small-grey-nopadding`} style={{textAlign: "center", paddingBottom: (option.label) ? 5 : 20}}>{option.label}</div>
                                            <div style={{textAlign: "center"}}>
                                                <Radio.Group value={this.state.surveyFeminine}>
                                                    <Radio value={option.value}/>
                                                </Radio.Group>
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    {surveyOptions3.map((option) => (
                                        <td style={{paddingTop: 5, paddingBottom: 5}} key={option.value}>
                                            <div className={`text-small-grey-nopadding`} style={{textAlign: "center", paddingBottom: (option.label) ? 5 : 20}}>{option.label}</div>
                                            <div style={{textAlign: "center"}}>
                                                <Radio.Group value={this.state.surveyUserAge}>
                                                    <Radio value={option.value}/>
                                                </Radio.Group>
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    {surveyOptions5.map((option) => (
                                        <td style={{paddingTop: 5, paddingBottom: 5}} key={option.value}>
                                            <div className={`text-small-grey-nopadding`} style={{textAlign: "center", paddingBottom: (option.label) ? 5 : 20}}>{option.label}</div>
                                            <div style={{textAlign: "center"}}>
                                                <Radio.Group value={this.state.surveyRoadField}>
                                                    <Radio value={option.value}/>
                                                </Radio.Group>
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    {surveyOptions6.map((option) => (
                                        <td style={{paddingTop: 5, paddingBottom: 5}} key={option.value}>
                                            <div className={`text-small-grey-nopadding`} style={{textAlign: "center", paddingBottom: (option.label) ? 5 : 20}}>{option.label}</div>
                                            <div style={{textAlign: "center"}}>
                                                <Radio.Group value={this.state.surveyBusinessUsage}>
                                                    <Radio value={option.value}/>
                                                </Radio.Group>
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    {surveyOptions7.map((option) => (
                                        <td style={{paddingTop: 5, paddingBottom: 5}} key={option.value}>
                                            <div className={`text-small-grey-nopadding`} style={{textAlign: "center", paddingBottom: (option.label) ? 5 : 20}}>{option.label}</div>
                                            <div style={{textAlign: "center"}}>
                                                <Radio.Group value={this.state.surveyTechnology}>
                                                    <Radio value={option.value}/>
                                                </Radio.Group>
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>

                    <Divider orientation="left">Karakteristik Calon Konsumen</Divider>
                    <Row className={`mt-20`}>
                        <Col className={`p-3`} xxl={{span: 12}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Faktor apa yang paling anda pertimbangkan sewaktu membeli kendaraan? <br/>(tentukan minimal 1 opsi / maksimal 5 opsi yang paling anda prioritaskan)</i><br/>
                                <Row>
                                    <Col span={12}>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxInterior}>
                                            <span style={{fontSize: "80%"}}>Desain Interior</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxExterior}>
                                            <span style={{fontSize: "80%"}}>Desain Exterior</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxFeature}>
                                            <span style={{fontSize: "80%"}}>Fitur</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxBrand}>
                                            <span style={{fontSize: "80%"}}>Merek</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxFuelConsumption}>
                                            <span style={{fontSize: "80%"}}>Konsumsi Bahan Bakar</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxPrice}>
                                            <span style={{fontSize: "80%"}}>Harga</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxComfort}>
                                            <span style={{fontSize: "80%"}}>Kenyamanan</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxOther}>
                                            <span style={{fontSize: "80%"}}>Lainya</span>
                                        </Checkbox><br/>
                                        <Input style={this.state.checkBoxOther ? {} : {display: "none"}} className={`pt-3`} value={this.state.checkBoxOtherText}/>
                                    </Col>
                                    <Col span={12}>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxPerform}>
                                            <span style={{fontSize: "80%"}}>Daya & Performa</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxDurable}>
                                            <span style={{fontSize: "80%"}}>Ketahanan & Kehandalan</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxAfterSales}>
                                            <span style={{fontSize: "80%"}}>Layanan After Sales</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxCabinRelief}>
                                            <span style={{fontSize: "80%"}}>Kelegaan Kabin</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxSparePart}>
                                            <span style={{fontSize: "80%"}}>Spare Part</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxTechnology}>
                                            <span style={{fontSize: "80%"}}>Teknologi</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxSecurity}>
                                            <span style={{fontSize: "80%"}}>Keamanan</span>
                                        </Checkbox><br/>
                                    </Col>
                                </Row>
                            </Form.Item>

                            <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Dari mana anda mendapatkan informasi yang berhubungan dengan kendaraan?</i><br/>
                                <Row>
                                    <Col span={12}>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxInfoTv}>
                                            <span style={{fontSize: "80%"}}>TV</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxInfoShoppingMall}>
                                            <span style={{fontSize: "80%"}}>Shopping Mall</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxInfoOfficialWebsite}>
                                            <span style={{fontSize: "80%"}}>Website Official</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxInfoInternet}>
                                            <span style={{fontSize: "80%"}}>Internet</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxInfoPhone}>
                                            <span style={{fontSize: "80%"}}>Ponsel (Pesan Whatsapp, SMS, dll)</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxInfoMagazine}>
                                            <span style={{fontSize: "80%"}}>Majalah</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxInfoOther}>
                                            <span style={{fontSize: "80%"}}>Lainya</span>
                                        </Checkbox><br/>
                                        <Input style={this.state.checkBoxInfoOther ? {} : {display: "none"}} className={`pt-3`} value={this.state.checkBoxInfoOtherText}/>
                                    </Col>
                                    <Col span={12}>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxInfoSocialMedia}>
                                            <span style={{fontSize: "80%"}}>Media Sosial (Youtube, Facebook dll)</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxInfoExhibition}>
                                            <span style={{fontSize: "80%"}}>Pameran</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxInfoBillboard}>
                                            <span style={{fontSize: "80%"}}>Papan Reklame</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxInfoRelatives}>
                                            <span style={{fontSize: "80%"}}>Keluarga / Kerabat</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxInfoClub}>
                                            <span style={{fontSize: "80%"}}>Klub Kendaraan</span>
                                        </Checkbox><br/>
                                        <Checkbox className={`pt-3`} checked={this.state.checkBoxInfoDealer}>
                                            <span style={{fontSize: "80%"}}>Dealer Lokal</span>
                                        </Checkbox><br/>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                        <Col className={`p-3`} xxl={{span: 12}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Penggunaan Kendaraan bagi calon konsumen?</i><br/>
                                <Radio.Group value={this.state.vehicleUsage}>
                                    <Space direction="vertical">
                                        <Radio value={"first_vehicle"}>Sebagai Kendaraan Pertama</Radio>
                                        <Radio value={"replace_existing_vehicle"}>Menggantikan kendaraan yang sudah ada</Radio>
                                        <Radio value={"increase_ownership_vehicle"}>Menambah kepemilikan kendaraan</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Rencana pembelian kendaraan?</i><br/>
                                <Radio.Group value={this.state.purchasePlan}>
                                    <Space direction="vertical">
                                        <Radio value={"1_month"}>1 Bulan Kedepan</Radio>
                                        <Radio value={"2_months"}>2 Bulan Kedepan</Radio>
                                        <Radio value={"3_months"}>3 Bulan Kedepan</Radio>
                                        <Radio value={"more_than_3_months"}>{">"} 3 Bulan Kedepan</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Rencana metode pembelian?</i><br/>
                                <Radio.Group value={this.state.purchaseMethod}>
                                    <Radio value={"cash"}>Tunai</Radio>
                                    <Radio value={"credit"}>Kredit</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Card title="Skema Kredit" size={"small"} style={this.state.purchaseMethod === "credit" ? {width: "100%"} : {display: "none"}}>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Uang Muka</i> <i style={{color: "gray", fontSize: "70%"}}>(Kelipatan 1jt)</i>
                                    <CurrencyInput
                                        className={"ant-input same-ant-input"}
                                        groupSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={"Rp. "}
                                        style={{marginTop: 5, width: "97%"}}
                                        value={this.state.downPayment}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Tenor</i><br/>
                                    <CurrencyInput
                                        className={"ant-input same-ant-input"}
                                        max={100}
                                        style={{marginTop: 5, width: "97%"}}
                                        value={this.state.tenor}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Cicilan</i> <i style={{color: "gray", fontSize: "70%"}}>(Kelipatan 100k)</i>
                                    <CurrencyInput
                                        className={"ant-input same-ant-input"}
                                        groupSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={"Rp. "}
                                        style={{marginTop: 5, width: "97%"}}
                                        value={this.state.installmentAmount}
                                    />
                                </Form.Item>
                                <Row>
                                    <Col span={24}>
                                        <br/><i style={{fontSize: "80%"}}>*Angsuran bisa berubah sesuai anjuran finance company / leasing selama belum tanda tangan perjanjian kredit</i><br/>
                                    </Col>
                                </Row>
                            </Card><br/>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        )
            ;
    }
}

export default DetailCustomerVehicleSurvey;
