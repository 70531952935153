import React, {Component} from 'react';
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import {InternalUserStates} from "./types/InternalUserTypes";
import {Button, Form, Select, Table, Tooltip} from "antd";
import {userServices} from "../../services/amartaVip/UserService";
import {ContactsOutlined, UserOutlined, MobileOutlined, MailOutlined, SmileOutlined, LikeOutlined} from "@ant-design/icons";

export interface InternalUserProps {
}

class InternalUser extends Component<InternalUserProps & any, InternalUserStates> {
    private cookies = new Cookies();

    constructor(props: InternalUserProps & any) {
        super(props);

        this.state = {
            token: this.cookies.get('_vip-t') ?? "",
            searchType: null,
            processingFetchData: false,
            dataUsers: [],
        }
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    onFieldChange = async <T extends keyof Pick<any, "searchType">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "searchType":
                currentState.searchType = value;
                currentState.searchField = "";
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    fetchUserData = async () => {
        if (this.state.searchType) {
            this.setState({
                processingFetchData: true,
                dataUsers: [],
            });
        }

        try {
            await userServices.setToken(this.state.token);
            const fetchResult = await userServices.getListUserInternalAffiliate({type: this.state.searchType});
            await this.promisedSetState({
                processingFetchData: false,
                dataUsers: fetchResult?.data?.data,
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetchData: false,
                dataUsers: {},
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`UserVIP`} breadcrumbMenu={`List Internal / Affiliate`} defaultSelectedKeys={['m-internalUser']} defaultOpenKeys={['sm-uservip']}>
                    <div className={`p-5`}>
                        <Form layout={`inline`} style={{marginTop: -3}}>
                            <Form.Item>
                                <i className={`small-text-grey`}>Search Type:</i><br/>
                                <Select
                                    style={{minWidth: 200}}
                                    showSearch
                                    placeholder="Choose SearchType"
                                    optionFilterProp="children"
                                    onChange={value => this.onFieldChange("searchType", value)}
                                    value={this.state.searchType}
                                >
                                    <Select.Option key={`list-internal`} value={`list-internal`}>{`List Internal`}</Select.Option>
                                    <Select.Option key={`list-affiliate`} value={`list-affiliate`}>{`List Affiliate`}</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>&nbsp;</i><br/>
                                <Button loading={this.state.processingFetchData} onClick={this.fetchUserData} type={"primary"}> Cari Data </Button>
                            </Form.Item>
                        </Form>
                    </div>

                    <div className={`p-5`}>
                        <Table
                            loading={this.state.processingFetchData}
                            key={'uid'}
                            scroll={{x: 'calc(500px + 50%)'}}
                            dataSource={this.state.dataUsers}
                            pagination={{pageSize: 1000}}
                            columns={[
                                {
                                    title: 'User Name | Uid',
                                    dataIndex: 'uid',
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            <span className={`font-90`}>{row.name}</span><br/>
                                            <span className={`red-85`}>{value}</span>
                                        </React.Fragment>
                                    })
                                }, {
                                    title: 'Agent Code | ID Card',
                                    dataIndex: 'client_name',
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            <span className={`red-85`}>
                                                <Tooltip title="Agent Code" color={'blue'}>
                                                    <UserOutlined style={{cursor: "help"}}/> &nbsp;
                                                </Tooltip> {row.agent_code}
                                            </span><br/>
                                            <span className={`gray-80`}>
                                                <Tooltip title="ID Card Number" color={'blue'}>
                                                    <ContactsOutlined style={{cursor: "help"}}/> &nbsp;
                                                </Tooltip> {row.id_card?.id_card_number}
                                            </span>
                                        </React.Fragment>
                                    })
                                },
                                {
                                    title: 'Contact',
                                    dataIndex: 'client_name',
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            <span className={`red-85`}>
                                                <Tooltip title="Phone Email Telegram" color={'blue'}>
                                                    <MailOutlined style={{cursor: "help"}}/> &nbsp;
                                                </Tooltip> {row.email}
                                            </span><br/>
                                            <span className={`gray-80`}>
                                                <Tooltip title="Phone Number" color={'blue'}>
                                                    <MobileOutlined style={{cursor: "help"}}/> &nbsp;
                                                </Tooltip> {row.phone} &nbsp;
                                                <Tooltip title="Telegram Id" color={'blue'}>
                                                    <ContactsOutlined style={{cursor: "help"}}/> &nbsp;
                                                </Tooltip> {row.telegram_id}
                                            </span>
                                        </React.Fragment>
                                    })
                                },
                                {
                                    title: 'Affiliate Data',
                                    dataIndex: 'affiliate_data',
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            <span className={`red-85`}>
                                                <Tooltip title="Type" color={'blue'}>
                                                    <UserOutlined style={{cursor: "help"}}/>
                                                </Tooltip> {row.affiliate_data.affiliate_type ?? "internal"}
                                            </span><br/>
                                            <span className={`gray-80`}>
                                                <Tooltip title="Company" color={'blue'}>
                                                    <UserOutlined style={{cursor: "help"}}/>
                                                </Tooltip> {row.affiliate_data.affiliate_company_code} - {row.affiliate_data.affiliate_company_name}
                                            </span>
                                        </React.Fragment>
                                    })
                                },
                                {
                                    title: 'Recognition Latest Time',
                                    dataIndex: 'latest_recognition',
                                    render: ((value: any, row: any) => {
                                        return <React.Fragment>
                                            <span className={`red-85`}>
                                                <Tooltip title="Face Recognition" color={'blue'}>
                                                    <SmileOutlined style={{cursor: "help"}}/> &nbsp;
                                                </Tooltip> {value.face_recognition ?? "N/A"}
                                            </span><br/>
                                            <span className={`red-85`}>
                                                <Tooltip title="Fingerprint Recognition" color={'blue'}>
                                                    <LikeOutlined style={{cursor: "help"}}/> &nbsp;
                                                </Tooltip> {value.fingerprint_recognition ?? "N/A"}
                                            </span>
                                        </React.Fragment>
                                    })
                                },
                                {
                                    title: 'Triforce Latest Login',
                                    dataIndex: 'latest_triforce_activity',
                                    render: ((value: any, row: any) => {
                                        return <React.Fragment>
                                            <span className={`red-85`}>
                                                {value.latest_login || ""}
                                            </span>
                                        </React.Fragment>
                                    })
                                }
                            ]}/>
                    </div>

                </AppLayout>
            </React.Fragment>
        );
    }
}

export default withAuth(InternalUser, 14);
