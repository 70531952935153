import React, {Component} from 'react';
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import {DownloadLogActivityStates} from "./types/DonwloadLogTypes";
import {SearchOutlined} from "@ant-design/icons";
import {activityApiService} from "../../services/amartaVip/ActivityApiService";
import DatePicker from "antd/lib/date-picker";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {Form, Table, Button} from "antd";

dayjs.extend(customParseFormat);

export interface DownloadLogActivityProps {
}

class DownloadLogActivity extends Component<DownloadLogActivityProps & any, DownloadLogActivityStates> {
    private cookies = new Cookies();
    private tableColumn: any = [
        {
            title: 'Code',
            dataIndex: 'code',
            render: ((value: any, row: any) => {
                return <React.Fragment>
                    <span className={`font-90`}>{value}</span><br/>
                </React.Fragment>
            })
        },
        {
            title: 'Agent',
            dataIndex: 'agen_code',
            render: ((value: string, row: any) => {
                return <React.Fragment>
                    <span style={{color: "#da2f2f"}} className={`font-90`}>{value}</span><br/>
                    <span className={`gray-80`}>{row.agen_name}</span>
                </React.Fragment>
            })
        },
        {
            title: 'Source & Name',
            dataIndex: 'source',
            render: ((value: string, row: any) => {
                return <React.Fragment>
                    <span style={{color: "#da2f2f"}} className={`font-90`}>{value}</span><br/>
                    <span className={`gray-80`}>{row.activity_name}</span>
                </React.Fragment>
            })
        },
        {
            title: 'Time',
            dataIndex: 'created_time',
            render: ((value: string, row: any) => {
                return <React.Fragment>
                    <span className={`font-90`}>{value?.slice(0, 16)?.replace("T", " ")}</span><br/>
                    <span className={`gray-80`}>{row.notes}</span>
                </React.Fragment>
            })
        },
    ];

    constructor(props: DownloadLogActivityProps & any) {
        super(props);

        this.state = {
            token: this.cookies.get('_vip-t') ?? "",
            startDate: dayjs().startOf('month'),
            endDate: dayjs(),
            processingFetchTableData: false,
            pageSize: 100,
            dataTable: [],
        }
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    onFieldChange = async <T extends keyof Pick<any, "startDate" | "endDate">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "startDate":
                currentState.startDate = value;
                break;
            case "endDate":
                currentState.endDate = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    fetchData = async () => {
        await this.promisedSetState({
            processingFetchTableData: true,
            dataTable: [],
        });

        try {
            await activityApiService.setToken(this.state.token);
            const fetchResult = await activityApiService.getActivityList({
                activityType: "donwloadactivity",
                companyCode: "amarta",
                startDate: dayjs(this.state.startDate).format("YYYY-MM-DD"),
                endDate: dayjs(this.state.endDate).format("YYYY-MM-DD"),
            });
            await this.promisedSetState({
                processingFetchTableData: false,
                dataTable: fetchResult?.data?.data,
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetchTableData: false,
                dataTable: [],
            });
        }
    }

    onChangeTable = (pagination: any, filters: any, sorter: any) => {
        if (pagination?.pageSize) {
            this.setState({
                pageSize: pagination?.pageSize,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`Activity / Download Log Activity`} breadcrumbMenu={`Download Log`} defaultSelectedKeys={['m-download-log']} defaultOpenKeys={['sm-activity', 'sm-downloadlog-activity']}>

                    <div className={`p-5`}>
                        <Form layout={`inline`}>
                            <Form layout={`inline`}>
                                <Form.Item className={'padTop-10'} label="">
                                    <i className={`small-text-grey`}>Start Date</i><br/>
                                    <DatePicker defaultValue={dayjs(this.state.startDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("startDate", event)} format={'YYYY-MM-DD'}/>
                                </Form.Item>
                                <Form.Item className={'padTop-10'} label="">
                                    <i className={`small-text-grey`}>End Date</i><br/>
                                    <DatePicker defaultValue={dayjs(this.state.endDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("endDate", event)} format={'YYYY-MM-DD'}/>
                                </Form.Item>
                            </Form>

                            <Form.Item className={'padTop-5'}>
                                <Button type="primary" style={{marginTop: 22}} onClick={this.fetchData}><SearchOutlined/> Cari Data</Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <Table
                        rowKey={'code'}
                        size={'small'}
                        scroll={{x: 'calc(500px + 50%)'}}
                        loading={this.state.processingFetchTableData}
                        columns={this.tableColumn}
                        dataSource={this.state.dataTable}
                        pagination={{pageSize: this.state.pageSize}}
                        onChange={this.onChangeTable}
                    />
                </AppLayout>
            </React.Fragment>
        );
    }
}

export default withAuth(DownloadLogActivity, 23);