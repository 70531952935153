import React, {Component} from "react";
import Cookies from "universal-cookie";
import {Empty, Row, Spin, Timeline} from "antd";
import {userServices} from "../../services/amartaVip/UserService";
import currencyMillionFormat from "../../helper/currencyFormat";

class DetailUserDevice extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            uid: "",
            processingFetch: false,
            dataDetailPayroll: [],
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            uid: this.props.uid,
        });
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.time !== prevProps.time) || (this.props.uid !== prevProps.uid)) {
            await this.promisedSetState({
                uid: this.props.uid,
            });
            await this.fetchData();
        }
    }

    fetchData = async () => {
        this.setState({
            processingFetch: true
        });
        try {
            await userServices.setToken(this.state.token);
            const fetchResult = await userServices.getListUserInternalAffiliate({type: this.props.uid + "?payroll=true"});
            const sortedData: any = fetchResult?.data?.data?.user_payroll || [];

            await this.promisedSetState({
                processingFetch: false,
                dataDetailPayroll: sortedData,
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetch: false,
                dataDetailPayroll: [],
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.processingFetch ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={(this.state.dataDetailPayroll.length < 1 && !this.state.processingFetch) ? {} : {display: 'none'}}>
                    <Empty/>
                </div>
                <div style={!this.state.processingFetch ? {} : {display: 'none'}}>
                    <Row className={`pt-10`}>
                        <Timeline>
                            {this.state.dataDetailPayroll?.map((item: any, x: number) =>
                                <Timeline.Item key={x}>
                                    <table>
                                        <tr>
                                            <td><span className={`gray-80`}>Periode: </span></td>
                                            <td>{item?.tanggal_periode?.replace("T", " ")}</td>
                                        </tr>
                                        <tr>
                                            <td><span className={`gray-80`}>Bank: </span></td>
                                            <td>{item?.data_kode_bank}</td>
                                        </tr>
                                        <tr>
                                            <td><span className={`gray-80`}>Gaji Bersih: </span></td>
                                            <td>{currencyMillionFormat(item?.data_gaji_bersih)}</td>
                                        </tr>
                                    </table>
                                </Timeline.Item>
                            )}
                        </Timeline>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default DetailUserDevice;