import React, {Component} from 'react';
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import {UserFaceRecognitionStates} from "./types/UserFaceRecognition";
import {userServices} from "../../services/amartaVip/UserService";
import {faceRecognitionService} from "../../services/amartaVip/FaceRecognitionService";
import {Select, Form, Input, Button, Empty, Spin, Row, Col, DatePicker, Table} from "antd";
import DetailUser from "../../component/detailData/DetailUser";
import dayjs from "dayjs";
import {FileImageOutlined} from "@ant-design/icons";

export interface UpdateUserProps {
}

class UserFaceRecognition extends Component<UpdateUserProps & any, UserFaceRecognitionStates> {
    private cookies = new Cookies();

    constructor(props: UpdateUserProps & any) {
        super(props);

        this.state = {
            token: this.cookies.get('_vip-t') ?? "",
            searchType: "IDCARD",
            processingFetchData: false,
            searchField: "",
            startDate: dayjs().startOf('month'),
            dataDetailUser: {},
            dataDetailFace: [],
        }
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    fetchUserData = async () => {
        if (this.state.searchType && this.state.searchField) {
            await this.promisedSetState({
                processingFetchData: true,
                dataDetailUser: {},
                dataDetailFace: [],
            });

            try {
                await userServices.setToken(this.state.token);
                const fetchResult = await userServices.getDetailUser({type: this.state.searchType, searchText: this.state.searchField});
                await this.promisedSetState({
                    // processingFetchData: false,
                    dataDetailUser: fetchResult?.data?.data,
                });
            } catch (e) {
                await this.promisedSetState({
                    // processingFetchData: false,
                    dataDetailUser: {},
                });
            }


            const startDate = dayjs(this.state.startDate).format("YYYY-MM-DD");
            const lastDayOfMonth = dayjs(startDate).endOf('month').format('YYYY-MM-DD');

            try {
                await faceRecognitionService.setToken(this.state.token);
                const fetchResult = await faceRecognitionService.getUserFace({uid: this.state.dataDetailUser.uid, startDate: startDate, endDate: lastDayOfMonth});

                const sortedData: any = fetchResult?.data?.data;
                await sortedData.sort((a: any, b: any) => (new Date(b.created_time)).getTime() - (new Date(a.created_time)).getTime());

                await this.promisedSetState({
                    processingFetchData: false,
                    dataDetailFace: sortedData,
                });
            } catch (e) {
                await this.promisedSetState({
                    processingFetchData: false,
                    dataDetailFace: [],
                });
            }
        }
    }

    async componentDidMount() {

    }

    onFieldChange = async <T extends keyof Pick<any, "searchType" | "searchField" | "startDate">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "searchType":
                currentState.searchType = value;
                currentState.searchField = "";
                break;
            case "searchField":
                currentState.searchField = value;
                break;
            case "startDate":
                currentState.startDate = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`UserVIP`} breadcrumbMenu={`User FaceRecognition`} defaultSelectedKeys={['m-faceuservip']} defaultOpenKeys={['sm-uservip', 'sm-absent-uservip']}>
                    <div className={`p-5`}>
                        <Form layout={`inline`} style={{marginTop: -3}}>
                            <Form.Item>
                                <i className={`small-text-grey`}>Search Type:</i><br/>
                                <Select
                                    style={{minWidth: 200}}
                                    showSearch
                                    placeholder="Choose SearchType"
                                    optionFilterProp="children"
                                    onChange={value => this.onFieldChange("searchType", value)}
                                    value={this.state.searchType}
                                >
                                    <Select.Option key={`IDCARD`} value={`IDCARD`}>{`ID Card`}</Select.Option>
                                    <Select.Option key={`PHONENUMBER`} value={`PHONENUMBER`}>{`Phone Number`}</Select.Option>
                                    <Select.Option key={`EMAIL`} value={`EMAIL`}>{`Email`}</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>Search Field:</i><br/>
                                <Input value={this.state.searchField} onChange={event => this.onFieldChange("searchField", event.target.value)} placeholder="Search Field"/>
                            </Form.Item>
                            <Form.Item className={'padTop-10'} label="">
                                <i className={`small-text-grey`}>Periode:</i><br/>
                                <DatePicker picker="month" defaultValue={dayjs(this.state.startDate, 'YYYY-MM')} onChange={event => this.onFieldChange("startDate", event)} format={'YYYY-MM'}/>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>&nbsp;</i><br/>
                                <Button loading={this.state.processingFetchData} onClick={this.fetchUserData} type={"primary"}> Cari Data </Button>
                            </Form.Item>
                        </Form>
                    </div>

                    <div style={this.state.processingFetchData ? {} : {display: 'none'}} className={`spinCenter`}>
                        <Spin size="large"/>
                    </div>

                    <div style={(!this.state.dataDetailUser?.name && !this.state.processingFetchData) ? {} : {display: 'none'}}>
                        <Empty/>
                    </div>

                    <div className={`p-5`} style={(this.state.dataDetailUser?.name && !this.state.processingFetchData) ? {} : {display: 'none'}}>
                        <Row>
                            <Col xs={24} xl={9} style={{padding: 5}}>
                                <DetailUser dataDetailUser={this.state.dataDetailUser} fullCol={true} showUserType={true} showIdCard={true}/>
                            </Col>
                            <Col xs={24} xl={15} style={{padding: 5}}>
                                <Table dataSource={this.state.dataDetailFace} columns={[
                                    {
                                        title: 'Time',
                                        dataIndex: 'created_time',
                                        key: 'created_time',
                                        render: ((value: number, row: any) => {
                                            return <React.Fragment>
                                                <span className={`font-90`}>{dayjs(value).format("YYYY-MM-DD")}</span> <span className={`gray-80`}>{dayjs(value).format("HH:mm:ss")}</span>
                                            </React.Fragment>
                                        })
                                    },
                                    {
                                        title: 'Device Id',
                                        dataIndex: 'device_id',
                                        render: ((value: number, row: any) => {
                                            return <React.Fragment>
                                                <span className={`font-90`}>{value}</span>
                                            </React.Fragment>
                                        })
                                    },
                                    {
                                        title: 'Url Snap Image',
                                        dataIndex: 'url_snap_image',
                                        render: ((value: number, row: any) => {
                                            return <React.Fragment>
                                                {row.media === "FINGERPRINT_RECOGNITION"
                                                    ? <span>FingerPrint</span>
                                                    : <a target={`_blank`} href={value?.toString()}><FileImageOutlined/></a>
                                                }
                                            </React.Fragment>
                                        })
                                    },
                                ]}/>
                            </Col>
                        </Row>
                    </div>
                </AppLayout>
            </React.Fragment>
        );
    }
}

export default withAuth(UserFaceRecognition, 10);
