import React, {Component} from "react";
import {Col, Divider, Row, Spin} from "antd";
import {activityApiService} from "../../services/amartaVip/ActivityApiService";
import Cookies from "universal-cookie";
import currencyMillionFormat from "../../helper/currencyFormat";

class DetailFormApproval extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            chosenAppCode: "",
            processingFetch: false,
            dataApplication: {},
            dataCv: {},
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            chosenAppCode: this.props.chosenAppCode,
        });
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.time !== prevProps.time) || (this.props.chosenAppCode !== prevProps.chosenAppCode)) {
            await this.promisedSetState({
                chosenAppCode: this.props.chosenAppCode,
            });
            await this.fetchData();
        }
    }

    fetchData = async () => {
        await this.promisedSetState({
            processingFetch: true,
            dataApplication: {},
        });

        try {
            await activityApiService.setToken(this.state.token);
            const fetchResult = await activityApiService.getActivityList({
                activityType: "applicationactivity",
                code: this.state.chosenAppCode,
            });
            await this.promisedSetState({
                processingFetch: false,
                dataApplication: fetchResult?.data?.data[0],
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetch: false,
                dataApplication: {},
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.processingFetch ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={!this.state.processingFetch ? {} : {display: 'none'}}>
                    <Row style={{padding: 20, paddingTop: 10}}>
                        <Col xxl={{span: 18, offset: 4}} xl={{span: 18, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                            <div style={{textAlign: "center", fontSize: "170%", marginBottom: 7, fontWeight: "600"}}>Activity Application Form</div>
                            <br/>
                            Created By : {this.state.dataApplication.created_admin}<br/>
                            Created Date : {this.state.dataApplication.created_time?.slice(0, 10)}<br/>
                            Activity Date &nbsp;: {this.state.dataApplication.activity_date?.slice(0, 10)}<br/>
                            <div style={{textAlign: "left", fontSize: "125%", marginBottom: 7, marginTop: 20, fontWeight: "600"}}>I. Activity Information <i style={{textAlign: "center", fontSize: "60%", marginBottom: 20, color: "gray"}}>(filled by Dealer)</i></div>

                            <table>
                                <tr>
                                    <td>Group</td>
                                    <td>: {this.state.dataApplication.data?.group}</td>
                                </tr>
                                <tr>
                                    <td>Dealer</td>
                                    <td>: {this.state.dataApplication.data?.dealer}</td>
                                </tr>
                                <tr>
                                    <td>Activity</td>
                                    <td>: {this.state.dataApplication.data?.activity}</td>
                                </tr>
                                <tr>
                                    <td>Date of Activity</td>
                                    <td>: {this.state.dataApplication.activity_date?.slice(0, 10)}</td>
                                </tr>
                                <tr>
                                    <td>Duration (days) &nbsp; &nbsp;</td>
                                    <td>: {this.state.dataApplication.data?.duration}</td>
                                </tr>
                                <tr>
                                    <td>Venue</td>
                                    <td>: {this.state.dataApplication.data?.venue}</td>
                                </tr>
                                <tr>
                                    <td>Display</td>
                                    <td>: {this.state.dataApplication.data?.display}</td>
                                </tr>
                                <tr>
                                    <td>Target Visitor</td>
                                    <td>: {this.state.dataApplication.data?.target_visitor}</td>
                                </tr>
                                <tr>
                                    <td>Target Test Drive</td>
                                    <td>: {this.state.dataApplication.data?.target_test_drive}</td>
                                </tr>
                                <tr>
                                    <td><b>Total Budget</b></td>
                                    <td>:<b>{currencyMillionFormat(this.state.dataApplication?.sum_budget_amount)}</b></td>
                                </tr>
                                <tr>
                                    <td>
                                        Target
                                    </td>
                                    <td>
                                        <table>
                                            <tr>
                                                <td><b style={{textAlign: "center", paddingLeft: 20, paddingRight: 20}}>Model</b></td>
                                                <td><b style={{textAlign: "center", paddingLeft: 20, paddingRight: 20}}>Special Promo</b></td>
                                                <td><b style={{textAlign: "center", paddingLeft: 20, paddingRight: 20}}>Target Visitor</b></td>
                                                <td><b style={{textAlign: "center", paddingLeft: 20, paddingRight: 20}}>Target SPK</b></td>
                                            </tr>

                                            {(this.state.dataApplication?.data_target_vehicle_model ?? []).map((item: any, i: number) =>
                                                <tr key={i}>
                                                    <td>{item?.model_name?.toUpperCase()} &nbsp; &nbsp;</td>
                                                    <td>{item?.special_promo} &nbsp; &nbsp;</td>
                                                    <td>{item?.target_visitor} &nbsp; &nbsp;</td>
                                                    <td>{item?.target_spk} &nbsp; &nbsp;</td>
                                                </tr>
                                            )}
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Description</td>
                                    <td>: {this.state.dataApplication.data?.description}</td>
                                </tr>
                            </table>

                            <br/>
                            <div style={{textAlign: "left", fontSize: "125%", marginBottom: 20, marginTop: 20, fontWeight: "600"}}>II. Key Elements</div>
                            <Row>
                                <Col lg={{span: 12}} xs={{span: 24}}>
                                    <i className={`text-small-grey-nopadding`}>Person In Charge</i><br/>
                                    <table>
                                        <tr>
                                            <td><b style={{textAlign: "center", paddingLeft: 20, paddingRight: 20}}>Outlet</b></td>
                                            <td><b style={{textAlign: "center", paddingLeft: 20, paddingRight: 20}}>Name</b></td>
                                        </tr>
                                        {(this.state.dataApplication?.data_pic ?? []).map((item: any, i: number) =>
                                            <tr key={i}>
                                                <td>{item?.pic_outlet?.toUpperCase()} &nbsp; &nbsp;</td>
                                                <td>{item?.pic_name} &nbsp; &nbsp;</td>
                                            </tr>
                                        )}
                                    </table>
                                </Col>
                                <Col lg={{span: 12}} xs={{span: 24}}>
                                    <i className={`text-small-grey-nopadding`}>Manpower</i><br/>
                                    <table>
                                        <tr>
                                            <td><b style={{textAlign: "center", paddingLeft: 20, paddingRight: 20}}>Item</b></td>
                                            <td><b style={{textAlign: "center", paddingLeft: 20, paddingRight: 20}}>Quantity</b></td>
                                        </tr>
                                        {(this.state.dataApplication?.data_man_power ?? []).map((item: any, i: number) =>
                                            <tr key={i}>
                                                <td>{item?.man_item?.toUpperCase()} &nbsp; &nbsp;</td>
                                                <td>{item?.man_quantity} &nbsp; &nbsp;</td>
                                            </tr>
                                        )}
                                    </table>
                                </Col>
                            </Row>

                            <br/>
                            <div style={{textAlign: "left", fontSize: "125%", marginBottom: 20, marginTop: 20, fontWeight: "600"}}>III. Additional Data</div>
                            <Row>
                                <Col lg={{span: 12}} xs={{span: 24}}>
                                    <i className={`text-small-grey-nopadding`}>Detail Budget</i><br/>
                                    <table>
                                        <tr>
                                            <td><b style={{textAlign: "center", paddingLeft: 20, paddingRight: 20}}>Outlet</b></td>
                                            <td><b style={{textAlign: "center", paddingLeft: 20, paddingRight: 20}}>Name</b></td>
                                        </tr>
                                        {(this.state.dataApplication?.data_budget ?? []).map((item: any, i: number) =>
                                            <tr key={i}>
                                                <td>{item?.budget_detail?.toUpperCase()} &nbsp; &nbsp;</td>
                                                <td>{currencyMillionFormat(item?.budget_amount)} &nbsp; &nbsp;</td>
                                            </tr>
                                        )}
                                    </table>
                                </Col>
                                <Col lg={{span: 12}} xs={{span: 24}}>
                                    <i className={`text-small-grey-nopadding`}>Guest List</i><br/>
                                    <table>
                                        <tr>
                                            <td><b style={{textAlign: "center", paddingLeft: 20, paddingRight: 20}}>Name</b></td>
                                        </tr>
                                        {(this.state.dataApplication?.guest_list ?? []).map((item: any, i: number) =>
                                            <tr key={i}>
                                                <td>{item.toUpperCase()} &nbsp; &nbsp;</td>
                                            </tr>
                                        )}
                                    </table>
                                </Col>
                            </Row>
                            <div style={this.state.dataApplication.assessment_time ? {} : {display: 'none'}}>
                                <br/><br/>
                                <Divider orientation="left" plain>Assessment Result</Divider>
                                <table>
                                    <tr>
                                        <td>Assessment Time</td>
                                        <td>: {this.state.dataApplication.assessment_time?.slice(0, 10)}</td>
                                    </tr>
                                    <tr>
                                        <td>Assessment By</td>
                                        <td>: {this.state.dataApplication.assessment_admin}</td>
                                    </tr>
                                    <tr>
                                        <td>Assessment Activity Date</td>
                                        <td>: {this.state.dataApplication.assessment_date}</td>
                                    </tr>
                                    <tr>
                                        <td>Assessment Budget</td>
                                        <td>: {currencyMillionFormat(this.state.dataApplication.assessment_budget)}</td>
                                    </tr>
                                    <tr>
                                        <td>Assessment Note</td>
                                        <td>: {this.state.dataApplication.assessment_note}</td>
                                    </tr>
                                </table>
                            </div>

                            <div style={this.state.dataApplication.complete_time ? {} : {display: 'none'}}>
                                <br/><br/>
                                <Divider orientation="left" plain>Final Result</Divider>
                                <table>
                                    <tr>
                                        <td>Result Time</td>
                                        <td>: {this.state.dataApplication.complete_time?.slice(0, 10)}</td>
                                    </tr>
                                    <tr>
                                        <td>Result By</td>
                                        <td>: {this.state.dataApplication.complete_admin}</td>
                                    </tr>
                                    <tr>
                                        <td>Result Notes</td>
                                        <td>: {this.state.dataApplication.complete_note}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Result
                                        </td>
                                        <td>
                                            <table>
                                                <tr>
                                                    <td><b style={{textAlign: "center", paddingLeft: 20, paddingRight: 20}}>Model</b></td>
                                                    <td><b style={{textAlign: "center", paddingLeft: 20, paddingRight: 20}}>Realization Visitor</b></td>
                                                    <td><b style={{textAlign: "center", paddingLeft: 20, paddingRight: 20}}>Realization SPK</b></td>
                                                </tr>

                                                {(this.state.dataApplication?.complete_vehicle_model ?? []).map((item: any, i: number) =>
                                                    <tr key={i}>
                                                        <td>{item?.model_name?.toUpperCase()} &nbsp; &nbsp;</td>
                                                        <td>{item?.result_visitor} &nbsp; &nbsp;</td>
                                                        <td>{item?.result_spk} &nbsp; &nbsp;</td>
                                                    </tr>
                                                )}
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Guest
                                        </td>
                                        <td>
                                            <table>
                                                <tr>
                                                    <td><b style={{textAlign: "center", paddingLeft: 20, paddingRight: 20}}>Name</b></td>
                                                    <td><b style={{textAlign: "center", paddingLeft: 20, paddingRight: 20}}>Entry Time</b></td>
                                                    <td><b style={{textAlign: "center", paddingLeft: 20, paddingRight: 20}}>Exit Time</b></td>
                                                </tr>

                                                {(this.state.dataApplication?.complete_guest_list ?? []).map((item: any, i: number) =>
                                                    <tr key={i}>
                                                        <td>{item?.guest_name?.toUpperCase()} &nbsp; &nbsp;</td>
                                                        <td>{item?.entry_time} &nbsp; &nbsp;</td>
                                                        <td>{item?.exit_time} &nbsp; &nbsp;</td>
                                                    </tr>
                                                )}
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default DetailFormApproval;
