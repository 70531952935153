import axios from "axios";
import {baseUrlAmartaVip, xApiKeyAmartaVip} from "../../config/apiConfig/apiConfig";

class ActivityApiService {
    private axios = axios.create({
        baseURL: baseUrlAmartaVip,
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    public async createActivity(inputData?: any) {
        try {
            return await this.axios.post<any>('/activity',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getActivityList(params: any) {
        const queries = {
            ...(params?.companyCode && {
                company_code: params.companyCode,
            }),
            ...(params?.startDate && {
                activity_start_date: params.startDate,
            }),
            ...(params?.endDate && {
                activity_end_date: params.endDate,
            }),
            ...(params?.activityStatus && {
                activity_status: params.activityStatus,
            }),
            ...(params?.code && {
                code: params.code,
            }),
        }

        try {
            return await this.axios.get<any>(`/activity/${params.activityType}`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async updateApplicationactivity(inputData?: any) {
        try {
            return await this.axios.put<any>('/activity/applicationactivity',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async updateTestDrive(inputData?: any) {
        try {
            return await this.axios.put<any>('/activity/testdrive',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }
}

export const activityApiService = new ActivityApiService();
