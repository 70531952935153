import axios from "axios";
import {baseUrlTrimitraIAM} from "../../config/apiConfig/apiConfig";

class AuthApiService {
    private axios = axios.create({
        baseURL: baseUrlTrimitraIAM,
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    public async login(params: { username: string; password: string; app_code: string }) {
        try {
            return await this.axios.post<any>('/auth/login', params, {
                    headers: {
                        "X-Api-Key": "Uj7IujdaOe2RqiTzSjo8zaW9NN6es1uZ54BTHeqi",
                    }
                }
            );
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async myAccount(pageCode?: any, projectCode?: any) {
        pageCode = (!pageCode) ? 0 : pageCode;
        projectCode = (!projectCode) ? 0 : projectCode;

        try {
            return await this.axios.get<any>(`/auth/check?app_code=AMARTAVIP&project_code=${projectCode}&page_code=${pageCode}`, {
                headers: {
                    "X-Api-Key": "Uj7IujdaOe2RqiTzSjo8zaW9NN6es1uZ54BTHeqi",
                }
            });
        } catch (e: any) {
            console.log(e)
            throw new Error(e);
        }
    }
}

export const authApiService = new AuthApiService();
