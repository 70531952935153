import React, {Component} from "react";
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from "universal-cookie";
import {Buffer} from "buffer";
import withAuth from "../../hoc/withAuth";
import {ListtWorksheetStates} from "./types/ListWorksheetTypes";
import {allowedOrganization, optCallResult, optSMSResult, optVisitResult} from "../../config/appDataConfig/organizationCondig";
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Table, Tag} from "antd";
import {worksheetApiService} from "../../services/amartaVip/WorksheetApiService";
import {leadService} from "../../services/trimitraIdeal/LeadServive";
import SelectDealer from "../../component/selects/SelectDealer";
import SelectConfigArray from "../../component/selects/SelectConfigArray";
import AreaProvinceSelect from "../../component/selects/AreaProvinceSelect";
import AreaCitySelect from "../../component/selects/AreaCitySelect";
import VehicleModelSelect from "../../component/vehicle-copan/VehicleModelSelect";
import VehicleVariantSelect from "../../component/vehicle-copan/VehicleVariantSelect";
import VehicleVariantColorSelect from "../../component/vehicle-copan/VehicleVariantColorSelect";

import dayjs from "dayjs";

export interface ListWorksheetProps {
}

class ListWorksheet extends Component<ListWorksheetProps & any, ListtWorksheetStates> {
    private cookies = new Cookies();
    private tableColumn: any = [
        {
            title: "Action",
            dataIndex: "code",
            align: "center",
            render: (value: string, row: any) => {
                return (
                    <React.Fragment>
                        {row.leads_status === "PENDING" ? (
                            <React.Fragment>
                                <Tag
                                    onClick={(event) => this.onShowModalWorksheet(row)}
                                    style={{
                                        cursor: "pointer",
                                        marginTop: 2,
                                        fontSize: "80%",
                                        display: "inline",
                                        padding: 4,
                                        color: "#fff",
                                        backgroundColor: "#c95050",
                                        borderRadius: 5,
                                    }}
                                >
                                    Update Worrksheet
                                </Tag>
                                <Tag
                                    onClick={(event) => this.onShowModalCreateLeads(row)}
                                    style={{
                                        cursor: "pointer",
                                        marginTop: 2,
                                        fontSize: "80%",
                                        display: "inline",
                                        padding: 4,
                                        color: "#fff",
                                        backgroundColor: "#1677ff",
                                        borderRadius: 5,
                                    }}
                                >
                                    Create Leads
                                </Tag>
                            </React.Fragment>
                        ) : (
                            <Tag
                                onClick={(event) => this.onShowModalWorksheet(row)}
                                style={{
                                    cursor: "pointer",
                                    marginTop: 2,
                                    fontSize: "80%",
                                    display: "inline",
                                    padding: 4,
                                    color: "#fff",
                                    backgroundColor: "#c95050",
                                    borderRadius: 5,
                                }}
                            >
                                Update Worrksheet
                            </Tag>
                        )}
                    </React.Fragment>
                );
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (value: string) => {
                return (
                    <React.Fragment>
                        <span className={`font-80`}>{value}</span>
                    </React.Fragment>
                );
            },
        },
        {
            title: "Phone",
            dataIndex: "phone",
            render: (value: string) => {
                return (
                    <React.Fragment>
                        <span className={`font-80`}>{value}</span>
                    </React.Fragment>
                );
            },
        },
        {
            title: "Period",
            dataIndex: "period_time",
            render: (value: string) => {
                return (
                    <React.Fragment>
                        <span className={`font-80`}>{value}</span>
                    </React.Fragment>
                );
            },
        },
        {
            title: "Leads Status",
            dataIndex: "leads_status",
            render: (value: string) => {
                return (
                    <React.Fragment>
                        <span className={`font-80`}>{value}</span>
                    </React.Fragment>
                );
            },
        },
        {
            title: "City",
            dataIndex: "province_name",
            render: (value: string, row: any) => {
                return (
                    <React.Fragment>
                        <span className={`font-80`}>{value}</span>
                        <br/>
                        <span className={`font-80`}>{row.city_name}</span>
                        <br/>
                    </React.Fragment>
                );
            },
        },
        {
            title: "Vehicle",
            dataIndex: "preferred_vehicle_model_name",
            render: (value: string, row: any) => {
                return (
                    <React.Fragment>
            <span className={`font-80`}>
              {row.preferred_vehicle_variant_name} - {row.preferred_vehicle_color_code}
            </span>
                        <br/>
                        <span className={`font-80`}>{row.preferred_engine_number}</span>
                    </React.Fragment>
                );
            },
        },
        {
            title: "SMS",
            dataIndex: "contact_sms_result",
            render: (value: string, row: any) => {
                return (
                    <React.Fragment>
                        <span className={`font-80`}>{row.contact_sms_result}</span>
                        <br/>
                        <span className={`font-80`}>{row.contact_sms_date}</span>
                    </React.Fragment>
                );
            },
        },
        {
            title: "Call",
            dataIndex: "contact_call_result",
            render: (value: string, row: any) => {
                return (
                    <React.Fragment>
                        <span className={`font-80`}>{row.contact_call_result}</span>
                        <br/>
                        <span className={`font-80`}>{row.contact_call_date}</span>
                    </React.Fragment>
                );
            },
        },
        {
            title: "Visit",
            dataIndex: "contact_visit_result",
            render: (value: string, row: any) => {
                return (
                    <React.Fragment>
                        <span className={`font-80`}>{row.contact_visit_result}</span>
                        <br/>
                        <span className={`font-80`}>{row.contact_visit_date}</span>
                    </React.Fragment>
                );
            },
        },
        {
            title: "Description",
            dataIndex: "description",
            render: (value: string) => {
                return (
                    <React.Fragment>
                        <span className={`font-80`}>{value}</span>
                    </React.Fragment>
                );
            },
        },
        {
            title: "Last Updated By",
            dataIndex: "last_updated_by",
            render: (value: string) => {
                return (
                    <React.Fragment>
                        <span className={`font-80`}>{value}</span>
                    </React.Fragment>
                );
            },
        },
    ];

    constructor(props: ListWorksheetProps & any) {
        super(props);

        this.state = {
            token: this.cookies.get("_vip-t") ?? "",
            user: JSON.parse(Buffer.from(this.cookies.get("_vip-u"), "base64").toString()),
            startDate: dayjs(),
            loading: false,
            organizationCode: allowedOrganization[0].otodisCode,
            dataOrg: allowedOrganization[0],
            modalUpdate: false,
            modalView: false,
            dealerCode: "",
            dataTable: [],
            leadsStatus: "ALL",
            tempCode: "",
            tempCompanyCode: "",
            tempDealerCode: "",
            tempLeadsStatus: "",
            tempName: "",
            tempPhone: "",
            tempAddress: "",
            tempProvinceCode: "",
            tempProvinceName: "",
            tempCityCode: "",
            tempCityName: "",
            tempDescription: "",
            tempVehicleColorCode: "",
            tempVehicleColorName: "",
            tempVehicleModelName: "",
            tempVehicleModelUuid: "",
            tempVehicleVariantCode: "",
            tempVehicleVariantName: "",
            tempVehicleVariantUuid: "",
            tempEngineNumber: "",
            tempContactSmsResult: "",
            tempContactSmsDate: dayjs().format("YYYY-MM-DD"),
            tempContactCallResult: "",
            tempContactCallDate: dayjs().format("YYYY-MM-DD"),
            tempContactVisitResult: "",
            tempContactVisitDate: dayjs().format("YYYY-MM-DD"),
        };
    }

    onFieldChange = async <
        T extends keyof Pick<any, "organizationCode" | "startDate" | "dealerCode" | "leadsStatus" | "tempDescription" | "tempEngineNumber" | "tempContactSmsResult" | "tempContactSmsDate" | "tempContactCallResult" | "tempContactCallDate" | "tempContactVisitResult" | "tempContactVisitDate">
    >(
        target: T,
        value: any
    ) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "organizationCode":
                const pickedOrganization: any = allowedOrganization.find((o) => o.companyCode === value.toUpperCase()) ?? {};
                currentState.organizationCode = value;
                currentState.dataOrg = pickedOrganization;
                currentState.dealerCode = "";
                break;
            case "startDate":
                currentState.startDate = value;
                break;
            case "dealerCode":
                currentState.dealerCode = value;
                break;
            case "leadsStatus":
                currentState.leadsStatus = value;
                break;
            case "tempDescription":
                currentState.tempDescription = value;
                break;

            case "tempEngineNumber":
                currentState.tempEngineNumber = value;
                break;
            case "tempContactSmsResult":
                currentState.tempContactSmsResult = value;
                break;
            case "tempContactSmsDate":
                currentState.tempContactSmsDate = value;
                break;
            case "tempContactCallResult":
                currentState.tempContactCallResult = value;
                break;
            case "tempContactCallDate":
                currentState.tempContactCallDate = value;
                break;
            case "tempContactVisitResult":
                currentState.tempContactVisitResult = value;
                break;
            case "tempContactVisitDate":
                currentState.tempContactVisitDate = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    };

    onAreaChange = async (data: any) => {
        console.log(data);
    };

    onGetData = async () => {
        const {organizationCode, dealerCode, startDate, leadsStatus} = this.state;

        if (organizationCode === "") {
            Modal.error({
                title: "Error Validation",
                content: "Organization is required",
            });
            return false;
        }

        if (dealerCode === "") {
            Modal.error({
                title: "Error Validation",
                content: "Dealer Code is required",
            });
            return false;
        }

        if (startDate === "") {
            Modal.error({
                title: "Error Validation",
                content: "Waktu Priode is required",
            });
            return false;
        }

        const postData = {
            company_code: organizationCode,
            leads_status: leadsStatus !== "ALL" ? leadsStatus : "",
            dealer_code: dealerCode,
            period_time: startDate,
        };

        this.setState({loading: true});

        try {
            worksheetApiService.setToken(this.state.token);
            const getData = await worksheetApiService.getWorksheetList(postData);

            await this.promisedSetState({
                loading: false,
                dataTable: getData.data.data,
            });
        } catch (e: any) {
            Modal.error({
                title: "Error ",
                content: e.toString(),
            });

            await this.promisedSetState({
                loading: false,
                dataTable: [],
            });
        }
    };

    onShowModalWorksheet = async (rowData: any) => {
        this.setState({
            modalView: true,
            tempCode: rowData.code,
            tempCompanyCode: rowData.company_code,
            tempDealerCode: rowData.dealer_code,
            tempLeadsStatus: rowData.leads_status,
            tempName: rowData.name,
            tempPhone: rowData.phone,
            tempAddress: rowData.address,
            tempProvinceCode: rowData.province_code,
            tempProvinceName: rowData.province_name,
            tempCityCode: rowData.city_code,
            tempCityName: rowData.city_name,
            tempDescription: rowData.description,
            tempVehicleModelUuid: rowData.preferred_vehicle_model_uuid,
            tempVehicleModelName: rowData.preferred_vehicle_model_name,
            tempVehicleVariantUuid: rowData.preferred_vehicle_variant_uuid,
            tempVehicleVariantCode: rowData.preferred_vehicle_variant_code,
            tempVehicleVariantName: rowData.preferred_vehicle_variant_name,
            tempVehicleColorCode: rowData.preferred_vehicle_color_code,
            tempVehicleColorName: rowData.preferred_vehicle_color_name,
            tempEngineNumber: rowData.preferred_engine_number,
            tempContactSmsResult: rowData.contact_sms_result,
            tempContactSmsDate: rowData.contact_sms_date !== "" ? dayjs(rowData.contact_sms_date).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD"),
            tempContactCallResult: rowData.contact_call_result,
            tempContactCallDate: rowData.contact_call_date !== "" ? dayjs(rowData.contact_call_date).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD"),
            tempContactVisitResult: rowData.contact_visit_result,
            tempContactVisitDate: rowData.contact_visit_date !== "" ? dayjs(rowData.contact_visit_date).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD"),
        });
    };

    onShowModalCreateLeads = async (rowData: any) => {
        this.setState({
            modalUpdate: true,
            tempCode: rowData.code,
            tempCompanyCode: rowData.company_code,
            tempDealerCode: rowData.dealer_code,
            tempLeadsStatus: rowData.leads_status,
            tempName: rowData.name,
            tempPhone: rowData.phone,
            tempAddress: rowData.address,
            tempProvinceCode: rowData.province_code,
            tempProvinceName: rowData.province_name,
            tempCityCode: rowData.city_code,
            tempCityName: rowData.city_name,
            tempDescription: rowData.description,
            tempVehicleModelUuid: rowData.preferred_vehicle_model_uuid,
            tempVehicleModelName: rowData.preferred_vehicle_model_name,
            tempVehicleVariantUuid: rowData.preferred_vehicle_variant_uuid,
            tempVehicleVariantCode: rowData.preferred_vehicle_variant_code,
            tempVehicleVariantName: rowData.preferred_vehicle_variant_name,
            tempVehicleColorCode: rowData.preferred_vehicle_color_code,
            tempVehicleColorName: rowData.preferred_vehicle_color_name,
            tempEngineNumber: rowData.preferred_engine_number,
            tempContactSmsResult: rowData.contact_sms_result,
            tempContactSmsDate: rowData.contact_sms_date,
            tempContactCallResult: rowData.contact_call_result,
            tempContactCallDate: rowData.contact_call_date,
            tempContactVisitResult: rowData.contact_visit_result,
            tempContactVisitDate: rowData.contact_visit_date,
        });
    };

    onProvinceChange = (data: any) => {
        this.setState({
            tempProvinceCode: data.value,
            tempProvinceName: data.children,
            tempCityCode: "",
            tempCityName: "",
        });
    };

    onCityChange = (data: any) => {
        this.setState({
            tempCityCode: data.value,
            tempCityName: data.children,
        });
    };

    onTempVehicleModelChange = (data: any) => {
        this.setState({
            tempVehicleModelName: data.value,
            tempVehicleModelUuid: data.model_uuid,
            tempVehicleVariantCode: "",
            tempVehicleVariantUuid: "",
            tempVehicleVariantName: "",
            tempVehicleColorCode: "",
            tempVehicleColorName: "",
        });
    };

    onTempVehicleVariantChange = (data: any) => {
        this.setState({
            tempVehicleVariantCode: data.value,
            tempVehicleVariantUuid: data.uuid,
            tempVehicleVariantName: data.name,
            tempVehicleColorCode: "",
            tempVehicleColorName: "",
        });
    };

    onTempVehicleVariantColorChange = (data: any) => {
        this.setState({
            tempVehicleColorCode: data.value,
            tempVehicleColorName: data.children,
        });
    };

    onCreateLeads = async () => {
        try {
            const currentState = this.state;

            if (currentState.tempName === "") throw new Error("Invalid Name");
            if (currentState.tempPhone === "") throw new Error("Invalid Phone");
            if (currentState.tempProvinceCode === "") throw new Error("Invalid Province Code");
            if (currentState.tempProvinceName === "") throw new Error("Invalid Province Name");
            if (currentState.tempCityCode === "") throw new Error("Invalid City Code");
            if (currentState.tempCityName === "") throw new Error("Invalid City Name");
            if (currentState.tempVehicleModelUuid === "") throw new Error("Invalid Vehicle Model Uuid");
            if (currentState.tempVehicleModelName === "") throw new Error("Invalid Vehicle Model Name");
            if (currentState.tempVehicleVariantUuid === "") throw new Error("Invalid Vehicle Variant Uuid");
            if (currentState.tempVehicleVariantCode === "") throw new Error("Invalid Vehicle Variant Code");
            if (currentState.tempVehicleVariantName === "") throw new Error("Invalid Vehicle Variant Name");
            if (currentState.tempVehicleColorCode === "") throw new Error("Invalid Vehicle Color Code");
            if (currentState.tempVehicleColorName === "") throw new Error("Invalid Vehicle Color Name");

            const postDataUpdate = {
                code: currentState.tempCode,
                company_code: currentState.tempCompanyCode,
                data_update: {
                    dealer_code: currentState.tempDealerCode,
                    description: currentState.tempDescription,
                    leads_id: "",
                    leads_status: "CREATED",
                    province_code: currentState.tempProvinceCode,
                    province_name: currentState.tempProvinceName,
                    city_code: currentState.tempCityCode,
                    city_name: currentState.tempCityName,
                    preferred_vehicle_model_uuid: currentState.tempVehicleModelUuid,
                    preferred_vehicle_model_name: currentState.tempVehicleModelName,
                    preferred_vehicle_variant_uuid: currentState.tempVehicleVariantUuid,
                    preferred_vehicle_variant_code: currentState.tempVehicleVariantCode,
                    preferred_vehicle_variant_name: currentState.tempVehicleVariantName,
                    preferred_vehicle_color_code: currentState.tempVehicleColorCode,
                    preferred_vehicle_color_name: currentState.tempVehicleColorName,
                    updated_by: currentState.user,
                },
            };

            const postDataFreeLeads = {
                agentCode: "",
                title: "",
                firstName: currentState.tempName,
                lastName: "",
                phoneNumber: currentState.tempPhone,
                email: "",
                provinceCode: currentState.tempProvinceCode,
                provinceName: currentState.tempProvinceName,
                cityCode: currentState.tempCityCode,
                cityName: currentState.tempCityName,
                vehicleUsage: "",
                purchasePlan: "",
                paymentPlan: "",
                nextTotalVehicleOwnerShip: "",
                hasVehicleLoan: "",
                vehicleOptions: [
                    {
                        brand: {
                            name: currentState.dataOrg.copanDefaultBrand,
                        },
                        model: {
                            uuid: currentState.tempVehicleModelUuid,
                            name: currentState.tempVehicleModelName,
                        },
                        variant: {
                            uuid: currentState.tempVehicleVariantUuid,
                            code: currentState.tempVehicleVariantCode,
                            name: currentState.tempVehicleVariantName,
                        },
                        color: {
                            code: currentState.tempVehicleColorCode,
                            name: currentState.tempVehicleColorName,
                        },
                    },
                ],
                organization: currentState.dataOrg.companyCode.toLowerCase(),
                idCard_number: "",
                driverLicense_number: "",
                notes: currentState.tempDescription,
                price: 0,
                source: `${currentState.user} - ${currentState.tempDealerCode}`,
            };

            this.setState({loading: true});

            worksheetApiService.setToken(this.state.token);
            leadService.setToken();

            const sentLeads = await leadService.addFreeLead(postDataFreeLeads);
            postDataUpdate.data_update.leads_id = sentLeads.data.success.data.documentId;
            await worksheetApiService.updateWorksheet(postDataUpdate);

            this.onResetModal();

            Modal.success({
                title: "Success",
                content: "Success Proses data",
            });

            this.onGetData();
        } catch (e: any) {
            Modal.error({
                title: "Error Validation",
                content: e.toString(),
            });

            await this.promisedSetState({
                loading: false,
            });
        }
    };

    onUpdateWorksheet = async () => {
        try {
            const currentState = this.state;

            const postDataUpdate = {
                code: currentState.tempCode,
                company_code: currentState.tempCompanyCode,
                data_update: {
                    leads_status: currentState.tempLeadsStatus,
                    dealer_code: currentState.tempDealerCode,
                    description: currentState.tempDescription,
                    preferred_engine_number: currentState.tempEngineNumber,
                    contact_sms_result: currentState.tempContactSmsResult,
                    contact_sms_date: currentState.tempContactSmsDate,
                    contact_call_result: currentState.tempContactCallResult,
                    contact_call_date: currentState.tempContactCallDate,
                    contact_visit_result: currentState.tempContactVisitResult,
                    contact_visit_date: currentState.tempContactVisitDate,
                    updated_by: currentState.user,
                },
            };

            this.setState({loading: true});

            worksheetApiService.setToken(this.state.token);
            await worksheetApiService.updateWorksheet(postDataUpdate);

            this.onResetModal();

            Modal.success({
                title: "Success",
                content: "Success Proses data",
            });

            this.onGetData();
        } catch (e: any) {
            Modal.error({
                title: "Error Validation",
                content: e.toString(),
            });

            await this.promisedSetState({
                loading: false,
            });
        }
    };

    onResetModal = async () => {
        await this.promisedSetState({
            loading: false,
            modalView: false,
            modalUpdate: false,
            dataTable: [],
            tempCode: "",
            tempCompanyCode: "",
            tempDealerCode: "",
            tempName: "",
            tempPhone: "",
            tempAddress: "",
            tempProvinceCode: "",
            tempProvinceName: "",
            tempCityCode: "",
            tempCityName: "",
            tempDescription: "",
            tempVehicleColorCode: "",
            tempVehicleColorName: "",
            tempVehicleModelName: "",
            tempVehicleModelUuid: "",
            tempVehicleVariantCode: "",
            tempVehicleVariantName: "",
            tempVehicleVariantUuid: "",
            tempEngineNumber: "",
            tempContactSmsResult: "",
            tempContactSmsDate: dayjs().format("YYYY-MM-DD"),
            tempContactCallResult: "",
            tempContactCallDate: dayjs().format("YYYY-MM-DD"),
            tempContactVisitResult: "",
            tempContactVisitDate: dayjs().format("YYYY-MM-DD"),
        });
    };

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`Worksheet`} breadcrumbMenu={`Worksheet`} defaultSelectedKeys={["m-list-worksheet"]} defaultOpenKeys={["sm-worksheet", "sm-other"]}>
                    <div style={{padding: 20}}>
                        <Form layout={`inline`} style={{marginTop: -3}}>
                            <Form.Item>
                                <i className={`small-text-grey`}>Organization:</i>
                                <br/>
                                <Select style={{float: "left", minWidth: "280px"}} showSearch placeholder="Choose organizationCode" optionFilterProp="children" onChange={(value) => this.onFieldChange("organizationCode", value)} value={this.state.organizationCode}>
                                    {(allowedOrganization ?? []).map((item: any, i: number) => (
                                        <Select.Option key={i} value={item?.otodisCode}>
                                            {item?.companyCode}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item className={"padTop-10"} label="">
                                <i className={`small-text-grey`}>Dealer</i>
                                <br/>
                                <SelectDealer value={this.state.dealerCode} onChangeValue={(value: string) => this.onFieldChange("dealerCode", value)} organizationCode={this.state.organizationCode}/>
                            </Form.Item>
                            <Form.Item className={"padTop-10"} label="">
                                <i className={`small-text-grey`}>Period</i>
                                <br/>
                                <DatePicker picker="month" defaultValue={dayjs(this.state.startDate, "YYYY-MM")} onChange={(event) => this.onFieldChange("startDate", event)} format={"YYYY-MM"}/>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>Leads Status:</i>
                                <br/>
                                <Select style={{float: "left", minWidth: "280px"}} onChange={(value) => this.onFieldChange("leadsStatus", value)} value={this.state.leadsStatus}>
                                    <Select.Option key="ALL" value="ALL">
                                        ALL
                                    </Select.Option>
                                    <Select.Option key="PENDING" value="PENDING">
                                        PENDING
                                    </Select.Option>
                                    <Select.Option key="CREATED" value="CREATED">
                                        CREATED
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>&nbsp;</i>
                                <br/>
                                <Button loading={this.state.loading} onClick={this.onGetData} type={"primary"}>
                                    {" "}
                                    Get Data{" "}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className={`p-10`}>
                        <Table rowKey={"code"} size={"small"} loading={this.state.loading} columns={this.tableColumn} dataSource={this.state.dataTable}/>
                    </div>

                    <Modal
                        open={this.state.modalUpdate}
                        title="Create Leads"
                        onCancel={() => this.setState({modalUpdate: false})}
                        cancelText={"Tutup"}
                        okText={false}
                        footer={[
                            <Button loading={this.state.loading} type={"primary"} key="1" onClick={this.onCreateLeads}>
                                Create Leads
                            </Button>,
                            <Button key="2" onClick={() => this.setState({modalUpdate: false})}>
                                Close
                            </Button>,
                        ]}
                    >
                        <React.Fragment>
                            <div className={`gray-80 mt-20`}>Dealer Code:</div>
                            <Input value={this.state.tempDealerCode} disabled/>
                            <div className={`gray-80 mt-20`}>Phone:</div>
                            <Input value={this.state.tempPhone} disabled/>
                            <div className={`gray-80 mt-20`}>Name:</div>
                            <Input value={this.state.tempName} disabled/>
                            <div className={`gray-80 mt-20`}>Address:</div>
                            <Input.TextArea
                                rows={2} // Set the number of visible rows
                                value={this.state.tempAddress}
                                disabled
                            />
                            <div className={`gray-80 mt-20`}>Province*:</div>
                            <AreaProvinceSelect onAreaProvinceChangeProps={this.onProvinceChange} value={this.state.tempProvinceCode} country="idn"/>
                            <div className={`gray-80 mt-20`}>City*:</div>
                            <AreaCitySelect onAreaCityChangeProps={this.onCityChange} value={this.state.tempCityCode} provinceCode={this.state.tempProvinceCode}/>
                            <div className={`gray-80 mt-20`}>Vehicle Model *:</div>
                            <VehicleModelSelect
                                onVehicleModelChangeProps={this.onTempVehicleModelChange}
                                value={this.state.tempVehicleModelName}
                                companyCode={this.state.dataOrg.copanCode}
                                areaCode={this.state.dataOrg.copanAreaCode}
                                brandCode={this.state.dataOrg.copanDefaultBrand}
                                time={Math.floor(+new Date() / 1000)}
                            />
                            <div className={`gray-80 mt-20`}>Vehicle Variant *:</div>
                            <VehicleVariantSelect
                                onVehicleVariantChangeProps={this.onTempVehicleVariantChange}
                                value={this.state.tempVehicleVariantCode}
                                companyCode={this.state.dataOrg.copanCode}
                                areaCode={this.state.dataOrg.copanAreaCode}
                                cityGroup={this.state.dataOrg.copanAreaCode}
                                brandCode={this.state.dataOrg.copanDefaultBrand}
                                modelCode={this.state.tempVehicleModelName}
                                time={Math.floor(+new Date() / 1000)}
                            />
                            <div className={`gray-80 mt-20`}>Vehicle Color *:</div>
                            <VehicleVariantColorSelect
                                companyCode={this.state.dataOrg.copanCode}
                                areaCode={this.state.dataOrg.copanAreaCode}
                                brandCode={this.state.dataOrg.copanDefaultBrand}
                                modelCode={this.state.tempVehicleModelName}
                                variantCode={this.state.tempVehicleVariantCode}
                                value={this.state.tempVehicleColorName}
                                onVehicleVariantColorChangeProps={this.onTempVehicleVariantColorChange}
                            />
                            <div className={`gray-80 mt-20`}>Description:</div>
                            <Input.TextArea
                                rows={4} // Set the number of visible rows
                                value={this.state.tempDescription}
                                onChange={(event) => this.onFieldChange("tempDescription", event.target.value)}
                                placeholder="description"
                            />
                        </React.Fragment>
                    </Modal>

                    <Modal
                        open={this.state.modalView}
                        title="Update Worksheet"
                        onCancel={() => this.setState({modalView: false})}
                        cancelText={"Tutup"}
                        okText={false}
                        footer={[
                            <Button loading={this.state.loading} type={"primary"} key="1" onClick={this.onUpdateWorksheet}>
                                Update Worksheet
                            </Button>,
                            <Button key="2" onClick={() => this.setState({modalView: false})}>
                                Close
                            </Button>,
                        ]}
                    >
                        <React.Fragment>
                            <Row>
                                <Col xl={{span: 24}} lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                    <div className={`gray-80 mt-20`}>Dealer Code:</div>
                                    <Input value={this.state.tempDealerCode} disabled/>
                                </Col>

                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                    <div className={`gray-80 mt-10`}>Phone:</div>
                                    <Input value={this.state.tempPhone} disabled/>
                                </Col>
                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                    <div className={`gray-80 mt-10`}>Name:</div>
                                    <Input value={this.state.tempName} disabled/>
                                </Col>

                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                    <div className={`gray-80 mt-10`}>Province:</div>
                                    <Input value={this.state.tempProvinceName} disabled/>
                                </Col>
                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                    <div className={`gray-80 mt-10`}>City:</div>
                                    <Input value={this.state.tempCityName} disabled/>
                                </Col>
                                <Col xl={{span: 24}} lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                    <div className={`gray-80 mt-10`}>Address:</div>
                                    <Input.TextArea
                                        rows={2} // Set the number of visible rows
                                        value={this.state.tempAddress}
                                        disabled
                                    />
                                </Col>

                                <Col xl={{span: 24}} lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                    <div className={`gray-80 mt-10`}>Engine Number :</div>
                                    <Input value={this.state.tempEngineNumber} onChange={(event) => this.onFieldChange("tempEngineNumber", event.target.value)} placeholder="Engine Number"/>
                                </Col>
                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                    <div className={`gray-80 mt-10`}>Vehicle Model :</div>
                                    <Input value={this.state.tempVehicleModelName} disabled/>
                                </Col>
                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                    <div className={`gray-80 mt-10`}>Vehicle Variant :</div>
                                    <Input value={this.state.tempVehicleVariantName} disabled/>
                                </Col>
                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                    <div className={`gray-80 mt-10`}>Vehicle Color :</div>
                                    <Input value={this.state.tempVehicleColorName} disabled/>
                                </Col>
                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                    <div className={`gray-80 mt-10`}>Vehicle Color :</div>
                                    <Input value={this.state.tempVehicleColorName} disabled/>
                                </Col>

                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                    <div className={`gray-80 mt-10`}>SMS Result :</div>
                                    <SelectConfigArray value={this.state.tempContactSmsResult} onChange={(value: string) => this.onFieldChange("tempContactSmsResult", value)} dataOption={optSMSResult}/>
                                </Col>
                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                    <div className={`gray-80 mt-10`}>Send SMS Date :</div>
                                    <DatePicker onChange={(date, dateString) => this.onFieldChange("tempContactSmsDate", dateString)} format={"YYYY-MM-DD"} placeholder="Send SMS Date" defaultValue={dayjs(this.state.tempContactSmsDate)}/>
                                </Col>

                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                    <div className={`gray-80 mt-10`}>Call Result :</div>
                                    <SelectConfigArray value={this.state.tempContactCallResult} onChange={(value: string) => this.onFieldChange("tempContactCallResult", value)} dataOption={optCallResult}/>
                                </Col>
                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                    <div className={`gray-80 mt-10`}>Call Date :</div>
                                    <DatePicker onChange={(date, dateString) => this.onFieldChange("tempContactCallDate", dateString)} format={"YYYY-MM-DD"} placeholder="Call Date" defaultValue={dayjs(this.state.tempContactCallDate)}/>
                                </Col>

                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                    <div className={`gray-80 mt-10`}>Visit Result :</div>
                                    <SelectConfigArray value={this.state.tempContactVisitResult} onChange={(value: string) => this.onFieldChange("tempContactVisitResult", value)} dataOption={optVisitResult}/>
                                </Col>
                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                    <div className={`gray-80 mt-10`}>Visited Date :</div>
                                    <DatePicker onChange={(date, dateString) => this.onFieldChange("tempContactVisitDate", dateString)} format={"YYYY-MM-DD"} placeholder="Visited Date" defaultValue={dayjs(this.state.tempContactVisitDate)}/>
                                </Col>

                                <Col xl={{span: 24}} lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                    <div className={`gray-80 mt-10`}>Description:</div>
                                    <Input.TextArea
                                        rows={4} // Set the number of visible rows
                                        value={this.state.tempDescription}
                                        onChange={(event) => this.onFieldChange("tempDescription", event.target.value)}
                                        placeholder="description"
                                    />
                                </Col>
                            </Row>
                        </React.Fragment>
                    </Modal>
                </AppLayout>
            </React.Fragment>
        );
    }
}

export default withAuth(ListWorksheet, 22);
