import React, {Component} from "react"
import {leadService} from "../../services/trimitraIdeal/LeadServive"
import {Button, Col, Divider, Dropdown, Empty, Menu, message, Modal, Row, Spin, Table, Tag, Tooltip} from "antd"
import {CopyOutlined, ScheduleOutlined, WhatsAppOutlined} from "@ant-design/icons"
import dayjs from "dayjs"
import WaChat from "./WaChat"

class LeadDetail extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            processingFetchData: false,
            dataDetailLead: []
        };
    }

    async componentDidMount() {
        await this.fetchIdealtData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.organization_code !== prevProps.organization_code || this.props.agent_code !== prevProps.agent_code) {
            await this.fetchIdealtData();
        }
    }

    fetchIdealtData = async () => {
        if (this.props.organization_code && this.props.agent_code) {
            await this.promisedSetState({processingFetchData: true, dataDetailLead: []});
            try {
                await leadService.setToken();
                const fetchResult = await leadService.getLeads({
                    organizationCode: this.props.organization_code.toLowerCase(),
                    agentCode: this.props.agent_code
                });
                await this.promisedSetState({
                    processingFetchData: false,
                    dataDetailLead: fetchResult?.data?.success?.data,
                });
            } catch (e) {
                await this.promisedSetState({
                    processingFetchData: false,
                    dataDetailLead: [],
                });
            }
        }
    }

    copyResumeFollowUp = async () => {
        let stringFollowUp = "";
        for (const item of this.state.dataDetailLead) {
            if (item.followUp) {
                const phoneHide = item.phoneNumber?.substring(0, 4) + '****' + item.phoneNumber.substring(8)
                stringFollowUp += item.firstName?.toUpperCase() + " " + item.lastName?.toUpperCase() + " - " + phoneHide + "\n"
            }
        }

        if (!stringFollowUp) {
            await navigator.clipboard.writeText("");
            message.error("tidak ada data followup yang aktif");
        } else {
            await navigator.clipboard.writeText("Butuh FollowUp \n" + stringFollowUp);
            message.info("Resume Berhasil di Copy");
        }
    }

    showWaConf = async (phoneNumber: any, agentWhatsappConversations: any) => {
        this.setState({
            modalWaConf: true,
            tempPhoneNumber: phoneNumber,
            tempWaConv: agentWhatsappConversations
        })
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve))

    render() {
        return (
            <React.Fragment>
                <div style={this.state.processingFetchData ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <Row>
                    <Col xs={24} xl={24} style={(this.state.dataDetailLead.length < 1 && !this.state.processingFetchData) ? {} : {display: 'none'}}>
                        <Empty/>
                    </Col>
                    <Col xs={24} xl={24} style={(this.state.dataDetailLead.length > 0 && !this.state.processingFetchData) ? {} : {display: 'none'}}>
                        <Table pagination={{pageSize: 1000}} scroll={{x: 'calc(500px + 50%)'}} dataSource={this.state.dataDetailLead} columns={[
                            {
                                title: 'Action',
                                width: 100,
                                dataIndex: 'phoneNumber',
                                render: ((value: number, row: any) => {
                                    const listPhoneAgent: any = [];
                                    for (const item of (this.state.dataDetailUser?.phones ?? [])) {
                                        listPhoneAgent.push(item.phone);
                                    }
                                    const menu = (
                                        <Menu>
                                            <Menu.Item key={1}>
                                                <span onClick={event => this.showWaConf(row.phoneNumber, row?.agentWhatsappConversations)} style={{cursor: "pointer"}}> <WhatsAppOutlined/> Lihat Percakapan WA</span>
                                            </Menu.Item>
                                        </Menu>
                                    );

                                    return <React.Fragment>
                                        <span className={`font-90`}>
                                            <Dropdown overlay={menu} placement="bottomLeft" arrow>
                                                <Button size={"small"} type={"primary"}> <ScheduleOutlined/> Aksi</Button>
                                            </Dropdown>
                                        </span>
                                    </React.Fragment>
                                })
                            }, {
                                title: <>
                                    FollowUp
                                    <Tooltip title="Click untuk copy resume followUp" color={'cyan'}>
                                        &nbsp; <CopyOutlined className={'small-avatar-link'} onClick={event => this.copyResumeFollowUp()}/>
                                    </Tooltip>
                                </>,
                                dataIndex: 'followUp',
                                width: 140,
                                render: ((value: number, row: any) => {
                                    return <React.Fragment>
                                        <span className={`gray-80`}>{(value) ? <Tag style={{fontSize: "80%"}} color="blue">True</Tag> : <Tag style={{fontSize: "80%"}} color="red">False</Tag>} </span><br/>
                                        <span className={`gray-80`}>Level: {row.statusLevel}</span>
                                    </React.Fragment>
                                })
                            }, {
                                title: 'Contact',
                                dataIndex: 'firstName',
                                width: 300,
                                render: ((value: string, row: any) => {
                                    const listPhoneAgent: any = [];
                                    for (const item of (this.state.dataDetailUser?.phones ?? [])) {
                                        listPhoneAgent.push(item.phone);
                                    }
                                    let whatsappLogo = <Tooltip title="Send Success" color={'#1f8646'}><WhatsAppOutlined style={{color: '#1f8646', cursor: "help"}}/></Tooltip>;
                                    if (row?.whatsapp?.failed) {
                                        whatsappLogo = <Tooltip title="Send Failed" color={'#cc2929'}><WhatsAppOutlined style={{color: '#cc2929', cursor: "help"}}/></Tooltip>;
                                    }
                                    return <>
                                        <span className={`gray-80`}>{row?.title}</span> <span className={`font-90`}>{value}</span> <span className={`font-90`}>{row.lastName}</span> <br/>
                                        <span onClick={event => this.showWaConf(row.phoneNumber, row?.agentWhatsappConversations)} style={{cursor: "pointer"}} className={`blue-85`}>{whatsappLogo} {row?.phoneNumber}</span> <span className={`gray-80`}>{row?.email}</span> <br/>
                                        <span className={`gray-80`}>{row?.firstMessageDirection}</span>
                                    </>
                                })
                            }, {
                                title: 'domicile',
                                dataIndex: 'domicile',
                                width: 129,
                                render: ((value: number, row: any) => {
                                    return <>
                                        <span className={`gray-80`}>{row?.domicile?.provinceName}</span><br/>
                                        <span className={`gray-80`}>{row?.domicile?.cityName}</span>
                                    </>
                                })
                            }, {
                                title: 'vehicle',
                                dataIndex: 'vehicle',
                                width: 420,
                                render: ((value: number, row: any) => {
                                    return <>
                                        {(row?.vehicleOptions ?? []).map((item: any, i: number) =>
                                            <React.Fragment key={i}>
                                                <span className={`gray-70`}>{item?.variant?.name}</span> <span className={`gray-70`}>{item?.color?.name}</span><br/>
                                            </React.Fragment>
                                        )}
                                    </>
                                })
                            }, {
                                title: 'Create Time',
                                dataIndex: 'createdAt',
                                key: 'createdAt',
                                width: 180,
                                render: ((value: number, row: any) => {
                                    return <>
                                        <span className={`font-90`}>{dayjs(value).format("YYYY-MM-DD")}</span> <span className={`gray-80`}>{dayjs(value).format("HH:mm:ss")}</span> <span className={`gray-60`}>{row?.paymentPlan}</span><br/>
                                        <span className={`gray-60`}>{(row?.source === 'https://crm.amarta.vip/newlead/amartahonda') ? "crm.amarta.vip" : row?.source}</span>
                                    </>
                                })
                            }, {
                                title: <>Last Update <i style={{fontSize: "65%"}}>Upload Chat Time</i></>,
                                dataIndex: 'createdAt',
                                key: 'createdAt',
                                width: 180,
                                render: ((value: number, row: any) => {
                                    if (row?.agentWhatsappConversations) {
                                        const lastElement = row?.agentWhatsappConversations[row?.agentWhatsappConversations.length - 1];
                                        if (lastElement?.createdAt) {
                                            return <>
                                                <span className={`font-90`}>{dayjs(lastElement?.createdAt).format("YYYY-MM-DD")}</span> <span className={`gray-80`}>{dayjs(lastElement?.createdAt).format("HH:mm:ss")}</span><br/>
                                            </>
                                        } else {
                                            return <span>-</span>
                                        }
                                    } else {
                                        return <span>-</span>
                                    }
                                })
                            }, {
                                title: 'FollowUp Schedule',
                                dataIndex: 'followUpScheduledAt',
                                key: 'followUpScheduledAt',
                                width: 180,
                                render: ((value: number, row: any) => {
                                    return <>
                                        {(() => {
                                            if (value) {
                                                return (
                                                    <>
                                                        <span className={`font-90`}>{dayjs(value).format("YYYY-MM-DD")}</span> <br/> <span className={`gray-80`}>{dayjs(value).format("HH:mm:ss")}</span>
                                                    </>
                                                )
                                            }
                                            return "-";
                                        })()}
                                    </>
                                })
                            },
                        ]}/>
                    </Col>
                </Row>

                <Modal
                    open={this.state.modalWaConf}
                    style={{top: 10}}
                    title="Detail Chat"
                    onCancel={() => this.setState({modalWaConf: false})}
                    cancelText={'Close'} okText={false}
                    footer={[<Button key="1" onClick={() => this.setState({modalWaConf: false})}>Close</Button>]}
                >
                    <>
                        <Divider/>
                        <WaChat phoneNumber={this.state.tempPhoneNumber} organizationCode={this.props.organization_code.toLowerCase()} waConv={this.state.tempWaConv}/>
                    </>
                </Modal>
            </React.Fragment>
        );
    }
}

export default LeadDetail;