import React, {Component} from "react";
import Cookies from "universal-cookie";
import {Empty, Row, Spin} from "antd";
import {leadService} from "../../services/trimitraIdeal/LeadServive";

class DetailAppboardingAffiliate extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            uid: "",
            processingFetch: false,
            contactsExported: 0,
            contactsBecomeLeads: 0,
            contactsBecomeLeadsPercentage: 0,
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            uid: this.props.uid,
        });
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.time !== prevProps.time) || (this.props.uid !== prevProps.uid) || (this.props.agentCode !== prevProps.agentCode)) {
            await this.promisedSetState({
                uid: this.props.uid,
            });
            await this.fetchData();
        }
    }

    fetchData = async () => {
        this.setState({
            processingFetch: true,
            contactsBecomeLeadsPercentage: 0,
            contactsExported: 0,
            contactsBecomeLeads: 0,
        });

        try {
            const fetchResult = await leadService.getPercentageContactBecomeLead({agentCode: this.props.agentCode});
            this.setState({
                processingFetch: false,
                contactsBecomeLeadsPercentage: Math?.floor(fetchResult?.data?.success?.data?.contactsBecomeLeadsPercentage),
                contactsExported: fetchResult?.data?.success?.data?.contactsExported,
                contactsBecomeLeads: fetchResult?.data?.success?.data?.contactsBecomeLeads,
            });
        } catch (e) {
            this.setState({
                processingFetch: false,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.processingFetch ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={(!this.state.processingFetch && !this.state.contactsExported) ? {} : {display: 'none'}}>
                    <Empty/>
                </div>
                <div style={!this.state.processingFetch && this.state.contactsExported ? {} : {display: 'none'}}>
                    <Row className={`pt-10`}>
                        <table>
                            <tr>
                                <td>Contact Exported</td>
                                <td>: {this.state.contactsExported}</td>
                            </tr>
                            <tr>
                                <td>Contact Became Lead</td>
                                <td>: {this.state.contactsBecomeLeads}</td>
                            </tr>
                            <tr>
                                <td>Percentage</td>
                                <td>: {this.state.contactsBecomeLeadsPercentage} %</td>
                            </tr>
                        </table>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default DetailAppboardingAffiliate;