import React, {Component} from 'react';
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import {ListTestDriveActivityStates} from "./types/ListTestDriveActivityTypes";
import {SearchOutlined, DownOutlined, CopyOutlined, ScheduleOutlined, AuditOutlined, FormOutlined, BellOutlined, UserOutlined, UserSwitchOutlined, FlagOutlined} from "@ant-design/icons";
import {activityApiService} from "../../services/amartaVip/ActivityApiService";
import DatePicker from "antd/lib/date-picker";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {Form, Select, Dropdown, Menu, Input, Tooltip, Table, Button, Divider, Tag} from "antd";
import Modal from "antd/lib/modal";
import DetailFormTestDrive from "../../component/detailData/DetailFormTestDrive";
import UpdateTestDriveData from "../../component/detailData/UpdateTestDriveData";
import DetailCustomerVehicleSurvey from "../../component/detailData/DetailCustomerVehicleSurvey";
import {allowedOrganization} from "../../config/appDataConfig/organizationCondig";

dayjs.extend(customParseFormat);

export interface ListTestDriveActivityProps {
}

class ListTestDriveActivity extends Component<ListTestDriveActivityProps & any, ListTestDriveActivityStates> {
    private cookies = new Cookies();
    private tableColumn: any = [
        {
            title: 'Code',
            dataIndex: 'code',
            render: ((value: any, row: any) => {
                return <React.Fragment>
                    <span onClick={event => this.showApprovalDetail(value)} style={{color: "#2f57da", fontWeight: 500, cursor: "pointer"}} className={`font-90`}>{value}</span>
                    <Tooltip title="Click untuk copy code" color={'cyan'}>
                        &nbsp; <CopyOutlined className={'small-avatar-link'} onClick={event => this.copyToClipboard(value)}/>
                    </Tooltip><br/>

                    <div style={row.activity_status === "ACTIVE" ? {} : {display: 'none'}}>
                        <Tag style={{fontSize: "70%", display: "inline", padding: 2, color: "#fff", backgroundColor: "#7c7d80"}}>Waiting For Process</Tag>
                        <Tag style={row.customer_survey_code ? {fontSize: "70%", display: "inline", padding: 2, color: "#fff", backgroundColor: "#353cc4"} : {display: 'none'}}>Surveyed</Tag>
                    </div>
                    <div style={row.activity_status === "REJECTED" ? {} : {display: 'none'}}>
                        <Tag style={{fontSize: "70%", display: "inline", padding: 2, color: "#fff", backgroundColor: "#962929"}}>Rejected</Tag>
                        <Tag style={row.customer_survey_code ? {fontSize: "70%", display: "inline", padding: 2, color: "#fff", backgroundColor: "#353cc4"} : {display: 'none'}}>Surveyed</Tag>
                    </div>
                    <div style={row.activity_status === "APPROVED" ? {} : {display: 'none'}}>
                        <Tag style={{fontSize: "70%", display: "inline", padding: 2, color: "#fff", backgroundColor: "#353cc4"}}>Approved</Tag>
                        <Tag style={row.customer_survey_code ? {fontSize: "70%", display: "inline", padding: 2, color: "#fff", backgroundColor: "#353cc4"} : {display: 'none'}}>Surveyed</Tag>
                    </div>
                    <div style={row.activity_status === "DOINGTESTDRIVE" ? {} : {display: 'none'}}>
                        <Tag style={{fontSize: "70%", display: "inline", padding: 2, color: "#fff", backgroundColor: "#353cc4"}}>Doing Test Drive</Tag>
                        <Tag style={row.customer_survey_code ? {fontSize: "70%", display: "inline", padding: 2, color: "#fff", backgroundColor: "#353cc4"} : {display: 'none'}}>Surveyed</Tag>
                    </div>
                    <div style={row.activity_status === "COMPLETED" ? {} : {display: 'none'}}>
                        <Tag style={{fontSize: "70%", display: "inline", padding: 2, color: "#fff", backgroundColor: "#2fa157"}}>Completed</Tag>
                        <Tag style={row.customer_survey_code ? {fontSize: "70%", display: "inline", padding: 2, color: "#fff", backgroundColor: "#353cc4"} : {display: 'none'}}>Surveyed</Tag>
                    </div>
                </React.Fragment>
            })
        },
        {
            title: 'Client',
            dataIndex: 'client_name',
            render: ((value: number, row: any) => {
                return <React.Fragment>
                    <span style={{color: "#da2f2f"}} className={`font-90`}>{value}</span><br/>
                    <span className={`gray-80`}>{row.client_phone}</span>
                </React.Fragment>
            })
        },
        {
            title: 'Vehicle',
            dataIndex: 'variant_name',
            render: ((value: number, row: any) => {
                return <React.Fragment>
                    <span style={{color: "#da2f2f"}} className={`font-90`}>{value}</span><br/>
                    <span className={`gray-80`}>{row.activity_name}</span>
                </React.Fragment>
            })
        },
        {
            title: 'Booking & Create Time',
            dataIndex: 'client_name',
            render: ((value: number, row: any) => {
                return <React.Fragment>
                    <span className={`gray-80`}>
                        <Tooltip title="Booking Time" color={'blue'}>
                            <BellOutlined style={{cursor: "help"}}/> &nbsp;
                        </Tooltip>
                        {dayjs(row?.activity_date).format("YYYY-MM-DD HH:mm")}
                    </span><br/>
                    <span className={`gray-80`}>
                        <Tooltip title="Create Time" color={'blue'}>
                            <FormOutlined style={{cursor: "help"}}/> &nbsp;
                        </Tooltip>
                        {dayjs(row?.created_time).format("YYYY-MM-DD HH:mm:ss")}
                    </span>
                </React.Fragment>
            })
        },
        {
            title: 'Agent',
            dataIndex: 'client_name',
            render: ((value: number, row: any) => {
                return <React.Fragment>
                    <span className={`gray-80`}>
                        <Tooltip title="Create Agent" color={'blue'}>
                            <UserOutlined style={{cursor: "help"}}/> &nbsp;
                        </Tooltip>
                        {row?.agen_code} - {row?.agen_name}
                    </span><br/>
                    <span className={`gray-80`}>
                        <Tooltip title="Test Drive Agent" color={'blue'}>
                            <UserSwitchOutlined style={{cursor: "help"}}/> &nbsp;
                        </Tooltip>
                        {row?.test_drive_agen_code} - {row?.test_drive_agen_name}
                    </span><br/>
                </React.Fragment>
            })
        },
        {
            title: 'Test Drive Time',
            dataIndex: 'client_name',
            render: ((value: number, row: any) => {
                return <React.Fragment>
                    <span className={`gray-80`}>
                        <Tooltip title="Start TestDrive Time" color={'blue'}>
                            <FlagOutlined style={{cursor: "help"}}/> &nbsp;
                        </Tooltip>
                        {(row?.test_drive_start_time) ? dayjs(row?.activity_date).format("YYYY-MM-DD HH:mm") : ""}
                    </span><br/>
                    <span className={`gray-80`}>
                        <Tooltip title="End TestDrive Time" color={'blue'}>
                            <FlagOutlined style={{cursor: "help"}}/> &nbsp;
                        </Tooltip>
                        {(row?.test_drive_end_time) ? dayjs(row?.test_drive_end_time).format("YYYY-MM-DD HH:mm") : ""}
                    </span>
                </React.Fragment>
            })
        },
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: ((row: any) => {
                const menu = (
                    <Menu>
                        <Menu.Item key={1}>
                            <span onClick={event => this.showApprovalDetail(row?.code)} style={{cursor: "pointer"}}> <SearchOutlined/> Lihat Detail TestDrive</span>
                        </Menu.Item>
                        <Menu.Item key={2} style={row.customer_survey_code ? {} : {display: 'none'}}>
                            <span onClick={event => this.showDetailSurveyed(row?.customer_survey_code)}><SearchOutlined/> Lihat Detail Survey </span>
                        </Menu.Item>
                        <Menu.Item key={3}>
                            <span onClick={event => this.showApprovalOrReject(row?.code)}><AuditOutlined/> Update Data </span>
                        </Menu.Item>
                    </Menu>
                );

                return <React.Fragment>
                    <span className={`font-90`}>
                        <Dropdown overlay={menu} placement="bottomLeft" arrow>
                          <Button size={"small"} type={"primary"}> <ScheduleOutlined/> Aksi</Button>
                        </Dropdown>
                    </span>
                </React.Fragment>
            })
        },
    ];

    constructor(props: ListTestDriveActivityProps & any) {
        super(props);

        this.state = {
            token: this.cookies.get('_vip-t') ?? "",
            organization: null,
            startDate: dayjs().startOf('month'),
            endDate: dayjs(),
            processingFetchTableData: false,
            pageSize: 100,
            dataTable: [],
            searchType: "date",
            searchApplication: "",
            chosenAppCode: "",
            showModalDetail: false,
            showModalApproveOrReject: false,
            chosenSurveyCode: "",
            showModalSurvey: false,
        }
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    copyToClipboard = async (data: any) => {
        await navigator.clipboard.writeText(data);
    }

    onFieldChange = async <T extends keyof Pick<any, "organization" | "searchType" | "searchApplication" | "startDate" | "endDate">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "organization":
                currentState.organization = value;
                break;
            case "searchType":
                currentState.searchType = value;
                break;
            case "searchApplication":
                currentState.searchApplication = value;
                break;
            case "startDate":
                currentState.startDate = value;
                break;
            case "endDate":
                currentState.endDate = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    fetchData = async () => {
        if (this.state.searchType === "code") {
            Modal.error({
                title: 'Error',
                content: 'search by code Under Construct'
            });
            return;
        }

        if (this.state.searchType === "date") {
            if (!this.state.organization) {
                Modal.error({
                    title: 'Error',
                    content: 'Organization Required'
                });
                return;
            }
        }

        await this.promisedSetState({
            processingFetchTableData: true,
            dataTable: [],
        });

        try {
            await activityApiService.setToken(this.state.token);
            const fetchResult = await activityApiService.getActivityList({
                activityType: "testdrive",
                companyCode: this.state.organization,
                startDate: dayjs(this.state.startDate).format("YYYY-MM-DD"),
                endDate: dayjs(this.state.endDate).format("YYYY-MM-DD"),
            });
            await this.promisedSetState({
                processingFetchTableData: false,
                dataTable: fetchResult?.data?.data,
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetchTableData: false,
                dataTable: [],
            });
        }
    }

    onChangeTable = (pagination: any, filters: any, sorter: any) => {
        if (pagination?.pageSize) {
            this.setState({
                pageSize: pagination?.pageSize,
            });
        }
    }

    showDetailSurveyed = (surveyedCode: string) => {
        this.setState({
            chosenSurveyCode: surveyedCode,
            showModalSurvey: true,
        });
    }

    showApprovalDetail = (applyCode: string) => {
        this.setState({
            chosenAppCode: applyCode,
            showModalDetail: true,
        });
    }

    showApprovalOrReject = (applyCode: string) => {
        this.setState({
            chosenAppCode: applyCode,
            showModalApproveOrReject: true,
        });
    }

    onCLoseModalUpdateAssessment = async (data: any) => {
        if (data.reload) {
            await this.fetchData();
        }
        this.setState({
            showModalApproveOrReject: false,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`Activity / Test Drive Activity`} breadcrumbMenu={`Test Drive`} defaultSelectedKeys={['m-test-drive']} defaultOpenKeys={['sm-activity', 'sm-testdrive-activity']}>

                    <div className={`p-5`}>
                        <Form layout={`inline`}>
                            <Form layout={`inline`} style={this.state.searchType === "code" ? {} : {display: 'none'}}>
                                <Form.Item className={'pt-3'} style={{width: 230}}>
                                    <i className={`small-text-grey`}>Kode Application</i>
                                    <Input value={this.state.searchApplication} onChange={event => this.onFieldChange("searchApplication", event.target.value)} placeholder="Kode Application"/>
                                </Form.Item>
                            </Form>
                            <Form layout={`inline`} style={this.state.searchType === "date" ? {} : {display: 'none'}}>
                                <Form.Item className={'pt-3'} style={{width: 230}}>
                                    <i className={`small-text-grey`}>Organization:</i>
                                    <Select
                                        style={{minWidth: 200}}
                                        showSearch
                                        placeholder="Choose Organization"
                                        optionFilterProp="children"
                                        onChange={value => this.onFieldChange("organization", value)}
                                        value={this.state.organization}
                                    >
                                        {(allowedOrganization ?? []).map((item: any, i: number) =>
                                            <Select.Option key={i} value={item?.amartaVipCode}>{item?.companyCode}</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                                <Form.Item className={'pt-3'} label="">
                                    <i className={`small-text-grey`}>Start Date</i><br/>
                                    <DatePicker defaultValue={dayjs(this.state.startDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("startDate", event)} format={'YYYY-MM-DD'}/>
                                </Form.Item>
                                <Form.Item className={'pt-3'} label="">
                                    <i className={`small-text-grey`}>End Date</i><br/>
                                    <DatePicker defaultValue={dayjs(this.state.endDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("endDate", event)} format={'YYYY-MM-DD'}/>
                                </Form.Item>
                            </Form>

                            <Form.Item className={'pt-5'}>
                                <Dropdown.Button
                                    type="primary"
                                    style={{marginTop: 22}}
                                    onClick={this.fetchData}
                                    icon={<DownOutlined/>}
                                    trigger={['click']}
                                    overlay={(
                                        <Menu>
                                            <Menu.Item key={1} onClick={event => this.onFieldChange("searchType", "date")}>
                                                Pencarian Tanggal
                                            </Menu.Item>
                                            <Menu.Item key={2} onClick={event => this.onFieldChange("searchType", "code")}>
                                                Pencarian Kode Application
                                            </Menu.Item>
                                        </Menu>
                                    )}
                                ><SearchOutlined/> Cari Data</Dropdown.Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <Table
                        rowKey={'code'}
                        size={'small'}
                        scroll={{x: 'calc(500px + 50%)'}}
                        loading={this.state.processingFetchTableData}
                        columns={this.tableColumn}
                        dataSource={this.state.dataTable}
                        pagination={{pageSize: this.state.pageSize}}
                        onChange={this.onChangeTable}
                    />
                </AppLayout>

                <Modal
                    open={this.state.showModalDetail}
                    title={`Detail Test Drive ` + this.state.chosenAppCode}
                    onCancel={() => this.setState({showModalDetail: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button key="back" type="default" onClick={() => this.setState({showModalDetail: false})}>
                            Close
                        </Button>,
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <DetailFormTestDrive organization={this.state.organization} chosenAppCode={this.state.chosenAppCode} time={Math.floor(+new Date() / 1000)}/>
                    </React.Fragment>
                </Modal>

                <Modal
                    open={this.state.showModalApproveOrReject}
                    title={`Update Data`}
                    onCancel={() => this.setState({showModalApproveOrReject: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[]}
                >
                    <React.Fragment>
                        <Divider/>
                        <UpdateTestDriveData onCloseModal={this.onCLoseModalUpdateAssessment} organization={this.state.organization} chosenAppCode={this.state.chosenAppCode} time={Math.floor(+new Date() / 1000)}/>
                    </React.Fragment>
                </Modal>

                <Modal
                    width={'80%'}
                    open={this.state.showModalSurvey}
                    title={`Detail Survey ` + this.state.chosenSurveyCode}
                    onCancel={() => this.setState({showModalSurvey: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[<Button key="back" type="default" onClick={() => this.setState({showModalSurvey: false})}>Close</Button>]}
                >
                    <React.Fragment>
                        <Divider/>
                        <DetailCustomerVehicleSurvey organization={this.state.organization} chosenAppCode={this.state.chosenSurveyCode} time={Math.floor(+new Date() / 1000)}/>
                    </React.Fragment>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withAuth(ListTestDriveActivity, 12);
