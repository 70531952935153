import React, {Component} from "react";
import Cookies from "universal-cookie";
import {userServices} from "../../services/amartaVip/UserService"
import {groupServices} from "../../services/amartaVip/GroupService";
import {Button, Divider, Modal, Select, Form} from "antd";

class UpdateUserSchemaTarget extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            loading: false,
            masterSchema: null,
            dataListSchema: [],
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        this.fetchMasterTargetSchema();
        this.setState({masterSchema: null})
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.data.agent_code !== prevProps.data.agent_code) || (this.props.time !== prevProps.time)) {
            this.setState({masterSchema: null})
        }
    }

    fetchMasterTargetSchema = async () => {
        await this.promisedSetState({fetching: true});
        try {
            await groupServices.setToken(this.state.token);
            const fetchResult = await groupServices.getTargetSchema({});
            this.setState({
                fetching: false,
                dataListSchema: fetchResult.data.data,
            })
        } catch (e) {
            this.setState({
                fetching: false,
                dataListSchema: [],
            })
        }
    }

    onCancel = async () => {
        this.props.onCloseModal({reload: false});
    }

    onSubmit = async () => {
        if (!this.state.masterSchema) {
            return Modal.error({
                title: 'Proses Gagal',
                content: 'Please select master schema'
            });
        }

        await this.promisedSetState({loading: true})

        try {
            await userServices.setToken(this.state.token)
            await userServices.updateUserInternal({
                type: "update_default_target_schema",
                uid: this.props.data.uid,
                user_type: this.props.data.user_type,
                code: this.state.masterSchema
            }).then(async successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'successfully updated '
                })
                this.setState({
                    loading: false,
                })
                this.props.onCloseModal({reload: true});
            })
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            })
            this.setState({loading: false,})
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "masterSchema">>(target: T, value: any) => {
        const currentState: any = {...this.state}
        switch (target) {
            case "masterSchema":
                currentState.masterSchema = value
                break
        }
        await this.promisedSetState({
            ...currentState,
        })
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <>
                <Form.Item>
                    <i className={`text-small-grey-nopadding`}>Master Skema *</i>
                    <Select style={{minWidth: 200}} showSearch optionFilterProp="children" onChange={value => this.onFieldChange("masterSchema", value)} value={this.state.masterSchema}>
                        {(this.state.dataListSchema ?? []).map((item: any, i: number) =>
                            <Select.Option key={i} value={item?.code}>{item?.name}</Select.Option>
                        )}
                    </Select>
                </Form.Item>

                <div style={{paddingTop: 20, textAlign: "right"}}>
                    <Divider/>
                    <Button loading={this.state.loading} onClick={event => this.onSubmit()} type="primary" style={{marginRight: 7}}>Update Data</Button>
                    <Button loading={this.state.loading} onClick={event => this.onCancel()} style={{marginRight: 7}}>Cancel</Button>
                </div>
            </>
        );
    }
}

export default UpdateUserSchemaTarget;