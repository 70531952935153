import React, {Component} from "react";
import {Spin} from "antd";
import {activityApiService} from "../../services/amartaVip/ActivityApiService";
import Cookies from "universal-cookie";
import {IdcardOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

class DetailFormTestDrive extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            chosenAppCode: "",
            processingFetch: false,
            dataApplication: {},
            dataCv: {},
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            chosenAppCode: this.props.chosenAppCode,
        });
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.time !== prevProps.time) || (this.props.chosenAppCode !== prevProps.chosenAppCode)) {
            await this.promisedSetState({
                chosenAppCode: this.props.chosenAppCode,
            });
            await this.fetchData();
        }
    }

    fetchData = async () => {
        await this.promisedSetState({
            processingFetch: true,
            dataApplication: {},
        });

        try {
            await activityApiService.setToken(this.state.token);
            const fetchResult = await activityApiService.getActivityList({
                activityType: "testdrive",
                code: this.state.chosenAppCode,
            });
            await this.promisedSetState({
                processingFetch: false,
                dataApplication: fetchResult?.data?.data[0],
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetch: false,
                dataApplication: {},
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.processingFetch ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={!this.state.processingFetch ? {} : {display: 'none'}}>
                    <div className={`gray-80 pt-5`}>Client Name:</div>
                    <span className={`font-90`}>{this.state.dataApplication.client_name}</span><br/>
                    <div className={`gray-80 pt-5`}>TestDrive Time:</div>
                    <span className={`font-90`}>{this.state.dataApplication.activity_date}</span><br/>
                    <div className={`gray-80 pt-5`}>PhoneNumber:</div>
                    <span className={`font-90`}>{this.state.dataApplication.client_phone}</span><br/>
                    <div className={`gray-80 pt-5`}>Client Email:</div>
                    <span className={`font-90`}>{this.state.dataApplication.client_email}</span><br/>
                    <div className={`gray-80 pt-5`}>Brand:</div>
                    <span className={`font-90`}>{this.state.dataApplication.brand_name}</span><br/>
                    <div className={`gray-80 pt-5`}>Model:</div>
                    <span className={`font-90`}>{this.state.dataApplication.model_name}</span><br/>
                    <div className={`gray-80 pt-5`}>Variant:</div>
                    <span className={`font-90`}>{this.state.dataApplication.variant_name}</span><br/>
                    <div className={`gray-80 pt-5`}>Agent Code - Name:</div>
                    <span className={`font-90`}>{this.state.dataApplication.agen_code} - {this.state.dataApplication.agen_name}</span><br/>
                    <div className={`gray-80 pt-5`}>Activity Note:</div>
                    <span className={`font-90`}>{this.state.dataApplication.activity_name}</span><br/>
                    <div className={`gray-80 pt-5`}>Driving License:</div>
                    <span className={`font-90`}>
                        {this.state.dataApplication?.document_asset?.[0]?.code} &nbsp;
                        <a href={this.state.dataApplication?.document_asset?.[0]?.url} target={`_blank`}>
                            <IdcardOutlined className={'small-avatar-link'}/>
                        </a>
                    </span><br/>
                    <div className={`gray-80 pt-5`}>Created Time:</div>
                    <span className={`font-90`}>{dayjs(this.state.dataApplication.created_time).format("YYYY-MM-DD HH:mm:ss")}</span><br/><br/>

                    <div className={`gray-80 pt-5`}>Test Drive Status:</div>
                    <span className={`font-90`}>{this.state.dataApplication.test_drive_status}</span><br/>
                    <div className={`gray-80 pt-5`}>Test Drive Time:</div>
                    <span className={`font-90`}>{dayjs(this.state.dataApplication.test_drive_start_time).format("YYYY-MM-DD HH:mm:ss")} until {dayjs(this.state.dataApplication.test_drive_end_time).format("YYYY-MM-DD HH:mm:ss")}</span><br/>
                    <div className={`gray-80 pt-5`}>Test Drive Agent:</div>
                    <span className={`font-90`}>{this.state.dataApplication.test_drive_agen_code} - {this.state.dataApplication.test_drive_agen_name}</span><br/>
                    <div className={`gray-80 pt-5`}>Test Drive Note:</div>
                    <span className={`font-90`}>{this.state.dataApplication.test_drive_note}</span><br/>
                </div>
            </React.Fragment>
        );
    }
}

export default DetailFormTestDrive;
