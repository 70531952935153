import React, {Component} from "react";
import {leadService} from "../../services/trimitraIdeal/LeadServive";
import {Empty, Spin, Divider} from "antd";

class WaChat extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            textData: [],
            loading: false
        };
    }

    componentDidMount() {
        this.fetchTextData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.phoneNumber !== prevProps.phoneNumber) {
            await this.fetchTextData();
        }
    }

    fetchTextData = async () => {
        this.setState({
            textData: [],
            loading: true
        })
        try {
            const fetchResult = await leadService.getWaConversation({
                organizationCode: this.props.organizationCode?.toLowerCase(),
                phoneNumber: this.props.phoneNumber
            });

            const conv = [];
            for (const item of fetchResult?.data?.success?.data || []) {
                for (const x of item?.conversations) {
                    conv.push(x)
                }
            }


            this.setState({
                loading: false,
                textData: conv
            })
        } catch (e) {
            this.setState({
                loading: false,
                textData: []
            })
        }
    };

    render() {
        return (
            <>
                File Percakapan:<br/>
                {(this.props.waConv ?? []).map((item: any, i: number) =>
                    <span key={i}>
                        <a style={{fontSize:'50%'}} target={"_blank"} rel={"noreferrer"} href={item.bucketStorage}>{item.bucketStorage}</a><br/>
                    </span>
                )}<br/><br/>

                <div style={this.state.loading ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                    <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                </div>
                <div style={!this.state.loading && this.state?.textData?.length < 1 ? {} : {display: 'none'}}>
                    <Empty/>
                </div>
                <div id="chat-container" style={!this.state.loading ? {maxHeight: "500px", overflow: "scroll"} : {display: 'none'}}>
                    {(this.state.textData ?? []).map((item: any, i: number) =>
                        <div>
                            {item.name} <span style={{float: "right", paddingRight: 5}} className={`gray-80`}>{item.datetime?.slice(0, 16)?.replace("T", " ")}</span><br/><span className={`gray-80`}>{item.message}</span>
                            <Divider style={{marginTop: 5, marginBottom: 5}}/>
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default WaChat;