import axios from "axios";
import {baseUrlFaceRecognition, xApiKeyAmartaVip} from "../../config/apiConfig/apiConfig";

class FaceRecognitionService {
    private axios = axios.create({
        baseURL: baseUrlFaceRecognition,
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    public async getUserFace(params: { uid: string, startDate: string, endDate: string }) {
        const queries = {
            ...(params?.uid && {
                uid: params.uid,
            }),
            ...(params?.startDate && {
                start_date: params.startDate,
            }),
            ...(params?.endDate && {
                end_date: params.endDate,
            }),
        }
        try {
            return await this.axios.get<any>(`/activity/face-recognition`, {
                headers: {"X-Api-Key": xApiKeyAmartaVip,},
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }
}

export const faceRecognitionService = new FaceRecognitionService();
