import React, { Component } from "react";
import { areaServices } from "../../services/autotrimitra/areaServices";
import { Select } from "antd";

class AreaProvinceSelect extends Component<any, any> {
  private initState!: any;

  constructor(props: any) {
    super(props);

    this.initState = {
      country: "idn",
      dataProvince: [],
      selectProvinceValue: null,
    };
    this.state = { ...this.initState };
  }

  fetchProvinceData = (country: any) => {
    try {
      areaServices.getProvince().then((dataFetchProvince: any) => {
        const dataForState: any = dataFetchProvince.data.data.map((province: { code: string; name: string }) => ({
          value: province.code,
          name: province.name,
        }));
        this.setState({
          country: country,
          dataProvince: dataForState,
          selectProvinceValue: null,
        });
      });
    } catch (e) {
      this.setState({
        country: country,
        dataProvince: [],
        selectProvinceValue: null,
      });
    }
  };

  onChange = (value: any, option: any) => {
    this.setState({
      selectProvinceValue: value,
    });

    this.props.onAreaProvinceChangeProps(option);
  };

  async componentDidMount() {
    await this.promisedSetState({
      country: this.props.country,
    });
    await this.fetchProvinceData(this.props.country);
    await this.onValueChange(this.props.value);
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (this.props.country !== prevProps.country) {
      this.fetchProvinceData(this.props.country);
    }
    if (this.props.country !== prevProps.country) {
      this.fetchProvinceData("");
    }
    // if (this.props.time !== prevProps.time) {
    //     this.onValueChange(this.props.value);
    // }
  }

  onValueChange = (value: any) => {
    this.setState({
      selectProvinceValue: value,
    });
  };

  promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

  render() {
    return (
      <Select showSearch placeholder="Pilih Provinsi" optionFilterProp="children" onChange={this.onChange} filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} value={this.props.value} style={{ width: "100%" }}>
        {this.state.dataProvince.map((item: any, i: number) => (
          <Select.Option key={i} value={item?.value}>
            {item?.name}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export default AreaProvinceSelect;
