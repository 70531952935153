import React, {Component} from "react";
import {Button, Col, Divider, Form, Input, Modal, Row, Spin, Table, TimePicker} from "antd";
import {activityApiService} from "../../services/amartaVip/ActivityApiService";
import Cookies from "universal-cookie";
import currencyMillionFormat from "../../helper/currencyFormat";
import TextArea from "antd/es/input/TextArea";
import {PlusCircleOutlined} from "@ant-design/icons";
import VehicleModelSelect from "../vehicle-copan/VehicleModelSelect";
import CurrencyInput from "react-currency-input-field";
import dayjs from "dayjs";

class DetailApprovalAssessment extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            chosenAppCode: "",
            processingFetch: false,
            dataApplication: {},
            resultNote: "",

            iDataModelTable: [],
            showModalAddVehicle: false,
            tempModelName: null,
            tempModelUuid: "",
            tempModelResultVisitor: 0,
            tempModelResultSpk: 0,

            iDataGuestTable: [],
            showModalAddGuest: false,
            tempGuestName: "",
            tempEntryTime: dayjs('10:00', 'HH:mm'),
            tempExitTime: dayjs('10:00', 'HH:mm'),
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            chosenAppCode: this.props.chosenAppCode,
        });
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.time !== prevProps.time) || (this.props.chosenAppCode !== prevProps.chosenAppCode)) {
            await this.promisedSetState({
                chosenAppCode: this.props.chosenAppCode,
            });
            await this.fetchData();
        }
    }

    fetchData = async () => {
        await this.promisedSetState({
            processingFetch: true,
            dataApplication: {},
            iDataModelTable: [],
            iDataGuestTable: [],
        });

        try {
            await activityApiService.setToken(this.state.token);
            const fetchResult = await activityApiService.getActivityList({
                activityType: "applicationactivity",
                code: this.state.chosenAppCode,
            });

            await this.promisedSetState({
                processingFetch: false,
                dataApplication: fetchResult?.data?.data[0],
                resultNote: "",
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetch: false,
                dataApplication: {},
                resultNote: "",
            });
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "resultNote" | "tempModelResultVisitor" | "tempModelResultSpk" | "tempGuestName" | "tempEntryTime" | "tempExitTime">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "resultNote":
                currentState.resultNote = value;
                break;
            case "tempModelResultVisitor":
                currentState.tempModelResultVisitor = value;
                break;
            case "tempModelResultSpk":
                currentState.tempModelResultSpk = value;
                break;
            case "tempGuestName":
                currentState.tempGuestName = value;
                break;
            case "tempEntryTime":
                currentState.tempEntryTime = value;
                break;
            case "tempExitTime":
                currentState.tempExitTime = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    onCancel = async () => {
        this.props.onCloseModal({reload: false});
    }
    onSubmit = async () => {
        if (!this.state.resultNote) {
            Modal.error({
                title: 'Error',
                content: 'please complete data '
            });
            return;
        }

        const completeResult = {
            source: "admin.amarta.vip",
            type: "APPLICATIONACTIVITY",
            update_type: "RESULT",
            code: this.props.chosenAppCode,
            status: "COMPLETED",
            complete_note: this.state.resultNote,
            complete_vehicle_model: this.state.iDataModelTable,
            complete_guest_list: this.state.iDataGuestTable
        }

        this.setState({
            loading: true,
        });

        try {
            await activityApiService.setToken(this.state.token);
            await activityApiService.updateApplicationactivity(
                completeResult
            ).then(successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'Successfully updated '
                });
                this.props.onCloseModal({reload: true});
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            await this.promisedSetState({
                loading: false,
            });
        }
    }

    onVehicleModelChange = (data: any) => {
        this.setState({
            tempModelName: data.value,
            tempModelUuid: data.model_uuid,
        })
    }

    confirmAddModelData = async () => {
        if (!this.state.tempModelName) {
            Modal.error({
                title: 'Error',
                content: 'please complete data '
            });
            return;
        }

        const currentTableData = [...this.state.iDataModelTable];
        currentTableData.push({
            model_uuid: this.state.tempModelUuid,
            model_name: this.state.tempModelName,
            result_visitor: this.state.tempModelResultVisitor,
            result_spk: this.state.tempModelResultSpk,
        });
        this.setState({
            iDataModelTable: currentTableData,
            showModalAddVehicle: false,
        });
    }

    confirmAddGuestData = async () => {
        if (!this.state.tempGuestName || !this.state.tempEntryTime || !this.state.tempExitTime) {
            Modal.error({
                title: 'Error',
                content: 'please complete data '
            });
            return;
        }

        const currentTableData = [...this.state.iDataGuestTable];
        currentTableData.push({
            guest_name: this.state.tempGuestName,
            entry_time: dayjs(this.state.tempEntryTime).format("HH:mm"),
            exit_time: dayjs(this.state.tempExitTime).format("HH:mm"),
        });
        this.setState({
            iDataGuestTable: currentTableData,
            showModalAddGuest: false,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.processingFetch ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={!this.state.processingFetch ? {} : {display: 'none'}}>
                    <Row>
                        <Col xs={24} xl={12} style={{padding: 20}}>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Application Code:</Col>
                                <Col xs={24} xl={14}>{this.state.dataApplication?.code}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Activity Name:</Col>
                                <Col xs={24} xl={14}>{this.state.dataApplication?.activity_name}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Activity Date:</Col>
                                <Col xs={24} xl={14}>{this.state.dataApplication?.activity_date?.slice(0, 10)}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Activity Duration:</Col>
                                <Col xs={24} xl={14}>{this.state.dataApplication?.data?.duration}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Activity Budget:</Col>
                                <Col xs={24} xl={14}>{currencyMillionFormat(this.state.dataApplication?.sum_budget_amount)}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Deskripsi:</Col>
                                <Col xs={24} xl={14}>{this.state.dataApplication?.data?.description}</Col>
                            </Row>
                        </Col>
                        <Col xs={24} xl={12} style={{padding: 20}}>
                            <Divider orientation="left">Application Result</Divider>

                            <div className={`gray-80 mt-20`}>Result Note:</div>
                            <TextArea value={this.state.resultNote} onChange={event => this.onFieldChange("resultNote", event.target.value)} rows={2} placeholder="Note"/>

                            <br/><br/>
                            <div className={`text-small-grey-nopadding`}>Result Vehicle Model *</div>
                            <Button onClick={() => this.setState({showModalAddVehicle: true, tempModelName: null, tempModelUuid: "", tempModelResultSpk: 0, tempModelResultVisitor: 0})} size={"small"} type="primary" danger icon={<PlusCircleOutlined/>} style={{marginTop: 20}}>Add Vehicle</Button>
                            <Table
                                size="small"
                                style={{marginTop: 10}}
                                rowKey={`model_name`}
                                dataSource={this.state.iDataModelTable}
                                columns={[{title: 'Model', dataIndex: 'model_name', key: 'model_name'}, {title: 'result Visitor', dataIndex: 'result_visitor', key: 'result_visitor'}, {title: 'result SPK', dataIndex: 'result_spk', key: 'result_spk'},]}
                            />
                            <br/><br/>

                            <i className={`text-small-grey-nopadding`}>Guest List *</i><br/>
                            <Button onClick={() => this.setState({showModalAddGuest: true, tempGuestName: "", tempEntryTime: dayjs('10:00', 'HH:mm')})} size={"small"} type="primary" danger icon={<PlusCircleOutlined/>} style={{marginTop: 20}}>Add Guest List</Button>
                            <Table
                                size="small"
                                style={{marginTop: 10}}
                                rowKey={`guest_name`}
                                dataSource={this.state.iDataGuestTable}
                                columns={[{title: 'Name', dataIndex: 'guest_name', key: 'guest_name'}, {title: 'Entry Time', dataIndex: 'entry_time', key: 'entry_time'}, {title: 'Exit Time', dataIndex: 'exit_time', key: 'exit_time'}]}/>
                            <br/>
                        </Col>
                    </Row>
                    <div style={{paddingTop: 20, textAlign: "right"}}>
                        <Divider/>
                        <Button onClick={event => this.onCancel()} style={{marginRight: 7}}>Cancel</Button>
                        <Button onClick={event => this.onSubmit()} loading={this.state.loading} type="primary" style={{marginRight: 7}}>Update</Button>
                    </div>
                </div>

                <Modal
                    open={this.state.showModalAddVehicle}
                    title="Add Vehicle Result"
                    onCancel={() => this.setState({showModalAddVehicle: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button type={"primary"} key="1" onClick={this.confirmAddModelData}>
                            Add
                        </Button>,
                        <Button key="2" onClick={() => this.setState({showModalAddVehicle: false})}>
                            Close
                        </Button>,
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Model *</i>
                            <VehicleModelSelect onVehicleModelChangeProps={this.onVehicleModelChange} value={this.state.tempModelName} companyCode={this.props.organization} areaCode={this.props.area} brandCode={this.props.brand} time={Math.floor(+new Date() / 1000)}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Target Visitor *</i>
                            <CurrencyInput
                                className={'ant-input-css'}
                                allowDecimals={false}
                                groupSeparator={','}
                                decimalSeparator={'.'}
                                value={this.state.tempModelResultVisitor}
                                onValueChange={value => this.onFieldChange("tempModelResultVisitor", value ? parseFloat(value) : 0)}
                            />
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Target SPK *</i>
                            <CurrencyInput
                                className={'ant-input-css'}
                                allowDecimals={false}
                                groupSeparator={','}
                                decimalSeparator={'.'}
                                value={this.state.tempModelResultSpk}
                                onValueChange={value => this.onFieldChange("tempModelResultSpk", value ? parseFloat(value) : 0)}
                            />
                        </Form.Item>
                    </React.Fragment>
                </Modal>

                <Modal
                    open={this.state.showModalAddGuest}
                    title="Add Guest Data"
                    onCancel={() => this.setState({showModalAddGuest: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button type={"primary"} key="1" onClick={this.confirmAddGuestData}>
                            Add
                        </Button>,
                        <Button key="2" onClick={() => this.setState({showModalAddGuest: false})}>
                            Close
                        </Button>,
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Name *</i>
                            <Input value={this.state.tempGuestName} onChange={event => this.onFieldChange("tempGuestName", event.target.value)} placeholder="Name"/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Time entry *</i><br/>
                            <TimePicker value={this.state.tempEntryTime} format={'HH:mm'} onChange={event => this.onFieldChange("tempEntryTime", event)}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Time exit *</i><br/>
                            <TimePicker value={this.state.tempExitTime} format={'HH:mm'} onChange={event => this.onFieldChange("tempExitTime", event)}/>
                        </Form.Item>
                    </React.Fragment>
                </Modal>
            </React.Fragment>
        );
    }
}

export default DetailApprovalAssessment;
