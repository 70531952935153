import React, {Component} from "react";
import {Button, Col, DatePicker, Divider, Input, Modal, Row, Select, Spin, TimePicker} from "antd";
import {activityApiService} from "../../services/amartaVip/ActivityApiService";
import Cookies from "universal-cookie";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import VehicleModelSelect from "../vehicle-copan/VehicleModelSelect";
import VehicleVariantSelect from "../vehicle-copan/VehicleVariantSelect";

class UpdateTestDriveData extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            chosenAppCode: "",
            processingFetch: false,
            dataTestDrive: {},

            activityStatus: null,
            updateNote: "",
            clientPhone: "",
            activityDate: "",
            activityHour: "",
            agentCode: "",
            tempBrandName: "",
            tempBrandUuid: "",
            tempModelName: "",
            tempModelUuid: "",
            tempVariantName: "",
            tempVariantUuid: "",
            tempVariantCode: "",
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            chosenAppCode: this.props.chosenAppCode,
        });
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.time !== prevProps.time) || (this.props.chosenAppCode !== prevProps.chosenAppCode)) {
            await this.promisedSetState({
                chosenAppCode: this.props.chosenAppCode,
            });
            await this.fetchData();
        }
    }

    fetchData = async () => {
        await this.promisedSetState({
            processingFetch: true,
            dataTestDrive: {},
        });

        try {
            await activityApiService.setToken(this.state.token);
            const fetchResult = await activityApiService.getActivityList({
                activityType: "testdrive",
                code: this.state.chosenAppCode,
            });

            await this.promisedSetState({
                activityStatus: fetchResult?.data?.data[0]?.activity_status,
                agentCode: fetchResult?.data?.data[0]?.agen_code,
                dataTestDrive: fetchResult?.data?.data[0],
                updateNote: "",
                clientPhone: fetchResult?.data?.data[0]?.client_phone,
                activityDate: dayjs(fetchResult?.data?.data[0]?.activity_date).format('YYYY-MM-DD'),
                activityHour: dayjs(fetchResult?.data?.data[0]?.activity_date).format('HH:mm'),
                tempBrandName: fetchResult?.data?.data[0]?.brand_name,
                tempBrandUuid: fetchResult?.data?.data[0]?.brand_uuid,
                tempModelName: fetchResult?.data?.data[0]?.model_name,
                tempModelUuid: fetchResult?.data?.data[0]?.model_uuid,
                tempVariantName: fetchResult?.data?.data[0]?.variant_name,
                tempVariantUuid: fetchResult?.data?.data[0]?.variant_uuid,
                tempVariantCode: fetchResult?.data?.data[0]?.variant_code,
                processingFetch: false,
                loading: false,
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetch: false,
                activityStatus: null,
                updateNote: "",
                loading: false,
            });
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "activityStatus" | "updateNote" | "clientPhone" | "activityDate" | "activityHour" | "agentCode">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "activityStatus":
                currentState.activityStatus = value;
                break;
            case "updateNote":
                currentState.updateNote = value;
                break;
            case "clientPhone":
                currentState.clientPhone = value;
                break;
            case "activityDate":
                currentState.activityDate = value;
                break;
            case "activityHour":
                currentState.activityHour = value;
                break;
            case "agentCode":
                currentState.agentCode = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    onCancel = async () => {
        this.props.onCloseModal({reload: false});
    }
    onSubmit = async () => {
        if (!this.state.activityStatus || !this.state.tempVariantCode) {
            Modal.error({
                title: 'Error',
                content: 'please complete variant data'
            });
            return;
        }

        const dataUpdate = {
            source: "admin.amarta.vip",
            activity_type: "TESTDRIVE",
            update_type: "GENERALUPDATE",
            update_notes: this.state.updateNote ?? "",
            code: this.state.dataTestDrive.code,
            activity_status: this.state.activityStatus,
            activity_date: dayjs(this.state.activityDate).format('YYYY-MM-DD') + " " + ((dayjs(this.state.activityHour).format('HH:mm') !== "Invalid Date" ? dayjs(this.state.activityHour).format('HH:mm') : this.state.activityHour)),
            client_phone: this.state.clientPhone,
            agen_code: this.state.agentCode,
            brand_uuid: this.state.tempBrandUuid,
            brand_name: this.state.tempBrandName,
            model_uuid: this.state.tempModelUuid,
            model_name: this.state.tempModelName,
            variant_uuid: this.state.tempVariantUuid,
            variant_code: this.state.tempVariantCode,
            variant_name: this.state.tempVariantName,
        }

        this.setState({
            loading: true,
        });

        try {
            await activityApiService.setToken(this.state.token);
            await activityApiService.updateTestDrive(
                dataUpdate
            ).then(successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'successfully updated '
                });
                this.props.onCloseModal({reload: true});
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            await this.promisedSetState({
                loading: false,
            });
        }
    }

    onVehicleModelChange = (data: any) => {
        this.setState({
            tempModelName: data.value,
            tempModelUuid: data.model_uuid,
            tempVariantName: "",
            tempVariantUuid: "",
            tempVariantCode: "",
        });
    }

    onVehicleVariantChange = (data: any) => {
        this.setState({
            tempVariantName: data.name,
            tempVariantUuid: data.uuid,
            tempVariantCode: data.value,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.processingFetch ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={!this.state.processingFetch ? {} : {display: 'none'}}>
                    <Row>
                        <Col xs={24} xl={24}>
                            <div className={`gray-80 mt-20`}>Client Name:</div>
                            <Input disabled={true} value={this.state.dataTestDrive.client_name}/>
                            <div className={`gray-80 mt-20`}>Driving License:</div>
                            <Input disabled={true} value={this.state.dataTestDrive.document_asset?.[0]?.code}/>
                            <div className={`gray-80 mt-20`}>Phone Number:</div>
                            <Input onChange={event => this.onFieldChange("clientPhone", event.target.value)} value={this.state.clientPhone}/>
                            <div className={`gray-80 mt-20`}>Booking Date:</div>
                            <DatePicker
                                name="activityDate"
                                format={'YYYY-MM-DD'} style={{width: "100%"}}
                                value={dayjs(this.state.activityDate, 'YYYY-MM-DD')}
                                onChange={(date, dateString) => this.onFieldChange("activityDate", dateString)}
                            />
                            <div className={`gray-80 mt-20`}>Booking Time:</div>
                            <TimePicker style={{width: "100%"}} value={dayjs(this.state.activityHour, "HH:mm")} format={'HH:mm'} onChange={event => this.onFieldChange("activityHour", event)}/>

                            <div className={`gray-80 mt-20`}>Vehicle Model:</div>
                            <VehicleModelSelect onVehicleModelChangeProps={this.onVehicleModelChange} value={this.state.tempModelName} companyCode={this.state.dataTestDrive.company_code} areaCode={"jawabarat"} brandCode={this.state.tempBrandName} time={Math.floor(+new Date() / 1000)}/>
                            <div className={`gray-80 mt-20`}>Vehicle Variant:</div>
                            <VehicleVariantSelect onVehicleVariantChangeProps={this.onVehicleVariantChange} modelCode={this.state.tempModelName} value={this.state.tempVariantCode} companyCode={this.state.dataTestDrive.company_code} areaCode={"jawabarat"} time={Math.floor(+new Date() / 1000)}/>

                            <div className={`gray-80 mt-20`}>Agent Code:</div>
                            <Input onChange={event => this.onFieldChange("agentCode", event.target.value)} value={this.state.agentCode}/>

                            <div className={`gray-80 mt-20`}>Status Apply:</div>
                            <Select
                                placeholder="Status"
                                showSearch
                                optionFilterProp="children"
                                value={this.state.activityStatus}
                                onChange={value => this.onFieldChange("activityStatus", value)}
                                style={{width: "100%"}}
                            >
                                <Select.Option value="ACTIVE">Active</Select.Option>
                                <Select.Option value="REJECTED">Rejected</Select.Option>
                                <Select.Option value="APPROVED">Approved</Select.Option>
                                <Select.Option value="COMPLETED">Completed</Select.Option>
                            </Select>

                            <div className={`gray-80 mt-20`}>Update Note:</div>
                            <TextArea value={this.state.updateNote} onChange={event => this.onFieldChange("updateNote", event.target.value)} rows={2} placeholder="Description"/>
                        </Col>
                    </Row>
                    <div style={{paddingTop: 20, textAlign: "right"}}>
                        <Divider/>
                        <Button onClick={event => this.onCancel()} style={{marginRight: 7}}>Cancel</Button>
                        <Button onClick={event => this.onSubmit()} loading={this.state.loading} type="primary" style={{marginRight: 7}}>Update</Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default UpdateTestDriveData;
