import React, {Component} from "react";
import Cookies from "universal-cookie";
import {Form, Button, Divider, Modal, Result, Spin, Table, Input} from "antd";
import {userServices} from "../../services/amartaVip/UserService";

class DetailIdeal extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            page: 1,
            uid: "",
            modalRemove: false,
            dataListIdealAccount: [],

            modalVerify: false,
            loadingConfirmActive: false,
            email: "",
            projectId: "",
            name: "",
            password: "",
            mediatorCode: "",
            mediatorName: "",
        }
        this.state = {...this.initState}
    }

    handleOkActiveData = async () => {
        await this.promisedSetState({
            loadingConfirmActive: true
        });

        try {
            await userServices.setToken(this.state.token);
            await userServices.updateUserInternal({
                type: "update_user_ideal_add",
                uid: this.state.uid,
                update_data: {
                    email: this.state.email,
                    project_id: this.state.projectId,
                    name: this.state.name,
                    password: this.state.password,
                    mediator_code: this.props.dataDetailUser.agent_code,
                    mediator_name: this.props.dataDetailUser.name,
                }
            }).then(async successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'Create User Success'
                });
                await this.promisedSetState({
                    loadingConfirmActive: false,
                    modalVerify: false
                });
                await this.props.onSuccesVerifyProps({refresh_data: true});
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            this.setState({
                loadingConfirmActive: false,
            });
        }
    }

    handleRemoveUser = async () => {
        await this.promisedSetState({
            loadingConfirmActive: true
        });

        try {
            await userServices.setToken(this.state.token);
            await userServices.updateUserInternal({
                type: "update_user_ideal_remove",
                uid: this.state.uid,
                update_data: {
                    email: this.state.email,
                    project_id: this.state.projectId
                }
            }).then(async successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'Remove User Success'
                });
                await this.promisedSetState({
                    loadingConfirmActive: false,
                    modalRemove: false
                });
                await this.props.onSuccesVerifyProps({refresh_data: true});
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            this.setState({
                loadingConfirmActive: false,
            });
        }
    }

    async componentDidMount() {
        await this.promisedSetState({
            uid: this.props.uid,
            name: this.props.dataDetailUser.name
        });
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.time !== prevProps.time) || (this.props.uid !== prevProps.uid)) {
            await this.promisedSetState({
                uid: this.props.uid,
                name: this.props.dataDetailUser.name
            });
            await this.fetchData();
        }
    }

    fetchData = async () => {
        if (this.state.uid) {
            await this.promisedSetState({page: 0});
            try {
                await userServices.setToken(this.state.token);
                const fetchResult = await userServices.getListUserInternalAffiliate({type: this.state.uid + "?userideal=true"});
                const sortedData: any = fetchResult?.data?.data?.user_ideal || [];

                await this.promisedSetState({
                    page: 1,
                    dataListIdealAccount: sortedData,
                });
            } catch (e) {
                await this.promisedSetState({
                    page: 1,
                    dataListIdealAccount: [],
                });
            }
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "email" | "projectId" | "name" | "password" | "mediatorCode" | "mediatorName">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "email":
                currentState.email = value;
                break;
            case "projectId":
                currentState.projectId = value;
                break;
            case "name":
                currentState.name = value;
                break;
            case "password":
                currentState.password = value;
                break;
            case "mediatorCode":
                currentState.mediatorCode = value;
                break;
            case "mediatorName":
                currentState.mediatorName = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <>
                <div style={this.state.page === 0 ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={this.state.page === 1 ? {} : {display: 'none'}}>
                    <div style={this.props.updateData ? {} : {display: 'none'}}>
                        <Button type="primary" key="console" onClick={() => this.setState({
                            modalVerify: true,
                            loadingConfirmActive: false,
                            email: "",
                            projectId: "",
                            name: this.props.dataDetailUser.name,
                            password: ""
                        })}>Buat Akun Ideal</Button>
                    </div>

                    <Table
                        style={{marginTop: 30}}
                        rowKey={'email'}
                        size={'small'}
                        scroll={{x: 'calc(500px + 50%)'}}
                        columns={[
                            {
                                title: 'Email',
                                dataIndex: 'email',
                                render: ((email: number, row: any) => {
                                    return <>
                                        <span className={`font-90`}>{email}</span><br/>
                                    </>
                                })
                            }, {
                                title: 'Project Id',
                                dataIndex: 'project_id',
                                render: ((project_id: number, row: any) => {
                                    return <>
                                        <span style={{color: "#da2f2f"}} className={`font-90`}>{project_id}</span><br/>
                                    </>
                                })
                            }, {
                                title: 'Action',
                                dataIndex: 'project_id',
                                render: ((project_id: number, row: any) => {
                                    return <>
                                        <Button style={this.props.updateData ? {} : {display: 'none'}} size={"small"} type="primary" danger key="console" onClick={() => this.setState({
                                            modalRemove: true,
                                            email: row.email,
                                            projectId: row.project_id,
                                            loadingConfirmActive: false
                                        })}>Remove User Ideal Access</Button>
                                    </>
                                })
                            }
                        ]}
                        dataSource={this.state.dataListIdealAccount}
                        pagination={{pageSize: 1000}}
                    />
                </div>


                <div style={this.state.page === 2 ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Result
                        status="success"
                        title="user terhubung dengan akun App Ideal"
                        subTitle={`User Ideal: ${this.props.dataDetailUser?.emails?.[0]?.email}`}
                        extra={[
                            <Button type="primary" danger key="console" onClick={() => this.setState({modalRemove: true})}>Remove User Ideal Access</Button>
                        ]}
                    />
                </div>

                <Modal
                    title="Create User Ideal"
                    open={this.state.modalVerify}
                    onOk={this.handleOkActiveData}
                    confirmLoading={this.state.loadingConfirmActive}
                    onCancel={() => this.setState({modalVerify: false})}
                >
                    <Divider/>
                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Name *</i>
                        <Input value={this.state.name} onChange={event => this.onFieldChange("name", event.target.value)} placeholder="name"/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Email *</i>
                        <Input value={this.state.email} onChange={event => this.onFieldChange("email", event.target.value)} placeholder="email"/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Project ID *</i>
                        <Input value={this.state.projectId} onChange={event => this.onFieldChange("projectId", event.target.value)} placeholder="projectid"/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Password *</i>
                        <Input value={this.state.password} onChange={event => this.onFieldChange("password", event.target.value)} placeholder="password"/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Mediator Code *</i>
                        <Input disabled={true} value={this.props.dataDetailUser.agent_code} onChange={event => this.onFieldChange("projectId", event.target.value)} placeholder="projectid"/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Mediator Name *</i>
                        <Input disabled={true} value={this.props.dataDetailUser.name} onChange={event => this.onFieldChange("projectId", event.target.value)} placeholder="projectid"/>
                    </Form.Item>
                </Modal>

                <Modal
                    title="Konfirmasi App Ideal"
                    open={this.state.modalRemove}
                    onOk={this.handleRemoveUser}
                    confirmLoading={this.state.loadingConfirmActive}
                    onCancel={() => this.setState({modalRemove: false})}
                >
                    <p>Anda yakin akan menghapus akses terhadap App Ideal ?</p> <br/>
                </Modal>
            </>
        );
    }
}

export default DetailIdeal;