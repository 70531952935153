import axios from "axios";
import { baseUrlOtodis } from "../../config/apiConfig/apiConfig";

class DealerApiService {
  private axios = axios.create({
    baseURL: baseUrlOtodis,
  });

  public setToken(token: string) {
    this.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  public async getDealers(params: any) {
    const queries = {
      ...(params?.company_code && {
        company_code: params.company_code,
      }),
      ...(params?.dealer_code && {
        dealer_code: params.dealer_code,
      }),

      active: true,
      // lite_respon: true,
    };

    try {
      return await this.axios.get<any>(`/dealer/`, {
        params: {
          ...queries,
        },
      });
    } catch (e: any) {
      throw e?.response?.data?.error?.message;
    }
  }
}

export const dealerApiService = new DealerApiService();
