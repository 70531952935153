import React, {Component} from "react";
import Cookies from "universal-cookie";
import {userServices} from "../../services/amartaVip/UserService"
import {Button, Divider, Modal, Form, Select} from "antd";

class UpdateSupervisorPemission extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            loading: false,
            spvPermission: false,
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        this.setState({spvPermission: this.props.data.supervisor_permission})
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.data.agent_code !== prevProps.data.agent_code) || (this.props.time !== prevProps.time)) {
            this.setState({spvPermission: this.props.data.supervisor_permission});
        }
    }

    onCancel = async () => {
        this.props.onCloseModal({reload: false});
    }

    onSubmit = async () => {
        await this.promisedSetState({loading: true})

        try {
            await userServices.setToken(this.state.token)
            await userServices.updateUserInternal({
                type: "update_supervisor_permission",
                uid: this.props.data.uid,
                user_type: this.props.data.user_type,
                supervisor_permission: this.state.spvPermission
            }).then(async successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'successfully updated '
                })
                this.setState({
                    loading: false,
                    showModalAdd: false,
                })
                this.props.onCloseModal({reload: true});
            })
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            })
            this.setState({loading: false,})
        }
    }

    onFieldChange = <T extends keyof Pick<any, "spvPermission">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "spvPermission":
                currentState.spvPermission = value;
                break;
        }

        this.setState({
            ...currentState,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <>
                <Form.Item>
                    <i className={`text-small-grey-nopadding`}>Master Skema *</i>
                    <Select style={{width: "100%"}} showSearch optionFilterProp="children" onChange={value => this.onFieldChange("spvPermission", value)} value={this.state.spvPermission}>
                        <Select.Option value={true}>True</Select.Option>
                        <Select.Option value={false}>False</Select.Option>
                    </Select>
                </Form.Item>

                <div style={{paddingTop: 20, textAlign: "right"}}>
                    <Divider/>
                    <Button loading={this.state.loading} onClick={event => this.onSubmit()} type="primary" style={{marginRight: 7}}>Update Data</Button>
                    <Button loading={this.state.loading} onClick={event => this.onCancel()} style={{marginRight: 7}}>Cancel</Button>
                </div>
            </>
        );
    }
}

export default UpdateSupervisorPemission;