import axios from "axios";
import {baseUrlCdnOrc, secretKeyOcr} from "../../config/apiConfig/apiConfig";

class OcrApiService {
    private axios = axios.create({
        baseURL: baseUrlCdnOrc,
    });

    public async getImageBankStatement(inputData?: any) {
        try {
            return await this.axios.post<any>('/ocr/check-bank-statement',
                inputData,{
                    headers: {
                        "Content-Type": "application/json",
                        "SecretKey": secretKeyOcr
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getImageIdCardNumber(inputData?: any) {
        try {
            return await this.axios.post<any>('/ocr/id-card',
                inputData,{
                    headers: {
                        "Content-Type": "application/json",
                        "SecretKey": secretKeyOcr
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

}

export const ocrApiService = new OcrApiService();