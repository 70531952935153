/** PRODUCTION LINK */
const baseUrlTrimitraIAM = "https://01z4tn3758.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlTrimitraCatalog = "https://zvu1c5uoue.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlAmartaVip = "https://3krjkubmjk.execute-api.ap-southeast-3.amazonaws.com/v1";
const baseUrlCdnUpload = "https://hbxhwzeej9.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlAutotrimitra = "https://au-api-trimitra-get-65q45htc.ts.gateway.dev";
const baseUrlFaceRecognition = "https://asia-southeast1-autotrimitra.cloudfunctions.net/amartavip__facerecognition/v1";
const baseUrlIdealTrimitra = "https://asia-southeast2-ideal-trimitra.cloudfunctions.net/ideal-backend";
const baseUrlIdealWhatsapp = "https://fh63sf7bye.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlOtodis = "https://c1dbgj7gv9.execute-api.ap-southeast-1.amazonaws.com/otodis";
const baseUrlCdnOrc = "https://asia-southeast1-autotrimitra.cloudfunctions.net/api-cdn-trimitra-biz__ocr-document";
const baseUrlScrape = "https://jts279mst6.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlGeneralOffer = "https://0vndxposq6.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlB2b = "https://asia-southeast2-amh-b2b.cloudfunctions.net/api-b2b";

/** KEY */
const xApiKeyTrimitraCatalog = "5ukoYcllpl6lIeKsbeIPI4hOZGDszFVk1dDBddHi";
const xApiKeyAmartaVip = "wbEBxCen12pHWwBHMakz3ebKWmttdwr9BfYrZlw2";
const xApiKeyCdnUpload = "zXSa4iZwycS0O5kaoMvi2yAFiIpimTZ55MvvJLy0";
const secretKeyOcr = 'Mn3zrubT3AM5NA8UFXZO6BgV6Rd69YrH';
const xApiKeyScrape = "4myyAOvpR66U7SZRQyxu25XdCWtAK7625qpvp8L2";
const xApiKeyGeneralOffer = "GyEnzcc3vm1MvwB5Y20K31OT5ylcW6wh7l2ilxiI";
const xApiKeyB2b = '';

export {baseUrlB2b, xApiKeyB2b, baseUrlGeneralOffer, xApiKeyGeneralOffer, baseUrlScrape, xApiKeyScrape, baseUrlCdnOrc, secretKeyOcr, baseUrlTrimitraIAM, baseUrlAmartaVip, baseUrlIdealWhatsapp, xApiKeyAmartaVip, baseUrlCdnUpload, xApiKeyCdnUpload, baseUrlTrimitraCatalog, xApiKeyTrimitraCatalog, baseUrlAutotrimitra, baseUrlFaceRecognition, baseUrlIdealTrimitra, baseUrlOtodis};
