import React, { Component } from "react";
import { areaServices } from "../../services/autotrimitra/areaServices";
import { Select } from "antd";

class AreaCitySelect extends Component<any, any> {
  private initState!: any;

  constructor(props: any) {
    super(props);

    this.initState = {
      provinceCode: "",
      dataCity: [],
      selectCityValue: null,
    };
    this.state = { ...this.initState };
  }

  fetchCityData = (provinceCode: any) => {
    // console.log("provinceCode", provinceCode);

    if (provinceCode) {
      try {
        areaServices
          .getCity({
            provinceCode: provinceCode,
          })
          .then((dataFetchCity) => {
            const dataForState: any = dataFetchCity.data.data.map((city: { code: string; administrative_type: string; name: string }) => ({
              value: city.code,
              name: city.administrative_type + " " + city.name,
            }));
            this.setState({
              provinceCode: provinceCode,
              dataCity: dataForState,
              selectCityValue: null,
            });
          });
      } catch (e) {
        this.setState({
          provinceCode: "",
          dataCity: [],
          selectCityValue: null,
        });
      }
    } else {
      this.setState({
        provinceCode: "",
        dataCity: [],
        selectCityValue: null,
      });
    }
  };

  onChange = (value: any, option: any) => {
    this.setState({
      selectCityValue: value,
    });

    this.props.onAreaCityChangeProps(option);
  };

  async componentDidMount() {
    await this.promisedSetState({
      provinceCode: this.props.provinceCode,
    });
    await this.fetchCityData(this.props.provinceCode);
    await this.onValueChange(this.props.value);
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (this.props.provinceCode !== prevProps.provinceCode) {
      this.fetchCityData(this.props.provinceCode);
    }
    if (this.props.provinceCode !== prevProps.provinceCode) {
      this.fetchCityData("");
    }
    // if (this.props.time !== prevProps.time) {
    //     this.onValueChange(this.props.value);
    // }
  }

  onValueChange = (value: any) => {
    this.setState({
      selectCityValue: value,
    });
  };

  promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

  render() {
    return (
      <Select showSearch placeholder="Pilih Kota" optionFilterProp="children" onChange={this.onChange} filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} value={this.props.value} style={{ width: "100%" }}>
        {this.state.dataCity.map((item: any, i: number) => (
          <Select.Option key={i} value={item?.value}>
            {item?.name}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export default AreaCitySelect;
