import React, {Component} from 'react';
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import {MasterTalentStates} from "./types/MasterTalentTypes";
import {vacancyApiService} from "../../services/amartaVip/VacancyApiService";
import {CopyOutlined, CheckCircleTwoTone} from "@ant-design/icons";
import {Select, Table, Tooltip} from "antd";
import currencyMillionFormat from "../../helper/currencyFormat";
import {allowedOrganization} from "../../config/appDataConfig/organizationCondig";

export interface MasterTalentProps {
}

class MasterTalent extends Component<MasterTalentProps & any, MasterTalentStates> {
    private cookies = new Cookies();
    private tableColumn: any = [
        {
            title: 'Vacancy',
            dataIndex: 'vacancy_code',
            render: ((value: number, row: any) => {
                return <React.Fragment>
                    <span style={{color: "#da2f2f"}} className={`font-90`}>{value}</span>
                    <Tooltip title="Click untuk copy code" color={'cyan'}>
                        &nbsp; <CopyOutlined className={'small-avatar-link'} onClick={event => this.copyToClipboard(value)}/>
                    </Tooltip>
                    <Tooltip title={(row?.active) ? "Active" : "Not Active"}>
                        <CheckCircleTwoTone style={{paddingLeft: 5, fontSize: "80%"}} twoToneColor={(row?.active) ? "#52c41a" : "#bebebe"}/> <br/>
                    </Tooltip>
                    <span className={`font-90`}>{row.vacancy_name}</span><br/>
                    <span className={`gray-80`}>{(row?.vacancy_caption?.length < 44) ? row?.vacancy_caption?.toUpperCase() : row?.vacancy_caption?.toUpperCase()?.substring(0, 42) + "..."}</span>
                </React.Fragment>
            })
        },
        {
            title: 'Role & Position',
            dataIndex: 'role_code',
            render: ((value: number, row: any) => {
                return <React.Fragment>
                    <span style={{color: "#da2f2f"}} className={`font-90`}>{value}</span><br/>
                    <span className={`font-90`}>{row.role_name}</span><br/>
                    <span className={`gray-80`}>{row?.position_type?.toString()}</span>
                </React.Fragment>
            })
        },
        {
            title: 'Organization',
            dataIndex: 'organization',
            render: ((value: number, row: any) => {
                return <React.Fragment>
                    <span style={{color: "#da2f2f"}} className={`font-90`}>{value}</span><br/>
                    <span className={`font-90`}>{row.organization_data?.name}</span>
                </React.Fragment>
            })
        },
        {
            title: 'Role & Position',
            dataIndex: 'compensation_type',
            render: ((value: number, row: any) => {
                return <React.Fragment>
                    <span className={`font-90`}>{value} | {row?.compensation_uom}</span><br/>
                    <span className={`gray-80`}>{currencyMillionFormat(row?.compensation_range_down)} - {currencyMillionFormat(row?.compensation_range_up)}</span>
                </React.Fragment>
            })
        },
        {
            title: 'Required Data',
            dataIndex: 'data_required',
            render: ((value: any, row: any) => {
                const list = [];
                for (const property in value) {
                    console.log(property);
                    if (value[property]) {
                        list.push(property);
                    }
                }
                return <React.Fragment>
                    <span className={`gray-80`}>{list?.toString()}</span>
                </React.Fragment>
            })
        },
    ];

    constructor(props: MasterTalentProps & any) {
        super(props);

        this.state = {
            token: this.cookies.get('_vip-t') ?? "",
            organization: null,
            processingFetchData: false,
            pageSize: 100,
            dataTable: [],
        }
    }

    copyToClipboard = async (data: any) => {
        await navigator.clipboard.writeText(data);
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    fetchData = async () => {
        if (this.state.organization) {
            await this.promisedSetState({
                processingFetchData: true
            });

            try {
                await vacancyApiService.setToken(this.state.token);
                const fetchResult = await vacancyApiService.getMasterVacancy({organization: this.state.organization});
                await this.promisedSetState({
                    processingFetchData: false,
                    dataTable: fetchResult?.data?.data,
                });
            } catch (e) {
                await this.promisedSetState({
                    processingFetchData: false,
                    dataTable: [],
                });
            }
        }
    }

    onChangeTable = (pagination: any, filters: any, sorter: any) => {
        if (pagination?.pageSize) {
            this.setState({
                pageSize: pagination?.pageSize,
            });
        }
    }

    async componentDidMount() {
        await this.fetchData();
    }

    onFieldChange = async <T extends keyof Pick<any, "organization">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "organization":
                currentState.organization = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });

        if (target === "organization") {
            await this.fetchData();
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`Setting`} breadcrumbMenu={`Master Talent`} defaultSelectedKeys={['m-talent']} defaultOpenKeys={['sm-setting']}>

                    <div className={`p-5`}>
                        <i className={`small-text-grey`}>Talent Organization:</i><br/>
                        <Select
                            style={{minWidth: 200}}
                            showSearch
                            placeholder="Choose Organization"
                            optionFilterProp="children"
                            onChange={value => this.onFieldChange("organization", value)}
                            value={this.state.organization}
                        >
                            {(allowedOrganization ?? []).map((item: any, i: number) =>
                                <Select.Option key={i} value={item?.amartaVipCode}>{item?.companyCode}</Select.Option>
                            )}
                        </Select>
                    </div>
                    <Table
                        rowKey={'vacancy_code'}
                        size={'small'}
                        scroll={{x: 'calc(500px + 50%)'}}
                        loading={this.state.processingFetchData}
                        columns={this.tableColumn}
                        dataSource={this.state.dataTable}
                        pagination={{pageSize: this.state.pageSize}}
                        onChange={this.onChangeTable}
                    />
                </AppLayout>
            </React.Fragment>
        );
    }
}

export default withAuth(MasterTalent, 2);
