import React, {Component} from "react";
import Col from "antd/lib/col";
import {Card, Divider, Row, Spin, Timeline, Image, Tabs} from "antd";
import {vacancyApiService} from "../../services/amartaVip/VacancyApiService";
import Cookies from "universal-cookie";
import {IdcardOutlined} from "@ant-design/icons";
import UserAssessmentHistory from "../../component/pageSection/UserAssessmentHistory";

class DetailOffer extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            organization: "",
            chosenApplyCode: "",
            processingFetch: false,
            dataApplicant: {},
            dataCv: {},
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            organization: this.props.organization,
            chosenApplyCode: this.props.chosenApplyCode,
        });
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.time !== prevProps.time) || (this.props.chosenApplyCode !== prevProps.chosenApplyCode)) {
            await this.promisedSetState({
                organization: this.props.organization,
                chosenApplyCode: this.props.chosenApplyCode,
            });
            await this.fetchData();
        }
    }

    fetchData = async () => {
        await this.promisedSetState({
            processingFetch: true,
            dataApplicant: {},
        });

        try {
            await vacancyApiService.setToken(this.state.token);
            const fetchResult = await vacancyApiService.getOrganizationApply({
                organization: this.state.organization,
                applyCode: this.state.chosenApplyCode,
            });
            await this.promisedSetState({
                processingFetch: false,
                dataApplicant: fetchResult?.data?.data[0],
                dataCv: (fetchResult?.data?.data[0]?.user_data?.other_documents ?? []).find((o: any) => o.type === 'cv') ?? {}
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetch: false,
                dataApplicant: {},
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.processingFetch ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={!this.state.processingFetch ? {} : {display: 'none'}}>

                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                label: 'Data user',
                                key: '1',
                                children:
                                    <React.Fragment>
                                        <div>
                                            <Row>
                                                <Col xs={24} xl={10}>
                                                    <Row className={`pt-5`}>
                                                        <Col xs={24} xl={6} className={`col-grey`}>Apply Code:</Col>
                                                        <Col xs={24} xl={18}>{this.state.dataApplicant?.apply_code}</Col>
                                                    </Row>
                                                    <Row className={`pt-5`}>
                                                        <Col xs={24} xl={6} className={`col-grey`}>Apply Status:</Col>
                                                        <Col xs={24} xl={18}>{this.state.dataApplicant?.vacancy_status}</Col>
                                                    </Row>
                                                    <Row className={`pt-5`}>
                                                        <Col xs={24} xl={6} className={`col-grey`}>Apply Time:</Col>
                                                        <Col xs={24} xl={18}>{this.state.dataApplicant?.created_time}</Col>
                                                    </Row>
                                                    <Row className={`pt-5`}>
                                                        <Col xs={24} xl={6} className={`col-grey`}>Vacancy Organization:</Col>
                                                        <Col xs={24} xl={18}>{this.state.dataApplicant?.organization}</Col>
                                                    </Row>
                                                    <Row className={`pt-5`}>
                                                        <Col xs={24} xl={6} className={`col-grey`}>Role Code:</Col>
                                                        <Col xs={24} xl={18}>{this.state.dataApplicant?.role_code}</Col>
                                                    </Row>
                                                    <Row className={`pt-5`}>
                                                        <Col xs={24} xl={6} className={`col-grey`}>Role Name:</Col>
                                                        <Col xs={24} xl={18}>{this.state.dataApplicant?.role_name}</Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={24} xl={10}>
                                                    <Row className={`pt-5`}>
                                                        <Col xs={24} xl={6} className={`col-grey`}>Full Name:</Col>
                                                        <Col xs={24} xl={18}>{this.state.dataApplicant?.user_data?.id_card?.full_name}</Col>
                                                    </Row>
                                                    <Row className={`pt-5`}>
                                                        <Col xs={24} xl={6} className={`col-grey`}>Phone:</Col>
                                                        <Col xs={24} xl={18}>{this.state.dataApplicant?.user_data?.phones}</Col>
                                                    </Row>
                                                    <Row className={`pt-5`}>
                                                        <Col xs={24} xl={6} className={`col-grey`}>Email:</Col>
                                                        <Col xs={24} xl={18}>{this.state.dataApplicant?.user_data?.email}</Col>
                                                    </Row>
                                                    <Row className={`pt-5`}>
                                                        <Col xs={24} xl={6} className={`col-grey`}>ID Card Number:</Col>
                                                        <Col xs={24} xl={18}>{this.state.dataApplicant?.user_data?.id_card?.id_card_number}</Col>
                                                    </Row>
                                                    <Row className={`pt-5`}>
                                                        <Col xs={24} xl={6} className={`col-grey`}>ID Card Image:</Col>
                                                        <Col xs={24} xl={18}>
                                                            <a href={this.state.dataApplicant?.user_data?.id_card?.id_card_image} target={`_blank`}>
                                                                <IdcardOutlined className={'small-avatar-link'}/>
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                    <Row className={`pt-5`}>
                                                        <Col xs={24} xl={6} className={`col-grey`}>Birth Place / Birth Date:</Col>
                                                        <Col xs={24} xl={18}>{this.state.dataApplicant?.user_data?.id_card?.birth_date}</Col>
                                                    </Row>
                                                    <Row className={`pt-5`}>
                                                        <Col xs={24} xl={6} className={`col-grey`}>Address:</Col>
                                                        <Col xs={24} xl={18}>
                                                            {this.state.dataApplicant?.user_data?.address?.province_name},&nbsp;
                                                            {this.state.dataApplicant?.user_data?.address?.city_name},&nbsp;
                                                            {this.state.dataApplicant?.user_data?.address?.district_name},&nbsp;
                                                            {this.state.dataApplicant?.user_data?.address?.sub_district_name}<br/>
                                                            {this.state.dataApplicant?.user_data?.address?.full_address}<br/>
                                                            {this.state.dataApplicant?.user_data?.address?.postal_code}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={24} xl={4}>
                                                    Additional user CV
                                                    <Card bordered={true} bodyStyle={{padding: 7}} style={this.state.dataCv?.document_url ? {width: "100%", marginTop: 20, marginBottom: 20} : {display: 'none'}}>
                                                        CV {this.state.name}<br/>
                                                        <a target={`_blank`} href={this.state.dataCv?.document_url}>Download</a>
                                                    </Card>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className={`p-5`} xxl={{span: 12}} xl={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                    <Divider orientation="left">Educational Background</Divider>
                                                    <Timeline className={`pt-10`}>
                                                        {this.state.dataApplicant?.user_data?.educational_background?.map((item: any, x: number) =>
                                                            <Timeline.Item>
                                                                <span style={{fontWeight: 500}}>{item?.degree_name}</span> - <span>{item?.institution_name}</span><br/>
                                                                <span className={`text-small-grey-nopadding`}>{item?.field_of_study} {item?.gpa}</span><br/>
                                                                <span className={`text-small-grey-nopadding`}>{item?.institution_location}, FROM: {item?.start_date} UNTIL: {item?.end_date}</span><br/>
                                                                <span className={`text-small-grey-nopadding`}>{item?.notes}</span>
                                                            </Timeline.Item>
                                                        )}
                                                    </Timeline>
                                                </Col>
                                                <Col className={`p-5`} xxl={{span: 12}} xl={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                    <Divider orientation="left">Employment Background</Divider>
                                                    <Timeline className={`pt-10`}>
                                                        {this.state.dataApplicant?.user_data?.employment_background?.map((item: any, x: number) =>
                                                            <Timeline.Item>
                                                                <span style={{fontWeight: 500}}>{item?.employer}</span> - <span>{item?.job_position}</span> <i style={item?.currently_work_here ? {} : {display: 'none'}} className={`text-small-red-nopadding`}>(Masih Bekerja ditempat ini)</i> <br/>
                                                                <span className={`text-small-grey-nopadding`}>{item?.job_description}</span><br/>
                                                                <span className={`text-small-grey-nopadding`}>{item?.employer_location}, FROM: {item?.start_date} UNTIL: {item?.end_date}</span><br/>
                                                                <span className={`text-small-grey-nopadding`}>{item?.notes}</span>
                                                            </Timeline.Item>
                                                        )}
                                                    </Timeline>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className={`p-5`} xxl={{span: 12}} xl={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                    <Divider orientation="left">Documents & Driving License</Divider>
                                                    <Row>
                                                        {this.state.dataApplicant?.user_data?.other_documents?.map((item: any, x: number) =>
                                                            <Col key={x} className={`p-5`} xxl={{span: 8}} xl={{span: 8}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                                                <Card hoverable={true} size="small" style={(item?.type === "cv") ? {display: 'none'} : {}}>
                                                                    {(() => {
                                                                        if (item?.type === "family_register") {
                                                                            return (
                                                                                <React.Fragment>
                                                                                    <span style={{paddingLeft: 10}}>KK</span><br/>
                                                                                </React.Fragment>
                                                                            )
                                                                        }
                                                                        if (item?.type === "driving_license_a") {
                                                                            return (
                                                                                <React.Fragment>
                                                                                    <span style={{paddingLeft: 10}}>SIM A</span><br/>
                                                                                </React.Fragment>
                                                                            )
                                                                        } else if (item?.type === "driving_license_c") {
                                                                            return (
                                                                                <React.Fragment>
                                                                                    <span style={{paddingLeft: 10}}>SIM C</span><br/>
                                                                                </React.Fragment>
                                                                            )
                                                                        }
                                                                        if (item?.type === "driving_license_b1") {
                                                                            return (
                                                                                <React.Fragment>
                                                                                    <span style={{paddingLeft: 10}}>SIM B1</span><br/>
                                                                                </React.Fragment>
                                                                            )
                                                                        }
                                                                        if (item?.type === "driving_license_b2") {
                                                                            return (
                                                                                <React.Fragment>
                                                                                    <span style={{paddingLeft: 10}}>SIM B2</span><br/>
                                                                                </React.Fragment>
                                                                            )
                                                                        }
                                                                        if (item?.type === "driving_license_d") {
                                                                            return (
                                                                                <React.Fragment>
                                                                                    <span style={{paddingLeft: 10}}>SIM D</span><br/>
                                                                                </React.Fragment>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <span> <br/> </span>
                                                                            )
                                                                        }
                                                                    })()}
                                                                    <span style={{paddingLeft: 10, color: "gray"}}>{item?.document_number}</span>
                                                                </Card>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Col>
                                                <Col className={`p-5`} xxl={{span: 12}} xl={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                    <Divider orientation="left">Social Media</Divider>
                                                    <Row>
                                                        {this.state.dataApplicant?.user_data?.social_media_accounts?.map((item: any, x: number) =>
                                                            <Col key={x} className={`p-5`} xxl={{span: 12}} xl={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                                <Card bordered={true} key={x} bodyStyle={{padding: 7}}>
                                                                    <Row>
                                                                        <Col xl={{span: 4}} lg={{span: 2}} md={{span: 4}} sm={{span: 4}} xs={{span: 4}}>
                                                                            <Image style={{height: 50}} alt="youtube" src={`../assets/img/${item?.media}.png`}/>
                                                                        </Col>
                                                                        <Col xl={{span: 20}} lg={{span: 20}} md={{span: 17}} sm={{span: 17}} xs={{span: 17}} style={{fontSize: "85%"}}>
                                                                            <i style={{paddingLeft: 10}}>{item?.user_account}</i><br/>
                                                                            <a href={item?.url} target={`_blank`}>{item?.url}</a>
                                                                        </Col>
                                                                    </Row>
                                                                </Card>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </React.Fragment>
                            },
                            {
                                label: 'Timeline History',
                                key: '2',
                                children:
                                    <React.Fragment>
                                        <UserAssessmentHistory time={Math.floor(+new Date() / 1000)} organization={this.state.dataApplicant?.organization} userUid={this.state.dataApplicant?.user_uid}/>
                                    </React.Fragment>
                            }
                        ]}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default DetailOffer;
