import React, {Component} from "react";
import Cookies from "universal-cookie";
import {Button, Card, Divider, Dropdown, Modal, Space, Typography} from "antd";
import {DownOutlined, EnvironmentOutlined} from "@ant-design/icons";
import {userServices} from "../../services/amartaVip/UserService";

const {Text} = Typography;

class UserAssessmentHistory extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            submitting: false,
            showModalRequest: false,
            requestType: "",
            text: "Dear team, \nKami membutuhkan lokasi berada saat ini, silakan lakukan share location.",
        }
        this.state = {...this.initState}
    }

    buttonTrigger = async (data: any) => {
        this.setState({
            showModalRequest: true,
            requestType: data.key,
        })
    }

    confirmSendTelegramRequest = async () => {

        await this.promisedSetState({submitting: true})

        try {
            await userServices.setToken(this.state.token);
            await userServices.sendTelegram({
                type: "FORM_NOTIFICATION_BY_CHAT_ID",
                chat_id: [this.props.telegramId],
                title: "Request Location",
                message: this.state.text
            })
            Modal.success({title: 'Proses Sukses', content: 'success'});
            this.setState({submitting: false, showModalRequest: false});
        } catch (e) {
            Modal.error({title: 'Proses Gagal', content: 'Error :' + e});
            this.setState({submitting: false});
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        const items: any = [{
            label: 'Request Send Location',
            key: 'REQUEST_SEND_LOCATION',
            icon: <EnvironmentOutlined/>,
        },];
        const menuProps = {
            items,
            onClick: this.buttonTrigger,
        };
        return (
            <React.Fragment>
                <Dropdown menu={menuProps}>
                    <Space className={`col-grey`} style={{cursor: "pointer"}}>{this.props.telegramId}<DownOutlined/></Space>
                </Dropdown>

                <Modal
                    open={this.state.showModalRequest}
                    title="Detail Request"
                    onCancel={() => this.setState({showModalRequest: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button loading={this.state.submitting} key="0" type={"primary"} onClick={this.confirmSendTelegramRequest}> Kirim Request </Button>,
                        <Button key="2" onClick={() => this.setState({showModalRequest: false})}>Close</Button>,
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <i>Isi Pesan Telegram:</i>
                        <Card>
                            <Text style={{fontSize: "90%", color: "gray"}}>
                                {this.state.text}
                            </Text>
                        </Card><br/>
                        <Divider/>
                    </React.Fragment>
                </Modal>
            </React.Fragment>
        );
    }
}

export default UserAssessmentHistory;