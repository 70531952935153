import React, {Component} from 'react';
import {Buffer} from "buffer";
import {Button, Form, Input, Modal} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import Cookies from 'universal-cookie';
import {withRouter} from "../../hoc/withRouter";
import {authApiService} from "../../services/trimitraIAM/AuthApiService";
import {appVersion} from "../../config/appDataConfig/appConfig";

export interface LoginProps {
}

export interface LoginStates extends LoginFields {
    processing: boolean;
    captchaSuccess: boolean;
}

export interface LoginFields {
    userName: string;
    password: string;
}

class Login extends Component<LoginProps & any, LoginStates> {
    private cookies = new Cookies();
    private readonly initState!: LoginStates;

    constructor(props: LoginProps & any) {
        super(props);

        this.initState = {
            processing: false,
            captchaSuccess: false,
            password: "",
            userName: "",
        };

        this.state = {...this.initState};
    }

    onFieldChange = <T extends keyof LoginFields>(target: T, value: string) => {
        this.setState(prevState => ({
            ...prevState,
            [target]: value
        }));
    };

    submit = async () => {
        this.setState({
            processing: true,
        });

        try {
            const login = await authApiService.login({
                username: this.state.userName,
                password: this.state.password,
                app_code: "AMARTAVIP"
            });

            const access_project = login.data.data.access_project;
            const areaData: any = [];
            for (let item of access_project) {
                areaData.push({
                    AreaCode: item.project_code,
                    AreaName: item.project_name
                });
            }
            const area_default = {
                AreaCode: login.data.data.default_project.project_code,
                AreaName: login.data.data.default_project.project_name
            };

            this.cookies.set('_vip-t', login.data.data.token);
            this.cookies.set('_vip-u', Buffer.from(JSON.stringify(login.data.data.name)).toString('base64'));
            this.cookies.set('_vip-a', Buffer.from(JSON.stringify(areaData)).toString('base64'));
            this.cookies.set('_vip-da', Buffer.from(JSON.stringify(area_default)).toString('base64'));
            this.cookies.set('_vip-am', Buffer.from(JSON.stringify(login.data.data.access_menu)).toString('base64'));

            authApiService.setToken(login.data.data.token);
            this.setState({
                processing: false,
            });

            this.props.history.push('/');
        } catch (e) {
            Modal.error({
                title: 'Login Failed',
                content: 'Username atau password tidak cocok',
            });

            this.setState({
                processing: false,
            });
        }
    };

    render() {
        return (
            <section className="flex flex-col md:flex-row h-screen items-center">
                <div className="hidden lg:flex bg-gray-200 w-full md:w-1/2 xl:w-2/3 h-screen items-center justify-center">
                    <img src="/assets/img/AmartaVIP.png" alt="" style={{width: 500}} className="mx-auto"/>
                </div>
                <div className="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 flex items-center justify-center">
                    <div className="w-full h-100">
                        <h1 className="text-center text-2xl font-bold">Login AmartaVIP</h1>

                        <img src={"/assets/img/favicon.png"} style={{width: 100, height: 100}} alt="logo" className="mx-auto mt-12"/>

                        <Form className="mt-7" onFinish={this.submit}>
                            <Form.Item name="username">
                                <Input
                                    prefix={<UserOutlined/>}
                                    placeholder='Username'
                                    className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                                    onChange={(e) => this.onFieldChange('userName', e.target.value)}
                                    autoFocus
                                    required
                                />
                            </Form.Item>
                            <Form.Item name="password">
                                <Input.Password
                                    prefix={<LockOutlined/>}
                                    placeholder='Password'
                                    className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                                    minLength={6}
                                    onChange={(e) => this.onFieldChange('password', e.target.value)}
                                    required
                                />
                            </Form.Item>
                            <Button
                                size={"large"}
                                type="primary"
                                block
                                className="w-full block bg-blue-500 hover:bg-blue-400 focus:bg-blue-400 text-white font-semibold rounded-lg mt-6"
                                htmlType="submit"
                                loading={this.state.processing}
                            >
                                Log In
                            </Button>
                        </Form>
                        <hr className="my-6 border-gray-300 w-full"/>
                        <p className="text-center text-sm text-gray-500 mt-12">&copy; {new Date().getFullYear()} AmartaVIP v-{appVersion}</p>
                    </div>
                </div>
            </section>
        );
    }
}

export default withRouter(Login);