import React, {Component} from "react";
import {Button, Divider, Row, Spin, TimePicker, Radio, Space, Checkbox, Tabs} from "antd";
import {vacancyApiService} from "../../services/amartaVip/VacancyApiService";
import Cookies from "universal-cookie";
import dayjs from "dayjs";
import DatePicker from "antd/lib/date-picker";
import Col from "antd/lib/col";
import TextArea from "antd/es/input/TextArea";
import Modal from "antd/lib/modal";
import UserAssessmentHistory from "./UserAssessmentHistory";

class ScheduleInterview extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            organization: "",
            chosenApplyCode: "",
            processingFetch: false,
            dataApplicant: {},
            dataCv: {},
            interviewDate: dayjs(),
            interviewTime: dayjs('10:00', 'HH:mm'),
            interviewLocation: "",
            interviewNotes: "",
            interviewNotesForApplicant: "",
            interviewCategory: "SCREENING",
            interviewReschedule: false,
            loading: false,
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            organization: this.props.organization,
            chosenApplyCode: this.props.chosenApplyCode,
            loading: false,
        });
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.time !== prevProps.time) || (this.props.chosenApplyCode !== prevProps.chosenApplyCode)) {
            await this.promisedSetState({
                organization: this.props.organization,
                chosenApplyCode: this.props.chosenApplyCode,
            });
            this.setState({
                interviewDate: dayjs(),
                interviewTime: dayjs('10:00', 'HH:mm'),
                interviewLocation: "",
                interviewNotes: "",
                interviewNotesForApplicant: "",
                interviewCategory: "SCREENING",
                loading: false,
            });
            await this.fetchData();
        }
    }

    fetchData = async () => {
        await this.promisedSetState({
            processingFetch: true,
            dataApplicant: {},
        });

        try {
            await vacancyApiService.setToken(this.state.token);
            const fetchResult = await vacancyApiService.getOrganizationApply({
                organization: this.state.organization,
                applyCode: this.state.chosenApplyCode,
            });
            await this.promisedSetState({
                processingFetch: false,
                dataApplicant: fetchResult?.data?.data[0],
                dataCv: (fetchResult?.data?.data[0]?.user_data?.other_documents ?? []).find((o: any) => o.type === 'cv') ?? {}
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetch: false,
                dataApplicant: {},
            });
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "interviewDate" | "interviewTime" | "interviewLocation" | "interviewNotes" | "interviewNotesForApplicant" | "interviewCategory" | "interviewReschedule">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "interviewDate":
                currentState.interviewDate = value;
                break;
            case "interviewTime":
                currentState.interviewTime = value;
                break;
            case "interviewLocation":
                currentState.interviewLocation = value;
                break;
            case "interviewNotes":
                currentState.interviewNotes = value;
                break;
            case "interviewNotesForApplicant":
                currentState.interviewNotesForApplicant = value;
                break;
            case "interviewCategory":
                currentState.interviewCategory = value.target.value;
                break;
            case "interviewReschedule":
                currentState.interviewReschedule = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    onCancel = async () => {
        this.props.onCloseModal({reload: false});
    }
    onSubmit = async () => {

        if (!this.state.interviewLocation) {
            Modal.error({
                title: 'Error',
                content: 'please complete the location field '
            });
            return;
        }

        const dataInterview = {
            organization: this.state.dataApplicant?.organization,
            type: "interview",
            apply_code: this.state.chosenApplyCode,
            date: dayjs(this.state.interviewDate).format("YYYY-MM-DD") + " " + dayjs(this.state.interviewTime).format("HH:mm"),
            location: this.state.interviewLocation,
            notes: this.state.interviewNotes,
            notes_for_applicant: this.state.interviewNotesForApplicant,
            category: this.state.interviewCategory,
            is_reschedule: this.state.interviewReschedule ?? false,
        }

        this.setState({
            loading: true,
        });

        try {
            await vacancyApiService.setToken(this.state.token);
            await vacancyApiService.createAssessment(
                dataInterview
            ).then(successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'Interview successfully scheduled '
                });
                this.props.onCloseModal({reload: true});
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            await this.promisedSetState({
                loading: false,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.processingFetch ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={!this.state.processingFetch ? {} : {display: 'none'}}>
                    <Row>
                        <Col style={{paddingRight: 20}} xxl={{span: 12}} xl={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <span className={`gray-80`}>Apply Code:</span><br/>
                            {this.state.dataApplicant?.apply_code}<br/>
                            <span className={`gray-80`}>Organisasi - Role:</span><br/>
                            {this.state.dataApplicant?.organization} - {this.state.dataApplicant?.role_code} {this.state.dataApplicant?.role_name}<br/>
                            <Divider/>
                            <span className={`gray-80`}>Nama Lengkap:</span><br/>
                            {this.state.dataApplicant?.user_data?.id_card?.full_name}<br/>
                            <span className={`gray-80`}>Nomor Telpon - Email:</span><br/>
                            {this.state.dataApplicant?.user_data?.phones} - {this.state.dataApplicant?.user_data?.email}<br/>
                            <span className={`gray-80`}>Alamat:</span><br/>
                            <div style={{fontSize: "85%"}}>
                                {this.state.dataApplicant?.user_data?.address?.province_name},&nbsp;
                                {this.state.dataApplicant?.user_data?.address?.city_name},&nbsp;
                                {this.state.dataApplicant?.user_data?.address?.district_name},&nbsp;
                                {this.state.dataApplicant?.user_data?.address?.sub_district_name}<br/>
                                {this.state.dataApplicant?.user_data?.address?.full_address}<br/>
                                {this.state.dataApplicant?.user_data?.address?.postal_code}
                            </div>
                            <Divider/>

                            <Tabs
                                defaultActiveKey="1"
                                items={[
                                    {
                                        label: 'User History',
                                        key: '1',
                                        children:
                                            <React.Fragment>
                                                <UserAssessmentHistory organization={this.state.dataApplicant?.organization} userUid={this.state.dataApplicant?.user_uid} checkSameApply={this.state.dataApplicant?.apply_code}/>
                                            </React.Fragment>
                                    },
                                    {
                                        label: 'Jadwal Interview di Hari yang sama',
                                        key: '2',
                                        children:
                                            <React.Fragment>
                                                Under Construct
                                            </React.Fragment>
                                    }
                                ]}
                            />
                        </Col>
                        <Col xxl={{span: 12}} xl={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <span className={`gray-80`}>Tanggal & Waktu Interview:</span><br/>
                            <Row>
                                <Col span={15} style={{paddingRight: 10}}>
                                    <DatePicker style={{width: "100%"}} value={dayjs(this.state.interviewDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("interviewDate", event)} format={'YYYY-MM-DD'}/>
                                </Col>
                                <Col span={9}>
                                    <TimePicker value={this.state.interviewTime} format={'HH:mm'} onChange={event => this.onFieldChange("interviewTime", event)}/>
                                </Col>
                            </Row>
                            <div className={`pt-10`}/>
                            <Checkbox value={this.state.interviewReschedule} onChange={e => this.onFieldChange("interviewReschedule", e.target.checked)}>Ini adalah Reschedule Interview</Checkbox>

                            <div className={`gray-80 mt-20`}>Kategori:</div>
                            <Radio.Group onChange={event => this.onFieldChange("interviewCategory", event)} value={this.state.interviewCategory}>
                                <Space direction="vertical">
                                    <Radio value={"SCREENING"}>
                                        Screening Interview<br/>
                                        <span className={`gray-80`}>dilakukan sebagai seleksi awal untuk mengevaluasi kelayakan dan kesesuaian calon kandidat dengan kualifikasi dan persyaratan pekerjaan yang dibutuhkan.</span>
                                    </Radio>
                                    <Radio value={"BEHAVIORAL"}>
                                        Behavioral Interview<br/>
                                        <span className={`gray-80`}>dilakukan untuk mengevaluasi kemampuan calon kandidat dalam mengatasi situasi tertentu di tempat kerja.</span>
                                    </Radio>
                                    <Radio value={"TECHNICAL"}>
                                        Technical Interview<br/>
                                        <span className={`gray-80`}>dilakukan untuk mengevaluasi kemampuan teknis calon kandidat dalam bidang yang dibutuhkan oleh perusahaan.</span>
                                    </Radio>
                                    <Radio value={"PANEL"}>
                                        Panel Interview<br/>
                                        <span className={`gray-80`}>melibatkan beberapa pewawancara dari berbagai departemen di perusahaan dan dilakukan untuk mengevaluasi kemampuan calon kandidat dari berbagai sudut pandang.</span>
                                    </Radio>
                                    <Radio value={"SECOND_ROUND"}>
                                        SecondRound Interview<br/>
                                        <span className={`gray-80`}>dilakukan setelah calon kandidat berhasil melewati tahap seleksi awal dan bertujuan untuk mengevaluasi kemampuan lebih lanjut.</span>
                                    </Radio>
                                    <Radio value={"NEGOTIATION"}>
                                        Negotiation Interview<br/>
                                        <span className={`gray-80`}>edua belah pihak dapat melakukan diskusi dan negosiasi agar dapat mencapai kesepakatan gaji dan penempatan.</span>
                                    </Radio>
                                </Space>
                            </Radio.Group>

                            <div className={`gray-80 mt-20`}>Lokasi:</div>
                            <TextArea showCount maxLength={200} value={this.state.interviewLocation} onChange={event => this.onFieldChange("interviewLocation", event.target.value)} placeholder="Lokasi Interview"/>

                            <div className={`gray-80 mt-20`}>Catatan System:</div>
                            <TextArea showCount maxLength={200} value={this.state.interviewNotes} onChange={event => this.onFieldChange("interviewNotes", event.target.value)} placeholder="Catatan System"/>

                            <div className={`gray-80 mt-20`}>Catatan Untuk Applicant:</div>
                            <TextArea showCount maxLength={400} value={this.state.interviewNotesForApplicant} onChange={event => this.onFieldChange("interviewNotesForApplicant", event.target.value)} placeholder="Catatan Untuk Applicant"/>
                        </Col>
                    </Row>

                    <div>

                    </div>
                    <div style={{paddingTop: 20, textAlign: "right"}}>
                        <Divider/>
                        <Button onClick={event => this.onCancel()} style={{marginRight: 7}}>Cancel</Button>
                        <Button onClick={event => this.onSubmit()} loading={this.state.loading} type="primary" style={{marginRight: 7}}>Create</Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ScheduleInterview;
