import React, {Component} from 'react';
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import {UserLedgerStates} from "./types/UserLedgerTypes";
import {userServices} from "../../services/amartaVip/UserService";
import {Button, Modal, Table} from "antd";
import currencyMillionFormat from "../../helper/currencyFormat";
import DetailUserLedger from "../../component/detailData/DetailUserLedger";

export interface UserLedgerProps {
}

class UserLedger extends Component<UserLedgerProps & any, UserLedgerStates> {
    private cookies = new Cookies();

    constructor(props: UserLedgerProps & any) {
        super(props);

        this.state = {
            token: this.cookies.get('_vip-t') ?? "",
            processingFetchData: false,
            dataAllLedger: [],
            chosenLedger: {},
            showModalDetailLedger: false
        }
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    fetchUserData = async () => {
        try {
            const fetchResult = await userServices.getUserLedger({ledgerCode: "ALL", idCardNumber: "ALL"});
            await this.promisedSetState({
                processingFetchData: false,
                dataAllLedger: fetchResult?.data?.data,
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetchData: false,
                dataAllLedger: {},
            });
        }
    }

    async componentDidMount() {
        this.fetchUserData();
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`UserVIP`} breadcrumbMenu={`User Ledger`} defaultSelectedKeys={['m-ledgeruservip']} defaultOpenKeys={['sm-uservip', 'sm-data-uservip']}>
                    <div className={`p-5`}>
                        <Table
                            pagination={false}
                            size={"small"}
                            loading={this.state.processingFetchData}
                            dataSource={this.state.dataAllLedger}
                            rowKey={'id_card_number'}
                            columns={[
                                {
                                    title: 'Nomor KTP',
                                    dataIndex: 'id_card_number',
                                    render: ((value: string, row: any) => {
                                        return <React.Fragment>
                                            <span onClick={() => this.setState({chosenLedger: row, showModalDetailLedger: true})} className={`font-90-link`}>{value}</span>
                                        </React.Fragment>
                                    })
                                }, {
                                    title: 'Nama',
                                    dataIndex: 'full_name',
                                    render: ((value: string, row: any) => {
                                        return <React.Fragment>
                                            <span className={`font-90`}>{value}</span>
                                        </React.Fragment>
                                    })
                                }, {
                                    title: 'Ledger Code',
                                    dataIndex: 'ledger_code',
                                    render: ((value: string, row: any) => {
                                        return <React.Fragment>
                                            <span className={`font-90`}>{value?.replace("_", " ")}</span>
                                        </React.Fragment>
                                    })
                                }, {
                                    title: 'Transaksi Terbaru',
                                    dataIndex: 'latest_transaction_time',
                                    render: ((value: string, row: any) => {
                                        return <React.Fragment>
                                            <span className={`font-90`}>{value?.slice(0, 16)?.replace("T", " ")}</span>
                                        </React.Fragment>
                                    })
                                }, {
                                    title: 'Mata Uang',
                                    dataIndex: 'currency',
                                    render: ((value: string, row: any) => {
                                        return <React.Fragment>
                                            <span className={`font-90`}>{value?.slice(0, 16)?.replace("T", " ")}</span>
                                        </React.Fragment>
                                    })
                                }, {
                                    title: 'Saldo',
                                    dataIndex: 'balance_amount',
                                    render: ((value: string, row: any) => {
                                        return <React.Fragment>
                                            <span className={`font-90`}>{currencyMillionFormat(value)}</span>
                                        </React.Fragment>
                                    })
                                }
                            ]}
                        />
                    </div>
                </AppLayout>

                <Modal
                    open={this.state.showModalDetailLedger}
                    title={`Detail Ledger`}
                    onOk={() => this.setState({showModalDetailLedger: false})}
                    onCancel={() => this.setState({showModalDetailLedger: false})}
                    width={1000}
                    style={{top: 10}}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button key="back" type="default" onClick={() => this.setState({showModalDetailLedger: false})}>Tutup</Button>,
                    ]}
                >
                    <DetailUserLedger idCardNumber={this.state.chosenLedger?.id_card_number} ledgerCode={this.state.chosenLedger?.ledger_code} time={Math.floor(+new Date() / 1000)}/>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withAuth(UserLedger, 30);