import React, {Component} from 'react';
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import {UserDetailStates} from "./types/UserDetailTypes";
import {userServices} from "../../services/amartaVip/UserService";
import {Select, Form, Input, Button, Empty, Spin, Tabs} from "antd";
import DetailUser from "../../component/detailData/DetailUser";
import DetailIdeal from "../../component/detailData/DetailIdeal";

export interface UpdateUserProps {
}

class UpdateUser extends Component<UpdateUserProps & any, UserDetailStates> {
    private cookies = new Cookies();

    constructor(props: UpdateUserProps & any) {
        super(props);

        this.state = {
            token: this.cookies.get('_vip-t') ?? "",
            searchType: "IDCARD",
            processingFetchData: false,
            searchField: "",
            dataDetailUser: {},
        }
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    fetchUserData = async () => {
        if (this.state.searchType && this.state.searchField) {
            await this.promisedSetState({
                processingFetchData: true,
                dataDetailUser: {},
            });

            try {
                await userServices.setToken(this.state.token);
                const fetchResult = await userServices.getDetailUser({type: this.state.searchType, searchText: this.state.searchField});
                await this.promisedSetState({
                    processingFetchData: false,
                    dataDetailUser: fetchResult?.data?.data,
                });
            } catch (e) {
                await this.promisedSetState({
                    processingFetchData: false,
                    dataDetailUser: {},
                });
            }
        }
    }

    async componentDidMount() {

    }

    onFieldChange = async <T extends keyof Pick<any, "searchType" | "searchField">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "searchType":
                currentState.searchType = value;
                currentState.searchField = "";
                break;
            case "searchField":
                currentState.searchField = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`UserVIP`} breadcrumbMenu={`User Detail`} defaultSelectedKeys={['m-detailuservip']} defaultOpenKeys={['sm-uservip']}>
                    <div className={`p-5`}>
                        <Form layout={`inline`} style={{marginTop: -3}}>
                            <Form.Item>
                                <i className={`small-text-grey`}>Search Type:</i><br/>
                                <Select
                                    style={{minWidth: 200}}
                                    showSearch
                                    placeholder="Choose SearchType"
                                    optionFilterProp="children"
                                    onChange={value => this.onFieldChange("searchType", value)}
                                    value={this.state.searchType}
                                >
                                    <Select.Option key={`IDCARD`} value={`IDCARD`}>{`ID Card`}</Select.Option>
                                    <Select.Option key={`PHONENUMBER`} value={`PHONENUMBER`}>{`Phone Number`}</Select.Option>
                                    <Select.Option key={`EMAIL`} value={`EMAIL`}>{`Email`}</Select.Option>
                                    <Select.Option key={`AGENTCODE`} value={`AGENTCODE`}>{`Agent / Affiliate Code`}</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>Search Field:</i><br/>
                                <Input value={this.state.searchField} onChange={event => this.onFieldChange("searchField", event.target.value)} placeholder="Search Field"/>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>&nbsp;</i><br/>
                                <Button loading={this.state.processingFetchData} onClick={this.fetchUserData} type={"primary"}> Cari Data </Button>
                            </Form.Item>
                        </Form>
                    </div>

                    <div style={this.state.processingFetchData ? {} : {display: 'none'}} className={`spinCenter`}>
                        <Spin size="large"/>
                    </div>

                    <div style={(!this.state.dataDetailUser?.name && !this.state.processingFetchData) ? {} : {display: 'none'}}>
                        <Empty/>
                    </div>

                    <div className={`p-5`} style={(this.state.dataDetailUser?.name) ? {} : {display: 'none'}}>
                        <Tabs
                            items={[
                                {
                                    label: 'AmartaVip User',
                                    key: '1',
                                    children: <DetailUser dataDetailUser={this.state.dataDetailUser} showUserType={true} showIdCard={true} showOtherData={true}/>
                                }, {
                                    label: 'Ideal User',
                                    key: '2',
                                    children: <DetailIdeal onSuccesVerifyProps={this.fetchUserData} dataDetailUser={this.state.dataDetailUser} updateData={false} uid={this.state.dataDetailUser?.uid} time={Math.floor(+new Date() / 1000)}/>
                                },
                            ]}
                        />
                    </div>

                </AppLayout>
            </React.Fragment>
        );
    }
}

export default withAuth(UpdateUser, 8);
