import React, {Component} from "react";
import Cookies from "universal-cookie";
import {Divider, Spin, Table} from "antd";
import {userServices} from "../../services/amartaVip/UserService";
import dayjs from "dayjs";
import currencyMillionFormat from "../../helper/currencyFormat";

class DetailUserLedger extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            idCardNumber: "",
            processingFetch: false,
            dataDetailLedger: [],
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            idCardNumber: this.props.idCardNumber,
        });
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.time !== prevProps.time) || (this.props.idCardNumber !== prevProps.idCardNumber)) {
            await this.promisedSetState({
                idCardNumber: this.props.idCardNumber,
            });
            await this.fetchData();
        }
    }

    fetchData = async () => {
        this.setState({
            processingFetch: true
        });
        try {
            const fetchResult = await userServices.getUserLedger({
                idCardNumber: this.props.idCardNumber,
                ledgerCode: this.props.ledgerCode,
                transactionStartDate: "2020-01-01",
                transactionEndDate: dayjs().format('YYYY-MM-DD'),
            });
            const sortedData = (fetchResult?.data?.data || []).sort((a: any, b: any) => {
                const dateA = new Date(a.transaction_time);
                const dateB = new Date(b.transaction_time);
                return dateB.getTime() - dateA.getTime(); // Descending order
            });

            await this.promisedSetState({
                processingFetch: false,
                dataDetailLedger: sortedData,
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetch: false,
                dataDetailLedger: [],
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.processingFetch ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>

                <Divider/>

                <Divider/>

                <Table
                    pagination={false}
                    size={"small"}
                    loading={this.state.processingFetch}
                    dataSource={this.state.dataDetailLedger}
                    rowKey={'transaction_id'}
                    columns={[
                        {
                            title: 'Transaksi ID',
                            dataIndex: 'transaction_id',
                            render: ((value: string, row: any) => {
                                return <React.Fragment>
                                    <span className={`font-90`}>{value}</span>
                                </React.Fragment>
                            })
                        }, {
                            title: 'Tipe Transaksi',
                            dataIndex: 'transaction_type',
                            render: ((value: string, row: any) => {
                                return <React.Fragment>
                                    <span className={`font-90`}>{value}</span>
                                </React.Fragment>
                            })
                        }, {
                            title: 'Transaksi Terbaru',
                            dataIndex: 'transaction_time',
                            render: ((value: string, row: any) => {
                                return <React.Fragment>
                                    <span className={`font-90`}>{value?.slice(0, 16)?.replace("T", " ")}</span>
                                </React.Fragment>
                            })
                        }, {
                            title: 'Nilai Transaksi',
                            dataIndex: 'amount',
                            render: ((value: string, row: any) => {
                                return <React.Fragment>
                                    <span className={`font-90`}>{currencyMillionFormat(value)}</span>
                                </React.Fragment>
                            })
                        }, {
                            title: 'Saldo Transaksi',
                            dataIndex: 'balance_amount',
                            render: ((value: string, row: any) => {
                                return <React.Fragment>
                                    <span className={`font-90`}>{currencyMillionFormat(value)}</span>
                                </React.Fragment>
                            })
                        }, {
                            title: 'Sumber',
                            dataIndex: 'source',
                            render: ((value: string, row: any) => {
                                return <React.Fragment>
                                    <span className={`font-90`}>{value}</span>
                                </React.Fragment>
                            })
                        }, {
                            title: 'External ID',
                            dataIndex: 'external_id',
                            render: ((value: string, row: any) => {
                                return <React.Fragment>
                                    <span className={`font-90`}>{value}</span>
                                </React.Fragment>
                            })
                        },
                    ]}
                />
            </React.Fragment>
        );
    }
}

export default DetailUserLedger;