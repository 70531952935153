import React, {Component} from 'react'
import AppLayout from "../../component/layouts/AppLatout"
import Cookies from 'universal-cookie'
import dayjs from "dayjs";
import withAuth from "../../hoc/withAuth"
import {UpdateUserStates} from "./types/UpdateUserTypes"
import {userServices} from "../../services/amartaVip/UserService"
import {Select, Form, Input, Button, Empty, Spin, Divider, Modal, Row, Col, Dropdown, Space, Collapse, Upload, Tabs} from "antd"
import DetailUser from "../../component/detailData/DetailUser"
import {DownOutlined, FormOutlined} from "@ant-design/icons"
import {cdnApiService} from "../../services/trimitraCdn/CdnApiService"
import CropSelfieImage from "../../component/image/CropSelfieImage"
import {baseUrlCdnUpload} from "../../config/apiConfig/apiConfig"
import UploadOutlined from "@ant-design/icons/UploadOutlined"
import UpdateAdditionalData from "../../component/detailData/UpdateAdditionalData"
import DetailIdeal from "../../component/detailData/DetailIdeal"
import UpdateCoverageArea from "../../component/detailData/UpdateCoverageArea"
import UpdateUserSchemaTarget from "../../component/detailData/UpdateUserSchemaTarget"
import {allowedOrganization} from "../../config/appDataConfig/organizationCondig"
import UpdateSupervisorPemission from "../../component/detailData/UpdateSupervisorPemission";

const {Panel} = Collapse

export interface UpdateUserProps {
}

class UpdateUser extends Component<UpdateUserProps & any, UpdateUserStates> {
    private cookies = new Cookies()

    constructor(props: UpdateUserProps & any) {
        super(props)

        this.state = {
            token: this.cookies.get('_vip-t') ?? "",
            searchType: "IDCARD",
            searchField: "",
            processingFetchData: false,
            dataDetailUser: {},
            showModalConfirmChangeInternal: false,
            submitChangeInternal: false,
            showModalUploadIdCardOwner: false,
            showOldUploadRawImage: false,
            freezeStatus: false,
            base64IdCardOwner: "",
            idCardNumber: "",
            idCardImageUrl: "",
            showModalUpdatePhoneNumber: false,
            organizationCode: null,
            showModalUpdateDefaultOrganization: false,
            oldPhoneNumber: "",
            newPhoneNumber: "",
            tempNewAgentCode: "",
            tempNewUserType: "",
            tempAffiliateType: "",
            tempAffiliateCompanyCode: "",
            tempAffiliateCompanyName: "",
            tempAffiliateFincoId: "",
            showModalUpdateAdditionalData: false,
            showModalUpdateCoverageArea: false,
            showModalUpdateSpvPermission: false,
            showModalUpdateUserSchemaTarget: false,
        }
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    fetchUserData = async () => {
        if (this.state.searchType && this.state.searchField) {
            await this.promisedSetState({
                processingFetchData: true,
                dataDetailUser: {},
            })

            try {
                await userServices.setToken(this.state.token)
                const fetchResult = await userServices.getDetailUser({type: this.state.searchType, searchText: this.state.searchField})
                await this.promisedSetState({
                    processingFetchData: false,
                    dataDetailUser: fetchResult?.data?.data,
                })
            } catch (e) {
                await this.promisedSetState({
                    processingFetchData: false,
                    dataDetailUser: {},
                })
            }
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "organizationCode" | "newPhoneNumber" | "searchType" | "searchField" | "tempNewAgentCode" | "tempNewUserType" | "tempAffiliateType" | "tempAffiliateCompanyCode" | "tempAffiliateCompanyName" | "tempAffiliateFincoId">>(target: T, value: any) => {
        const currentState: any = {...this.state}
        switch (target) {
            case "searchType":
                currentState.searchType = value
                currentState.searchField = ""
                break
            case "organizationCode":
                currentState.organizationCode = value
                break
            case "searchField":
                currentState.searchField = value
                break
            case "tempNewAgentCode":
                currentState.tempNewAgentCode = value
                break
            case "newPhoneNumber":
                currentState.newPhoneNumber = value
                break
            case "tempNewUserType":
                currentState.tempNewUserType = value
                break
            case "tempAffiliateType":
                currentState.tempAffiliateType = value
                break
            case "tempAffiliateCompanyCode":
                currentState.tempAffiliateCompanyCode = value
                break
            case "tempAffiliateCompanyName":
                currentState.tempAffiliateCompanyName = value
                break
            case "tempAffiliateFincoId":
                currentState.tempAffiliateFincoId = value
                break
        }
        await this.promisedSetState({
            ...currentState,
        })
    }


    confirmUpdateOrganization = async () => {
        if (!this.state.organizationCode) {
            return Modal.error({title: 'Error', content: 'lengkapi data'})
        }
        this.setState({submitChangeInternal: true})

        const pickedOrganization: any = allowedOrganization?.find(o => o?.companyCode === this.state.organizationCode?.toUpperCase()) ?? {};
        let defaultOrganizationId = pickedOrganization.copanCode;

        try {
            await userServices.setToken(this.state.token)
            await userServices.updateUserInternal({
                type: "update_default_organization",
                uid: this.state.dataDetailUser.uid,
                user_type: this.state.dataDetailUser.user_type,
                default_organization_code: this.state.organizationCode,
                default_organization_id: defaultOrganizationId,
            }).then(async successData => {
                Modal.success({title: 'Proses Sukses', content: 'successfully updated '})
                this.setState({submitChangeInternal: false, showModalUpdateDefaultOrganization: false,})
                await this.fetchUserData()
            })
        } catch (e) {
            Modal.error({title: 'Proses Gagal', content: 'Error :' + e})
            this.setState({submitChangeInternal: false,})
        }
    }

    confirmUpdatePhoneNumber = async () => {
        if (!this.state.newPhoneNumber) {
            Modal.error({
                title: 'Error',
                content: 'lengkapi data'
            })
            return
        }

        this.setState({submitChangeInternal: true})

        try {
            await userServices.setToken(this.state.token)
            await userServices.updateUserInternal({
                type: "update_phone_number",
                phone_number: this.state.oldPhoneNumber,
                new_phone_number: this.state.newPhoneNumber,
            }).then(async successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'successfully updated '
                })
                this.setState({
                    submitChangeInternal: false,
                    showModalUpdatePhoneNumber: false,
                })
                await this.fetchUserData()
            })
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            })
            this.setState({submitChangeInternal: false,})
        }
    }

    confirmUpdateUserType = async () => {
        const idCard = this.state.dataDetailUser?.id_card?.id_card_number?.trim()
        const newAgentCode = this.state.tempNewAgentCode?.trim()
        if (this.state.tempNewUserType !== 'user') {
            if (!newAgentCode) {
                return Modal.error({title: 'Error', content: 'lengkapi kode agen'})
            }
            if (!this.state.organizationCode) {
                return Modal.error({title: 'Error', content: 'lengkapi kode organisasi'})
            }
        }

        if (!idCard) {
            return Modal.error({title: 'Error', content: 'user belum melengkapi data ktp'})
        }

        const pickedOrganization: any = allowedOrganization?.find(o => o?.companyCode === this.state?.organizationCode?.toUpperCase()) ?? {};
        let defaultOrganizationId = pickedOrganization?.copanCode;

        const dataUpdate = {
            id_card: idCard,
            agent_code: newAgentCode,
            type: `update_type_to_${this.state.tempNewUserType}`,
            affiliate_type: this.state.tempAffiliateType,
            affiliate_company_code: "",
            affiliate_company_name: "",
            affiliate_finco_id: "",
            default_organization_code: this.state.organizationCode,
            default_organization_id: defaultOrganizationId,
        }

        this.setState({submitChangeInternal: true});

        try {
            await userServices.setToken(this.state.token)
            await userServices.updateUserInternal(dataUpdate).then(async successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'successfully updated '
                })
                this.setState({
                    submitChangeInternal: false,
                    showModalConfirmChangeInternal: false,
                })
                await this.fetchUserData()
            })
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            })
            this.setState({
                submitChangeInternal: false,
            })
        }
    }

    onCLoseModalUpdateAdditionalData = async (data: any) => {
        if (data.reload) {
            await this.fetchUserData()
        }
        this.setState({showModalUpdateAdditionalData: false, showModalUpdateCoverageArea: false, showModalUpdateUserSchemaTarget: false, showModalUpdateSpvPermission: false,})
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve))

    buttonTrigger = async (data: any) => {
        if (data.key === "UPDATE_TO_INTERNAL") {
            this.setState({
                showModalConfirmChangeInternal: true,
                tempNewAgentCode: "",
                tempNewUserType: "internal",
                organizationCode: "TRIMOBI",
                tempAffiliateType: "",
                tempAffiliateCompanyCode: "",
                tempAffiliateCompanyName: "",
                tempAffiliateFincoId: "",
            })
        }
        if (data.key === "UPDATE_TO_AFFILIATE") {
            const agentCode = `AFF${dayjs().format('YYMMDD')}${Math.floor(1000000 + Math.random() * 9000000)}`;
            this.setState({
                showModalConfirmChangeInternal: true,
                tempNewAgentCode: agentCode,
                tempNewUserType: "affiliate",
                organizationCode: "TRIMOBI",
                tempAffiliateType: "internal",
                tempAffiliateCompanyCode: "",
                tempAffiliateCompanyName: "",
                tempAffiliateFincoId: "",
            })
        }
        if (data.key === "UPDATE_TO_NORMAL_USER") {
            this.setState({
                showModalConfirmChangeInternal: true,
                tempNewAgentCode: "",
                tempNewUserType: "user",
                tempAffiliateType: "",
                tempAffiliateCompanyCode: "",
                tempAffiliateCompanyName: "",
                tempAffiliateFincoId: "",
            })
        }

        if (data.key === "UPDATE_SELFIE_IMAGE") {
            this.setState({
                showModalUploadIdCardOwner: true,
                showOldUploadRawImage: false,
                freezeStatus: false,
                base64IdCardOwner: "",
                idCardNumber: this.state.dataDetailUser?.uid,
                idCardImageUrl: "",
            })
        }
        if (data.key === "UPDATE_PHONE_NUMBER") {
            this.setState({
                showModalUpdatePhoneNumber: true,
                submitChangeInternal: false,
                oldPhoneNumber: this.state.dataDetailUser?.phones[0]?.phone,
                newPhoneNumber: "",
            })
        }
        if (data.key === "UPDATE_DEFAULT_ORGANIZATION") {
            this.setState({
                showModalUpdateDefaultOrganization: true,
                submitChangeInternal: false,
                organizationCode: this.state.dataDetailUser?.default_organization_code || null,
            })
        }

        if (data.key === "UPDATE_SPV_PERMISSION") {
            this.setState({showModalUpdateSpvPermission: true})
        }

        if (data.key === "UPDATE_COVERAGE_AREA") {
            this.setState({showModalUpdateCoverageArea: true})
        }

        if (data.key === "UPDATE_ADDITIONAL_DATA") {
            this.setState({showModalUpdateAdditionalData: true})
        }

        if (data.key === "UPDATE_USER_SCHEMA_TARGET") {
            this.setState({showModalUpdateUserSchemaTarget: true})
        }
    }

    uploadImageAndUpdateState = async (type: string, dataUpload: any) => {
        if (type === "IDCARDOWNER_IMAGE") {
            if (!this.state.base64IdCardOwner) {
                Modal.error({title: 'Error', content: "Mohon Lengkapi Image Selfie Pemilik"})
                return
            }
        }

        await this.promisedSetState({freezeStatus: true})

        try {
            await cdnApiService.uploadIdCardBase64(dataUpload).then(async successData => {
                if (type === "IDCARDOWNER_IMAGE") {
                    await this.promisedSetState({
                        idCardImageUrl: successData?.data?.data?.url_document,
                    })
                }
            })
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            })
            await this.promisedSetState({freezeStatus: false})
        }
    }

    saveOwnerIdCardData = async () => {
        if (this.state.base64IdCardOwner?.length < 10) {
            if (!this.state.idCardImageUrl) {
                Modal.error({title: 'Error', content: "Terjadi error pada saat proses cropping, mohon lakukan upload image sekali lagi"})
                await this.promisedSetState({
                    showOldUploadRawImage: true,
                })
                return
            }
        } else {
            const dataUpload = {
                id_image: this.state.idCardNumber,
                image_data: this.state.base64IdCardOwner,
            }
            await this.uploadImageAndUpdateState("IDCARDOWNER_IMAGE", dataUpload)
        }

        this.setState({
            freezeStatus: true,
        })

        try {
            await userServices.setToken(this.state.token)
            await userServices.updateUserInternal({
                type: "update_selfie",
                uid: this.state.dataDetailUser.uid,
                user_type: this.state.dataDetailUser.user_type,
                url_image: this.state.idCardImageUrl,
            }).then(async successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'successfully updated '
                })
                this.setState({
                    freezeStatus: false,
                    showModalUploadIdCardOwner: false,
                })
                await this.fetchUserData()
            })
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            })
            this.setState({
                freezeStatus: false,
            })
        }
    }

    uploadImageChange = (data: any) => {
        if (data?.file?.status === "done") {
            const newImgLocation = data?.file?.response?.data?.url_document
            this.setState({
                freezeStatus: false,
                idCardImageUrl: newImgLocation,
            })
        }
        if (data?.file?.status === "uploading") {
            this.setState({
                freezeStatus: true,
            })
        }
        if (data?.file?.status === "removed") {
            this.setState({
                freezeStatus: false,
                idCardImageUrl: "",
            })
        }
    }

    onCropIdCardOwner = async (base64DataImagePng: any) => {
        await this.promisedSetState({base64IdCardOwner: base64DataImagePng})
    }

    render() {
        const items: any = [
            {
                label: 'Update User Selfie Image',
                key: 'UPDATE_SELFIE_IMAGE',
                icon: <FormOutlined/>,
            }, {
                label: 'Update User Default Organization',
                key: 'UPDATE_DEFAULT_ORGANIZATION',
                icon: <FormOutlined/>,
            }, {
                label: 'Update User SPV Permission',
                key: 'UPDATE_SPV_PERMISSION',
                icon: <FormOutlined/>,
            }, {
                label: 'Update User Schema Target',
                key: 'UPDATE_USER_SCHEMA_TARGET',
                icon: <FormOutlined/>,
            },
            {
                label: 'Update User Phone Number',
                key: 'UPDATE_PHONE_NUMBER',
                icon: <FormOutlined/>,
            }, {
                label: 'Update User Coverage Area',
                key: 'UPDATE_COVERAGE_AREA',
                icon: <FormOutlined/>,
            }, {
                label: 'Update Supervisor, Team, Track',
                key: 'UPDATE_ADDITIONAL_DATA',
                icon: <FormOutlined/>,
            }, {
                type: 'divider',
            }, {
                label: 'Update User Menjadi Internal',
                key: 'UPDATE_TO_INTERNAL',
                icon: <FormOutlined/>,
            }, {
                label: 'Update User Menjadi Affiliate',
                key: 'UPDATE_TO_AFFILIATE',
                icon: <FormOutlined/>,
            }, {
                label: 'Update User Menjadi User Normal',
                key: 'UPDATE_TO_NORMAL_USER',
                icon: <FormOutlined/>,
            }
        ]

        const menuProps = {
            items,
            onClick: this.buttonTrigger,
        }

        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`UserVIP`} breadcrumbMenu={`Update User`} defaultSelectedKeys={['m-updateuservip']} defaultOpenKeys={['sm-uservip', 'sm-management-uservip']}>
                    <div className={`p-5`}>
                        <Form layout={`inline`} style={{marginTop: -3}}>
                            <Form.Item>
                                <i className={`small-text-grey`}>Search Type:</i><br/>
                                <Select style={{minWidth: 200}} showSearch placeholder="Choose SearchType" optionFilterProp="children" onChange={value => this.onFieldChange("searchType", value)} value={this.state.searchType}>
                                    <Select.Option key={`IDCARD`} value={`IDCARD`}>{`ID Card`}</Select.Option>
                                    <Select.Option key={`PHONENUMBER`} value={`PHONENUMBER`}>{`Phone Number`}</Select.Option>
                                    <Select.Option key={`EMAIL`} value={`EMAIL`}>{`Email`}</Select.Option>
                                    <Select.Option key={`AGENTCODE`} value={`AGENTCODE`}>{`Agent / Affiliate Code`}</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>Search Field:</i><br/>
                                <Input value={this.state.searchField} onChange={event => this.onFieldChange("searchField", event.target.value)} placeholder="Search Field"/>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>&nbsp;</i><br/>
                                <Button loading={this.state.processingFetchData} onClick={this.fetchUserData} type={"primary"}> Cari Data </Button>
                            </Form.Item>
                        </Form>
                    </div>

                    <div style={this.state.processingFetchData ? {} : {display: 'none'}} className={`spinCenter`}>
                        <Spin size="large"/>
                    </div>

                    <div style={(!this.state.dataDetailUser?.name && !this.state.processingFetchData) ? {} : {display: 'none'}}>
                        <Empty/>
                    </div>

                    <div className={`p-5`} style={(this.state.dataDetailUser?.name) ? {} : {display: 'none'}}>
                        <Tabs
                            items={[
                                {
                                    label: 'AmartaVip User',
                                    key: '1',
                                    children: <>
                                        <DetailUser allowSendTelegram={true} onSuccesVerifyProps={this.fetchUserData} dataDetailUser={this.state.dataDetailUser} showUserType={true} showIdCard={true} showOtherData={true} allowVerifyBankAccount={true}/>
                                        <Row>
                                            <Col xs={24} xl={24} style={{padding: 5}}><br/>
                                                <Dropdown menu={menuProps}>
                                                    <Button type={`primary`}><Space>Multi Action<DownOutlined/></Space></Button>
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                    </>
                                }, {
                                    label: 'Ideal User',
                                    key: '2',
                                    children: <DetailIdeal onSuccesVerifyProps={this.fetchUserData} dataDetailUser={this.state.dataDetailUser} updateData={true} uid={this.state.dataDetailUser?.uid} time={Math.floor(+new Date() / 1000)}/>
                                },
                            ]}
                        />
                    </div>
                </AppLayout>

                <Modal
                    open={this.state.showModalConfirmChangeInternal}
                    title={`Change UserType to ${this.state.tempNewUserType}`}
                    onCancel={() => this.setState({showModalConfirmChangeInternal: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button loading={this.state.submitChangeInternal} type={"primary"} key="1" onClick={this.confirmUpdateUserType}>Change</Button>,
                        <Button key="2" onClick={() => this.setState({showModalConfirmChangeInternal: false})}>Close</Button>,
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <div style={this.state.tempNewUserType !== "user" ? {} : {display: 'none'}}>
                            <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Default Organization *</i>
                                <Select style={{minWidth: 200}} showSearch placeholder="Choose Organization" optionFilterProp="children" onChange={value => this.onFieldChange("organizationCode", value)} value={this.state.organizationCode}>
                                    {(allowedOrganization ?? []).map((item: any, i: number) =>
                                        <Select.Option key={i} value={item?.companyCode}>{item?.companyCode}</Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Kode Agen *</i>
                                <Input value={this.state.tempNewAgentCode} onChange={event => this.onFieldChange("tempNewAgentCode", event.target.value)} placeholder="Kode Agen"/>
                            </Form.Item>
                            <Form.Item style={this.state.tempNewUserType === "affiliate" ? {} : {display: 'none'}}>
                                <i className={`text-small-grey-nopadding`}>Tipe Affiliate *</i>
                                <Select style={{minWidth: 200}} showSearch optionFilterProp="children" onChange={value => this.onFieldChange("tempAffiliateType", value)} value={this.state.tempAffiliateType}>
                                    <Select.Option key={`internal`} value={`internal`}>{`Internal`}</Select.Option>
                                    <Select.Option key={`external`} value={`external`}>{`External`}</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item style={this.state.tempNewUserType === "affiliate" ? {} : {display: 'none'}}>
                                <i className={`text-small-grey-nopadding`}>Company Code</i>
                                <Input value={this.state.tempAffiliateCompanyCode} onChange={event => this.onFieldChange("tempAffiliateCompanyCode", event.target.value)} placeholder="Company Code"/>
                            </Form.Item>
                            <Form.Item style={this.state.tempNewUserType === "affiliate" ? {} : {display: 'none'}}>
                                <i className={`text-small-grey-nopadding`}>Company Name</i>
                                <Input value={this.state.tempAffiliateCompanyName} onChange={event => this.onFieldChange("tempAffiliateCompanyName", event.target.value)} placeholder="Company Name"/>
                            </Form.Item>
                            <Form.Item style={this.state.tempNewUserType === "affiliate" ? {} : {display: 'none'}}>
                                <i className={`text-small-grey-nopadding`}>Finco ID</i>
                                <Input value={this.state.tempAffiliateFincoId} onChange={event => this.onFieldChange("tempAffiliateFincoId", event.target.value)} placeholder="Finco Id"/>
                            </Form.Item>
                        </div>
                    </React.Fragment>
                </Modal>

                <Modal
                    title="Lengkapi Data Selfie"
                    style={{top: 2}}
                    maskClosable={false}
                    open={this.state.showModalUploadIdCardOwner}
                    onCancel={() => this.setState({showModalUploadIdCardOwner: false})}
                    footer={[
                        <Button key={'saveCustomer'} type="primary" onClick={this.saveOwnerIdCardData}> Simpan </Button>,
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalUploadIdCardOwner: false})}> Close </Button>,
                    ]}
                >
                    <Form layout="vertical" style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Collapse collapsible="header" defaultActiveKey={['1']}>
                            <Panel header="Cara Melengkapi Data" key={(this.state.base64IdCardOwner) ? "0" : "1"}>
                                <ul style={{fontSize: "80%", marginLeft: 25}}>
                                    <li>Klik Tombol Upload Gambar Selfie Pemilik</li>
                                    <li>Jika gambar telah sesuai, klik Tombol Crop Image</li>
                                    <li>Tekan Tombol Simpan</li>
                                </ul>
                            </Panel>
                        </Collapse><br/>
                        <Form.Item>
                            <CropSelfieImage params={this.state} onCropImage={this.onCropIdCardOwner}/>
                        </Form.Item>
                    </Form>

                    <Form layout="vertical" style={this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Upload
                            method="POST"
                            action={baseUrlCdnUpload + "/upload-document/id-card"}
                            listType="picture"
                            maxCount={1}
                            data={{id_card_number: this.state.idCardNumber}}
                            name="image"
                            onChange={this.uploadImageChange}
                        > <Button icon={<UploadOutlined/>}>Upload Ulang Gambar Selfie</Button>
                        </Upload>
                    </Form>
                </Modal>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}><Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/></div>
                    <div style={{textAlign: "center", padding: 20}}>Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.</div>
                </Modal>

                <Modal
                    open={this.state.showModalUpdateDefaultOrganization}
                    title="Change Default Organization"
                    onCancel={() => this.setState({showModalUpdateDefaultOrganization: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button loading={this.state.submitChangeInternal} type={"primary"} key="1" onClick={this.confirmUpdateOrganization}>Change</Button>,
                        <Button key="2" onClick={() => this.setState({showModalUpdateDefaultOrganization: false})}>Close</Button>,
                    ]}
                >
                    <>
                        <Divider/>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Default Organization *</i>
                            <Select style={{minWidth: 200}} showSearch placeholder="Choose Organization" optionFilterProp="children" onChange={value => this.onFieldChange("organizationCode", value)} value={this.state.organizationCode}>
                                {(allowedOrganization ?? []).map((item: any, i: number) =>
                                    <Select.Option key={i} value={item?.companyCode}>{item?.companyCode}</Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </>
                </Modal>

                <Modal
                    open={this.state.showModalUpdatePhoneNumber}
                    title="Change Phone Number"
                    onCancel={() => this.setState({showModalUpdatePhoneNumber: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button loading={this.state.submitChangeInternal} type={"primary"} key="1" onClick={this.confirmUpdatePhoneNumber}>Change</Button>,
                        <Button key="2" onClick={() => this.setState({showModalUpdatePhoneNumber: false})}>Close</Button>,
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Old Phone Number *</i>
                            <Input value={this.state.oldPhoneNumber} disabled={true}/>
                            <br/><br/>
                            <i className={`text-small-grey-nopadding`}>New Phone Number *</i>
                            <Input type="number" pattern="[0-9]*" value={this.state.newPhoneNumber} onChange={event => this.onFieldChange("newPhoneNumber", event.target.value)}/>
                        </Form.Item>
                    </React.Fragment>
                </Modal>

                <Modal
                    open={this.state.showModalUpdateAdditionalData}
                    title={`Update Data`}
                    onCancel={() => this.setState({showModalUpdateAdditionalData: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[]}
                >
                    <>
                        <Divider/>
                        <UpdateAdditionalData onCloseModal={this.onCLoseModalUpdateAdditionalData} data={this.state.dataDetailUser}/>
                    </>
                </Modal>

                <Modal
                    open={this.state.showModalUpdateCoverageArea}
                    title={`Update Data Coverage`}
                    onCancel={() => this.setState({showModalUpdateCoverageArea: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[]}
                >
                    <>
                        <Divider/>
                        <UpdateCoverageArea onCloseModal={this.onCLoseModalUpdateAdditionalData} data={this.state.dataDetailUser} time={Math.floor(+new Date() / 1000)}/>
                    </>
                </Modal>

                <Modal
                    open={this.state.showModalUpdateSpvPermission}
                    title={`Update SPV Permission`}
                    onCancel={() => this.setState({showModalUpdateSpvPermission: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[]}
                >
                    <>
                        <Divider/>
                        <UpdateSupervisorPemission onCloseModal={this.onCLoseModalUpdateAdditionalData} data={this.state.dataDetailUser} time={Math.floor(+new Date() / 1000)}/>
                    </>
                </Modal>

                <Modal
                    open={this.state.showModalUpdateUserSchemaTarget}
                    title={`Update User Skema`}
                    onCancel={() => this.setState({showModalUpdateUserSchemaTarget: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[]}
                >
                    <>
                        <Divider/>
                        <UpdateUserSchemaTarget onCloseModal={this.onCLoseModalUpdateAdditionalData} data={this.state.dataDetailUser} time={Math.floor(+new Date() / 1000)}/>
                    </>
                </Modal>
            </React.Fragment>
        )
    }
}

export default withAuth(UpdateUser, 9)