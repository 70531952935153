import React, {Component} from "react";
import Cookies from "universal-cookie";
import {Empty, Row, Spin, Timeline} from "antd";
import {userServices} from "../../services/amartaVip/UserService";

class DetailUserDevice extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            uid: "",
            processingFetch: false,
            dataDetailLog: [],
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            uid: this.props.uid,
        });
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.time !== prevProps.time) || (this.props.uid !== prevProps.uid)) {
            await this.promisedSetState({
                uid: this.props.uid,
            });
            await this.fetchData();
        }
    }

    fetchData = async () => {
        this.setState({
            processingFetch: true
        });
        try {
            await userServices.setToken(this.state.token);
            const fetchResult = await userServices.getListUserInternalAffiliate({type: this.props.uid + "?userdevice=true"});
            const sortedData: any = fetchResult?.data?.data?.user_device || [];

            await this.promisedSetState({
                processingFetch: false,
                dataDetailLog: sortedData,
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetch: false,
                dataDetailLog: [],
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.processingFetch ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={(this.state.dataDetailLog.length < 1 && !this.state.processingFetch) ? {} : {display: 'none'}}>
                    <Empty/>
                </div>
                <div style={!this.state.processingFetch ? {} : {display: 'none'}}>
                    <Row className={`pt-10`}>
                        <Timeline>
                            {this.state.dataDetailLog?.map((item: any, x: number) =>
                                <Timeline.Item key={x}>
                                    <table>
                                        <tr>
                                            <td><span className={`gray-80`}>Latest Update: </span></td>
                                            <td>{item?.latest_update_time?.replace("T", " ")}</td>
                                        </tr>
                                        <tr>
                                            <td><span className={`gray-80`}>Source: </span></td>
                                            <td>{item?.source}</td>
                                        </tr>
                                        <tr>
                                            <td><span className={`gray-80`}>ID: </span></td>
                                            <td>{item?.id}</td>
                                        </tr>
                                        <tr>
                                            <td><span className={`gray-80`}>device: </span></td>
                                            <td>{item?.device}</td>
                                        </tr>
                                        <tr>
                                            <td><span className={`gray-80`}>brand: </span></td>
                                            <td>{item?.brand}</td>
                                        </tr>
                                        <tr>
                                            <td><span className={`gray-80`}>model: </span></td>
                                            <td>{item?.model}</td>
                                        </tr>
                                        <tr>
                                            <td><span className={`gray-80`}>advertising_id: </span></td>
                                            <td>{item?.advertising_id}</td>
                                        </tr>
                                        <tr>
                                            <td><span className={`gray-80`}>is_physical_device: </span></td>
                                            <td>{item?.is_physical_device?.toString()}</td>
                                        </tr>
                                    </table>
                                </Timeline.Item>
                            )}
                        </Timeline>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default DetailUserDevice;