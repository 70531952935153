import axios from "axios";
import { baseUrlAmartaVip } from "../../config/apiConfig/apiConfig";

class WorksheetApiService {
  private axios = axios.create({
    baseURL: baseUrlAmartaVip,
  });

  public setToken(token: string) {
    this.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  public async importWorksheet(inputData?: any) {
    try {
      return await this.axios.post<any>("/worksheet", inputData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (e: any) {
      throw e.response.data.error.message;
    }
  }

  public async getWorksheetList(params: any) {
    const queries = {
      ...(params?.company_code && {
        company_code: params.company_code,
      }),
      ...(params?.leads_status && {
        leads_status: params.leads_status,
      }),
      ...(params?.dealer_code && {
        dealer_code: params.dealer_code,
      }),
      ...(params?.period_time && {
        period_time: params.period_time,
      }),
      ...(params?.phone && {
        phone: params.phone,
      }),
    };

    try {
      return await this.axios.get<any>(`/worksheet`, {
        params: {
          ...queries,
        },
      });
    } catch (e: any) {
      throw e.response.data.error.message;
    }
  }

  public async updateWorksheet(inputData?: any) {
    try {
      return await this.axios.put<any>("/worksheet", inputData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (e: any) {
      throw e.response.data.error.message;
    }
  }
}

export const worksheetApiService = new WorksheetApiService();
