import React, {Component} from 'react';
import {AppNotesStates} from "./types/AppNotesTypes";
import withAuth from "../../hoc/withAuth";
import Cookies from "universal-cookie";
import AppLayout from "../../component/layouts/AppLatout";
import {groupServices} from "../../services/amartaVip/GroupService";
import {Button, Form, Card, Col, Divider, Modal, Row, Input, Spin, Empty} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import parse from 'html-react-parser';
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import draftToHtml from 'draftjs-to-html';
import {EditorState, convertToRaw} from 'draft-js';

class AppNotes extends Component<any, AppNotesStates> {
    private readonly initState!: AppNotesStates;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);

        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            fetching: true,
            loading: false,
            dataListNotes: [],
            modalDelete: false,
            modalAddUpdate: false,
            updateType: "",
            confirmDelete: "",

            uid: "",
            noteTitle: "",
            noteDescription: "",
            dataInformation: EditorState.createEmpty(),
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        this.fetchSupervisor();
    }

    fetchSupervisor = async () => {
        await this.promisedSetState({
            fetching: true,
        });
        try {
            await groupServices.setToken(this.state.token);
            const fetchResult = await groupServices.getNotes({});
            this.setState({
                fetching: false,
                dataListNotes: fetchResult.data.data,
            })
        } catch (e) {
            this.setState({
                fetching: false,
                dataListNotes: [],
            })
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "noteTitle" | "noteDescription" | "confirmDelete">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "noteTitle":
                currentState.noteTitle = value;
                break;
            case "noteDescription":
                currentState.noteDescription = value;
                break;
            case "confirmDelete":
                currentState.confirmDelete = value.toUpperCase();
                break;
        }
        this.setState({
            ...currentState,
        });
    }

    onDeleteSubmit = async () => {
        const confirmDelete = this.state.confirmDelete;

        if (confirmDelete !== "DELETE") {
            return Modal.error({
                title: 'Proses Gagal',
                content: 'lengkapi konfirmasi DELETE'
            });
        }

        try {
            await groupServices.setToken(this.state.token);
            await groupServices.deleteNotes({
                app_code: "triforce",
                uid: this.state.uid
            }).then(successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'Proses Sukses '
                });
                this.setState({
                    loading: false,
                    modalDelete: false,
                });
                this.fetchSupervisor();
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            this.setState({
                loading: false,
            });
        }
    }

    onUpdateSubmit = async () => {
        await this.promisedSetState({loading: true});

        const data = {
            app_code: "triforce",
            title: this.state.noteTitle,
            description: this.state.noteDescription,
            notes: draftToHtml(convertToRaw(this.state.dataInformation.getCurrentContent())),
            active: true
        }

        try {
            await groupServices.setToken(this.state.token);
            await groupServices.addNotes(data)
                .then(successData => {
                    Modal.success({
                        title: 'Proses Sukses',
                        content: 'Proses Sukses '
                    });
                    this.setState({
                        loading: false,
                        modalAddUpdate: false,
                    });
                    this.fetchSupervisor();
                });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            this.setState({
                loading: false,
            });
        }
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    onDelete = async (data: any) => {
        this.setState({
            modalDelete: true,
            confirmDelete: "",
            uid: data.uid,
        })
    }

    onAddUpdate = async (data: any, type: string) => {
        this.setState({
            updateType: type,
            modalAddUpdate: true,
            noteTitle: "",
            noteDescription: "",
            dataInformation: EditorState.createEmpty(),
        });
    }

    onEditorStateChange = (dataInformation: any) => {
        this.setState({
            dataInformation,
        })
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`GroupVIP`} breadcrumbMenu={`App Notes`} defaultSelectedKeys={['m-appnotes']} defaultOpenKeys={['sm-groupvip']}>
                    <div className={`p-3`}>
                        <Button onClick={event => this.onAddUpdate({}, "INSERT")} className={`mt-10`} type={"primary"}>Create Notes</Button>
                        <Divider/>

                        <div style={this.state.fetching ? {} : {display: 'none'}} className={`spinCenter`}>
                            <Spin size="large"/>
                        </div>

                        <div style={((!this.state.fetching) && (this.state.dataListNotes.length < 1)) ? {} : {display: 'none'}}>
                            <Empty/>
                        </div>

                        <Row>
                            {this.state.dataListNotes?.map((item: any, x: number) =>
                                <Col key={x} className={`p-2`} xxl={{span: 12}} xl={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                    <Card hoverable={true} size="small" style={{backgroundColor: ""}}>
                                        <span style={{float: "right"}}>
                                            <Button onClick={event => this.onDelete(item)} type="primary" danger size={"small"} icon={<DeleteOutlined/>}/>
                                        </span>
                                        <b>{item?.title}</b> <br/> {item?.description}
                                        <Divider style={{marginTop: 5, marginBottom: 1}}/>
                                        <i className={`small-text-grey`}>
                                            {parse(item?.notes ?? "")}
                                        </i>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </div>
                </AppLayout>

                <Modal
                    open={this.state.modalAddUpdate}
                    title={`Notes`}
                    width={800}
                    onCancel={() => this.setState({modalAddUpdate: false})}
                    cancelText={'Close'} okText={false}
                    footer={[
                        <Button key={1} onClick={event => this.onUpdateSubmit()} loading={this.state.loading} type="primary" style={{marginRight: 7}}>Save</Button>,
                        <Button key={2} onClick={() => this.setState({modalAddUpdate: false})}>Close</Button>,
                    ]}
                >
                    <Divider style={{marginTop: 10, marginBottom: 10}}/>
                    <Form.Item>
                        <i className={`small-text-grey`}>Judul Catatan</i><br/>
                        <Input disabled={(this.state.updateType === "UPDATE")} value={this.state.noteTitle} placeholder={`Judul Catatan`} onChange={event => this.onFieldChange("noteTitle", event.target.value)}/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>Deskripsi Catatan</i><br/>
                        <Input value={this.state.noteDescription} placeholder={`Deskripsi Catatan`} onChange={event => this.onFieldChange("noteDescription", event.target.value)}/>
                    </Form.Item>
                    <Form.Item className={'padTop-7'}>
                        <i className={`small-text-grey`}>Catatan</i><br/>
                        <Editor
                            editorState={this.state.dataInformation}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={this.onEditorStateChange}
                        />
                    </Form.Item>

                    <Divider style={{marginTop: 40, marginBottom: 20}}/>
                </Modal>

                <Modal
                    open={this.state.modalDelete}
                    title={`Delete`}
                    onCancel={() => this.setState({modalDelete: false})}
                    cancelText={'Close'} okText={false}
                    footer={[
                        <Button key={1} onClick={event => this.onDeleteSubmit()} loading={this.state.loading} type="primary" style={{marginRight: 7}}>Save</Button>,
                        <Button key={2} onClick={() => this.setState({modalDelete: false})}>Close</Button>
                    ]}
                >
                    <Divider style={{marginTop: 5, marginBottom: 10}}/>
                    Apakah anda yakin akan menghapus Catatan ? <br/>
                    <i className={`small-text-grey`}>*Menghapus supervisor yang ditentukan tidak dapat dibatalkan.</i> <br/><br/>
                    Untuk mengonfirmasi penghapusan, ketik <b>DELETE</b> pada inputan berikut. <br/><br/>
                    <Input value={this.state.confirmDelete} placeholder={`ketik delete untuk konfirmasi`} onChange={event => this.onFieldChange("confirmDelete", event.target.value)}/>
                    <br/><br/>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withAuth(AppNotes, 32);