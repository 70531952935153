import React, {Component} from 'react';
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import {UserTrackMedsosStates} from "./types/UserTrackMedsosTypes";
import {Select, Form, Input, Button, Empty, Spin, Image, DatePicker, Card, Row, Col, Table} from "antd";
import dayjs from "dayjs";
import {scrapeService} from "../../services/trimitraScrape/ScrapeService"

export interface UserTrackMedsosProps {
}

class UserTrackMedsos extends Component<UserTrackMedsosProps & any, UserTrackMedsosStates> {
    private cookies = new Cookies();

    constructor(props: UserTrackMedsosProps & any) {
        super(props);

        this.state = {
            token: this.cookies.get('_vip-t') ?? "",
            processingFetchData: false,
            processingFetchTableData: false,
            searchMedia: null,
            searchField: "",
            startDate: dayjs().startOf('month'),
            dataTrackMedia: [],
            dataAccount: {}
        }
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    fetchUserData = async () => {
        if (this.state.searchField && this.state.searchMedia) {
            await this.promisedSetState({
                processingFetchData: true,
                dataTrackMedia: [],
                dataAccount: {}
            });

            const startDate = dayjs(this.state.startDate).format("YYYY-MM-DD")
            const lastDayOfMonth = dayjs(startDate).endOf('month').format('YYYY-MM-DD')

            try {
                const fetchResult = await scrapeService.getUserStatistics({organizationCode: "AMARTAVIP", media: this.state.searchMedia, userAccount: this.state.searchField, startDate: startDate, endDate: lastDayOfMonth});
                await this.promisedSetState({
                    processingFetchData: false,
                    dataTrackMedia: Object.values(fetchResult?.data?.data?.history),
                    dataAccount: fetchResult?.data?.data?.account
                });
            } catch (e) {
                await this.promisedSetState({
                    processingFetchData: false,
                    dataTrackMedia: [],
                    dataAccount: {}
                });
            }
        }
    }

    async componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const media = urlParams.get('media');
        const account = urlParams.get('account');

        if (media && account) {
            await this.promisedSetState({
                searchField: account,
                searchMedia: media.toUpperCase()
            });
        }

        await this.fetchUserData()
    }

    onFieldChange = async <T extends keyof Pick<any, "searchField" | "startDate" | "searchMedia">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "searchMedia":
                currentState.searchMedia = value;
                break;
            case "searchField":
                currentState.searchField = value;
                break;
            case "startDate":
                currentState.startDate = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {

        console.log(this.state.dataTrackMedia)

        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`UserVIP`} breadcrumbMenu={`User Track Sosmed`} defaultSelectedKeys={['m-tracksosmed']} defaultOpenKeys={['sm-uservip', 'sm-data-uservip']}>
                    <div className={`p-5`}>
                        <Form layout={`inline`} style={{marginTop: -3}}>
                            <Form.Item>
                                <i className={`small-text-grey`}>Medsos:</i><br/>
                                <Select
                                    style={{minWidth: 200}}
                                    showSearch
                                    placeholder="Choose Media"
                                    optionFilterProp="children"
                                    onChange={value => this.onFieldChange("searchMedia", value)}
                                    value={this.state.searchMedia}
                                >
                                    <Select.Option key={`TIKTOK`} value={`TIKTOK`}>{`Tiktok`}</Select.Option>
                                    <Select.Option key={`YOUTUBE`} value={`YOUTUBE`}>{`Youtube`}</Select.Option>
                                    <Select.Option key={`INSTAGRAM`} value={`INSTAGRAM`}>{`Instagram`}</Select.Option>
                                </Select>og
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>Account ID:</i><br/>
                                <Input value={this.state.searchField} onChange={event => this.onFieldChange("searchField", event.target.value)} placeholder="Search Field"/>
                            </Form.Item>
                            <Form.Item className={'padTop-10'}>
                                <i className={`small-text-grey`}>Periode:</i><br/>
                                <DatePicker picker="month" defaultValue={dayjs(this.state.startDate, 'YYYY-MM')} onChange={event => this.onFieldChange("startDate", event)} format={'YYYY-MM'}/>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>&nbsp;</i><br/>
                                <Button loading={this.state.processingFetchData} onClick={this.fetchUserData} type={"primary"}> Cari Data </Button>
                            </Form.Item>
                        </Form>
                    </div>

                    <div style={this.state.processingFetchData ? {} : {display: 'none'}} className={`spinCenter`}>
                        <Spin size="large"/>
                    </div>

                    <div style={(this.state.dataTrackMedia.length < 1 && !this.state.processingFetchData) ? {} : {display: 'none'}}>
                        <Empty/>
                    </div>

                    <div className={`p-5`} style={(this.state.dataTrackMedia.length > 0) ? {} : {display: 'none'}}>
                        <div className={`p-2`}>
                            <Card hoverable={true} bordered={true} bodyStyle={{padding: 7}}>
                                <Row>
                                    <Col xl={{span: 3}} lg={{span: 2}} md={{span: 4}} sm={{span: 4}} xs={{span: 4}}>
                                        <Image style={{width: 30, paddingTop: 5}} alt="youtube" src={`../assets/img/${this.state.dataAccount?.media?.toLowerCase()}.png`}/>
                                    </Col>
                                    <Col xl={{span: 20}} lg={{span: 20}} md={{span: 17}} sm={{span: 17}} xs={{span: 17}} style={{fontSize: "85%"}}>
                                        <i className={`small-text-grey`}>ID Account:</i> {this.state.dataAccount?.user_account}<br/>
                                        <div style={((this.state.dataAccount.media === "TIKTOK")) ? {} : {display: 'none'}}>
                                            https://www.tiktok.com/@{this.state.dataAccount?.user_account}
                                        </div>
                                        <div style={((this.state.dataAccount.media === "INSTAGRAM")) ? {} : {display: 'none'}}>
                                            https://www.instagram.com/{this.state.dataAccount?.user_account}
                                        </div>
                                        <div style={((this.state.dataAccount.media === "YOUTUBE")) ? {} : {display: 'none'}}>
                                            https://www.youtube.com/@{this.state.dataAccount?.user_account}
                                        </div>
                                        <i className={`small-text-grey`}>Exist Account:</i> {(this.state.dataAccount?.exist_account) ? "True" : "False"}<br/>
                                        <i className={`small-text-grey`}>Latest Scrape Time:</i> {this.state.dataAccount?.latest_scrape_time?.slice(0, 16)?.replace("T", " ")}<br/>
                                        <div style={((this.state.dataAccount.media === "TIKTOK")) ? {} : {display: 'none'}}>
                                            <i className={`small-text-grey`}>Account Name:</i> {this.state.dataAccount?.latest_scrape_data?.account_name}<br/>
                                            <i className={`small-text-grey`}>Count Followers:</i> {this.state.dataAccount?.latest_scrape_data?.count_followers}<br/>
                                            <i className={`small-text-grey`}>Count Following:</i> {this.state.dataAccount?.latest_scrape_data?.count_following}<br/>
                                            <i className={`small-text-grey`}>Count Likes:</i> {this.state.dataAccount?.latest_scrape_data?.count_likes}<br/>
                                            <i className={`small-text-grey`}>Count Videos:</i> {this.state.dataAccount?.latest_scrape_data?.count_videos}
                                        </div>
                                        <div style={((this.state.dataAccount.media === "INSTAGRAM")) ? {} : {display: 'none'}}>
                                            <i className={`small-text-grey`}>Account Name:</i> {this.state.dataAccount?.latest_scrape_data?.account_name}<br/>
                                            <i className={`small-text-grey`}>Count Followers:</i> {this.state.dataAccount?.latest_scrape_data?.count_followers}<br/>
                                            <i className={`small-text-grey`}>Count Following:</i> {this.state.dataAccount?.latest_scrape_data?.count_following}<br/>
                                            <i className={`small-text-grey`}>Count Posts:</i> {this.state.dataAccount?.latest_scrape_data?.count_posts}
                                        </div>
                                        <div style={((this.state.dataAccount.media === "YOUTUBE")) ? {} : {display: 'none'}}>
                                            <i className={`small-text-grey`}>Account Name:</i> {this.state.dataAccount?.latest_scrape_data?.account_name}<br/>
                                            <i className={`small-text-grey`}>Count Subscribers:</i> {this.state.dataAccount?.latest_scrape_data?.count_subscribers}<br/>
                                            <i className={`small-text-grey`}>Count Videos:</i> {this.state.dataAccount?.latest_scrape_data?.count_videos}
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </div>

                        <div style={((this.state.dataAccount.media === "TIKTOK")) ? {} : {display: 'none'}}>
                            <Table
                                style={{marginTop: 10}}
                                pagination={false}
                                size={"small"}
                                dataSource={this.state.dataTrackMedia}
                                rowKey={'date'}
                                columns={[
                                    {
                                        title: 'Date',
                                        dataIndex: 'date',
                                        render: ((value: string) => {
                                            return <React.Fragment><span className={`font-90`}>{value?.slice(0, 16)?.replace("T", " ")}</span></React.Fragment>
                                        })
                                    }, {
                                        title: 'Followers',
                                        dataIndex: 'scrape_data',
                                        render: ((value: any) => {
                                            return <React.Fragment><span className={`font-90`}>{(value?.count_followers || 0).toLocaleString('en-US')}</span></React.Fragment>
                                        })
                                    }, {
                                        title: 'Following',
                                        dataIndex: 'scrape_data',
                                        render: ((value: any) => {
                                            return <React.Fragment><span className={`font-90`}>{(value?.count_following || 0).toLocaleString('en-US')}</span></React.Fragment>
                                        })
                                    }, {
                                        title: 'Likes',
                                        dataIndex: 'scrape_data',
                                        render: ((value: any) => {
                                            return <React.Fragment><span className={`font-90`}>{(value?.count_likes || 0).toLocaleString('en-US')}</span></React.Fragment>
                                        })
                                    }, {
                                        title: 'Videos',
                                        dataIndex: 'scrape_data',
                                        render: ((value: any) => {
                                            return <React.Fragment><span className={`font-90`}>{(value?.count_videos || 0).toLocaleString('en-US')}</span></React.Fragment>
                                        })
                                    }
                                ]}
                            />
                        </div>
                        <div style={((this.state.dataAccount.media === "INSTAGRAM")) ? {} : {display: 'none'}}>
                            <Table
                                style={{marginTop: 10}}
                                pagination={false}
                                size={"small"}
                                dataSource={this.state.dataTrackMedia}
                                rowKey={'date'}
                                columns={[
                                    {
                                        title: 'Date',
                                        dataIndex: 'date',
                                        render: ((value: string) => {
                                            return <React.Fragment><span className={`font-90`}>{value?.slice(0, 16)?.replace("T", " ")}</span></React.Fragment>
                                        })
                                    }, {
                                        title: 'Followers',
                                        dataIndex: 'scrape_data',
                                        render: ((value: any) => {
                                            return <React.Fragment><span className={`font-90`}>{(value?.count_followers || 0).toLocaleString('en-US')}</span></React.Fragment>
                                        })
                                    }, {
                                        title: 'Following',
                                        dataIndex: 'scrape_data',
                                        render: ((value: any) => {
                                            return <React.Fragment><span className={`font-90`}>{(value?.count_following || 0).toLocaleString('en-US')}</span></React.Fragment>
                                        })
                                    }, {
                                        title: 'Post',
                                        dataIndex: 'scrape_data',
                                        render: ((value: any) => {
                                            return <React.Fragment><span className={`font-90`}>{(value?.count_posts || 0).toLocaleString('en-US')}</span></React.Fragment>
                                        })
                                    }
                                ]}
                            />
                        </div>
                        <div style={((this.state.dataAccount.media === "YOUTUBE")) ? {} : {display: 'none'}}>
                            <Table
                                style={{marginTop: 10}}
                                pagination={false}
                                size={"small"}
                                dataSource={this.state.dataTrackMedia}
                                rowKey={'date'}
                                columns={[
                                    {
                                        title: 'Date',
                                        dataIndex: 'date',
                                        render: ((value: string) => {
                                            return <React.Fragment><span className={`font-90`}>{value?.slice(0, 16)?.replace("T", " ")}</span></React.Fragment>
                                        })
                                    }, {
                                        title: 'Subscribers',
                                        dataIndex: 'scrape_data',
                                        render: ((value: any) => {
                                            return <React.Fragment><span className={`font-90`}>{(value?.count_subscribers || 0).toLocaleString('en-US')}</span></React.Fragment>
                                        })
                                    }, {
                                        title: 'Count Videos',
                                        dataIndex: 'scrape_data',
                                        render: ((value: any) => {
                                            return <React.Fragment><span className={`font-90`}>{(value?.count_videos || 0).toLocaleString('en-US')}</span></React.Fragment>
                                        })
                                    }
                                ]}
                            />
                        </div>

                    </div>

                </AppLayout>
            </React.Fragment>
        );
    }
}

export default withAuth(UserTrackMedsos, 28);