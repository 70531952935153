import React, {Component} from 'react';
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import {ListInterviewStates} from "./types/ListInterviewTypes";
import {SearchOutlined, DownOutlined, CopyOutlined, FormOutlined, ScheduleOutlined} from "@ant-design/icons";
import {vacancyApiService} from "../../services/amartaVip/VacancyApiService";
import DatePicker from "antd/lib/date-picker";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {Form, Select, Dropdown, Menu, Input, Tooltip, Table, Button, Divider, Tag} from "antd";
import Modal from "antd/lib/modal";
import DetailAssessment from "../../component/detailData/DetailAssessment";
import UpdateAssessmentStatus from "../../component/pageSection/UpdateAssessmentStatus";
import {allowedOrganization} from "../../config/appDataConfig/organizationCondig";

dayjs.extend(customParseFormat);

export interface ListInterviewProps {
}

class ListInterview extends Component<ListInterviewProps & any, ListInterviewStates> {
    private cookies = new Cookies();
    private tableColumn: any = [
        {
            title: 'Code',
            dataIndex: 'assessment_code',
            render: ((value: any, row: any) => {
                return <React.Fragment>
                    <span onClick={event => this.showAssessmentDetail(value, row)} style={{color: "#2f57da", fontWeight: 500, cursor: "pointer"}} className={`font-90`}>{value}</span>
                    <Tooltip title="Click untuk copy code" color={'cyan'}>
                        &nbsp; <CopyOutlined className={'small-avatar-link'} onClick={event => this.copyToClipboard(value)}/>
                    </Tooltip><br/>
                    <div style={row.status === "OPEN" ? {} : {display: 'none'}}>
                        <Tag style={{fontSize: "70%", display: "inline", padding: 2, color: "#fff", backgroundColor: "#7c7d80"}}>Scheduled Interview </Tag>
                    </div>
                    <div style={row.status === "REJECTED" ? {} : {display: 'none'}}>
                        <Tag style={{fontSize: "70%", display: "inline", padding: 2, color: "#fff", backgroundColor: "#962929"}}>Rejected</Tag>
                    </div>
                    <div style={row.status === "ACCEPTED" ? {} : {display: 'none'}}>
                        <Tag style={{fontSize: "70%", display: "inline", padding: 2, color: "#fff", backgroundColor: "#353cc4"}}>Accepted</Tag>
                    </div>
                </React.Fragment>
            })
        },
        {
            title: 'Date',
            dataIndex: 'date',
            render: ((value: number, row: any) => {
                return <React.Fragment>
                    <span className={`gray-90`}>{dayjs(row?.date).format("YYYY-MM-DD HH:mm:ss")}</span>
                </React.Fragment>
            })
        },
        {
            title: 'Vacancy',
            dataIndex: 'vacancy_code',
            render: ((value: number, row: any) => {
                return <React.Fragment>
                    <span style={{color: "#da2f2f"}} className={`font-90`}>{value}</span>
                    <Tooltip title="Click untuk copy code" color={'cyan'}>
                        &nbsp; <CopyOutlined className={'small-avatar-link'} onClick={event => this.copyToClipboard(value)}/>
                    </Tooltip><br/>
                    <span className={`font-90`}>{row.vacancy_name}</span><br/>
                </React.Fragment>
            })
        },
        {
            title: 'User',
            dataIndex: 'vacancy_code',
            render: ((value: number, row: any) => {
                return <React.Fragment>
                    <span className={`font-90`}>{row?.user_data?.full_name}</span><br/>
                    <span className={`gray-80`}>{row?.user_data?.phones} - {row?.user_data?.email}</span>
                </React.Fragment>
            })
        },
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: ((row: any) => {
                const menu = (
                    <Menu>
                        <Menu.Item key={1}>
                            <span onClick={event => this.showAssessmentDetail(row?.assessment_code, row)} style={{cursor: "pointer"}}> <SearchOutlined/> Lihat Detail</span>
                        </Menu.Item>
                        <Menu.Item key={2} style={row.status === "OPEN" ? {} : {display: 'none'}}>
                            <span onClick={event => this.showUpdateAssessment(row?.assessment_code, row)} style={{cursor: "pointer"}}> <FormOutlined/> Update Status Interview</span>
                        </Menu.Item>
                    </Menu>
                );

                return <React.Fragment>
                    <span className={`font-90`}>
                        <Dropdown overlay={menu} placement="bottomLeft" arrow>
                          <Button size={"small"} type={"primary"}> <ScheduleOutlined/> Aksi</Button>
                        </Dropdown>
                    </span>
                </React.Fragment>
            })
        },
    ];

    constructor(props: ListInterviewProps & any) {
        super(props);

        this.state = {
            token: this.cookies.get('_vip-t') ?? "",
            organization: null,
            startDate: dayjs().startOf('month'),
            endDate: dayjs(),
            processingFetchTableData: false,
            pageSize: 100,
            dataTable: [],
            searchType: "date",
            searchInterviewCode: "",
            chosenInterviewCode: "",
            chosenApplyCode: "",
            showModalDetail: false,
            showModalUpdate: false,
        }
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    copyToClipboard = async (data: any) => {
        await navigator.clipboard.writeText(data);
    }

    onFieldChange = async <T extends keyof Pick<any, "organization" | "searchType" | "searchInterviewCode" | "startDate" | "endDate">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "organization":
                currentState.organization = value;
                break;
            case "searchType":
                currentState.searchType = value;
                break;
            case "searchInterviewCode":
                currentState.searchInterviewCode = value;
                break;
            case "startDate":
                currentState.startDate = value;
                break;
            case "endDate":
                currentState.endDate = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    fetchData = async () => {
        if (this.state.searchType === "apply_code") {
            Modal.error({
                title: 'Error',
                content: 'search by apply code Under Construct'
            });
            return;
        }

        if (this.state.searchType === "date") {
            if (!this.state.organization) {
                Modal.error({
                    title: 'Error',
                    content: 'Organization Required'
                });
                return;
            }
        }

        await this.promisedSetState({
            processingFetchTableData: true,
            dataTable: [],
        });

        try {
            await vacancyApiService.setToken(this.state.token);
            const fetchResult = await vacancyApiService.getOrganizationInterview({
                organization: this.state.organization,
                startDate: dayjs(this.state.startDate).format("YYYY-MM-DD"),
                endDate: dayjs(this.state.endDate).format("YYYY-MM-DD"),
            });
            await this.promisedSetState({
                processingFetchTableData: false,
                dataTable: fetchResult?.data?.data,
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetchTableData: false,
                dataTable: [],
            });
        }
    }

    onChangeTable = (pagination: any, filters: any, sorter: any) => {
        if (pagination?.pageSize) {
            this.setState({
                pageSize: pagination?.pageSize,
            });
        }
    }

    showAssessmentDetail = (applyCode: string, row: any) => {
        this.setState({
            chosenInterviewCode: applyCode,
            showModalDetail: true,
            chosenApplyCode: row.apply_code,
        });
    }

    showUpdateAssessment = (applyCode: string, row: any) => {
        this.setState({
            chosenInterviewCode: applyCode,
            showModalUpdate: true,
            chosenApplyCode: row.apply_code,
        });
    }

    onCLoseModalUpdateAssessment = async (data: any) => {
        if (data.reload) {
            await this.fetchData();
        }
        this.setState({
            showModalUpdate: false,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`List Interview`} breadcrumbMenu={`Talent`} defaultSelectedKeys={['m-interview']} defaultOpenKeys={['sm-talent']}>

                    <div className={`p-5`}>
                        <Form layout={`inline`}>
                            <Form layout={`inline`} style={this.state.searchType === "apply_code" ? {} : {display: 'none'}}>
                                <Form.Item className={'pt-2'} style={{width: 230}}>
                                    <i className={`small-text-grey`}>Kode Interview</i>
                                    <Input value={this.state.searchInterviewCode} onChange={event => this.onFieldChange("searchInterviewCode", event.target.value)} placeholder="Kode Interview"/>
                                </Form.Item>
                            </Form>
                            <Form layout={`inline`} style={this.state.searchType === "date" ? {} : {display: 'none'}}>
                                <Form.Item className={'pt-2'} style={{width: 230}}>
                                    <i className={`small-text-grey`}>Talent Organization:</i>
                                    <Select
                                        style={{minWidth: 200}}
                                        showSearch
                                        placeholder="Choose Organization"
                                        optionFilterProp="children"
                                        onChange={value => this.onFieldChange("organization", value)}
                                        value={this.state.organization}
                                    >
                                        {(allowedOrganization ?? []).map((item: any, i: number) =>
                                            <Select.Option key={i} value={item?.amartaVipCode}>{item?.companyCode}</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                                <Form.Item className={'pt-2'} label="">
                                    <i className={`small-text-grey`}>Start Date</i><br/>
                                    <DatePicker defaultValue={dayjs(this.state.startDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("startDate", event)} format={'YYYY-MM-DD'}/>
                                </Form.Item>
                                <Form.Item className={'pt-2'} label="">
                                    <i className={`small-text-grey`}>End Date</i><br/>
                                    <DatePicker defaultValue={dayjs(this.state.endDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("endDate", event)} format={'YYYY-MM-DD'}/>
                                </Form.Item>
                            </Form>

                            <Form.Item className={'pt-20'}>
                                <Dropdown.Button
                                    type="primary"
                                    style={{marginTop: 22}}
                                    onClick={this.fetchData}
                                    icon={<DownOutlined/>}
                                    trigger={['click']}
                                    overlay={(
                                        <Menu>
                                            <Menu.Item key={1} onClick={event => this.onFieldChange("searchType", "date")}>
                                                Pencarian Tanggal
                                            </Menu.Item>
                                            <Menu.Item key={2} onClick={event => this.onFieldChange("searchType", "apply_code")}>
                                                Pencarian Kode Interview
                                            </Menu.Item>
                                        </Menu>
                                    )}
                                ><SearchOutlined/> Cari Data</Dropdown.Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <Table
                        rowKey={'assessment_code'}
                        size={'small'}
                        scroll={{x: 'calc(500px + 50%)'}}
                        loading={this.state.processingFetchTableData}
                        columns={this.tableColumn}
                        dataSource={this.state.dataTable}
                        pagination={{pageSize: this.state.pageSize}}
                        onChange={this.onChangeTable}
                    />
                </AppLayout>

                <Modal
                    open={this.state.showModalDetail}
                    title={`Detail Applicant ` + this.state.chosenInterviewCode}
                    onCancel={() => this.setState({showModalDetail: false})}
                    cancelText={'Tutup'} okText={false}
                    width={`80%`}
                    footer={[
                        <Button key="back" type="default" onClick={() => this.setState({showModalDetail: false})}>
                            Close
                        </Button>,
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <DetailAssessment organization={this.state.organization} chosenInterviewCode={this.state.chosenInterviewCode} chosenApplyCode={this.state.chosenApplyCode} time={Math.floor(+new Date() / 1000)}/>
                    </React.Fragment>
                </Modal>

                <Modal
                    open={this.state.showModalUpdate}
                    title={`Detail Applicant ` + this.state.chosenInterviewCode}
                    onCancel={() => this.setState({showModalUpdate: false})}
                    cancelText={'Tutup'} okText={false}
                    width={`80%`}
                    footer={[]}
                >
                    <React.Fragment>
                        <Divider/>
                        <UpdateAssessmentStatus onCloseModal={this.onCLoseModalUpdateAssessment} organization={this.state.organization} chosenInterviewCode={this.state.chosenInterviewCode} chosenApplyCode={this.state.chosenApplyCode} time={Math.floor(+new Date() / 1000)}/>
                    </React.Fragment>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withAuth(ListInterview, 4);
