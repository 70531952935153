import React, {Component} from 'react';
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import {TrackLeadStates} from "./types/TrackLeadTypes";
import {allowedOrganization} from "../../config/appDataConfig/organizationCondig";
import {Button, DatePicker, Divider, Form, Modal, Select, Table} from "antd";
import {userServices} from "../../services/amartaVip/UserService";
import dayjs from "dayjs";
import currencyMillionFormat from "../../helper/currencyFormat";

export interface TrackLeadProps {
}

class TrackLead extends Component<TrackLeadProps & any, TrackLeadStates> {
    private cookies = new Cookies();

    constructor(props: TrackLeadProps & any) {
        super(props);

        this.state = {
            token: this.cookies.get('_vip-t') ?? "",
            startDate: dayjs(),
            processingFetchData: false,
            dataTrackLead: [],
            organizationCode: allowedOrganization[0].otodisCode,
            modalDetail: false,
            chosenAgentString: "",
            chosenAgentDetail: [],
        }
    }

    showAgentDetail = async (data: any) => {
        this.setState({
            modalDetail: true,
            chosenAgentString: `${data.agent_code} - ${data.agent_name}`,
            chosenAgentDetail: data.detail_data,
        })
    }

    onFieldChange = async <T extends keyof Pick<any, "organizationCode" | "startDate">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "organizationCode":
                currentState.organizationCode = value;
                break;
            case "startDate":
                currentState.startDate = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    fetchCounter = async () => {
        await this.promisedSetState({
            processingFetchData: true,
            dataTrackLead: [],
        });

        try {
            const fetchResult = await userServices.getLeadsTrack({
                company: this.state.organizationCode.toLocaleLowerCase(),
                period: dayjs(this.state.startDate).format("YYYY-MM"),
            });
            await this.promisedSetState({
                processingFetchData: false,
                dataTrackLead: fetchResult?.data?.data,
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetchData: false,
                dataTrackLead: [],
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`Track`} breadcrumbMenu={`Leads Track`} defaultSelectedKeys={['m-leadtrack']} defaultOpenKeys={['sm-track']}>
                    <div style={{padding: 20}}>
                        <Form layout={`inline`} style={{marginTop: -3}}>
                            <Form.Item>
                                <i className={`small-text-grey`}>Organization:</i><br/>
                                <Select
                                    style={{float: "left", minWidth: "280px"}}
                                    showSearch
                                    placeholder="Choose organizationCode"
                                    optionFilterProp="children"
                                    onChange={value => this.onFieldChange("organizationCode", value)}
                                    value={this.state.organizationCode}
                                >
                                    {(allowedOrganization ?? []).map((item: any, i: number) =>
                                        <Select.Option key={i} value={item?.otodisCode}>{item?.companyCode}</Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                            <Form.Item className={'padTop-10'} label="">
                                <i className={`small-text-grey`}>Period</i><br/>
                                <DatePicker picker="month" defaultValue={dayjs(this.state.startDate, 'YYYY-MM')} onChange={event => this.onFieldChange("startDate", event)} format={'YYYY-MM'}/>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>&nbsp;</i><br/>
                                <Button loading={this.state.processingFetchData} onClick={this.fetchCounter} type={"primary"}> Cari Data </Button>
                            </Form.Item>
                        </Form>
                    </div>

                    <div className={`p-5`}>
                        <Table
                            rowKey={'code'}
                            size={'small'}
                            scroll={{x: 'calc(500px + 50%)'}}
                            loading={this.state.processingFetchData}
                            columns={[
                                {
                                    title: 'Agent',
                                    dataIndex: 'agent_code',
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            <span onClick={event => this.showAgentDetail(row)} style={{color: "#2f57da", fontWeight: 500, cursor: "pointer"}} className={`font-90`}>{value}</span><br/>
                                            <span className={`gray-80`}>{row.agent_name} - {row.id_card_number}</span>
                                        </React.Fragment>
                                    })
                                },
                                {
                                    title: 'Achievement Days',
                                    dataIndex: 'target_achievement_days',
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            <span style={{color: "#da2f2f"}} className={`font-90`}>{value}</span><br/>
                                        </React.Fragment>
                                    })
                                },
                                {
                                    title: 'Failure Days',
                                    dataIndex: 'target_failure_days',
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            <span style={{color: "#da2f2f"}} className={`font-90`}>{value}</span><br/>
                                        </React.Fragment>
                                    })
                                },
                                {
                                    title: 'Accumulated Fines',
                                    dataIndex: 'cumulative_fine_amount',
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            <span style={{color: "#da2f2f"}} className={`font-90`}>{currencyMillionFormat(value)}</span><br/>
                                        </React.Fragment>
                                    })
                                }
                            ]}
                            dataSource={this.state.dataTrackLead}
                            pagination={{pageSize: 1000}}
                        />
                    </div>
                </AppLayout>

                <Modal
                    open={this.state.modalDetail}
                    title={`Detail Track ${this.state.chosenAgentString}`}
                    onCancel={() => this.setState({modalDetail: false})}
                    width={"80%"}
                    cancelText={'Close'} okText={false}
                    footer={[
                        <Button key="1" onClick={() => this.setState({modalDetail: false})}>
                            Close
                        </Button>,
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <Table
                            rowKey={'code'}
                            size={'small'}
                            scroll={{x: 'calc(500px + 50%)'}}
                            loading={this.state.processingFetchData}
                            columns={[
                                {
                                    title: 'Date',
                                    dataIndex: 'date',
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            <span style={{color: "#262424"}} className={`font-90`}>{value}</span><br/>
                                        </React.Fragment>
                                    })
                                },
                                {
                                    title: 'Active Lead',
                                    dataIndex: 'active_leads',
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            <span style={{color: "#262424"}} className={`font-90`}>{value}</span><br/>
                                        </React.Fragment>
                                    })
                                },
                                {
                                    title: 'Target Lead',
                                    dataIndex: 'target_active_leads',
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            <span style={{color: "#262424"}} className={`font-90`}>{value}</span><br/>
                                        </React.Fragment>
                                    })
                                },
                                {
                                    title: 'Achieve The Target',
                                    dataIndex: 'fine',
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            <span style={{color: "#262424"}} className={`font-90`}>{((!value)?.toString())}</span><br/>
                                        </React.Fragment>
                                    })
                                },
                                {
                                    title: 'Fine Amount',
                                    dataIndex: 'fine_amount',
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            <span style={{color: "#262424"}} className={`font-90`}>{currencyMillionFormat(value)}</span><br/>
                                        </React.Fragment>
                                    })
                                },
                                {
                                    title: 'Create Time',
                                    dataIndex: 'create_time',
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            <span style={{color: "#262424"}} className={`font-90`}>{value}</span><br/>
                                        </React.Fragment>
                                    })
                                }
                            ]}
                            dataSource={this.state.chosenAgentDetail}
                            pagination={{pageSize: 1000}}
                        />
                    </React.Fragment>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withAuth(TrackLead, 15);
