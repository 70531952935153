import axios from "axios";
import {baseUrlGeneralOffer, xApiKeyGeneralOffer} from "../../config/apiConfig/apiConfig";

class OfferGeneralApiService {
    private axios = axios.create({
        baseURL: baseUrlGeneralOffer,
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    public async getDetailOffer(params?: any) {
        let siteUrl = `/general-offer`;
        const queries = {
            ...(params?.companyCode && {
                company: params.companyCode,
            }),
            ...(params?.offerCode && {
                code: params.offerCode,
            }),
        };

        try {
            return await this.axios.get<any>(`${siteUrl}`, {
                headers: {
                    "X-Api-Key": xApiKeyGeneralOffer,
                },
                params: {
                    ...queries,
                },
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getOffer(params?: any) {
        const queries = {
            ...(params?.companyCode && {
                company: params.companyCode,
            }),
            ...(params?.startDate && {
                start_date: params.startDate,
            }),
            ...(params?.endDate && {
                end_date: params.endDate,
            }),
            ...(params?.area && {
                area: params.area.toLowerCase(),
            }),
            ...(params?.status && {
                status: params.status.toLowerCase(),
            }),
            ...(params?.purchaseMethod && {
                purchase_method: params.purchaseMethod.toLowerCase(),
            }),
            ...(params?.searchType && {
                search_type: params.searchType,
            }),
            ...(params?.searchOfferCode && {
                code: params.searchOfferCode,
            }),
            ...(params?.searchPoCode && {
                po_code: params.searchPoCode,
            }),
            ...(params?.searchProductCode && {
                product_code: params.searchProductCode,
            }),
        };
        try {
            return await this.axios.get<any>(`/general-offer`, {
                headers: {
                    "X-Api-Key": xApiKeyGeneralOffer,
                },
                params: {
                    ...queries,
                },
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async updateOffer(inputData?: any) {
        try {
            return await this.axios.put<any>("/general-offer", inputData, {
                headers: {
                    "Content-Type": "application/json",
                    "X-Api-Key": xApiKeyGeneralOffer,
                },
            });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async validateBank(params?: any) {

        // const sleep = (waitTimeInMs: number) => new Promise(resolve => setTimeout(resolve, waitTimeInMs));
        // await sleep(1100);
        // return {
        //     "responseCode": "2001500",
        //     "responseMessage": "Successful",
        //     "referenceNo": "241010183662030460",
        //     "partnerReferenceNo": "241010180354",
        //     "beneficiaryAccountName": "ARIEF BAGUS WIBISONO",
        //     "beneficiaryAccountNo": "7115342720"
        // };

        const queries = {
            ...(params?.bankCode && {
                bank_code: params.bankCode,
            }),
            ...(params?.accountNumber && {
                account_number: params.accountNumber,
            }),
        };
        try {
            const data = await this.axios.get<any>(`/bank/validate-account`, {
                headers: {"X-Api-Key": xApiKeyGeneralOffer,},
                params: {...queries},
            });
            return data.data.data;
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }
}

export const offerGeneralApiService = new OfferGeneralApiService();