import React, {Component} from 'react';
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import {MasterTrackAgentStates} from "./types/MasterTrackAgentTypes";
import {trackAgentService} from "../../services/amartaVip/TrackAgentService";
import {Button, Divider, Modal, Select, Table, Tooltip} from "antd";
import currencyMillionFormat from "../../helper/currencyFormat";
import {CheckCircleTwoTone, ScheduleOutlined} from "@ant-design/icons";
import UpdateMasterTrack from "../../component/pageSection/UpdateMasterTrack";
import {allowedOrganization} from "../../config/appDataConfig/organizationCondig";

export interface MasterTrackAgentProps {
}

class MasterTrackAgent extends Component<MasterTrackAgentProps & any, MasterTrackAgentStates> {
    private cookies = new Cookies();

    constructor(props: MasterTrackAgentProps & any) {
        super(props);

        this.state = {
            token: this.cookies.get('_vip-t') ?? "",
            organization: null,
            processingFetchData: false,
            pageSize: 100,
            dataTable: [],
            chosenData: {},
            showModalUpdate: false,
        }
    }

    onAreaChange = async (data: any) => {
        console.log(data);
    }

    fetchData = async () => {
        if (this.state.organization) {
            await this.promisedSetState({
                processingFetchData: true
            });

            try {
                await trackAgentService.setToken(this.state.token);
                const fetchResult = await trackAgentService.getMasterTrackAgent({companyCode: this.state.organization});
                await this.promisedSetState({
                    processingFetchData: false,
                    dataTable: fetchResult?.data?.data,
                });
            } catch (e) {
                await this.promisedSetState({
                    processingFetchData: false,
                    dataTable: [],
                });
            }
        }
    }

    onChangeTable = (pagination: any, filters: any, sorter: any) => {
        if (pagination?.pageSize) {
            this.setState({
                pageSize: pagination?.pageSize,
            });
        }
    }

    async componentDidMount() {
        await this.fetchData();
    }

    onFieldChange = async <T extends keyof Pick<any, "organization">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "organization":
                currentState.organization = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });

        if (target === "organization") {
            await this.fetchData();
        }
    }

    updateData = (data: any) => {
        this.setState({
            chosenData: data,
            showModalUpdate: true,
        })
    }

    onCLoseModalUpdate = async (data: any) => {
        if (data.reload) {
            await this.fetchData();
        }
        this.setState({
            showModalUpdate: false
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`Setting`} breadcrumbMenu={`Master Track Agent`} defaultSelectedKeys={['m-track-agent']} defaultOpenKeys={['sm-setting']}>

                    <div className={`p-5`}>
                        <i className={`small-text-grey`}>Organization:</i><br/>
                        <Select
                            style={{minWidth: 200}}
                            showSearch
                            placeholder="Choose Organization"
                            optionFilterProp="children"
                            onChange={value => this.onFieldChange("organization", value)}
                            value={this.state.organization}
                        >
                            {(allowedOrganization ?? []).map((item: any, i: number) =>
                                <Select.Option key={i} value={item?.amartaVipCode}>{item?.companyCode}</Select.Option>
                            )}
                        </Select>
                    </div>
                    <Table
                        rowKey={'vacancy_code'}
                        size={'small'}
                        scroll={{x: 'calc(500px + 50%)'}}
                        loading={this.state.processingFetchData}
                        columns={[
                            {
                                title: 'Type & Schedule',
                                dataIndex: 'type',
                                render: ((value: number, row: any) => {
                                    return <React.Fragment>
                                        <span style={{color: "#da2f2f"}} className={`font-90`}>{value}</span>
                                        <Tooltip title={(row?.active) ? "Active" : "Not Active"}>
                                            <CheckCircleTwoTone style={{paddingLeft: 5, fontSize: "80%"}} twoToneColor={(row?.active) ? "#52c41a" : "#bebebe"}/> <br/>
                                        </Tooltip>
                                        <span className={`gray-80`}>{row.schedule}</span>
                                    </React.Fragment>
                                })
                            },
                            {
                                title: 'Organization & Agent',
                                dataIndex: 'company_name',
                                render: ((value: number, row: any) => {
                                    return <React.Fragment>
                                        <span className={`font-90`}>{value}</span><br/>
                                        <span className={`gray-80`}>{row.list_agent?.toString()}</span>
                                    </React.Fragment>
                                })
                            },
                            {
                                title: 'Minimum Target & Fine',
                                dataIndex: 'compensation_type',
                                render: ((value: number, row: any) => {
                                    return <React.Fragment>
                                        <span className={`gray-80`}>{row?.minimum_target} <br/> {currencyMillionFormat(row?.fine)}</span>
                                    </React.Fragment>
                                })
                            },
                            {
                                title: 'Action',
                                dataIndex: 'compensation_type',
                                fixed: 'right',
                                width: 120,
                                render: ((value: number, row: any) => {
                                    return <React.Fragment>
                                        <Button onClick={event => this.updateData(row)} size={"small"} type={"primary"}> <ScheduleOutlined/> Update</Button>
                                    </React.Fragment>
                                })
                            },
                        ]}
                        dataSource={this.state.dataTable}
                        pagination={{pageSize: this.state.pageSize}}
                        onChange={this.onChangeTable}
                    />
                </AppLayout>

                <Modal
                    open={this.state.showModalUpdate}
                    title={`Update Master Data Track`}
                    onCancel={() => this.setState({showModalUpdate: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[]}>
                    <React.Fragment>
                        <Divider/>
                        <UpdateMasterTrack token={this.state.token} time={Math.floor(+new Date() / 1000)} data={this.state.chosenData} onCloseModal={this.onCLoseModalUpdate}/>
                    </React.Fragment>
                </Modal>

            </React.Fragment>
        );
    }
}

export default withAuth(MasterTrackAgent, 17);