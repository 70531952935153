import React, {Component} from 'react';
import {Buffer} from "buffer";
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import {UserLeadStates} from "./types/UserLeadTypes";
import {userServices} from "../../services/amartaVip/UserService";
import {leadService} from "../../services/trimitraIdeal/LeadServive";
import {Select, Form, Input, Button, Empty, Spin, Row, Col, Table, Tabs, Divider, Modal, Space, Radio, Tag, Menu, Dropdown, Checkbox, Tooltip, message} from "antd";
import DetailUser from "../../component/detailData/DetailUser";
import {allowedOrganization} from "../../config/appDataConfig/organizationCondig";
import dayjs from "dayjs";
import {WhatsAppOutlined, ScheduleOutlined, DiffOutlined, ControlOutlined, FileTextOutlined, UserSwitchOutlined, HeatMapOutlined, CopyOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import LeadNotes from "../../component/detailData/LeadNotes";
import WaChat from "../../component/detailData/WaChat";

export interface UpdateUserProps {
}

class UserLead extends Component<UpdateUserProps & any, UserLeadStates> {
    private cookies = new Cookies();

    constructor(props: UpdateUserProps & any) {
        super(props);

        this.state = {
            user: JSON.parse(Buffer.from(this.cookies.get('_vip-u'), "base64").toString()),
            token: this.cookies.get('_vip-t') ?? "",
            activeTabKey: "1",
            searchType: "IDCARD",
            processingFetchData: false,
            searchField: "",
            dataDetailUser: {},
            dataDetailLead: [],
            processingFetchCounterData: false,
            dataCounterLead: [],
            modalUpdateLevel: false,
            submitUpdateLevel: false,
            tempPhoneNumber: "",
            tempWaConv: [],
            tempLevel: 0,
            tempNotes: "",
            organizationCode: allowedOrganization[0].companyCode,
            tempAgentConversation: {
                agentCode: "",
                agentName: "",
                phoneNumbersAgent: [],
                phoneNumberLead: "",
            },
            submitUpdateFollowUp: false,
            modalUpdateFollowUp: false,
            tempFollowUp: false,
            modalShowLeadNotes: false,
            dataLeadNotes: [],
            modalUpdateAgent: false,
            submitUpdateAgent: false,
            tempAgentCode: "",
            tempOrganizationCode: "",
            modalUpdateMoveToCold: false,
            submitMoveToCold: false,
            modalUpdateMoveToFree: false,
            submitMoveToFree: false,
            hideZeroActiveLead: true,
            finalCounterLead: [],
            modalWaConf: false,
            dataWaConf: [],
        }
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    fetchUserData = async () => {
        if (this.state.searchType && this.state.searchField) {
            await this.promisedSetState({processingFetchData: true, dataDetailUser: {}, dataDetailLead: []});

            try {
                await userServices.setToken(this.state.token);
                const fetchResult = await userServices.getDetailUser({
                    type: this.state.searchType,
                    searchText: this.state.searchField
                });
                await this.promisedSetState({
                    dataDetailUser: fetchResult?.data?.data,
                });
            } catch (e) {
                await this.promisedSetState({
                    dataDetailUser: {},
                });
            }

            try {
                await leadService.setToken();
                const fetchResult = await leadService.getLeads({
                    organizationCode: this.state.organizationCode.toLocaleLowerCase(),
                    agentCode: this.state.dataDetailUser.agent_code
                });
                await this.promisedSetState({
                    processingFetchData: false,
                    dataDetailLead: fetchResult?.data?.success?.data,
                });
            } catch (e) {
                await this.promisedSetState({
                    processingFetchData: false,
                    dataDetailLead: [],
                });
            }
        }
    }

    fetchCounter = async () => {
        await this.promisedSetState({processingFetchCounterData: true, dataCounterLead: []});

        try {
            await leadService.setToken();
            const fetchResult = await leadService.getCounterLeads({
                organizationCode: this.state.organizationCode.toLocaleLowerCase(),
            });
            await this.promisedSetState({
                dataCounterLead: fetchResult?.data?.success?.data,
            });
            await this.manipulateDataCounter();
        } catch (e) {
            await this.promisedSetState({
                processingFetchCounterData: false,
                dataCounterLead: [],
            });
        }
    }

    manipulateDataCounter = async () => {
        await this.promisedSetState({processingFetchCounterData: true});

        const finalCounterLead = [];
        for (const items of this.state.dataCounterLead) {
            if (this.state.hideZeroActiveLead) {
                if (items.leads > 0) {
                    finalCounterLead.push(items);
                }
            } else {
                finalCounterLead.push(items);
            }
        }

        await this.promisedSetState({
            finalCounterLead: finalCounterLead,
            processingFetchCounterData: false,
        })
    }

    async componentDidMount() {
        await this.fetchCounter();
    }

    onFieldChange = async <T extends keyof Pick<any, "hideZeroActiveLead" | "tempFollowUp" | "searchType" | "searchField" | "activeTabKey" | "tempLevel" | "tempNotes" | "organizationCode" | "tempAgentCode">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "hideZeroActiveLead":
                currentState.hideZeroActiveLead = value;
                break;
            case "tempAgentCode":
                currentState.tempAgentCode = value;
                break;
            case "searchType":
                currentState.searchType = value;
                currentState.searchField = "";
                break;
            case "searchField":
                currentState.searchField = value;
                break;
            case "activeTabKey":
                currentState.activeTabKey = value;
                break;
            case "tempLevel":
                currentState.tempLevel = value.target.value;
                break;
            case "tempNotes":
                currentState.tempNotes = value;
                break;
            case "organizationCode":
                currentState.organizationCode = value;
                break;
            case "tempFollowUp":
                currentState.tempFollowUp = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
        if (target === "organizationCode") {
            await this.fetchCounter();
        }
        if (target === "hideZeroActiveLead") {
            await this.manipulateDataCounter();
        }
    }

    showAgentDetail = async (data: any) => {
        await this.promisedSetState({
            activeTabKey: "2",
            searchType: "AGENTCODE",
            searchField: data.agentId,
        });
        await this.fetchUserData();
    }

    updateLeadLevel = async (phoneNumber: any, statusLevel: any, agentCode: any) => {
        this.setState({
            modalUpdateLevel: true,
            submitUpdateLevel: false,
            tempPhoneNumber: phoneNumber,
            tempLevel: statusLevel,
            tempNotes: "",
        });
    }

    changeAgent = async (phoneNumber: any, agentCode: any, rowData: any) => {
        this.setState({
            modalUpdateAgent: true,
            submitUpdateAgent: false,
            tempAgentCode: agentCode,
            tempPhoneNumber: rowData.phoneNumber,
            tempOrganizationCode: rowData.organization,
        });
    }

    moveToCold = async (phoneNumber: any, agentCode: any, rowData: any) => {
        this.setState({
            tempNotes: "",
            modalUpdateMoveToCold: true,
            submitMoveToCold: false,
            tempPhoneNumber: phoneNumber,
            tempOrganizationCode: rowData.organization,
        });
    }

    moveToFree = async (phoneNumber: any, agentCode: any, rowData: any) => {
        this.setState({
            tempNotes: "",
            modalUpdateMoveToFree: true,
            submitMoveToFree: false,
            tempPhoneNumber: phoneNumber,
            tempOrganizationCode: rowData.organization,
        });
    }

    updateLeadFollowUp = async (phoneNumber: any, followUp: any, agentCode: any, type: string) => {
        if (type === "request" && (!followUp)) {
            this.setState({
                tempFollowUp: !followUp, // reverse
                tempPhoneNumber: phoneNumber,
                modalUpdateFollowUp: true,
            });
        }
        if (type === "finished" && (followUp)) {
            this.setState({
                tempFollowUp: !followUp, // reverse
                tempPhoneNumber: phoneNumber,
                modalUpdateFollowUp: true,
            });
        }
    }

    getDetailLeadNotes = async (dataHistory: any) => {
        this.setState({
            modalShowLeadNotes: true,
            dataLeadNotes: dataHistory,
        });
    }

    showWaConf = async (phoneNumber: any, agentWhatsappConversations: any) => {
        this.setState({
            modalWaConf: true,
            tempPhoneNumber: phoneNumber,
            tempWaConv: agentWhatsappConversations
        })
    }

    confirmUpdateFollowUp = async () => {
        this.setState({submitUpdateLevel: true,});
        try {
            await leadService.setToken();
            await leadService.updateLeadFollowUp({
                leadsPhoneNumber: this.state.tempPhoneNumber,
                organization: this.state.organizationCode.toLowerCase(),
                followUp: this.state.tempFollowUp,
            });
            this.setState({
                submitUpdateLevel: false,
                modalUpdateFollowUp: false,
            });
            await this.fetchUserData();
            await this.fetchCounter();
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + (e?.toString() ?? "error lead server")
            });
            this.setState({
                submitUpdateLevel: false,
            });
        }
    }

    confirmMoveToFree = async () => {
        this.setState({
            submitMoveToFree: true,
        });

        try {
            await leadService.setToken();
            await leadService.moveToFree({
                leadsPhoneNumber: this.state.tempPhoneNumber,
                organization: this.state.tempOrganizationCode,
                movedBy: this.state.user
            });

            this.setState({
                submitMoveToFree: false,
                modalUpdateMoveToCold: false,
            });

            await this.fetchUserData();
            await this.fetchCounter();
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + (e?.toString() ?? "error lead server")
            });
            this.setState({
                submitMoveToFree: false,
            });
        }
    }

    confirmMoveToCold = async () => {
        this.setState({
            submitMoveToCold: true,
        });

        try {
            await leadService.setToken();
            await leadService.moveToCold({
                leadsPhoneNumber: this.state.tempPhoneNumber,
                organization: this.state.tempOrganizationCode,
                movedBy: this.state.user,
                notes: this.state.tempNotes
            });

            this.setState({
                submitMoveToCold: false,
                modalUpdateMoveToCold: false,
            });

            await this.fetchUserData();
            await this.fetchCounter();
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + (e?.toString() ?? "error lead server")
            });
            this.setState({
                submitMoveToCold: false,
            });
        }
    }

    confirmUpdateAgent = async () => {
        if (!this.state.tempAgentCode) {
            Modal.error({
                title: 'Error',
                content: 'please complete agent code'
            });
            return;
        }

        this.setState({
            submitUpdateAgent: true,
        });

        try {
            await leadService.setToken();
            await leadService.changeAgent({
                phoneNumber: this.state.tempPhoneNumber,
                organization: this.state.tempOrganizationCode,
                changedAgentCode: this.state.tempAgentCode
            });

            this.setState({
                submitUpdateAgent: false,
                modalUpdateAgent: false,
            });

            await this.fetchUserData();
            await this.fetchCounter();
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + (e?.toString() ?? "error lead server")
            });
            this.setState({
                submitUpdateAgent: false,
            });
        }
    }


    confirmUpdateLevel = async () => {
        if (!this.state.tempNotes) {
            Modal.error({
                title: 'Error',
                content: 'please complete notes'
            });
            return;
        }

        if (this.state.tempNotes.length < 30 || this.state.tempNotes.length > 600) {
            Modal.error({
                title: 'Error',
                content: 'Note Minimum 30 to 600 characters'
            });
            return;
        }

        this.setState({
            submitUpdateLevel: true,
        });

        try {
            await leadService.setToken();
            await leadService.updateLeadLevel({
                statusLevel: this.state.tempLevel,
                notes: this.state.tempNotes,
                leadsPhoneNumber: this.state.tempPhoneNumber,
                updatedBy: this.state.user,
                organization: this.state.organizationCode.toLowerCase()
            });

            this.setState({
                submitUpdateLevel: false,
                modalUpdateLevel: false,
            });

            await this.fetchUserData();
            await this.fetchCounter();
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + (e?.toString() ?? "error lead server")
            });
            this.setState({
                submitUpdateLevel: false,
            });
        }
    }

    copyResumeFollowUp = async () => {
        let stringFollowUp = "";
        for (const item of this.state.dataDetailLead) {
            if (item.followUp) {
                const phoneHide = item.phoneNumber?.substring(0, 4) + '****' + item.phoneNumber.substring(8)
                stringFollowUp += item.firstName?.toUpperCase() + " " + item.lastName?.toUpperCase() + " - " + phoneHide + "\n"
            }
        }

        if (!stringFollowUp) {
            await navigator.clipboard.writeText("");
            message.error("tidak ada data followup yang aktif");
        } else {
            await navigator.clipboard.writeText("Butuh FollowUp \n" + stringFollowUp);
            message.info("Resume Berhasil di Copy");
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`UserVIP`} breadcrumbMenu={`User Lead`} defaultSelectedKeys={['m-leaduservip']} defaultOpenKeys={['sm-lead']}>

                    <div style={{padding: 20}}>
                        <Row>
                            <Col xs={24} xl={24}>
                                <div style={{float: "left"}}>
                                    <i className={`small-text-grey`}>Organization:</i><br/>
                                    <Select
                                        style={{float: "left", minWidth: "200px"}}
                                        showSearch
                                        placeholder="Choose organizationCode"
                                        optionFilterProp="children"
                                        onChange={value => this.onFieldChange("organizationCode", value)}
                                        value={this.state.organizationCode}
                                    >
                                        {(allowedOrganization ?? []).map((item: any, i: number) =>
                                            <Select.Option key={i} value={item?.companyCode}>{item?.companyCode}</Select.Option>
                                        )}
                                    </Select>
                                </div>
                            </Col>
                            <Col xs={24} xl={24}>
                                <div style={{float: "left", paddingTop: 20}}>
                                    <Checkbox checked={this.state.hideZeroActiveLead} onChange={e => this.onFieldChange("hideZeroActiveLead", e.target.checked)}>Hide Agent dengan 0 Active Lead</Checkbox>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className={`p-5`}>
                        <div>
                            <Tabs
                                activeKey={this.state.activeTabKey}
                                onChange={value => this.onFieldChange("activeTabKey", value)}
                                items={[
                                    {
                                        label: 'Resume Agent',
                                        key: '1',
                                        children:
                                            <React.Fragment>
                                                <div style={this.state.processingFetchCounterData ? {} : {display: 'none'}} className={`spinCenter`}>
                                                    <Spin size="large"/>
                                                </div>

                                                <div className={`p-5`} style={((this.state.finalCounterLead.length < 1) && !this.state.processingFetchCounterData) ? {} : {display: 'none'}}>
                                                    <Empty/>
                                                </div>

                                                <div style={((this.state.finalCounterLead.length > 0) && !this.state.processingFetchCounterData) ? {} : {display: 'none'}}>
                                                    <Table
                                                        scroll={{x: 'calc(500px + 50%)'}}
                                                        dataSource={this.state.finalCounterLead}
                                                        pagination={{pageSize: 1000}}
                                                        rowKey={'agentId'}
                                                        columns={[
                                                            {
                                                                title: 'Agent Code',
                                                                dataIndex: 'agentId',
                                                                width: "140px",
                                                                render: ((value: string, row: any) => {
                                                                    return <React.Fragment>
                                                                        <span onClick={event => this.showAgentDetail(row)} style={{color: "#2f57da", fontWeight: 500, fontSize: "85%", cursor: "pointer"}} className={`font-90`}>{value}</span>
                                                                    </React.Fragment>
                                                                })
                                                            },
                                                            {
                                                                title: 'Agent Name',
                                                                dataIndex: 'agentName',
                                                                render: ((value: string, row: any) => {
                                                                    return <React.Fragment>
                                                                        <span className={`font-90`} style={{fontSize: "80%"}}>{value.toUpperCase()}</span>
                                                                    </React.Fragment>
                                                                })
                                                            },
                                                            {
                                                                title: 'Total Active Leads',
                                                                dataIndex: 'totalTrackedLeads',
                                                                render: ((value: number, row: any) => {
                                                                    return <React.Fragment>
                                                                        <span className={`font-80`}>{value}</span>
                                                                    </React.Fragment>
                                                                })
                                                            },
                                                            {
                                                                title: 'Total Agent Leads',
                                                                dataIndex: 'totalAllLeads',
                                                                render: ((value: number, row: any) => {
                                                                    return <React.Fragment>
                                                                        <span className={`font-80`}>{value}</span>
                                                                    </React.Fragment>
                                                                })
                                                            },
                                                            {
                                                                title: 'Total Acquire Leads',
                                                                dataIndex: 'totalLeadsWithCold',
                                                                render: ((value: number, row: any) => {
                                                                    return <React.Fragment>
                                                                        <span className={`font-80`}>{value}</span>
                                                                    </React.Fragment>
                                                                })
                                                            },
                                                            {
                                                                title: 'status LV0',
                                                                dataIndex: 'statusLevel_0',
                                                                render: ((value: number, row: any) => {
                                                                    return <React.Fragment>
                                                                        <span className={`font-80`}>{value}</span>
                                                                    </React.Fragment>
                                                                })
                                                            },
                                                            {
                                                                title: 'status LV1',
                                                                dataIndex: 'statusLevel_1',
                                                                render: ((value: number, row: any) => {
                                                                    return <React.Fragment>
                                                                        <span className={`font-80`}>{value}</span>
                                                                    </React.Fragment>
                                                                })
                                                            },
                                                            {
                                                                title: 'status LV2',
                                                                dataIndex: 'statusLevel_2',
                                                                render: ((value: number, row: any) => {
                                                                    return <React.Fragment>
                                                                        <span className={`font-80`}>{value}</span>
                                                                    </React.Fragment>
                                                                })
                                                            },
                                                            {
                                                                title: 'status LV3',
                                                                dataIndex: 'statusLevel_3',
                                                                render: ((value: number, row: any) => {
                                                                    return <React.Fragment>
                                                                        <span className={`font-80`}>{value}</span>
                                                                    </React.Fragment>
                                                                })
                                                            },
                                                            {
                                                                title: 'status LV4',
                                                                dataIndex: 'statusLevel_4',
                                                                render: ((value: number, row: any) => {
                                                                    return <React.Fragment>
                                                                        <span className={`font-80`}>{value}</span>
                                                                    </React.Fragment>
                                                                })
                                                            },
                                                            {
                                                                title: 'status Lv5',
                                                                dataIndex: 'statusLevel_5',
                                                                render: ((value: number, row: any) => {
                                                                    return <React.Fragment>
                                                                        <span className={`font-80`}>{value}</span>
                                                                    </React.Fragment>
                                                                })
                                                            },
                                                            {
                                                                title: 'Updated Time',
                                                                dataIndex: 'updatedAt',
                                                                key: 'updatedAt',
                                                                render: ((value: number, row: any) => {
                                                                    return <React.Fragment>
                                                                        <span className={`font-80`}>{dayjs(value).format("YYYY-MM-DD")}</span> <span className={`gray-80`}>{dayjs(value).format("HH:mm:ss")}</span>
                                                                    </React.Fragment>
                                                                })
                                                            }
                                                        ]}/>
                                                </div>
                                            </React.Fragment>
                                    },
                                    {
                                        label: 'Detail Agent',
                                        key: '2',
                                        children:
                                            <React.Fragment>
                                                <Form layout={`inline`} style={{marginTop: -3}}>
                                                    <Form.Item>
                                                        <i className={`small-text-grey`}>Search Type:</i><br/>
                                                        <Select
                                                            style={{minWidth: 200}}
                                                            showSearch
                                                            placeholder="Choose SearchType"
                                                            optionFilterProp="children"
                                                            onChange={value => this.onFieldChange("searchType", value)}
                                                            value={this.state.searchType}
                                                        >
                                                            <Select.Option key={`IDCARD`} value={`IDCARD`}>{`ID Card`}</Select.Option>
                                                            <Select.Option key={`PHONENUMBER`} value={`PHONENUMBER`}>{`Phone Number`}</Select.Option>
                                                            <Select.Option key={`EMAIL`} value={`EMAIL`}>{`Email`}</Select.Option>
                                                            <Select.Option key={`AGENTCODE`} value={`AGENTCODE`}>{`Agent Code`}</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`small-text-grey`}>Search Field:</i><br/>
                                                        <Input value={this.state.searchField} onChange={event => this.onFieldChange("searchField", event.target.value)} placeholder="Search Field"/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`small-text-grey`}>&nbsp;</i><br/>
                                                        <Button loading={this.state.processingFetchData} onClick={this.fetchUserData} type={"primary"}> Cari Data </Button>
                                                    </Form.Item>
                                                </Form>
                                                <br/>
                                                <div style={this.state.processingFetchData ? {} : {display: 'none'}} className={`spinCenter`}>
                                                    <Spin size="large"/>
                                                </div>
                                                <Row>
                                                    <Col xs={24} xl={8} style={(!this.state.dataDetailUser?.name && !this.state.processingFetchData) ? {} : {display: 'none'}}>
                                                        <Empty/>
                                                    </Col>
                                                    <Col xs={24} xl={8} style={(this.state.dataDetailUser?.name && !this.state.processingFetchData) ? {} : {display: 'none'}}>
                                                        <Divider orientation="left" style={{paddingLeft: 10, paddingRight: 40,}}>Data Agent</Divider>
                                                        <br/>
                                                        <DetailUser dataDetailUser={this.state.dataDetailUser} fullCol={true} showUserType={true} showIdCard={true}/>
                                                    </Col>
                                                    <Col xs={24} xl={16} style={(this.state.dataDetailLead.length < 1 && !this.state.processingFetchData) ? {} : {display: 'none'}}>
                                                        <Empty/>
                                                    </Col>
                                                    <Col xs={24} xl={16} style={(this.state.dataDetailLead.length > 0 && !this.state.processingFetchData) ? {} : {display: 'none'}}>
                                                        <Divider orientation="left" style={{paddingLeft: 10, paddingRight: 40,}}>Data Leads</Divider>
                                                        <Table pagination={{pageSize: 1000}} scroll={{x: 'calc(500px + 50%)'}} dataSource={this.state.dataDetailLead} columns={[
                                                            {
                                                                title: 'Action',
                                                                width: 100,
                                                                dataIndex: 'phoneNumber',
                                                                render: ((value: number, row: any) => {
                                                                    const listPhoneAgent: any = [];
                                                                    for (const item of (this.state.dataDetailUser?.phones ?? [])) {
                                                                        listPhoneAgent.push(item.phone);
                                                                    }
                                                                    const menu = (
                                                                        <Menu>
                                                                            <Menu.Item key={1}>
                                                                                <span onClick={event => this.showWaConf(row.phoneNumber, row?.agentWhatsappConversations)} style={{cursor: "pointer"}}> <WhatsAppOutlined/> Lihat Percakapan WA</span>
                                                                            </Menu.Item>
                                                                            <Menu.Item key={2}>
                                                                                <span onClick={event => this.getDetailLeadNotes(row.updateHistories)} style={{cursor: "pointer"}}> <FileTextOutlined/> Lihat Lead Notes</span>
                                                                            </Menu.Item>
                                                                            <Menu.Item key={3}>
                                                                                <span onClick={event => this.updateLeadLevel(row?.phoneNumber, row.statusLevel, row.agentCode)} style={{cursor: "pointer"}}> <DiffOutlined/> Update Level</span>
                                                                            </Menu.Item>
                                                                            <Menu.Item key={4} disabled={(row.followUp)}>
                                                                                <span onClick={event => this.updateLeadFollowUp(row?.phoneNumber, row.followUp, row.agentCode, "request")} style={{cursor: "pointer"}}> <ControlOutlined/> Request FollowUp</span>
                                                                            </Menu.Item>
                                                                            <Menu.Item key={5} disabled={!row.followUp}>
                                                                                <span onClick={event => this.updateLeadFollowUp(row?.phoneNumber, row.followUp, row.agentCode, "finished")} style={{cursor: "pointer"}}> <ControlOutlined/> Finished FollowUp</span>
                                                                            </Menu.Item>
                                                                            <Menu.Item key={6}>
                                                                                <span onClick={event => this.changeAgent(row?.phoneNumber, row.agentCode, row)} style={{cursor: "pointer"}}> <UserSwitchOutlined/> Rubah Agen</span>
                                                                            </Menu.Item>
                                                                            <Menu.Item key={7}>
                                                                                <span onClick={event => this.moveToCold(row?.phoneNumber, row.agentCode, row)} style={{cursor: "pointer"}}> <HeatMapOutlined/> Move To Cold</span>
                                                                            </Menu.Item>
                                                                            <Menu.Item key={8}>
                                                                                <span onClick={event => this.moveToFree(row?.phoneNumber, row.agentCode, row)} style={{cursor: "pointer"}}> <HeatMapOutlined/> Move To Free</span>
                                                                            </Menu.Item>
                                                                        </Menu>
                                                                    );

                                                                    return <React.Fragment>
                                                                        <span className={`font-90`}>
                                                                            <Dropdown overlay={menu} placement="bottomLeft" arrow>
                                                                              <Button size={"small"} type={"primary"}> <ScheduleOutlined/> Aksi</Button>
                                                                            </Dropdown>
                                                                        </span>
                                                                    </React.Fragment>
                                                                })
                                                            }, {
                                                                title: <>
                                                                    FollowUp
                                                                    <Tooltip title="Click untuk copy resume followUp" color={'cyan'}>
                                                                        &nbsp; <CopyOutlined className={'small-avatar-link'} onClick={event => this.copyResumeFollowUp()}/>
                                                                    </Tooltip>
                                                                </>,
                                                                dataIndex: 'followUp',
                                                                width: 140,
                                                                render: ((value: number, row: any) => {
                                                                    return <React.Fragment>
                                                                        <span className={`gray-80`}>{(value) ? <Tag style={{fontSize: "80%"}} color="blue">True</Tag> : <Tag style={{fontSize: "80%"}} color="red">False</Tag>} </span><br/>
                                                                        <span className={`gray-80`}>Level: {row.statusLevel}</span>
                                                                    </React.Fragment>
                                                                })
                                                            }, {
                                                                title: 'Contact',
                                                                dataIndex: 'firstName',
                                                                width: 300,
                                                                render: ((value: string, row: any) => {
                                                                    const listPhoneAgent: any = [];
                                                                    for (const item of (this.state.dataDetailUser?.phones ?? [])) {
                                                                        listPhoneAgent.push(item.phone);
                                                                    }
                                                                    let whatsappLogo = <Tooltip title="Send Success" color={'#1f8646'}><WhatsAppOutlined style={{color: '#1f8646', cursor: "help"}}/></Tooltip>;
                                                                    if (row?.whatsapp?.failed) {
                                                                        whatsappLogo = <Tooltip title="Send Failed" color={'#cc2929'}><WhatsAppOutlined style={{color: '#cc2929', cursor: "help"}}/></Tooltip>;
                                                                    }
                                                                    return <>
                                                                        <span className={`gray-80`}>{row?.title}</span> <span className={`font-90`}>{value}</span> <span className={`font-90`}>{row.lastName}</span> <br/>
                                                                        <span onClick={event => this.showWaConf(row.phoneNumber, row?.agentWhatsappConversations)} style={{cursor: "pointer"}} className={`blue-85`}>{whatsappLogo} {row?.phoneNumber}</span> <span className={`gray-80`}>{row?.email}</span> <br/>
                                                                        <span className={`gray-80`}>{row?.firstMessageDirection}</span>
                                                                    </>
                                                                })
                                                            }, {
                                                                title: 'domicile',
                                                                dataIndex: 'domicile',
                                                                width: 129,
                                                                render: ((value: number, row: any) => {
                                                                    return <>
                                                                        <span className={`gray-80`}>{row?.domicile?.provinceName}</span><br/>
                                                                        <span className={`gray-80`}>{row?.domicile?.cityName}</span>
                                                                    </>
                                                                })
                                                            }, {
                                                                title: 'vehicle',
                                                                dataIndex: 'vehicle',
                                                                width: 420,
                                                                render: ((value: number, row: any) => {
                                                                    return <>
                                                                        {(row?.vehicleOptions ?? []).map((item: any, i: number) =>
                                                                            <React.Fragment key={i}>
                                                                                <span className={`gray-70`}>{item?.variant?.name}</span> <span className={`gray-70`}>{item?.color?.name}</span><br/>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </>
                                                                })
                                                            }, {
                                                                title: 'Create Time',
                                                                dataIndex: 'createdAt',
                                                                key: 'createdAt',
                                                                width: 180,
                                                                render: ((value: number, row: any) => {
                                                                    return <>
                                                                        <span className={`font-90`}>{dayjs(value).format("YYYY-MM-DD")}</span> <span className={`gray-80`}>{dayjs(value).format("HH:mm:ss")}</span> <span className={`gray-60`}>{row?.paymentPlan}</span><br/>
                                                                        <span className={`gray-60`}>{(row?.source === 'https://crm.amarta.vip/newlead/amartahonda') ? "crm.amarta.vip" : row?.source}</span>
                                                                    </>
                                                                })
                                                            }, {
                                                                title: <>Last Update <i style={{fontSize: "65%"}}>Upload Chat Time</i></>,
                                                                dataIndex: 'createdAt',
                                                                key: 'createdAt',
                                                                width: 180,
                                                                render: ((value: number, row: any) => {
                                                                    if (row?.agentWhatsappConversations) {
                                                                        const lastElement = row?.agentWhatsappConversations[row?.agentWhatsappConversations.length - 1];
                                                                        if (lastElement?.createdAt) {
                                                                            return <>
                                                                                <span className={`font-90`}>{dayjs(lastElement?.createdAt).format("YYYY-MM-DD")}</span> <span className={`gray-80`}>{dayjs(lastElement?.createdAt).format("HH:mm:ss")}</span><br/>
                                                                            </>
                                                                        } else {
                                                                            return <span>-</span>
                                                                        }
                                                                    } else {
                                                                        return <span>-</span>
                                                                    }
                                                                })
                                                            }, {
                                                                title: 'FollowUp Schedule',
                                                                dataIndex: 'followUpScheduledAt',
                                                                key: 'followUpScheduledAt',
                                                                width: 180,
                                                                render: ((value: number, row: any) => {
                                                                    return <>
                                                                        {(() => {
                                                                            if (value) {
                                                                                return (
                                                                                    <>
                                                                                        <span className={`font-90`}>{dayjs(value).format("YYYY-MM-DD")}</span> <br/> <span className={`gray-80`}>{dayjs(value).format("HH:mm:ss")}</span>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            return "-";
                                                                        })()}
                                                                    </>
                                                                })
                                                            },
                                                        ]}/>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </AppLayout>

                <Modal
                    open={this.state.modalUpdateAgent}
                    title="Update Kode Agen"
                    onCancel={() => this.setState({modalUpdateAgent: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button loading={this.state.submitUpdateAgent} type={"primary"} key="1" onClick={this.confirmUpdateAgent}>Update</Button>,
                        <Button key="2" onClick={() => this.setState({modalUpdateAgent: false})}>Close</Button>,
                    ]}
                >
                    <>
                        <Divider/>
                        <div className={`gray-80 mt-20`}>Kode Agen Baru:</div>
                        <Input value={this.state.tempAgentCode} onChange={event => this.onFieldChange("tempAgentCode", event.target.value)} placeholder="Kode Agen Baru"/>
                        <Divider/>
                    </>
                </Modal>

                <Modal
                    open={this.state.modalUpdateLevel}
                    title="Update Level"
                    onCancel={() => this.setState({modalUpdateLevel: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button loading={this.state.submitUpdateLevel} type={"primary"} key="1" onClick={this.confirmUpdateLevel}>Update</Button>,
                        <Button key="2" onClick={() => this.setState({modalUpdateLevel: false})}>Close</Button>,
                    ]}
                >
                    <>
                        <Divider/>
                        <div className={`gray-80 mt-20`}>Level:</div>
                        <Radio.Group onChange={event => this.onFieldChange("tempLevel", event)} value={this.state.tempLevel}>
                            <Space direction="vertical">
                                <Radio value={0}>Level 0<br/><span className={`gray-80`}>Awareness: Konsumen baru mengenal produk atau layanan Anda dan masih belum memiliki ketertarikan yang kuat.</span></Radio>
                                <Radio value={1}>Level 1<br/><span className={`gray-80`}>Interest: Konsumen mulai menunjukkan minat dalam produk atau layanan Anda dan sedang mencari informasi lebih lanjut.</span></Radio>
                                <Radio value={2}>
                                    Level 2<br/>
                                    <span className={`gray-80`}>Consideration: Konsumen sudah mempertimbangkan untuk membeli produk atau layanan Anda, dan sedang membandingkan dengan opsi lain.</span>
                                </Radio>
                                <Radio value={3}>
                                    Level 3<br/>
                                    <span className={`gray-80`}>Intent: Konsumen sudah memiliki niat untuk membeli produk atau layanan Anda, dan sedang mempertimbangkan kapan dan di mana untuk membeli.</span>
                                </Radio>
                                <Radio value={4}>
                                    Level 4<br/>
                                    <span className={`gray-80`}>SPK (Sales Purchase): Konsumen sudah memutuskan untuk membeli produk atau layanan Anda dan melakukan tindakan untuk melakukan pembelian.</span>
                                </Radio>
                                <Radio value={5}>
                                    Level 5<br/>
                                    <span className={`gray-80`}>Loyalty: Konsumen sudah melakukan pembelian dan puas dengan produk atau layanan Anda, dan menjadi pelanggan yang loyal dan berpotensi untuk melakukan pembelian berulang atau merekomendasikan produk atau layanan Anda ke orang lain.</span>
                                </Radio>
                            </Space>
                        </Radio.Group>
                        <div className={`gray-80 mt-20`}>Catatan:</div>
                        <TextArea showCount maxLength={200} value={this.state.tempNotes} onChange={event => this.onFieldChange("tempNotes", event.target.value)} placeholder="Catatan"/>
                        <Divider/>
                    </>
                </Modal>

                <Modal
                    open={this.state.modalShowLeadNotes}
                    title="Detail Lead Notes"
                    width={"80%"}
                    onCancel={() => this.setState({modalShowLeadNotes: false})}
                    cancelText={'Close'} okText={false}
                    footer={[<Button key="1" onClick={() => this.setState({modalShowLeadNotes: false})}>Close</Button>,]}
                >
                    <>
                        <Divider/>
                        <LeadNotes data={this.state.dataLeadNotes}/>
                    </>
                </Modal>

                <Modal
                    open={this.state.modalUpdateFollowUp}
                    title={(this.state.tempFollowUp) ? "Request FollowUp" : "Finsih FollowUp"}
                    onCancel={() => this.setState({modalUpdateFollowUp: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button loading={this.state.submitUpdateFollowUp} type={"primary"} key="1" onClick={this.confirmUpdateFollowUp}>Update</Button>,
                        <Button key="2" onClick={() => this.setState({modalUpdateFollowUp: false})}>Close</Button>,
                    ]}
                >
                    <>
                        <Divider/>
                        <div style={{display: "none"}}><Checkbox checked={this.state.tempFollowUp} onChange={e => this.onFieldChange("tempFollowUp", e.target.checked)}>FollowUp</Checkbox></div>
                        {(this.state.tempFollowUp) ? "Dengan menekan tombol 'Update' anda akan merubah status followUp menjadi true" : "Dengan menekan tombol 'Update' anda akan merubah status followUp menjadi false"}
                        <Divider/>
                    </>
                </Modal>

                <Modal
                    open={this.state.modalUpdateMoveToCold}
                    title="Move To Cold"
                    onCancel={() => this.setState({modalUpdateMoveToCold: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button loading={this.state.submitMoveToCold} type={"primary"} key="1" onClick={this.confirmMoveToCold}>Update</Button>,
                        <Button key="2" onClick={() => this.setState({modalUpdateMoveToCold: false})}>Close</Button>,
                    ]}
                >
                    <>
                        <Divider/>
                        <div className={`gray-80 mt-20`}>Catatan:</div>
                        <Input value={this.state.tempNotes} onChange={event => this.onFieldChange("tempNotes", event.target.value)} placeholder="Catatan"/>
                        <Divider/>
                    </>
                </Modal>

                <Modal
                    open={this.state.modalUpdateMoveToFree}
                    title="Move To Free"
                    onCancel={() => this.setState({modalUpdateMoveToFree: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button loading={this.state.submitMoveToFree} onClick={this.confirmMoveToFree} type={"primary"} key="1">Update to Free Lead</Button>,
                        <Button key="2" onClick={() => this.setState({modalUpdateMoveToFree: false})}>Close</Button>,
                    ]}
                >
                    <>
                        <Divider/>
                        Anda yakin akan merubah lead menjadi free lead?
                        <Divider/>
                    </>
                </Modal>

                <Modal
                    open={this.state.modalWaConf}
                    style={{top: 10}}
                    title="Detail Chat"
                    onCancel={() => this.setState({modalWaConf: false})}
                    cancelText={'Close'} okText={false}
                    footer={[<Button key="1" onClick={() => this.setState({modalWaConf: false})}>Close</Button>]}
                >
                    <>
                        <Divider/>
                        <WaChat phoneNumber={this.state.tempPhoneNumber} organizationCode={this.state.organizationCode} waConv={this.state.tempWaConv}/>
                    </>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withAuth(UserLead, 11);