import React, {Component} from "react";
import {Button, Divider, Row, Select, Spin, Tabs} from "antd";
import {vacancyApiService} from "../../services/amartaVip/VacancyApiService";
import Cookies from "universal-cookie";
import Col from "antd/lib/col";
import TextArea from "antd/es/input/TextArea";
import Modal from "antd/lib/modal";
import UserAssessmentHistory from "./UserAssessmentHistory";

class UpdateStatusApplicant extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            organization: "",
            chosenApplyCode: "",
            processingFetch: false,
            dataApplicant: {},
            interviewNotes: "",
            interviewNotesForApplicant: "",
            loading: false,
            applicantStatus: null,
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            organization: this.props.organization,
            chosenApplyCode: this.props.chosenApplyCode,
            applicantStatus: null,
            interviewNotes: "",
            interviewNotesForApplicant: "",
            loading: false,
        });
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.time !== prevProps.time) || (this.props.chosenApplyCode !== prevProps.chosenApplyCode)) {
            await this.promisedSetState({
                organization: this.props.organization,
                chosenApplyCode: this.props.chosenApplyCode,
            });
            this.setState({
                applicantStatus: null,
                interviewNotes: "",
                interviewNotesForApplicant: "",
                loading: false,
            });
            await this.fetchData();
        }
    }

    fetchData = async () => {
        await this.promisedSetState({
            processingFetch: true,
            dataApplicant: {},
        });

        try {
            await vacancyApiService.setToken(this.state.token);
            const fetchResult = await vacancyApiService.getOrganizationApply({
                organization: this.state.organization,
                applyCode: this.state.chosenApplyCode,
            });
            await this.promisedSetState({
                processingFetch: false,
                dataApplicant: fetchResult?.data?.data[0],
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetch: false,
                dataApplicant: {},
            });
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "interviewNotes" | "interviewNotesForApplicant" | "applicantStatus">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "interviewNotes":
                currentState.interviewNotes = value;
                break;
            case "interviewNotesForApplicant":
                currentState.interviewNotesForApplicant = value;
                break;
            case "applicantStatus":
                currentState.applicantStatus = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    onCancel = async () => {
        this.props.onCloseModal({reload: false});
    }
    onSubmit = async () => {

        const dataUpdate = {
            organization: this.state.dataApplicant?.organization,
            apply_code: this.state.dataApplicant?.apply_code,
            update_notes: this.state.interviewNotes,
            notes_for_applicant: this.state.interviewNotesForApplicant,
            apply_status: this.state.applicantStatus
        }

        this.setState({
            loading: true,
        });

        try {
            await vacancyApiService.setToken(this.state.token);
            await vacancyApiService.updateApplicantStatus(
                dataUpdate
            ).then(successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'Data successfully updated '
                });
                this.props.onCloseModal({reload: true});
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            await this.promisedSetState({
                loading: false,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.processingFetch ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={!this.state.processingFetch ? {} : {display: 'none'}}>
                    <Row>
                        <Col style={{paddingRight: 20}} xxl={{span: 12}} xl={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <span className={`gray-80`}>Apply Code:</span><br/>
                            {this.state.dataApplicant?.apply_code}<br/>
                            <span className={`gray-80`}>Organisasi - Role:</span><br/>
                            {this.state.dataApplicant?.organization} - {this.state.dataApplicant?.role_code} {this.state.dataApplicant?.role_name}<br/>
                            <Divider/>
                            <span className={`gray-80`}>Nama Lengkap:</span><br/>
                            {this.state.dataApplicant?.user_data?.id_card?.full_name}<br/>
                            <span className={`gray-80`}>Nomor Telpon - Email:</span><br/>
                            {this.state.dataApplicant?.user_data?.phones} - {this.state.dataApplicant?.user_data?.email}<br/>
                            <span className={`gray-80`}>Alamat:</span><br/>
                            <div style={{fontSize: "85%"}}>
                                {this.state.dataApplicant?.user_data?.address?.province_name},&nbsp;
                                {this.state.dataApplicant?.user_data?.address?.city_name},&nbsp;
                                {this.state.dataApplicant?.user_data?.address?.district_name},&nbsp;
                                {this.state.dataApplicant?.user_data?.address?.sub_district_name}<br/>
                                {this.state.dataApplicant?.user_data?.address?.full_address}<br/>
                                {this.state.dataApplicant?.user_data?.address?.postal_code}
                            </div>
                            <Divider/>

                            <Tabs
                                defaultActiveKey="1"
                                items={[
                                    {
                                        label: 'User History',
                                        key: '1',
                                        children:
                                            <React.Fragment>
                                                <UserAssessmentHistory organization={this.state.dataApplicant?.organization} userUid={this.state.dataApplicant?.user_uid} checkSameApply={this.state.dataApplicant?.apply_code}/>
                                            </React.Fragment>
                                    },
                                    {
                                        label: 'Jadwal Interview di Hari yang sama',
                                        key: '2',
                                        children:
                                            <React.Fragment>
                                                Under Construct
                                            </React.Fragment>
                                    }
                                ]}
                            />
                        </Col>
                        <Col xxl={{span: 12}} xl={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <div className={`gray-80 mt-20`}>Status Applicant:</div>
                            <Select
                                placeholder="Apply Status"
                                showSearch
                                optionFilterProp="children"
                                value={this.state.applicantStatus}
                                onChange={value => this.onFieldChange("applicantStatus", value)}
                                style={{width: "100%"}}
                            >
                                <Select.Option value="ACCEPTED">Accepted</Select.Option>
                                <Select.Option value="REJECTED">Rejected</Select.Option>
                            </Select>

                            <div className={`gray-80 mt-20`}>Catatan:</div>
                            <TextArea showCount maxLength={200} value={this.state.interviewNotes} onChange={event => this.onFieldChange("interviewNotes", event.target.value)} placeholder="Catatan"/>

                            <div className={`gray-80 mt-20`}>Catatan Untuk Applicant:</div>
                            <TextArea showCount maxLength={400} value={this.state.interviewNotesForApplicant} onChange={event => this.onFieldChange("interviewNotesForApplicant", event.target.value)} placeholder="Catatan Untuk Applicant"/>
                        </Col>
                    </Row>
                    <div>

                    </div>
                    <div style={{paddingTop: 20, textAlign: "right"}}>
                        <Divider/>
                        <Button onClick={event => this.onCancel()} style={{marginRight: 7}}>Cancel</Button>
                        <Button onClick={event => this.onSubmit()} loading={this.state.loading} type="primary" style={{marginRight: 7}}>Update</Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default UpdateStatusApplicant;
