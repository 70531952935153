import React, {Component} from 'react';
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import {UserQrStates} from "./types/UserQrTypes";
import {userServices} from "../../services/amartaVip/UserService";
import {Select, Form, Input, Button, Empty, Spin, Row, Col, Divider, Modal, Card, QRCode} from "antd";
import DetailUser from "../../component/detailData/DetailUser";
import {DeleteOutlined} from "@ant-design/icons";

export interface UserQrProps {
}

class UserQr extends Component<UserQrProps & any, UserQrStates> {
    private cookies = new Cookies();

    constructor(props: UserQrProps & any) {
        super(props);

        this.state = {
            token: this.cookies.get('_vip-t') ?? "",
            processingFetchData: false,
            searchType: "IDCARD",
            showModalAdd: false,
            searchField: "",
            dataQrUser: {},
            dataDetailQr: [],
            modalDelete: false,
            codeDelete: "",
            loading: false,

            qrCode: "",
            qrType: "URL_LINK",
            qrAction: "OPEN_NEW_TAB",
            qrTarget: "",
            qrNotes: "",
        }
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    fetchUserData = async () => {
        if (this.state.searchType && this.state.searchField) {
            await this.promisedSetState({
                processingFetchData: true,
                dataQrUser: {},
            });

            try {
                await userServices.setToken(this.state.token);
                const fetchResult = await userServices.getDetailUser({type: this.state.searchType, searchText: this.state.searchField});
                await this.promisedSetState({
                    processingFetchData: false,
                    dataQrUser: fetchResult?.data?.data,
                });
            } catch (e) {
                await this.promisedSetState({
                    processingFetchData: false,
                    dataQrUser: {},
                });
            }

            if (this.state.dataQrUser.uid) {
                try {
                    await userServices.setToken(this.state.token);
                    const fetchResult = await userServices.getListUserQr({
                        uid: this.state.dataQrUser.uid
                    });

                    const sortedData: any = fetchResult?.data?.data;
                    await this.promisedSetState({
                        processingFetchData: false,
                        dataDetailQr: sortedData,
                    });
                } catch (e) {
                    await this.promisedSetState({
                        processingFetchData: false,
                        dataDetailQr: [],
                    });
                }
            }
        }
    }

    async componentDidMount() {

    }

    onFieldChange = async <T extends keyof Pick<any, "searchType" | "searchField" | "qrCode" | "qrType" | "qrAction" | "qrTarget" | "qrNotes">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "searchType":
                currentState.searchType = value;
                currentState.searchField = "";
                break;
            case "searchField":
                currentState.searchField = value;
                break;
            case "qrCode":
                currentState.qrCode = value?.toUpperCase()?.replace(/ /g, '')?.trim();
                break;
            case "qrType":
                currentState.qrType = value;
                break;
            case "qrAction":
                currentState.qrAction = value;
                break;
            case "qrTarget":
                currentState.qrTarget = value;
                break;
            case "qrNotes":
                currentState.qrNotes = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    confirmAddModelData = async () => {
        if (!this.state.qrCode || !this.state.qrTarget) {
            Modal.error({
                title: 'Error',
                content: 'mohon lengkapi data',
            });
            return;
        }

        this.setState({
            loading: true
        });

        const data = {
            uid: this.state.dataQrUser.uid,
            code: this.state.qrCode,
            type: this.state.qrType,
            action: this.state.qrAction,
            target: this.state.qrTarget,
            notes: this.state.qrNotes
        }

        try {
            await userServices.setToken(this.state.token);
            await userServices.createUserQr(
                data
            ).then(async successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'QR berhasil dibuat '
                });
                await this.fetchUserData();
                await this.promisedSetState({
                    showModalAdd: false,
                    loading: false,
                });
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            await this.promisedSetState({
                loading: false,
            });
        }
    }

    onDelete = async (data: any) => {
        this.setState({
            modalDelete: true,
            codeDelete: data,
        });
    }

    onDeleteSubmit = async () => {
        this.promisedSetState({
            loading: true
        });

        try {
            await userServices.setToken(this.state.token);
            await userServices.removeQr({
                uid: this.state.dataQrUser.uid,
                code: this.state.codeDelete
            }).then(successData => {
                this.fetchUserData();
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'Proses Sukses '
                });
                this.setState({
                    loading: false,
                    modalDelete: false,
                });
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            this.setState({
                loading: false,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`UserVIP`} breadcrumbMenu={`User QR`} defaultSelectedKeys={['m-qruservip']} defaultOpenKeys={['sm-uservip', 'sm-data-uservip']}>
                    <div className={`p-5`}>
                        <Form layout={`inline`} style={{marginTop: -3}}>
                            <Form.Item>
                                <i className={`small-text-grey`}>Search Type:</i><br/>
                                <Select
                                    style={{minWidth: 200}}
                                    showSearch
                                    placeholder="Choose SearchType"
                                    optionFilterProp="children"
                                    onChange={value => this.onFieldChange("searchType", value)}
                                    value={this.state.searchType}
                                >
                                    <Select.Option key={`IDCARD`} value={`IDCARD`}>{`ID Card`}</Select.Option>
                                    <Select.Option key={`PHONENUMBER`} value={`PHONENUMBER`}>{`Phone Number`}</Select.Option>
                                    <Select.Option key={`EMAIL`} value={`EMAIL`}>{`Email`}</Select.Option>
                                    <Select.Option key={`AGENTCODE`} value={`AGENTCODE`}>{`Agent / Affiliate Code`}</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>Search Field:</i><br/>
                                <Input value={this.state.searchField} onChange={event => this.onFieldChange("searchField", event.target.value)} placeholder="Search Field"/>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>&nbsp;</i><br/>
                                <Button loading={this.state.processingFetchData} onClick={this.fetchUserData} type={"primary"}> Cari Data </Button>
                            </Form.Item>
                        </Form>
                    </div>

                    <div style={this.state.processingFetchData ? {} : {display: 'none'}} className={`spinCenter`}>
                        <Spin size="large"/>
                    </div>

                    <div style={(!this.state.dataQrUser?.name && !this.state.processingFetchData) ? {} : {display: 'none'}}>
                        <Empty/>
                    </div>

                    <div className={`p-5`} style={(this.state.dataQrUser?.name) ? {} : {display: 'none'}}>
                        <Row>
                            <Col xs={24} xl={9} style={{padding: 5}}>
                                <DetailUser dataDetailUser={this.state.dataQrUser} fullCol={true} showUserType={true} showIdCard={true}/>
                            </Col>
                            <Col xs={24} xl={15} style={{padding: 5}}>
                                <Button
                                    onClick={event =>
                                        this.setState({
                                            showModalAdd: true,
                                            qrCode: "",
                                            qrTarget: "",
                                            qrNotes: "",
                                        })} type={"primary"}
                                > Tambah QR </Button>

                                <div style={(this.state.dataDetailQr.length < 1 && !this.state.processingFetchData) ? {} : {display: 'none'}}>
                                    <Empty/>
                                </div>

                                <div style={(this.state.dataDetailQr.length > 0 && !this.state.processingFetchData) ? {} : {display: 'none'}}>
                                    <Row className={`pt-10`}>
                                        {this.state.dataDetailQr?.map((item: any, x: number) =>
                                            <Col className={`p-5`} xxl={{span: 12}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                <Card hoverable={true} size="small">
                                                    <Row>
                                                        <Col span={6}>
                                                            <div style={{height: "auto", margin: "0 auto", maxWidth: "100%", width: "100%"}}>
                                                                <QRCode
                                                                    size={64}
                                                                    value={item.target}
                                                                    style={{height: "auto", maxWidth: "100%", width: "100%"}}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col span={18}>
                                                            <span>{item?.code}</span>
                                                            <span style={{float: "right"}}>
                                                                <Button onClick={event => this.onDelete(item.code)} type="primary" danger size={"small"} icon={<DeleteOutlined/>}/>
                                                            </span>
                                                            <br/>
                                                            <span className={`extra-small-text-grey`}>{item.target}</span><br/>
                                                            <span className={`small-text-grey`}>Notes: {item.notes ?? "-"}</span>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </AppLayout>

                <Modal
                    open={this.state.showModalAdd}
                    title="Add New QR"
                    onCancel={() => this.setState({showModalAdd: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button loading={this.state.loading} type={"primary"} key="1" onClick={this.confirmAddModelData}>Add</Button>,
                        <Button key="2" onClick={() => this.setState({showModalAdd: false})}>Close</Button>,
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <Form.Item>
                            <i className={`small-text-grey`}>Code:</i><br/>
                            <Input value={this.state.qrCode} onChange={event => this.onFieldChange("qrCode", event.target.value)} placeholder="Code"/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`small-text-grey`}>Type:</i><br/>
                            <Select
                                style={{float: "left", minWidth: "150px"}}
                                showSearch
                                onChange={value => this.onFieldChange("qrType", value)}
                                value={this.state.qrType}
                                disabled={true}
                            >
                                <Select.Option value={"URL_LINK"}>URL_LINK</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <i className={`small-text-grey`}>Type:</i><br/>
                            <Select
                                style={{float: "left", minWidth: "150px"}}
                                showSearch
                                onChange={value => this.onFieldChange("qrAction", value)}
                                value={this.state.qrAction}
                                disabled={true}
                            >
                                <Select.Option value={"OPEN_NEW_TAB"}>OPEN_NEW_TAB</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <i className={`small-text-grey`}>Target URL:</i><br/>
                            <Input value={this.state.qrTarget} onChange={event => this.onFieldChange("qrTarget", event.target.value)} placeholder="Target URL"/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`small-text-grey`}>Notes:</i><br/>
                            <Input value={this.state.qrNotes} onChange={event => this.onFieldChange("qrNotes", event.target.value)} placeholder="Notes"/>
                        </Form.Item>
                    </React.Fragment>
                </Modal>

                <Modal
                    open={this.state.modalDelete}
                    title={`Delete QR`}
                    onCancel={() => this.setState({modalDelete: false})}
                    cancelText={'Close'} okText={false}
                    footer={[
                        <Button key={1} onClick={event => this.onDeleteSubmit()} loading={this.state.loading} type="primary" style={{marginRight: 7}}>Save</Button>,
                        <Button key={2} onClick={() => this.setState({modalDelete: false})}>Close</Button>
                    ]}
                >
                    <Divider style={{marginTop: 5, marginBottom: 10}}/>
                    Apakah anda yakin akan menghapus QR {this.state.codeDelete} ? <br/>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withAuth(UserQr, 26);