import React, {Component} from "react";
import Cookies from "universal-cookie";
import {Card, Col, Empty, QRCode, Row, Spin} from "antd";
import {userServices} from "../../services/amartaVip/UserService";

class DetailUserQr extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            uid: "",
            processingFetch: false,
            dataDetailQr: [],
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            uid: this.props.uid,
        });
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.time !== prevProps.time) || (this.props.uid !== prevProps.uid)) {
            await this.promisedSetState({
                uid: this.props.uid,
            });
            await this.fetchData();
        }
    }

    fetchData = async () => {
        this.setState({
            processingFetch: true
        });
        try {
            await userServices.setToken(this.state.token);
            const fetchResult = await userServices.getListUserQr({
                uid: this.props.uid
            });

            const sortedData: any = fetchResult?.data?.data;
            await this.promisedSetState({
                processingFetch: false,
                dataDetailQr: sortedData,
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetch: false,
                dataDetailQr: [],
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.processingFetch ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={(this.state.dataDetailQr.length < 1 && !this.state.processingFetch) ? {} : {display: 'none'}}>
                    <Empty/>
                </div>
                <div style={!this.state.processingFetch ? {} : {display: 'none'}}>
                    <Row className={`pt-10`}>
                        {this.state.dataDetailQr?.map((item: any, x: number) =>
                            <Col className={`p-5`} span={24}>
                                <Card hoverable={true} size="small">
                                    <Row>
                                        <Col span={6}>
                                            <div style={{height: "auto", margin: "0 auto", maxWidth: "100%", width: "100%"}}>
                                                <QRCode
                                                    size={64}
                                                    value={item.target}
                                                    style={{height: "auto", maxWidth: "100%", width: "100%"}}
                                                />
                                            </div>
                                        </Col>
                                        <Col span={18}>
                                            <span>{item?.code}</span><br/>
                                            <span className={`extra-small-text-grey`}>{item.target}</span><br/>
                                            <span className={`small-text-grey`}>Notes: {item.notes ?? "-"}</span>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default DetailUserQr;