import axios from "axios";
import {baseUrlAmartaVip, xApiKeyAmartaVip} from "../../config/apiConfig/apiConfig";

class UserServices {
    private axios = axios.create({
        baseURL: baseUrlAmartaVip,
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    public async getUserLedger(params: { idCardNumber?: string, ledgerCode?: string, transactionStartDate?: string, transactionEndDate?: string }) {
        const queries = {
            ...(params?.idCardNumber && {
                id_card_number: params.idCardNumber,
            }),
            ...(params?.ledgerCode && {
                ledger_code: params.ledgerCode,
            }),
            ...(params?.transactionStartDate && {
                transaction_start_date: params.transactionStartDate,
            }),
            ...(params?.transactionEndDate && {
                transaction_end_date: params.transactionEndDate,
            })
        }
        try {
            return await this.axios.get<any>(`user/ledger`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                    "Authorization": "Bearer xs8dw8a9j28Y78w7a9skhjayeIEJ8e7akU8",
                },
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async updateUserInternal(data?: any) {
        try {
            return await this.axios.put<any>('/user/internal',
                data, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getDetailUser(params: { type: string, searchText: string }) {
        let urlType = "";
        let searchText = params.searchText.trim();
        if (params.type === "IDCARD") {
            urlType = "id-card-number";
        }
        if (params.type === "PHONENUMBER") {
            urlType = "phone-number";
            searchText = (searchText.substring(0, 2) === "08") ? searchText.replace(/^.{2}/g, '628') : searchText;
        }
        if (params.type === "EMAIL") {
            urlType = "email";
        }
        if (params.type === "AGENTCODE") {
            urlType = "agent-code";
        }

        const queries: any = {}
        try {
            return await this.axios.get<any>(`/user/${urlType}/${searchText}`, {
                headers: {"X-Api-Key": xApiKeyAmartaVip,},
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getListUserInternalAffiliate(params: { type: string | null }) {
        const queries: any = {}
        try {
            return await this.axios.get<any>(`/user/uid/${params.type}`, {
                headers: {"X-Api-Key": xApiKeyAmartaVip,},
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async checkBlacklistPhoneNumber(params?: { phoneNumber?: string }) {
        const queries: any = {}
        try {
            return await this.axios.get<any>(`/user/blacklist-phone-number/${params?.phoneNumber}`, {
                headers: {"X-Api-Key": xApiKeyAmartaVip,},
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async sendTelegram(inputData?: any) {
        try {
            return await this.axios.post<any>('/user/send-telegram',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async verifyAccount(data?: any) {
        try {
            return await this.axios.put<any>('/user/verify-account',
                data, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getLeadsTrack(params: { company: string, period: string }) {
        const queries = {
            ...(params?.period && {
                period: params.period,
            })
        }
        try {
            return await this.axios.get<any>(`tracking/lead-agent/${params.company}`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                    "Authorization": "Basic xGVhYHNDbGllbnRBcHA6cER5PREyVC7zKGYzYDZLWg==",
                },
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getTestDriveTrack(params: { company: string, period: string }) {
        const queries = {
            ...(params?.period && {
                period: params.period,
            })
        }
        try {
            return await this.axios.get<any>(`tracking/testdrive-agent/${params.company}`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                    "Authorization": "Basic xGVhYHNDbGllbnRBcHA6cER5PREyVC7zKGYzYDZLWg==",
                },
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getOrderTrack(params: { company: string, period: string }) {
        const queries = {
            ...(params?.period && {
                period: params.period,
            })
        }
        try {
            return await this.axios.get<any>(`tracking/order-agent/${params.company}`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                    "Authorization": "Basic xGVhYHNDbGllbnRBcHA6cER5PREyVC7zKGYzYDZLWg==",
                },
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getInActiveAffiliate(inputData?: any) {
        const queries = {}
        try {
            return await this.axios.get<any>(`/affiliate/inactive-affiliate-list`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            console.log(e.response.data.error.message);
            throw new Error(e.response.data.error.message);
        }
    }

    public async updateAffiliateStatus(inputData?: any) {
        try {
            return await this.axios.put<any>('/affiliate/update-status',
                inputData, {
                    headers: {
                        "X-Api-Key": xApiKeyAmartaVip,
                        "Content-Type": "application/json"
                    }
                });
        } catch (e: any) {
            console.log(e.response.data.error.message);
            throw new Error(e.response.data.error.message);
        }
    }

    public async getListUserOrder(params: { startDate: string, endDate: string, source?: string, internal: string, uid: string, transactionStatus: string, organization?: any }) {
        const queries = {
            ...(params?.organization && {
                source: params.organization,
            }),
            ...(params?.startDate && {
                start_date: params.startDate,
            }),
            ...(params?.endDate && {
                end_date: params.endDate,
            }),
            ...(params?.source && {
                source: params.source,
            }),
            ...(params?.internal && {
                internal: params.internal,
            }),
            ...(params?.uid && {
                uid: params.uid,
            }),
            ...(params?.transactionStatus && {
                transaction_status: params.transactionStatus,
            }),
        }
        try {
            return await this.axios.get<any>(`profile/order`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip
                },
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getMultiUserByAgentCOde(params: { agentCodes: string | null }) {
        const queries: any = {}
        try {
            return await this.axios.get<any>(`/user/agent-code/${params.agentCodes}`, {
                headers: {"X-Api-Key": xApiKeyAmartaVip,},
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async createUserQr(inputData?: any) {
        try {
            return await this.axios.post<any>('/profile/qr',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getListUserQr(params: { uid: string }) {
        const queries = {
            uid: params.uid,
            internal: "true",
        }
        try {
            return await this.axios.get<any>(`profile/qr`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip
                },
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async removeQr(params: any) {
        try {
            return await this.axios.delete<any>('profile/qr', {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                    "Content-Type": "application/json"
                },
                data: params
            });
        } catch (e: any) {
            console.log(e.response.data.error.message);
            throw new Error(e.response.data.error.message);
        }
    }

    public async getResumeLead(params: any) {
        const queries = {
            ...(params?.source && {
                source: params.source,
            }),
            ...(params?.startDate && {
                start_date: params.startDate,
            }),
            ...(params?.endDate && {
                end_date: params.endDate,
            }),
            ...(params?.agentCodes && {
                // agent_codes: params.agentCodes,
            }),
            ...(params?.spvCode && {
                spv_code: params.spvCode,
            }),
            internal: "true"
        }
        try {
            return await this.axios.get<any>(`profile/resume-lead`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip
                },
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }


}

export const userServices = new UserServices();
