import React, {Component} from 'react';
import {Buffer} from "buffer";
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import {NotesLeadStates} from "./types/NotesLeadTypes";
import {allowedOrganization} from "../../config/appDataConfig/organizationCondig";
import {DatePicker, Form, Menu, Select, Table, Dropdown, Input, Modal, Tag, Button, Divider} from "antd";
import {leadService} from "../../services/trimitraIdeal/LeadServive";
import dayjs from "dayjs";
import {DownOutlined, HeatMapOutlined, SearchOutlined} from "@ant-design/icons";

export interface CounterLeadProps {
}

class CounterLead extends Component<CounterLeadProps & any, NotesLeadStates> {
    private cookies = new Cookies();

    constructor(props: CounterLeadProps & any) {
        super(props);

        this.state = {
            user: JSON.parse(Buffer.from(this.cookies.get('_vip-u'), "base64").toString()),
            token: this.cookies.get('_vip-t') ?? "",
            startDate: dayjs(),
            processingFetchData: false,
            dataNotesLead: [],
            countNotesLead: 0,
            organizationCode: allowedOrganization[0].companyCode,

            searchType: "date",
            searchLeadPhoneNumber: "",

            modalUpdateMoveToCold: false,
            submitMoveToCold: false,
            tempNotes: "",
            tempPhoneNumber: "",
            tempOrganizationCode: "",
            eventType: "all",
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "eventType" | "searchLeadPhoneNumber" | "searchType" | "organizationCode" | "startDate" | "tempNotes">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "eventType":
                currentState.eventType = value;
                break;
            case "tempNotes":
                currentState.tempNotes = value;
                break;
            case "organizationCode":
                currentState.organizationCode = value;
                break;
            case "startDate":
                currentState.startDate = value;
                break;
            case "searchType":
                currentState.searchType = value;
                break;
            case "searchLeadPhoneNumber":
                currentState.searchLeadPhoneNumber = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    fetchNotes = async () => {
        if (this.state.searchType === 'date') {
            await this.promisedSetState({
                processingFetchData: true,
                dataNotesLead: [],
                countNotesLead: 0,
            });
            try {
                await leadService.setToken();
                const fetchResult = await leadService.getGlobalNotes({
                    organizationCode: this.state.organizationCode.toLocaleLowerCase(),
                    date: dayjs(this.state.startDate).format("YYYY-MM-DD"),
                });

                let finalTableData = [];
                const responseData = fetchResult?.data?.success?.data;

                if (this.state.eventType === "all") {
                    finalTableData = responseData;
                } else {
                    for (const item of responseData) {
                        if (item.event === this.state.eventType) {
                            finalTableData.push(item);
                        }
                    }
                }

                await this.promisedSetState({
                    processingFetchData: false,
                    dataNotesLead: finalTableData,
                    countNotesLead: finalTableData?.length
                });
            } catch (e) {
                await this.promisedSetState({
                    processingFetchData: false,
                    dataNotesLead: [],
                    countNotesLead: 0,
                });
            }
        } else {
            if (!this.state.searchLeadPhoneNumber) {
                Modal.error({
                    title: 'Error',
                    content: 'lead phone number required'
                });
                return;
            }

            await this.promisedSetState({
                processingFetchData: true,
                dataNotesLead: [],
            });
            try {
                await leadService.setToken();
                const fetchResult = await leadService.getGlobalNotesByPhoneNumber({
                    organizationCode: this.state.organizationCode.toLocaleLowerCase(),
                    phoneNumber: (this.state.searchLeadPhoneNumber.substring(0, 2) === "08") ? this.state.searchLeadPhoneNumber.replace(/^.{2}/g, '628') : this.state.searchLeadPhoneNumber,
                });
                await this.promisedSetState({
                    processingFetchData: false,
                    dataNotesLead: fetchResult?.data?.success?.data,
                });
            } catch (e) {
                await this.promisedSetState({
                    processingFetchData: false,
                    dataNotesLead: [],
                });
            }
        }
    }

    confirmMoveToCold = async () => {
        this.setState({
            submitMoveToCold: true,
        });

        try {
            await leadService.setToken();
            await leadService.moveToCold({
                leadsPhoneNumber: this.state.tempPhoneNumber,
                organization: this.state.tempOrganizationCode,
                movedBy: this.state.user,
                notes: this.state.tempNotes
            });

            this.setState({
                submitMoveToCold: false,
                modalUpdateMoveToCold: false,
            });

            // await this.fetchNotes();
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + (e?.toString() ?? "error lead server")
            });
            this.setState({
                submitMoveToCold: false,
            });
        }
    }

    moveToCold = async (rowData: any) => {
        this.setState({
            tempNotes: "",
            modalUpdateMoveToCold: true,
            submitMoveToCold: false,
            tempPhoneNumber: rowData.phoneNumber,
            tempOrganizationCode: rowData.organization,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`UserVIP`} breadcrumbMenu={`Lead Notes`} defaultSelectedKeys={['m-leadnotes']} defaultOpenKeys={['sm-lead']}>
                    <div style={{padding: 20}}>
                        <Form layout={(window.innerWidth > 992) ? `inline` : 'horizontal'} style={{marginTop: -3}}>
                            <Form.Item>
                                <i className={`small-text-grey`}>Organization:</i><br/>
                                <Select
                                    style={{float: "left", minWidth: "150px"}}
                                    showSearch
                                    placeholder="Choose organizationCode"
                                    optionFilterProp="children"
                                    onChange={value => this.onFieldChange("organizationCode", value)}
                                    value={this.state.organizationCode}
                                >
                                    {(allowedOrganization ?? []).map((item: any, i: number) =>
                                        <Select.Option key={i} value={item?.companyCode}>{item?.companyCode}</Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                            <Form.Item className={'padTop-10'} label="" style={this.state.searchType === "date" ? {} : {display: 'none'}}>
                                <i className={`small-text-grey`}>Date</i><br/>
                                <DatePicker style={(window.innerWidth > 992) ? {} : {width: "100%"}} defaultValue={dayjs(this.state.startDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("startDate", event)} format={'YYYY-MM-DD'}/>
                            </Form.Item>
                            <Form.Item className={'padTop-10'} label="" style={this.state.searchType === "lead_phone_number" ? {} : {display: 'none'}}>
                                <i className={`small-text-grey`}>Lead Phone Number</i><br/>
                                <Input value={this.state.searchLeadPhoneNumber} onChange={event => this.onFieldChange("searchLeadPhoneNumber", event.target.value)} placeholder="Lead Phone Number"/>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>Tipe Event:</i><br/>
                                <Select
                                    style={{float: "left", minWidth: "150px"}}
                                    showSearch
                                    placeholder="Choose Event"
                                    optionFilterProp="children"
                                    onChange={value => this.onFieldChange("eventType", value)}
                                    value={this.state.eventType}
                                >
                                    <Select.Option value={"all"}>All Event</Select.Option>
                                    <Select.Option value={"init"}>Init</Select.Option>
                                    <Select.Option value={"triforceNotes"}>Triforce Notes</Select.Option>
                                    <Select.Option value={"whatsappAnswer"}>Whatsapp Answer</Select.Option>
                                    <Select.Option value={"untrack"}>Untrack</Select.Option>
                                    <Select.Option value={"reactivateTrack"}>Reactivate Track</Select.Option>
                                    <Select.Option value={"track"}>Track</Select.Option>
                                    <Select.Option value={"increaseLevelStatus"}>Increase Level Status</Select.Option>
                                    <Select.Option value={"decreaseLevelStatus"}>Decrease Level Status</Select.Option>
                                    <Select.Option value={"updateTestDrive"}>Update TestDrive</Select.Option>
                                    <Select.Option value={"updateSpk"}>Update Spk</Select.Option>
                                    <Select.Option value={"moveToCold"}>MoveToCold</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>&nbsp;</i><br/>
                                <Form.Item className={'pt-5'}>
                                    <Dropdown.Button
                                        type="primary"
                                        onClick={this.fetchNotes}
                                        icon={<DownOutlined/>}
                                        trigger={['click']}
                                        overlay={(
                                            <Menu>
                                                <Menu.Item key={1} onClick={event => this.onFieldChange("searchType", "date")}>
                                                    Pencarian Tanggal
                                                </Menu.Item>
                                                <Menu.Item key={2} onClick={event => this.onFieldChange("searchType", "lead_phone_number")}>
                                                    Pencarian Phone Number Lead
                                                </Menu.Item>
                                            </Menu>
                                        )}
                                    ><SearchOutlined/> Cari Data</Dropdown.Button>
                                </Form.Item>
                            </Form.Item>
                        </Form>
                    </div>

                    <div className={`p-10`}>
                        <div style={{paddingBottom: 10, paddingLeft: 20}}><i className={`small-text-grey`}>Total Data: </i> {this.state.countNotesLead} </div>
                        <Table
                            rowKey={'code'}
                            size={'small'}
                            scroll={{x: 'calc(500px + 50%)'}}
                            loading={this.state.processingFetchData}
                            columns={[
                                {
                                    title: 'Action',
                                    dataIndex: 'agentCode',
                                    render: ((value: string, row: { agentName: string | null }) => {
                                        return <React.Fragment>
                                            <Tag onClick={event => this.moveToCold(row)} style={{cursor: "pointer", marginTop: 2, fontSize: "70%", display: "inline", padding: 4, color: "#fff", backgroundColor: "#c95050", borderRadius: 5}}><HeatMapOutlined/> Move To Cold</Tag>
                                        </React.Fragment>
                                    })
                                },
                                {
                                    title: 'Agent',
                                    dataIndex: 'agentCode',
                                    render: ((value: string, row: { agentName: string | null }) => {
                                        return <React.Fragment>
                                            <span style={{color: "#da2f2f"}} className={`font-90`}>{value}</span><br/>
                                            <span className={`gray-80`}>{row.agentName}</span>
                                        </React.Fragment>
                                    })
                                },
                                {
                                    title: 'Lead',
                                    dataIndex: 'phoneNumber',
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            <span style={{color: "#da2f2f"}} className={`font-90`}>{value}</span><br/>
                                            <span className={`gray-80`}>{row.firstName} {row.lastName}</span>
                                        </React.Fragment>
                                    })
                                },
                                {
                                    title: 'Event & Level',
                                    dataIndex: 'event',
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            <span className={`gray-80`}>{value}</span> | <span className={`gray-80`}>Level: {row.statusLevel}</span> <br/>
                                        </React.Fragment>
                                    })
                                },
                                {
                                    title: 'Counter Notes & Reactive',
                                    dataIndex: 'agentCode',
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            <span className={`gray-80`}>Reactive: {row?.reactivate?.currentTotal ?? 0}</span><br/>
                                            <span className={`gray-80`}>UpdateNote: {row?.totalUpdateNotes ?? 0}</span>
                                        </React.Fragment>
                                    })
                                },
                                {
                                    title: 'Date & Notes',
                                    dataIndex: 'updatedAt',
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            <span className={`gray-80`}>{value}</span><br/>
                                            <span className={`gray-80`}>{row.notes}</span>
                                        </React.Fragment>
                                    })
                                },
                                {
                                    title: 'FollowUp Schedule',
                                    dataIndex: 'followUpScheduledAt',
                                    width: 180,
                                    render: ((value: number, row: any) => {
                                        return <React.Fragment>
                                            {(() => {
                                                if (value) {
                                                    return (
                                                        <React.Fragment>
                                                            <span className={`font-90`}>{dayjs(value).format("YYYY-MM-DD")}</span> <br/> <span className={`gray-80`}>{dayjs(value).format("HH:mm:ss")}</span>
                                                        </React.Fragment>
                                                    )
                                                }
                                                return "-";
                                            })()}
                                        </React.Fragment>
                                    })
                                },
                            ]}
                            dataSource={this.state.dataNotesLead}
                            pagination={{pageSize: 1000}}
                        />
                    </div>
                </AppLayout>

                <Modal
                    open={this.state.modalUpdateMoveToCold}
                    title="Move To Cold"
                    onCancel={() => this.setState({modalUpdateMoveToCold: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button loading={this.state.submitMoveToCold} type={"primary"} key="1" onClick={this.confirmMoveToCold}>
                            Update
                        </Button>,
                        <Button key="2" onClick={() => this.setState({modalUpdateMoveToCold: false})}>
                            Close
                        </Button>,
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <div className={`gray-80 mt-20`}>Catatan:</div>
                        <Input value={this.state.tempNotes} onChange={event => this.onFieldChange("tempNotes", event.target.value)} placeholder="Catatan"/>
                        <Divider/>
                    </React.Fragment>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withAuth(CounterLead, 13);
