import React, {FunctionComponent} from "react";
import {Button} from "antd";
import Cookies from "universal-cookie";
import {withRouter} from "../../hoc/withRouter";

const ButtonLogout: FunctionComponent<any> = (props) => {

    const cookies = new Cookies();

    function onPressed() {
        const c = cookies.get('_vip-t');
        if (c) {
            cookies.remove('_vip-t');
            cookies.remove('_vip-u');
            cookies.remove('_vip-a');
            cookies.remove('_vip-da');
            cookies.remove('_vip-am');
        }

        props.history.push('/login');
    }

    return <Button onClick={onPressed}>Keluar</Button>
}

export default withRouter(ButtonLogout);
