import React, {ComponentType} from "react";
import Cookies from 'universal-cookie';
import {authApiService} from "../services/trimitraIAM/AuthApiService";
import AppLayout from "../component/layouts/AppLatout";
import {Spin} from "antd";

export default function withAuth<P extends object>(Component: ComponentType<P>, pageCode?: any) {
    const cookies = new Cookies();

    class InnerHoc extends React.Component<any, any> {
        constructor(props: any) {
            super(props);

            this.state = {
                authenticated: false,
                authenticating: true,
            }
        }

        checkAuth = async () => {
            await this.promisedSetState({
                authenticating: true,
            });
            let currentState = {...this.state};
            const token = cookies.get('_vip-t');
            if (!token) {
                currentState.authenticated = false;
            } else {
                authApiService.setToken(token);
                try {
                    await authApiService.myAccount(pageCode);
                    currentState.authenticated = true;
                } catch (e) {
                    currentState.authenticated = false;
                }
            }
            currentState.authenticating = false;
            await this.setState({...currentState});
        }

        componentDidMount = () => {
            this.checkAuth().then();
        };

        promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

        render = () => {
            if (this.state.authenticating) {
                if (cookies.get('_vip-t')) {
                    return <AppLayout>
                        <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                            <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                        </div>
                    </AppLayout>
                } else {
                    return <><Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/></>;
                }
            } else {
                if (this.state.authenticated) {
                    return (<Component {...this.props as P}/>)
                } else {
                    cookies.remove('_vip-t');
                    cookies.remove('_vip-u');
                    cookies.remove('_vip-a');
                    cookies.remove('_vip-da');
                    cookies.remove('_vip-am');
                    setTimeout(() => {
                        window.location.href = "/login";
                    }, 200);
                }
            }
        };
    }

    return InnerHoc;
}
