import axios from "axios";
import {baseUrlTrimitraCatalog, xApiKeyTrimitraCatalog} from "../../config/apiConfig/apiConfig";

class CatalogVehicleService {
    private axios = axios.create({
        baseURL: baseUrlTrimitraCatalog,
    });

    public async getCatalogModel(params?: { companyCode?: string, areaCode?: string, modelClass?: string, homeShowed?: boolean }) {
        const finalCompanyCode = (params?.companyCode) ? params?.companyCode : "amarta";
        const finalAreaCode = (params?.areaCode) ? params?.areaCode : "jabar2";

        const queries = {
            ...(params?.modelClass && {
                class: params.modelClass,
            }),
            ...(params?.homeShowed && {
                home_showed: params.homeShowed,
            }),
        }
        try {
            return await this.axios.get<any>(`products/${finalCompanyCode}/area/${finalAreaCode}/model`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            console.log(e.response.data.error.message);
            throw new Error(e.response.data.error.message);
        }
    }

    public async getCatalogVariant(params?: { companyCode?: string, areaCode?: string, modelName?: string, variantCode?: string, homeShowed?: boolean }) {
        const finalCompanyCode = (params?.companyCode) ? params?.companyCode : "amarta";
        const finalAreaCode = (params?.areaCode) ? params?.areaCode : "jabar2";

        const queries = {
            ...(params?.modelName && {
                model_name: params.modelName,
            }),
            ...(params?.variantCode && {
                code: params.variantCode,
            }),
            ...(params?.homeShowed && {
                home_showed: params.homeShowed,
            }),
        }
        try {
            return await this.axios.get<any>(`products/${finalCompanyCode}/area/${finalAreaCode}/variant`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }
}

export const catalogVehicleService = new CatalogVehicleService();
