import React, { Component } from "react";
import { dealerApiService } from "../../services/otodis/DealerApiService";
import { Select } from "antd";
const { Option } = Select;

class SelectDealer extends Component<any, any> {
  initState = {
    dataOption: [],
  };
  state = { ...this.initState };

  componentDidMount = async () => {
    this.fetchDealers(this.props.organizationCode);
  };

  componentDidUpdate = (prevProps: any) => {
    if (prevProps.organizationCode !== this.props.organizationCode) {
      this.fetchDealers(this.props.organizationCode);
    }
  };

  fetchDealers = async (organizationCode: any) => {
    try {
      const dataDealer = await dealerApiService.getDealers({
        company_code: organizationCode,
      });

      let listOption = dataDealer.data.data.map((d: any) => {
        return { dealer_code: d.dealer_code, dealer_name: d.dealer_name, city_code: d.city_code, city_name: d.city_name, province_code: d.province_code, province_name: d.province_name };
      });

      this.setState({
        dataOption: listOption,
      });
    } catch (error) {
      this.setState({
        dataOption: [],
      });
    }
  };

  render() {
    return (
      <Select style={{ fontSize: "85%", minWidth: "280px" }} placeholder={"Pilih " + this.props.label} onChange={this.props.onChangeValue} value={this.props.value}>
        {this.state.dataOption.map((d: any, i) => {
          return (
            <Option key={d.dealer_code} value={d.dealer_code} add_text={d.city_code + "#" + d.city_name + "#" + d.province_code + "#" + d.province_name}>
              {d.dealer_name}
            </Option>
          );
        })}
      </Select>
    );
  }
}

export default SelectDealer;
