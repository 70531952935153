import React, {Component} from "react";
import Col from "antd/lib/col";
import {Divider, Row, Spin} from "antd";
import {vacancyApiService} from "../../services/amartaVip/VacancyApiService";
import Cookies from "universal-cookie";

class DetailAssessment extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            organization: "",
            chosenInterviewCode: "",
            processingFetch: false,
            dataAssessment: {},
        }

        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            organization: this.props.organization,
            chosenInterviewCode: this.props.chosenInterviewCode,
        });
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.time !== prevProps.time) || (this.props.chosenInterviewCode !== prevProps.chosenInterviewCode)) {
            await this.promisedSetState({
                organization: this.props.organization,
                chosenInterviewCode: this.props.chosenInterviewCode,
            });

            await this.fetchData();
        }
    }

    fetchData = async () => {
        await this.promisedSetState({
            processingFetch: true,
            dataAssessment: {},
        });

        try {
            await vacancyApiService.setToken(this.state.token);
            const fetchResult = await vacancyApiService.getOrganizationInterview({
                organization: this.state.organization,
                assessmentCode: this.state.chosenInterviewCode,
            });
            await this.promisedSetState({
                processingFetch: false,
                dataAssessment: fetchResult?.data?.data[0],
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetch: false,
                dataAssessment: {},
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.processingFetch ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={!this.state.processingFetch ? {} : {display: 'none'}}>
                    <Row>
                        <Col xs={24} xl={12}>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Apply Code:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.apply_code}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Assessment Code:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.assessment_code}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Category:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.category}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Organization:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.organization}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Vacancy:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.vacancy_code} ({this.state.dataAssessment?.vacancy_name})</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Role:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.role_name}</Col>
                            </Row>
                            <Divider/>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Admin:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.created_admin}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Create Time:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.created_time?.slice(0, 16)?.replace("T", " ")}</Col>
                            </Row>
                        </Col>
                        <Col xs={24} xl={12}>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Type:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.type}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Category:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.category}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Interview Time:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.date?.slice(0, 16)?.replace("T", " ")}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Name:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.user_data?.full_name}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Phone:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.user_data?.phones}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Email:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.user_data?.email}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Location:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.location}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Notes:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.notes}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Notes For Applicant:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.notes_for_applicant}</Col>
                            </Row>
                        </Col>
                        <Col xs={24} xl={24} style={this.state.dataAssessment.status !== "OPEN" ? {} : {display: 'none'}}>
                            <Divider/>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>STATUS:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment.status}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Notes:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.result_notes}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Notes for internal:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.result_notes_for_internal}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Notes for applicant:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.result_notes_for_applicant}</Col>
                            </Row>
                            <br/>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Updated Admin:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.update_admin}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Updated Time:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.update_time}</Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default DetailAssessment;
