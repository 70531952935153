import React, {Component} from "react";
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from "universal-cookie";
import {Buffer} from "buffer";
import {fincoServices} from "../../services/b2b/FincoService";
import withAuth from "../../hoc/withAuth";
import {BlacklistStates} from "./types/BlacklistTypes";
import {Button, Col, Modal, Row, Table, Tabs} from "antd";
import {SaveOutlined} from "@ant-design/icons";
import Spreadsheet from "react-spreadsheet";
import {authApiService} from "../../services/amartaVip/AuthApiService";

export interface BlacklistProps {
}

class Blacklist extends Component<BlacklistProps & any, BlacklistStates> {
    private cookies = new Cookies();
    private columnLabelsPhone = ["Nomor Telpon", "Alasan Blacklist"];
    private columnLabelsIdCard = ["Nomor KTP", "Kode Leasing", "Alasan Blacklist"];
    private columnLabelsInternal = ["Nomor KTP", "Organisasi", "Alasan Blacklist"];

    constructor(props: BlacklistProps & any) {
        super(props);

        this.state = {
            token: this.cookies.get("_vip-t") ?? "",
            user: JSON.parse(Buffer.from(this.cookies.get("_vip-u"), "base64").toString()),
            listFinco: [],
            listCodeFinco: [],
            loading: false,
            dataSheetBlacklistPhoneNumber: [
                [{value: ""}, {value: ""}],
                [{value: ""}, {value: ""}],
                [{value: ""}, {value: ""}],
                [{value: ""}, {value: ""}],
                [{value: ""}, {value: ""}]
            ],
            dataSheetBlacklistIdCard: [
                [{value: ""}, {value: ""}, {value: ""}],
                [{value: ""}, {value: ""}, {value: ""}],
                [{value: ""}, {value: ""}, {value: ""}],
                [{value: ""}, {value: ""}, {value: ""}],
                [{value: ""}, {value: ""}, {value: ""}]
            ],
            dataSheetInternal: [
                [{value: ""}, {value: ""}, {value: ""}],
                [{value: ""}, {value: ""}, {value: ""}],
                [{value: ""}, {value: ""}, {value: ""}],
                [{value: ""}, {value: ""}, {value: ""}],
                [{value: ""}, {value: ""}, {value: ""}]
            ],
        };
    }

    onAreaChange = async (data: any) => {
        console.log(data);
    };

    onPhoneSubmit = async () => {
        const dataTable = this.state.dataSheetBlacklistPhoneNumber;
        const product: any = []
        for (const item of dataTable) {
            if (item[0]?.value && item[1]?.value) {
                if (isNaN(item[0]?.value)) {
                    return Modal.error({title: 'Proses Gagal', content: 'format nomor telpon salah'})
                }
                product.push({
                    phone: item[0]?.value?.trim(),
                    reason: item[1]?.value?.trim(),
                })
            }
        }
        if (product?.length < 1) {
            return Modal.error({title: 'Proses Gagal', content: 'lengkapi data'})
        }

        await this.promisedSetState({loading: true});
        const input = {
            type: "blacklist",
            source: "blacklist_form",
            phone_numbers: product
        }

        try {
            await authApiService.blacklistNumber(input).then(() => {
                Modal.success({title: 'Proses Sukses', content: 'Blacklist berhasil ditambahkan'});
                setTimeout(function () {
                    window.location.reload()
                }, 2000);
            })
        } catch (e) {
            Modal.error({title: 'Proses Gagal', content: 'Server Menolak Inputan Anda :' + e});
            this.setState({loading: false});
        }
    }

    onIdCardSubmit = async () => {
        const dataTable = this.state.dataSheetBlacklistIdCard;
        const product: any = []
        for (const item of dataTable) {
            if (item[0]?.value) {
                if (item[0]?.value?.trim()?.length !== 16) {
                    return Modal.error({title: 'Proses Gagal', content: `format id card ${item[0]?.value} salah`})
                }

                let listFincoSelected = [];
                if (item[1]?.value) {
                    const fincoCodeX = item[1]?.value?.trim()?.toUpperCase()?.replace(/,$/, '')
                    for (let fincoCode of fincoCodeX.split(",")) {
                        fincoCode = fincoCode?.trim()?.toUpperCase()
                        if (this.state.listCodeFinco.includes(fincoCode)) {
                            listFincoSelected.push(fincoCode)
                        } else {
                            return Modal.error({title: 'Proses Gagal', content: `kode finco ${fincoCode} tidak terdaftar`})
                        }
                    }
                }
                product.push({
                    id_card_number: item[0]?.value?.trim(),
                    finco_lists: listFincoSelected,
                    reason: item[2]?.value?.trim(),
                })
            }
        }

        if (product?.length < 1) {
            return Modal.error({title: 'Proses Gagal', content: 'lengkapi data'})
        }

        await this.promisedSetState({loading: true})
        const input = {
            type: "blacklist",
            source: "blacklist_form",
            id_card_numbers: product
        }

        try {
            await authApiService.blacklistIdCard(input).then(() => {
                Modal.success({title: 'Proses Sukses', content: 'Blacklist berhasil ditambahkan'})
                setTimeout(function () {
                    window.location.reload()
                }, 2000)
            })
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            })
            this.setState({
                loading: false,
            })
        }
    }

    onInternalSubmit = async () => {
        const dataTable = this.state.dataSheetInternal;
        const product: any = []
        for (const item of dataTable) {
            if (item[0]?.value && item[1]?.value) {
                if (item[0]?.value?.trim()?.length !== 16) {
                    return Modal.error({title: 'Proses Gagal', content: `format id card ${item[0]?.value} salah`})
                }
                product.push({
                    id_card_number: item[0]?.value?.trim(),
                    organization: item[1]?.value?.trim(),
                    reason: item[1]?.value?.trim(),
                });
            }
        }
        if (product?.length < 1) {
            return Modal.error({title: 'Proses Gagal', content: 'lengkapi data'})
        }

        await this.promisedSetState({loading: true})
        const input = {
            type: "blacklist",
            source: "blacklist_internal",
            data: product
        }

        try {
            await authApiService.blacklistInternal(input).then(() => {
                Modal.success({title: 'Proses Sukses', content: 'Blacklist berhasil ditambahkan'})
                setTimeout(function () {
                    window.location.reload()
                }, 2000)
            })
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            })
            this.setState({
                loading: false,
            })
        }
    }

    onSheetPhoneNumberChange = (newData: any) => {
        this.setState({dataSheetBlacklistPhoneNumber: newData});
    };

    onSheetIdCardChange = (newData: any) => {
        this.setState({dataSheetBlacklistIdCard: newData});
    };

    onSheetInternal = (newData: any) => {
        this.setState({dataSheetInternal: newData});
    };

    async componentDidMount() {
        fincoServices.getListLeasing().then(async value => {
            const listCodeFinco = []
            for (const x of value.data?.data) {
                listCodeFinco.push(x.code)
            }
            await this.setState({listFinco: value.data?.data, listCodeFinco: listCodeFinco})
        }).catch(async reason => {
            await this.setState({listFinco: []})
        })
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`Blacklist`} breadcrumbMenu={`Blacklist`} defaultSelectedKeys={["m-blacklist"]} defaultOpenKeys={["sm-blacklist", "sm-other"]}>
                    <div style={{padding: 20}}>
                        <Tabs
                            defaultActiveKey="1"
                            items={[
                                {
                                    label: 'Blacklist Phone Number',
                                    key: '1',
                                    children: (
                                        <div>
                                            <Button type={'primary'} icon={<SaveOutlined/>} onClick={this.onPhoneSubmit} loading={this.state.loading}>Update Data</Button>
                                            <div style={{marginTop: 30}}>
                                                <Spreadsheet data={this.state.dataSheetBlacklistPhoneNumber} columnLabels={this.columnLabelsPhone} onChange={this.onSheetPhoneNumberChange}/>
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    label: 'Blacklist ID Card',
                                    key: '2',
                                    children: (
                                        <Row>
                                            <Col xs={24} xl={12}>
                                                <Button type={'primary'} icon={<SaveOutlined/>} onClick={this.onIdCardSubmit} loading={this.state.loading}>Update Data</Button>
                                                <div style={{marginTop: 30}}>
                                                    *Jika Kode Leasing dikosongkan = Blacklist General<br/>*Gunakan comma separator untuk menentuka leasing mana saja yang di blacklist <br/>&nbsp;pada nomor ktp bersangkutan contoh: <b>ADMF, FIFG</b>
                                                </div>
                                                <div style={{marginTop: 30}}>
                                                    <Spreadsheet data={this.state.dataSheetBlacklistIdCard} columnLabels={this.columnLabelsIdCard} onChange={this.onSheetIdCardChange}/>
                                                </div>
                                            </Col>
                                            <Col xs={24} xl={12}>
                                                <Table
                                                    rowKey={'code'}
                                                    size={'small'}
                                                    columns={[
                                                        {title: 'Kode Finco', dataIndex: 'code', render: (value) => <span className="font-90">{value}</span>,},
                                                        {title: 'Nama Finco', dataIndex: 'name', render: (value) => <span className="font-90">{value}</span>,}
                                                    ]}
                                                    dataSource={this.state.listFinco}
                                                    pagination={{pageSize: 100}}
                                                />
                                            </Col>
                                        </Row>
                                    ),
                                },
                                {
                                    label: 'Blacklist Internal',
                                    key: '3',
                                    children: (
                                        <div>
                                            <Button type={'primary'} icon={<SaveOutlined/>} onClick={this.onInternalSubmit} loading={this.state.loading}>Update Data</Button>
                                            <div style={{marginTop: 30}}>
                                                <Spreadsheet data={this.state.dataSheetInternal} columnLabels={this.columnLabelsInternal} onChange={this.onSheetInternal}/>
                                            </div>
                                        </div>
                                    ),
                                }
                            ]}
                        />
                    </div>
                </AppLayout>
            </React.Fragment>
        );
    }
}

export default withAuth(Blacklist, 29);