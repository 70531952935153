import React, {Component} from 'react';
import {MasterTargetSchemaStates} from "./types/MasterTargetSchemaTypes";
import withAuth from "../../hoc/withAuth";
import Cookies from "universal-cookie";
import AppLayout from "../../component/layouts/AppLatout";
import {groupServices} from "../../services/amartaVip/GroupService";
import {Button, Form, Card, Col, Divider, Modal, Row, Input, Spin, Empty, Select} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import {allowedProduct} from "../../config/appDataConfig/organizationCondig";
import CurrencyInput from "react-currency-input-field";

class MasterTargetSchema extends Component<any, MasterTargetSchemaStates> {
    private readonly initState!: MasterTargetSchemaStates;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);

        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            fetching: true,
            loading: false,
            dataListSchema: [],
            modalAddUpdate: false,

            updateType: "",
            code: "",
            name: "",
            description: "",
            products: [],

            showModalAdd: false,
            productCode: null,
            weak: 0,
            month: 0,
            year: 0,
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        this.fetchMasterTargetSchema();
    }

    fetchMasterTargetSchema = async () => {
        await this.promisedSetState({fetching: true});
        try {
            await groupServices.setToken(this.state.token);
            const fetchResult = await groupServices.getTargetSchema({});
            this.setState({
                fetching: false,
                dataListSchema: fetchResult.data.data,
            })
        } catch (e) {
            this.setState({
                fetching: false,
                dataListSchema: [],
            })
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "code" | "name" | "description" | "productCode" | "weak" | "month" | "year">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "code":
                currentState.code = value?.toUpperCase();
                break;
            case "name":
                currentState.name = value?.toUpperCase();
                break;
            case "description":
                currentState.description = value;
                break;
            case "productCode":
                currentState.productCode = value;
                break;
            case "weak":
                currentState.weak = value;
                break;
            case "month":
                currentState.month = value;
                break;
            case "year":
                currentState.year = value;
                break;
        }
        this.setState({
            ...currentState,
        });
    }

    onUpdateSubmit = async () => {
        if (this.state.updateType === "INSERT") {
            try {
                await groupServices.setToken(this.state.token);
                await groupServices.addTargetSchema({
                    code: this.state.code,
                    name: this.state.name,
                    description: this.state.description,
                    target_products: this.state.products,
                    active: true
                }).then(successData => {
                    Modal.success({title: 'Proses Sukses', content: 'Proses Sukses'});
                    this.fetchMasterTargetSchema();
                    this.setState({modalAddUpdate: false})
                });
            } catch (e) {
                Modal.error({
                    title: 'Proses Gagal',
                    content: 'Error :' + e
                });
                this.setState({
                    loading: false,
                });
            }
        }
        if (this.state.updateType === "UPDATE") {
            try {
                await groupServices.setToken(this.state.token);
                await groupServices.updateTargetSchema({
                    code: this.state.code,
                    name: this.state.name,
                    description: this.state.description,
                    target_products: this.state.products,
                    active: true
                }).then(successData => {
                    Modal.success({title: 'Proses Sukses', content: 'Proses Sukses'});
                    this.fetchMasterTargetSchema();
                    this.setState({modalAddUpdate: false})
                });
            } catch (e) {
                Modal.error({
                    title: 'Proses Gagal',
                    content: 'Error :' + e
                });
                this.setState({
                    loading: false,
                });
            }
        }
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    onAddUpdate = async (data: any, type: string) => {
        let a = "";
        let b = "";
        let c = "";
        let d = [];

        if (type === "UPDATE") {
            let tempAgentCode = [];
            for (const item of (data.member_data ?? [])) {
                tempAgentCode.push(item.code);
            }
            a = data.code;
            b = data.name;
            c = data.description;
            d = data.target_products;
        }

        this.setState({
            updateType: type,
            modalAddUpdate: true,
            code: a,
            name: b,
            description: c,
            products: d
        });
    }

    confirmDelete = async (id: any) => {
        const latestData = [...this.state.products]
        latestData.splice(id, 1);
        this.setState({
            products: latestData
        })
    }

    confirmAdd = async () => {
        if (!this.state.productCode || !this.state.weak || !this.state.month || !this.state.year) {
            return Modal.error({title: 'Error', content: 'please complete data '});
        }

        const companyId = allowedProduct.find((org) => org.productCode === this.state.productCode)?.companyId || null;
        const latestData = [...this.state.products]
        latestData.push({
            code: this.state.productCode,
            week: this.state.weak,
            month: this.state.month,
            year: this.state.year,
            organization_code: this.state.productCode,
            organization_id: companyId
        })

        this.setState({
            showModalAdd: false,
            products: latestData
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`Setting`} breadcrumbMenu={`Master Target Schema`} defaultSelectedKeys={['m-target-schema']} defaultOpenKeys={['sm-setting']}>
                    <div className={`p-3`}>
                        <Button onClick={event => this.onAddUpdate({}, "INSERT")} className={`mt-10`} type={"primary"}>Add MasterTargetSchema</Button>
                        <Divider/>

                        <div style={this.state.fetching ? {} : {display: 'none'}} className={`spinCenter`}>
                            <Spin size="large"/>
                        </div>

                        <div style={((!this.state.fetching) && (this.state.dataListSchema.length < 1)) ? {} : {display: 'none'}}>
                            <Empty/>
                        </div>

                        <Row>
                            {this.state.dataListSchema?.map((item: any, x: number) =>
                                <Col key={x} className={`p-2`} xxl={{span: 6}} xl={{span: 6}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                    <Card hoverable={true} size="small" style={{backgroundColor: ""}}>
                                        <b>{item?.code?.toUpperCase()}</b>
                                        <span style={{float: "right"}}>
                                            <Button onClick={event => this.onAddUpdate(item, "UPDATE")} type="primary" size={"small"} icon={<EditOutlined/>}/>
                                        </span>

                                        <br/> <i className={`small-text-grey`}>{item?.name}</i>
                                        <Divider orientation="left" plain style={{marginTop: 5, marginBottom: 1}}>
                                            <i style={{fontSize: "80%"}}>Product</i>
                                        </Divider>
                                        {item?.target_products?.map((z: any, y: number) =>
                                            <div key={y}>
                                                {z?.code}
                                                <br/><i className={`small-text-grey`}>week: {z?.week}</i><br/>
                                                <i className={`small-text-grey`}>month: {z?.month}</i><br/>
                                                <i className={`small-text-grey`}>year: {z?.year}</i><br/>
                                                <Divider orientation="left" plain style={{marginTop: 5, marginBottom: 1}}/>
                                            </div>
                                        )}
                                        <Divider style={{marginTop: 5, marginBottom: 1}}/>
                                        <i className={`small-text-grey`}>{item?.description}</i>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </div>
                </AppLayout>

                <Modal
                    open={this.state.modalAddUpdate}
                    title={`Form Master Target Schema`}
                    onCancel={() => this.setState({modalAddUpdate: false})}
                    cancelText={'Close'} okText={false}
                    footer={[
                        <Button key={1} onClick={event => this.onUpdateSubmit()} loading={this.state.loading} type="primary" style={{marginRight: 7}}>Save</Button>,
                        <Button key={2} onClick={() => this.setState({modalAddUpdate: false})}>Close</Button>,
                    ]}
                >
                    <Divider style={{marginTop: 10, marginBottom: 10}}/>
                    <Form.Item>
                        <i className={`small-text-grey`}>Kode</i><br/>
                        <Input disabled={(this.state.updateType === "UPDATE")} value={this.state.code} placeholder={`Kode skema`} onChange={event => this.onFieldChange("code", event.target.value)}/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>Nama</i><br/>
                        <Input value={this.state.name} placeholder={`Nama Skema`} onChange={event => this.onFieldChange("name", event.target.value)}/>
                    </Form.Item>
                    <Form.Item className={'padTop-5'}>
                        <i className={`small-text-grey`}>Catatan</i>
                        <TextArea
                            showCount maxLength={1000}
                            value={this.state.description}
                            onChange={event => this.onFieldChange("description", event.target.value)}
                            placeholder="Catatan"/>
                    </Form.Item>

                    <Button size={"small"} style={{marginBottom: 15}} onClick={() => this.setState({showModalAdd: true, productCode: null, weak: 0, month: 0, year: 0})} type={"primary"}> Add Product </Button>
                    {this.state.products?.map((item: any, x: number) =>
                        <>
                            <Row key={x}>
                                <Col span={20}>
                                    {item?.code?.toString()}<br/>
                                    <i className={`small-text-grey`}>Week: {item?.week}</i><br/>
                                    <i className={`small-text-grey`}>Month: {item?.month}</i><br/>
                                    <i className={`small-text-grey`}>Year: {item?.year}</i><br/>
                                </Col>
                                <Col span={4}>
                                    <DeleteOutlined onClick={() => this.confirmDelete(x)} style={{cursor: "pointer", color: "red", fontSize: "120%"}}/>
                                </Col>
                            </Row>
                            <Divider/>
                        </>
                    )}

                    <Divider style={{marginTop: 40, marginBottom: 20}}/>
                </Modal>

                <Modal
                    open={this.state.showModalAdd}
                    title="Add Products"
                    onCancel={() => this.setState({showModalAdd: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button type={"primary"} key="1" onClick={this.confirmAdd}>Add</Button>,
                        <Button key="2" onClick={() => this.setState({showModalAdd: false})}>Close</Button>
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Product Code *</i>
                            <Select style={{minWidth: 200}} showSearch placeholder="Choose Organization" optionFilterProp="children" onChange={value => this.onFieldChange("productCode", value)} value={this.state.productCode}>
                                {(allowedProduct ?? []).map((item: any, i: number) =>
                                    <Select.Option key={i} value={item?.productCode}>{item?.productCode}</Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Weekly Target *</i>
                            <CurrencyInput className={'ant-input-css'} allowDecimals={false} value={this.state.weak} onValueChange={value => this.onFieldChange("weak", value ? parseFloat(value) : 0)}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Monthly Target *</i>
                            <CurrencyInput className={'ant-input-css'} allowDecimals={false} value={this.state.month} onValueChange={value => this.onFieldChange("month", value ? parseFloat(value) : 0)}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Yearly Target *</i>
                            <CurrencyInput className={'ant-input-css'} allowDecimals={false} value={this.state.year} onValueChange={value => this.onFieldChange("year", value ? parseFloat(value) : 0)}/>
                        </Form.Item>
                    </React.Fragment>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withAuth(MasterTargetSchema, 31);