import React, {Component} from "react";
import {Button, Col, DatePicker, Divider, Modal, Row, Select, Spin} from "antd";
import {activityApiService} from "../../services/amartaVip/ActivityApiService";
import Cookies from "universal-cookie";
import currencyMillionFormat from "../../helper/currencyFormat";
import dayjs from "dayjs";
import CurrencyInput from "react-currency-input-field";
import TextArea from "antd/es/input/TextArea";

class DetailApprovalAssessment extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            chosenAppCode: "",
            processingFetch: false,
            dataApplication: {},
            applyStatus: null,
            approvedBudget: 0,
            dataCv: {},
            assessmentNote: "",
            approvedDate: dayjs()
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            chosenAppCode: this.props.chosenAppCode,
        });
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.time !== prevProps.time) || (this.props.chosenAppCode !== prevProps.chosenAppCode)) {
            await this.promisedSetState({
                chosenAppCode: this.props.chosenAppCode,
            });
            await this.fetchData();
        }
    }

    fetchData = async () => {
        await this.promisedSetState({
            processingFetch: true,
            dataApplication: {},
        });

        try {
            await activityApiService.setToken(this.state.token);
            const fetchResult = await activityApiService.getActivityList({
                activityType: "applicationactivity",
                code: this.state.chosenAppCode,
            });

            await this.promisedSetState({
                processingFetch: false,
                applyStatus: null,
                approvedBudget: fetchResult?.data?.data[0]?.sum_budget_amount,
                approvedDate: dayjs(fetchResult?.data?.data[0]?.activity_date?.slice(0, 10)).format('YYYY-MM-DD'),
                dataApplication: fetchResult?.data?.data[0],
                assessmentNote: "",
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetch: false,
                applyStatus: null,
                approvedBudget: 0,
                dataApplication: {},
                assessmentNote: "",
            });
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "applyStatus" | "approvedDate" | "approvedBudget" | "assessmentNote">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "applyStatus":
                currentState.applyStatus = value;
                break;
            case "approvedDate":
                currentState.approvedDate = value;
                break;
            case "approvedBudget":
                currentState.approvedBudget = value;
                break;
            case "assessmentNote":
                currentState.assessmentNote = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    onCancel = async () => {
        this.props.onCloseModal({reload: false});
    }
    onSubmit = async () => {
        if (!this.state.applyStatus || !this.state.assessmentNote) {
            Modal.error({
                title: 'Error',
                content: 'please complete data '
            });
            return;
        }

        const assessmentResult = {
            source: "admin.amarta.vip",
            type: "APPLICATIONACTIVITY",
            update_type: "ASSESSMENT",
            code: this.props.chosenAppCode,
            status: this.state.applyStatus,
            assessment_date: this.state.approvedDate,
            assessment_budget: this.state.approvedBudget,
            assessment_note: this.state.assessmentNote,
        }

        this.setState({
            loading: true,
        });

        try {
            await activityApiService.setToken(this.state.token);
            await activityApiService.updateApplicationactivity(
                assessmentResult
            ).then(successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'successfully updated '
                });
                this.props.onCloseModal({reload: true});
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            await this.promisedSetState({
                loading: false,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.processingFetch ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={!this.state.processingFetch ? {} : {display: 'none'}}>
                    <Row>
                        <Col xs={24} xl={12} style={{padding: 20}}>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Application Code:</Col>
                                <Col xs={24} xl={14}>{this.state.dataApplication?.code}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Activity Name:</Col>
                                <Col xs={24} xl={14}>{this.state.dataApplication?.activity_name}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Activity Date:</Col>
                                <Col xs={24} xl={14}>{this.state.dataApplication?.activity_date?.slice(0, 10)}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Activity Duration:</Col>
                                <Col xs={24} xl={14}>{this.state.dataApplication?.data?.duration}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Activity Budget:</Col>
                                <Col xs={24} xl={14}>{currencyMillionFormat(this.state.dataApplication?.sum_budget_amount)}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Desktipsi:</Col>
                                <Col xs={24} xl={14}>{this.state.dataApplication?.data?.description}</Col>
                            </Row>
                        </Col>
                        <Col xs={24} xl={12} style={{padding: 20}}>
                            <Divider orientation="left">Assessment Result</Divider>

                            <div className={`gray-80 mt-20`}>Status Apply:</div>
                            <Select
                                placeholder="Apply Status"
                                showSearch
                                optionFilterProp="children"
                                value={this.state.applyStatus}
                                onChange={value => this.onFieldChange("applyStatus", value)}
                                style={{width: "100%"}}
                            >
                                <Select.Option value="REJECTED">Rejected</Select.Option>
                                <Select.Option value="APPROVED">Approved</Select.Option>
                            </Select>

                            <div className={`gray-80 mt-20`}>Approved Date:</div>
                            <DatePicker
                                name="approvedDate"
                                format={'YYYY-MM-DD'} style={{width: "100%"}}
                                value={dayjs(this.state.approvedDate, 'YYYY-MM-DD')}
                                onChange={(date, dateString) => this.onFieldChange("approvedDate", dateString)}
                            />

                            <div className={`gray-80 mt-20`}>Approved Total Budget:</div>
                            <CurrencyInput
                                className={'ant-input-css'}
                                allowDecimals={false}
                                groupSeparator={','}
                                decimalSeparator={'.'}
                                value={this.state.approvedBudget}
                                onValueChange={value => this.onFieldChange("approvedBudget", value ? parseFloat(value) : 0)}
                            />

                            <div className={`gray-80 mt-20`}>Assesment Note:</div>
                            <TextArea value={this.state.assessmentNote} onChange={event => this.onFieldChange("assessmentNote", event.target.value)} rows={2} placeholder="Description"/>
                        </Col>
                    </Row>
                    <div style={{paddingTop: 20, textAlign: "right"}}>
                        <Divider/>
                        <Button onClick={event => this.onCancel()} style={{marginRight: 7}}>Cancel</Button>
                        <Button onClick={event => this.onSubmit()} loading={this.state.loading} type="primary" style={{marginRight: 7}}>Update</Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default DetailApprovalAssessment;
