import React, {Component} from 'react';
import {SupervisorStates} from "./types/SupervisorTypes";
import withAuth from "../../hoc/withAuth";
import Cookies from "universal-cookie";
import AppLayout from "../../component/layouts/AppLatout";
import {groupServices} from "../../services/amartaVip/GroupService";
import {userServices} from "../../services/amartaVip/UserService";
import {Button, Form, Card, Col, Divider, Modal, Row, Input, Spin, Empty, FloatButton, Drawer, Avatar} from "antd";
import {DeleteOutlined, EditOutlined, FileImageOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

class Supervisor extends Component<any, SupervisorStates> {
    private readonly initState!: SupervisorStates;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);

        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            fetching: true,
            loading: false,
            dataListGroup: [],
            modalDelete: false,
            modalAddUpdate: false,
            supervisorAgentCode: "",
            supervisorShortCode: "",
            memberAgentCode: "",
            notes: "",
            updateType: "",
            supervisorCode: "",
            supervisorName: "",
            confirmDelete: "",
            showSelfieImage: false,
            fetchSelfieImage: false,
            listSelfieData: [],
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        this.fetchSupervisor();
    }

    fetchSupervisor = async () => {
        await this.promisedSetState({
            fetching: true,
        });
        try {
            await groupServices.setToken(this.state.token);
            const fetchResult = await groupServices.getGroup({type: "supervisor"});
            this.setState({
                fetching: false,
                dataListGroup: fetchResult.data.data,
            })
        } catch (e) {
            this.setState({
                fetching: false,
                dataListGroup: [],
            })
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "supervisorAgentCode" | "supervisorShortCode" | "memberAgentCode" | "notes" | "supervisorCode" | "confirmDelete">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "supervisorAgentCode":
                currentState.supervisorAgentCode = value?.toUpperCase();
                break;
            case "supervisorShortCode":
                currentState.supervisorShortCode = value?.toUpperCase();
                break;
            case "memberAgentCode":
                currentState.memberAgentCode = value?.toUpperCase();
                break;
            case "notes":
                currentState.notes = value;
                break;
            case "supervisorCode":
                currentState.supervisorCode = value?.toUpperCase();
                break;
            case "confirmDelete":
                currentState.confirmDelete = value.toUpperCase();
                break;
        }
        this.setState({
            ...currentState,
        });
    }

    onDeleteSubmit = async () => {
        const supervisorCode = this.state.supervisorCode;
        const confirmDelete = this.state.confirmDelete;

        if (confirmDelete !== "DELETE") {
            Modal.error({
                title: 'Proses Gagal',
                content: 'lengkapi konfirmasi DELETE'
            });
            return;
        }

        const dataDelete = {
            type: "remove_supervisor",
            supervisor_code: supervisorCode
        }

        try {
            await groupServices.setToken(this.state.token);
            await groupServices.updateGroup(
                dataDelete
            ).then(successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'Proses Sukses '
                });
                this.setState({
                    loading: false,
                    modalDelete: false,
                });
                this.fetchSupervisor();
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            this.setState({
                loading: false,
            });
        }
    }

    onUpdateSubmit = async () => {
        const supervisorAgentCode = this.state.supervisorAgentCode;
        const supervisorShortCode = this.state.supervisorShortCode;
        const memberAgentCode = this.state.memberAgentCode?.split(',');

        const member = [];
        for (const item of memberAgentCode) {
            if (item.trim() !== supervisorAgentCode) {
                member.push(item?.trim());
            }
        }

        if (!supervisorAgentCode || !supervisorShortCode || member.length < 1) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'lengkapi data'
            });
            return;
        }

        this.setState({
            loading: true,
        });

        const dataUpdateGroup = {
            type: "update_supervisor",
            supervisor_agent_code: supervisorAgentCode,
            supervisor_short_code: supervisorShortCode,
            member_agent_code: member,
            notes: this.state.notes,
            active: true
        }

        try {
            await groupServices.setToken(this.state.token);
            await groupServices.updateGroup(
                dataUpdateGroup
            ).then(successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'Proses Sukses '
                });
                this.setState({
                    loading: false,
                    modalAddUpdate: false,
                });
                this.fetchSupervisor();
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            this.setState({
                loading: false,
            });
        }
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    onDelete = async (data: any) => {
        this.setState({
            modalDelete: true,
            supervisorCode: data.supervisor_code,
            supervisorName: data.supervisor_name,
            confirmDelete: "",
        })
    }

    onAddUpdate = async (data: any, type: string) => {
        let a = "";
        let b = "";
        let c = "";
        let d = "";
        if (type === "UPDATE") {
            let tempAgentCode = [];
            for (const item of (data.member_data ?? [])) {
                tempAgentCode.push(item.agent_code);
            }
            a = data.lead_data.agent_code;
            b = tempAgentCode?.toString();
            c = data.notes;
            d = data.supervisor_short_code;
        }

        this.setState({
            updateType: type,
            modalAddUpdate: true,
            supervisorAgentCode: a,
            memberAgentCode: b,
            supervisorShortCode: d,
            notes: c,
        });
    }

    showSelfieImage = async () => {
        this.setState({
            showSelfieImage: true,
            fetchSelfieImage: true,
        });

        const listAgentCode: any = [];
        for (const item of this.state.dataListGroup ?? []) {
            if (!listAgentCode.includes(item?.lead_data?.agent_code)) {
                listAgentCode.push(item?.lead_data?.agent_code);
            }
            for (const mItem of item?.member_data) {
                if (!listAgentCode.includes(mItem.agent_code)) {
                    listAgentCode.push(mItem.agent_code);
                }
            }
        }

        try {
            const listAgentSelfie = await userServices.getMultiUserByAgentCOde({agentCodes: listAgentCode?.toString()});
            this.setState({
                fetchSelfieImage: false,
                listSelfieData: listAgentSelfie.data.data,
            });
        } catch (e: any) {
            this.setState({
                fetchSelfieImage: false,
                listSelfieData: [],
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`GroupVIP`} breadcrumbMenu={`Supervisor`} defaultSelectedKeys={['m-groupsupervisor']} defaultOpenKeys={['sm-groupvip']}>
                    <div className={`p-3`}>
                        <Button onClick={event => this.onAddUpdate({}, "INSERT")} className={`mt-10`} type={"primary"}>Add Supervisor</Button>
                        <Divider/>

                        <div style={this.state.fetching ? {} : {display: 'none'}} className={`spinCenter`}>
                            <Spin size="large"/>
                        </div>

                        <div style={((!this.state.fetching) && (this.state.dataListGroup.length < 1)) ? {} : {display: 'none'}}>
                            <Empty/>
                        </div>

                        <Row>
                            {this.state.dataListGroup?.map((item: any, x: number) =>
                                <Col key={x} className={`p-2`} xxl={{span: 6}} xl={{span: 6}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                    <Card hoverable={true} size="small" style={{backgroundColor: ""}}>
                                        <b>{item?.supervisor_name?.toUpperCase()}</b> - {item?.lead_data?.agent_code}
                                        <span style={{float: "right"}}>
                                            <Button onClick={event => this.onAddUpdate(item, "UPDATE")} type="primary" size={"small"} icon={<EditOutlined/>}/>
                                            &nbsp;
                                            <Button onClick={event => this.onDelete(item)} type="primary" danger size={"small"} icon={<DeleteOutlined/>}/>
                                        </span>

                                        <br/> <i className={`small-text-grey`}>{item?.supervisor_short_code}</i>
                                        <Divider orientation="left" plain style={{marginTop: 5, marginBottom: 1}}>
                                            <i style={{fontSize: "80%"}}>Member</i>
                                        </Divider>
                                        {item?.member_data?.map((z: any, y: number) =>
                                            <div key={y}>
                                                <i className={`small-text-grey`}>{z?.agent_code}</i> - <b className={`small-text-grey`}>{z?.name?.toUpperCase()}</b>
                                            </div>
                                        )}
                                        <Divider style={{marginTop: 5, marginBottom: 1}}/>
                                        <i className={`small-text-grey`}>{item?.notes}</i>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </div>
                </AppLayout>

                <Modal
                    open={this.state.modalAddUpdate}
                    title={`Form Supervisor`}
                    onCancel={() => this.setState({modalAddUpdate: false})}
                    cancelText={'Close'} okText={false}
                    footer={[
                        <Button key={1} onClick={event => this.onUpdateSubmit()} loading={this.state.loading} type="primary" style={{marginRight: 7}}>Save</Button>,
                        <Button key={2} onClick={() => this.setState({modalAddUpdate: false})}>Close</Button>,
                    ]}
                >
                    <Divider style={{marginTop: 10, marginBottom: 10}}/>
                    <Form.Item>
                        <i className={`small-text-grey`}>Kode Agen Supervisor</i><br/>
                        <Input disabled={(this.state.updateType === "UPDATE")} value={this.state.supervisorAgentCode} placeholder={`Kode agen supervisor`} onChange={event => this.onFieldChange("supervisorAgentCode", event.target.value)}/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>Kode Supervisor</i><br/>
                        <Input value={this.state.supervisorShortCode} placeholder={`Kode supervisor`} onChange={event => this.onFieldChange("supervisorShortCode", event.target.value)}/>
                    </Form.Item>
                    <Form.Item className={'padTop-5'}>
                        <i className={`small-text-grey`}>Kode Agen Member (comma separator)</i>
                        <TextArea
                            showCount maxLength={1000}
                            value={this.state.memberAgentCode.toString()}
                            onChange={event => this.onFieldChange("memberAgentCode", event.target.value)}
                            placeholder="Kode Agen Member (comma separator)"/>
                    </Form.Item>
                    <Form.Item className={'padTop-5'}>
                        <i className={`small-text-grey`}>Catatan</i>
                        <TextArea
                            showCount maxLength={1000}
                            value={this.state.notes}
                            onChange={event => this.onFieldChange("notes", event.target.value)}
                            placeholder="Catatan"/>
                    </Form.Item>
                    <Divider style={{marginTop: 40, marginBottom: 20}}/>
                </Modal>

                <Modal
                    open={this.state.modalDelete}
                    title={`Delete Supervisor`}
                    onCancel={() => this.setState({modalDelete: false})}
                    cancelText={'Close'} okText={false}
                    footer={[
                        <Button key={1} onClick={event => this.onDeleteSubmit()} loading={this.state.loading} type="primary" style={{marginRight: 7}}>Save</Button>,
                        <Button key={2} onClick={() => this.setState({modalDelete: false})}>Close</Button>
                    ]}
                >
                    <Divider style={{marginTop: 5, marginBottom: 10}}/>
                    Apakah anda yakin akan menghapus Group SPV {this.state.supervisorName} ? <br/>
                    <i className={`small-text-grey`}>*Menghapus supervisor yang ditentukan tidak dapat dibatalkan.</i> <br/><br/>
                    Untuk mengonfirmasi penghapusan, ketik <b>DELETE</b> pada inputan berikut. <br/><br/>
                    <Input value={this.state.confirmDelete} placeholder={`ketik delete untuk konfirmasi`} onChange={event => this.onFieldChange("confirmDelete", event.target.value)}/>
                    <br/><br/>
                </Modal>

                <Drawer title="List User Image" placement="right" onClose={() => this.setState({showSelfieImage: false})} open={this.state.showSelfieImage}>
                    <div style={this.state.fetchSelfieImage ? {} : {display: 'none'}} className={`spinCenter`}>
                        <Spin size="large"/>
                    </div>

                    <div style={((!this.state.fetchSelfieImage) && (this.state.listSelfieData.length < 1)) ? {} : {display: 'none'}}>
                        <Empty/>
                    </div>

                    {this.state.listSelfieData?.map((z: any, y: number) =>
                        <div key={y}>
                            <a target={"_blank"} rel="noreferrer" href={(z?.selfie_image) ? z?.selfie_image : `/assets/img/favicon.png`}><Avatar src={(z?.selfie_image) ? z?.selfie_image : `/assets/img/favicon.png`}/></a>  &nbsp;
                            <i className={`small-text-grey`}>{z?.agent_code}</i> - <b className={`small-text-grey`}>{z?.name?.toUpperCase()}</b>
                            <Divider style={{marginTop: 5, marginBottom: 5}}/>
                        </div>
                    )}
                </Drawer>

                <FloatButton onClick={event => this.showSelfieImage()} icon={<FileImageOutlined/>} type="primary" style={{right: 20}}/>
            </React.Fragment>
        );
    }
}

export default withAuth(Supervisor, 24);