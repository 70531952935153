import React, {Component} from 'react'
import {Buffer} from "buffer"
import AppLayout from "../../component/layouts/AppLatout"
import Cookies from 'universal-cookie'
import withAuth from "../../hoc/withAuth"
import {ResumeLeadStates} from "./types/ResumeLeadTypes"
import {userServices} from "../../services/amartaVip/UserService"
import exportExcel from "../../helper/exportExcel"
import {Select, Empty, Image, Spin, Table, Form, DatePicker, Button, Modal, Row, Col, Checkbox, Dropdown, Divider, Card} from "antd"
import {DownOutlined, FileExcelOutlined} from "@ant-design/icons";
import {allowedOrganization} from "../../config/appDataConfig/organizationCondig"
import dayjs from "dayjs"
import {groupServices} from "../../services/amartaVip/GroupService"
import LeadDetail from "../../component/detailData/LeadDetail";
import {scrapeService} from "../../services/trimitraScrape/ScrapeService";

export interface UpdateUserProps {
}

class ResumeLead extends Component<UpdateUserProps & any, ResumeLeadStates> {
    private cookies = new Cookies()

    constructor(props: UpdateUserProps & any) {
        super(props)
        this.state = {
            user: JSON.parse(Buffer.from(this.cookies.get('_vip-u'), "base64").toString()),
            token: this.cookies.get('_vip-t') ?? "",
            processingFetchLeadData: false,
            dataListSpv: [],
            spvCodeChosen: null,
            fetchingSpv: false,
            organizationCode: allowedOrganization[0].companyCode,
            dataCounterLead: [],
            finalCounterLead: [],
            hideZeroActiveLead: true,
            startDate: dayjs().startOf('month'),
            modalShowDetailLead: false,
            choseAgentCode: "",
            scrapeData: {},
            chosenScrapeData: [],
            modalDetailScrapeData: false
        }
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    exportData = async (type = "excel") => {
        const listData = this.state.dataCounterLead
        const sortedData = listData.sort((a: any, b: any) => b.total_data - a.total_data);

        const xlsData = []
        xlsData.push(['Agent Code', 'Agent Name', 'Months Join', 'Sosmed Monitoring', 'Total Active Leads', 'New Leads', 'Credit B2B', 'Close Tunai', 'Close Credit', 'Total Data'])
        for (const item of sortedData) {
            const tanggalJoin = new Date(item?.join_time);
            const tanggalSekarang = new Date();
            const tahunJoin = tanggalJoin.getFullYear();
            const bulanJoin = tanggalJoin.getMonth();
            const tahunSekarang = tanggalSekarang.getFullYear();
            const bulanSekarang = tanggalSekarang.getMonth();
            const totalBulan = (tahunSekarang - tahunJoin) * 12 + (bulanSekarang - bulanJoin);

            xlsData.push([
                item?.agent_code,
                item?.agent_name,
                totalBulan,
                item?.social_media_monitored,
                item?.active_lead,
                item?.new_lead,
                item?.credit_b2b,
                item?.order_cash,
                item?.order_credit,
                item?.total_data
            ])
        }

        const dataExportExcel = {
            title: `Lead Resume ${this.state.organizationCode} ${dayjs().format("YYYY-MM-DD")}`,
            generatedTime: 'GenerateTime: ' + dayjs().format('YYYY-MM-DD HH:mm:ss'),
            data: xlsData,
            sheetName: "Sheet1",
            fileName: `Lead Resume - ${this.state.organizationCode}`
        }
        await exportExcel(dataExportExcel)
    }

    fetchCounter = async () => {
        if (!this.state.spvCodeChosen) {
            return Modal.error({title: 'Error', content: 'Pilih SPV'});
        }
        const picked = this.state.dataListSpv.find((o: { supervisor_short_code: any; }) => o.supervisor_short_code === this.state.spvCodeChosen)

        const listMember = picked.member_data.map((item: { agent_code: any }) => item.agent_code)
        let jsonMember = JSON.stringify(picked.member_data)
        let base64Encoded = btoa(jsonMember)

        await this.promisedSetState({processingFetchLeadData: true, dataCounterLead: [], finalCounterLead: []})
        await userServices.setToken(this.state.token)
        const startDate = dayjs(this.state.startDate).format("YYYY-MM-DD")
        const lastDayOfMonth = dayjs(startDate).endOf('month').format('YYYY-MM-DD')

        try {
            const [scrapeResult, coreDataResult] = await Promise.all([
                scrapeService.getUserLatestData({
                    organizationCode: "AMARTAVIP",
                    agentsCode: listMember
                }),
                userServices.getResumeLead({
                    source: this.state.organizationCode.toLocaleLowerCase(),
                    startDate: startDate,
                    endDate: lastDayOfMonth,
                    agentCodes: base64Encoded,
                    spvCode: picked.supervisor_code
                })
            ]);
            await this.promisedSetState({
                scrapeData: scrapeResult,
                dataCounterLead: coreDataResult?.data?.data,
                processingFetchLeadData: false
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetchLeadData: false,
                dataCounterLead: [],
                finalCounterLead: []
            });
        }

        // FINAL
        await this.manipulateDataCounter()
    }

    async componentDidMount() {
        await this.fetchSupervisor()
    }

    fetchSupervisor = async () => {
        await this.promisedSetState({fetchingSpv: true})
        try {
            await groupServices.setToken(this.state.token);
            const fetchResult = await groupServices.getGroup({type: "supervisor"});
            this.setState({
                fetchingSpv: false,
                dataListSpv: fetchResult.data.data
            })
        } catch (e) {
            this.setState({
                fetchingSpv: false,
                dataListSpv: []
            })
        }
    }

    manipulateDataCounter = async () => {
        await this.promisedSetState({processingFetchLeadData: true});
        const finalCounterLead = [];
        for (const items of this.state.dataCounterLead) {
            const totalData = items.active_lead + items.new_lead + items.credit_b2b + items.order_cash + items.order_credit
            items.total_data = totalData
            if (this.state.hideZeroActiveLead) {
                if (totalData > 0) {
                    finalCounterLead.push(items);
                }
            } else {
                finalCounterLead.push(items);
            }
        }
        await this.promisedSetState({
            finalCounterLead: finalCounterLead,
            processingFetchLeadData: false,
        })
    }

    onFieldChange = async <T extends keyof Pick<any, "hideZeroActiveLead" | "organizationCode" | "spvCodeChosen" | "startDate">>(target: T, value: any) => {
        const currentState: any = {...this.state}
        switch (target) {
            case "hideZeroActiveLead":
                currentState.hideZeroActiveLead = value
                break
            case "organizationCode":
                currentState.organizationCode = value
                break
            case "spvCodeChosen":
                currentState.spvCodeChosen = value
                break
            case "startDate":
                currentState.startDate = value
                break
        }
        await this.promisedSetState({...currentState})
        if (target === "hideZeroActiveLead") {
            await this.manipulateDataCounter()
        }
    }

    showAgentDetail = async (data: any) => {
        await this.promisedSetState({
            modalShowDetailLead: true,
            choseAgentCode: data.agent_code
        })
    }

    showScrapeDetail = async (dataScrapeAccount: any) => {
        await this.promisedSetState({
            modalDetailScrapeData: true,
            chosenScrapeData: dataScrapeAccount
        })
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve))

    render() {
        const items = [{key: '1', label: (<span onClick={() => this.exportData("excel")}><FileExcelOutlined/> Export Excel</span>)}]
        return (
            <>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`Leads`} breadcrumbMenu={`Lead Resume`} defaultSelectedKeys={['m-leadresumevip']} defaultOpenKeys={['sm-lead']}>
                    <div className={`p-5`}>
                        <Form layout={`inline`} style={{marginTop: -3}}>
                            <Form.Item>
                                <i className={`small-text-grey`}>Organization:</i><br/>
                                <Select
                                    style={{minWidth: "200px"}}
                                    showSearch
                                    placeholder="Choose organizationCode"
                                    optionFilterProp="children"
                                    onChange={value => this.onFieldChange("organizationCode", value)}
                                    value={this.state.organizationCode}
                                >
                                    {(allowedOrganization ?? []).map((item: any, i: number) => <Select.Option key={i} value={item?.companyCode}>{item?.companyCode}</Select.Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>SPV:</i><br/>
                                <Select
                                    loading={this.state.fetchingSpv}
                                    style={{minWidth: "200px"}}
                                    showSearch
                                    placeholder="Pilih SPV"
                                    optionFilterProp="children"
                                    onChange={value => this.onFieldChange("spvCodeChosen", value)}
                                    value={this.state.spvCodeChosen}
                                >
                                    {(this.state.dataListSpv ?? []).map((item: any, i: number) => <Select.Option key={i} value={item?.supervisor_short_code}>{item?.supervisor_name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item className={'padTop-10'}>
                                <i className={`small-text-grey`}>Periode:</i><br/>
                                <DatePicker style={{minWidth: "200px"}} picker="month" defaultValue={dayjs(this.state.startDate, 'YYYY-MM')} onChange={event => this.onFieldChange("startDate", event)} format={'YYYY-MM'}/>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>&nbsp;</i><br/>
                                <Button loading={this.state.processingFetchLeadData} onClick={this.fetchCounter} type={"primary"}> Tampilkan Data </Button>
                            </Form.Item>
                        </Form>

                        <Row>
                            <Col xs={24} xl={24}>
                                <div style={{float: "left", paddingTop: 10}}>
                                    <Checkbox checked={this.state.hideZeroActiveLead} onChange={e => this.onFieldChange("hideZeroActiveLead", e.target.checked)}>Hide Agent dengan 0 Total Data</Checkbox>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div>
                        <div style={this.state.processingFetchLeadData ? {} : {display: 'none'}} className={`spinCenter`}>
                            <Spin size="large"/>
                        </div>
                        <div className={`p-5`} style={((this.state.dataCounterLead.length < 1) && !this.state.processingFetchLeadData) ? {} : {display: 'none'}}>
                            <Empty/>
                        </div>

                        <div style={((this.state.dataCounterLead.length > 0) && !this.state.processingFetchLeadData) ? {} : {display: 'none'}}>
                            <div style={{float: 'right', marginBottom: 20}}>
                                <Dropdown.Button
                                    type="primary"
                                    onClick={event => this.exportData("excel")}
                                    icon={<DownOutlined/>}
                                    trigger={['click']}
                                    menu={{items}}
                                ><FileExcelOutlined/></Dropdown.Button>
                            </div>
                            <Table
                                size={"small"}
                                scroll={{x: 'calc(500px + 50%)'}}
                                dataSource={this.state.finalCounterLead}
                                pagination={{pageSize: 1000}}
                                rowKey={'agent_code'}
                                columns={[
                                    {
                                        title: 'Agent Code',
                                        dataIndex: 'agent_code',
                                        width: "140px",
                                        sorter: (a, b) => a.agent_code.localeCompare(b.agent_code),
                                        render: ((value: string, row: any) => {
                                            return <React.Fragment>
                                                <span onClick={event => this.showAgentDetail(row)} style={{color: "#2f57da", fontWeight: 500, fontSize: "85%", cursor: "pointer"}} className={`font-90`}>{value}</span>
                                            </React.Fragment>
                                        })
                                    }, {
                                        title: 'Agent Name',
                                        dataIndex: 'agent_name',
                                        sorter: (a, b) => a.agent_name.localeCompare(b.agent_name),
                                        render: (value: string) => <span className={`font-90`} style={{fontSize: "80%"}}>{value.toUpperCase()}</span>,
                                    }, {
                                        title: 'Months Join',
                                        dataIndex: 'join_time',
                                        sorter: (a, b) => a.join_time.localeCompare(b.join_time),
                                        render: (value: string) => {
                                            const tanggalJoin = new Date(value);
                                            const tanggalSekarang = new Date();
                                            const tahunJoin = tanggalJoin.getFullYear();
                                            const bulanJoin = tanggalJoin.getMonth();
                                            const tahunSekarang = tanggalSekarang.getFullYear();
                                            const bulanSekarang = tanggalSekarang.getMonth();
                                            const totalBulan = (tahunSekarang - tahunJoin) * 12 + (bulanSekarang - bulanJoin);

                                            return <span className={`gray-80`} style={{fontSize: "80%"}}>{totalBulan} months</span>;
                                        },
                                    }, {
                                        title: 'Sosmed Monitoring',
                                        dataIndex: 'social_media_monitored',
                                        sorter: (a, b) => a.social_media_monitored - b.social_media_monitored,
                                        render: (value: number) => <span className={`gray-80`}>{value} Accounts</span>,
                                    }, {
                                        title: 'Sosmed Exist',
                                        dataIndex: 'agent_code',
                                        render: ((value: string, row: any) => {
                                            const dataScrapeAccount = this.state.scrapeData?.[value]?.scrape_data || []
                                            const countExistAccount = this.state.scrapeData?.[value]?.count_exist_account || 0
                                            return <React.Fragment>
                                                <span onClick={event => this.showScrapeDetail(dataScrapeAccount)} style={{color: "#2f57da", fontWeight: 500, fontSize: "85%", cursor: "pointer"}} className={`font-90`}>{countExistAccount} Accounts</span>
                                            </React.Fragment>
                                        })
                                    }, {
                                        title: 'Total Active Leads',
                                        dataIndex: 'active_lead',
                                        sorter: (a, b) => a.active_lead - b.active_lead,
                                        render: (value: number) => <span className={`font-80`}>{value}</span>,
                                    }, {
                                        title: 'New Leads',
                                        dataIndex: 'new_lead',
                                        sorter: (a, b) => a.new_lead - b.new_lead,
                                        render: (value: number) => <span className={`font-80`}>{value}</span>,
                                    }, {
                                        title: 'Credit B2B',
                                        dataIndex: 'credit_b2b',
                                        sorter: (a, b) => a.credit_b2b - b.credit_b2b,
                                        render: (value: number) => <span className={`font-80`}>{value}</span>,
                                    }, {
                                        title: 'Close Tunai',
                                        dataIndex: 'order_cash',
                                        sorter: (a, b) => a.order_cash - b.order_cash,
                                        render: (value: number) => <span className={`font-80`}>{value}</span>,
                                    }, {
                                        title: 'Close Credit',
                                        dataIndex: 'order_credit',
                                        sorter: (a, b) => a.order_credit - b.order_credit,
                                        render: (value: number) => <span className={`font-80`}>{value}</span>,
                                    }, {
                                        title: 'Total Data',
                                        dataIndex: 'total_data',
                                        sorter: (a, b) => a.total_data - b.total_data,
                                        defaultSortOrder: 'descend',
                                        render: (value: number) => <span className={`font-80`}>{value}</span>,
                                    }
                                ]}
                            />
                        </div>
                    </div>

                    <Modal
                        open={this.state.modalShowDetailLead}
                        title="Detail Lead"
                        width={"80%"}
                        style={{top: 10}}
                        onCancel={() => this.setState({modalShowDetailLead: false})}
                        cancelText={'Close'} okText={false}
                        footer={[<Button key="1" onClick={() => this.setState({modalShowDetailLead: false})}>Close</Button>,]}
                    >
                        <>
                            <Divider/>
                            <LeadDetail organization_code={this.state.organizationCode.toLowerCase()} agent_code={this.state.choseAgentCode}/>
                        </>
                    </Modal>

                    <Modal
                        open={this.state.modalDetailScrapeData}
                        title="Detail Scrape"
                        style={{top: 10}}
                        onCancel={() => this.setState({modalDetailScrapeData: false})}
                        cancelText={'Close'} okText={false}
                        footer={[<Button key="1" onClick={() => this.setState({modalDetailScrapeData: false})}>Close</Button>,]}
                    >
                        <>
                            <Divider/>
                            {this.state.chosenScrapeData?.map((item: any, x: number) =>
                                <div className={`p-5`} key={x}>
                                    <Card hoverable={true} bordered={true} bodyStyle={{padding: 7}}>
                                        <Row>
                                            <Col xl={{span: 3}} lg={{span: 2}} md={{span: 4}} sm={{span: 4}} xs={{span: 4}}>
                                                <Image style={{width: 30, paddingTop: 5}} alt="youtube" src={`../assets/img/${item?.media?.toLowerCase()}.png`}/>
                                            </Col>
                                            <Col xl={{span: 20}} lg={{span: 20}} md={{span: 17}} sm={{span: 17}} xs={{span: 17}} style={{fontSize: "85%"}}>
                                                <i className={`small-text-grey`}>ID Account:</i> {item?.user_account}<br/>
                                                <div style={((item.media === "TIKTOK")) ? {} : {display: 'none'}}>
                                                    https://www.tiktok.com/@{item?.user_account}
                                                </div>
                                                <div style={((item.media === "INSTAGRAM")) ? {} : {display: 'none'}}>
                                                    https://www.instagram.com/{item?.user_account}
                                                </div>
                                                <div style={((item.media === "YOUTUBE")) ? {} : {display: 'none'}}>
                                                    https://www.youtube.com/@{item?.user_account}
                                                </div>
                                                <i className={`small-text-grey`}>Exist Account:</i> {(item?.exist_account) ? "True" : "False"}<br/>
                                                <i className={`small-text-grey`}>Latest Scrape Time:</i> {item?.latest_scrape_time?.slice(0, 16)?.replace("T", " ")}<br/>
                                                <div style={((item.media === "TIKTOK")) ? {} : {display: 'none'}}>
                                                    <i className={`small-text-grey`}>Account Name:</i> {item?.latest_scrape_data?.account_name}<br/>
                                                    <i className={`small-text-grey`}>Count Followers:</i> {item?.latest_scrape_data?.count_followers}<br/>
                                                    <i className={`small-text-grey`}>Count Following:</i> {item?.latest_scrape_data?.count_following}<br/>
                                                    <i className={`small-text-grey`}>Count Likes:</i> {item?.latest_scrape_data?.count_likes}<br/>
                                                    <i className={`small-text-grey`}>Count Videos:</i> {item?.latest_scrape_data?.count_videos}
                                                </div>
                                                <div style={((item.media === "INSTAGRAM")) ? {} : {display: 'none'}}>
                                                    <i className={`small-text-grey`}>Account Name:</i> {item?.latest_scrape_data?.account_name}<br/>
                                                    <i className={`small-text-grey`}>Count Followers:</i> {item?.latest_scrape_data?.count_followers}<br/>
                                                    <i className={`small-text-grey`}>Count Following:</i> {item?.latest_scrape_data?.count_following}<br/>
                                                    <i className={`small-text-grey`}>Count Posts:</i> {item?.latest_scrape_data?.count_posts}
                                                </div>
                                                <div style={((item.media === "YOUTUBE")) ? {} : {display: 'none'}}>
                                                    <i className={`small-text-grey`}>Account Name:</i> {item?.latest_scrape_data?.account_name}<br/>
                                                    <i className={`small-text-grey`}>Count Subscribers:</i> {item?.latest_scrape_data?.count_subscribers}<br/>
                                                    <i className={`small-text-grey`}>Count Videos:</i> {item?.latest_scrape_data?.count_videos}
                                                </div>
                                            </Col>

                                            <div style={(item.exist_account) ? {float: "right"} : {display: 'none'}}>
                                                <a target={"_blank"} rel="noreferrer" href={`/user-track-sosmed?media=${item?.media}&account=${item?.user_account}`}>
                                                    <Button style={{marginTop: 10}} size={"small"} type={"primary"}>Lihat History</Button>
                                                </a>
                                            </div>
                                        </Row>
                                    </Card>
                                </div>
                            )}
                            <div className={`p-5`} style={((this.state.chosenScrapeData.length < 1)) ? {} : {display: 'none'}}>
                                <Empty/>
                            </div>
                        </>
                    </Modal>
                </AppLayout>
            </>
        )
    }
}

export default withAuth(ResumeLead, 27)