import axios from "axios"
import {baseUrlScrape, xApiKeyScrape} from "../../config/apiConfig/apiConfig"

class ScrapeService {
    private axios = axios.create({
        baseURL: baseUrlScrape,
    })

    public setToken() {
        this.axios.defaults.headers.common["Authorization"] = "Basic bGVhZHNDbGllbnRBcHA6cER5PEEyVC5zKGYzYDZIWg=="
    }

    public async getUserStatistics(params: { organizationCode: string, media: string, userAccount: string, startDate: string, endDate: string }) {
        const queries = {
            ...(params?.organizationCode && {
                organization_code: params.organizationCode,
            }),
            ...(params?.media && {
                media: params.media,
            }),
            ...(params?.userAccount && {
                user_account: params.userAccount,
            }),
            ...(params?.startDate && {
                start_date: params.startDate,
            }),
            ...(params?.endDate && {
                end_date: params.endDate,
            })
        }

        try {
            return await this.axios.get<any>('statistics/account', {
                headers: {
                    "X-Api-Key": xApiKeyScrape,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getUserLatestData(params: { organizationCode: string, agentsCode: string }) {
        let finalData: any = {}
        let externalId = ""
        for (const id of params.agentsCode) {
            externalId += `&external_id=${id}`;
            finalData[id] = {
                count_exist_account: 0,
                scrape_data: []
            }
        }
        try {
            const data = await this.axios.get<any>(`/statistics/latest-data?organization_code=${params.organizationCode}${externalId}`, {headers: {"X-Api-Key": xApiKeyScrape}})
            for (const item of data?.data?.data) {
                if (item.exist_account) {
                    finalData[item.external_id].count_exist_account += 1
                }
                finalData[item.external_id].scrape_data.push({
                    exist_account: item.exist_account,
                    media: item.media,
                    user_account: item.user_account,
                    latest_scrape_time: item.latest_scrape_time,
                    latest_scrape_data: item.latest_scrape_data
                })
            }

            return finalData
        } catch (e: any) {
            return {}
        }
    }
}

export const scrapeService = new ScrapeService()
