import axios from "axios";
import {baseUrlIdealTrimitra, xApiKeyAmartaVip} from "../../config/apiConfig/apiConfig";

class LeadService {
    private axios = axios.create({
        baseURL: baseUrlIdealTrimitra,
    });

    public setToken() {
        this.axios.defaults.headers.common["Authorization"] = "Basic bGVhZHNDbGllbnRBcHA6cER5PEEyVC5zKGYzYDZIWg==";
    }

    public async getLeads(params: { organizationCode: string; agentCode: string }) {
        const queries = {};
        try {
            return await this.axios.get<any>(`/leads/get-lead/${params.organizationCode}/${params.agentCode}`, {
                headers: {"X-Api-Key": xApiKeyAmartaVip},
                params: {...queries},
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getCounterLeads(params: { organizationCode: string }) {
        const queries = {};
        try {
            return await this.axios.get<any>(`/leads/get-lead/${params.organizationCode}`, {
                headers: {"X-Api-Key": xApiKeyAmartaVip},
                params: {...queries},
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getGlobalNotes(params: { organizationCode: string; date: string }) {
        const queries = {};
        try {
            return await this.axios.get<any>(`/leads/get-notes/${params.organizationCode}?date=${params.date}`, {
                headers: {"X-Api-Key": xApiKeyAmartaVip},
                params: {...queries},
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getGlobalNotesByPhoneNumber(params: { organizationCode: string; phoneNumber: string }) {
        const queries = {};
        try {
            return await this.axios.get<any>(`/leads/get-notes/${params.organizationCode}/${params.phoneNumber?.trim()}`, {
                headers: {"X-Api-Key": xApiKeyAmartaVip},
                params: {...queries},
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async updateLeadLevel(inputData?: any) {
        try {
            return await this.axios.post<any>("/leads/update-level", inputData, {
                headers: {
                    "Content-Type": "application/json",
                    "X-Api-Key": "",
                },
            });
        } catch (e: any) {
            throw new Error(e.response.data?.error);
        }
    }

    public async changeAgent(inputData?: any) {
        try {
            return await this.axios.post<any>("/leads/change-leads-agent", inputData, {
                headers: {
                    "Content-Type": "application/json",
                    "X-Api-Key": "",
                },
            });
        } catch (e: any) {
            throw new Error(e.response.data?.error);
        }
    }

    public async updateLeadFollowUp(inputData?: any) {
        try {
            return await this.axios.post<any>("/leads/update-follow-up-status", inputData, {
                headers: {
                    "Content-Type": "application/json",
                    "X-Api-Key": "",
                },
            });
        } catch (e: any) {
            throw new Error(e.response.data?.error);
        }
    }

    public async moveToCold(inputData?: any) {
        try {
            return await this.axios.post<any>("/leads/move-to-cold", inputData, {
                headers: {
                    "Content-Type": "application/json",
                    "X-Api-Key": "",
                },
            });
        } catch (e: any) {
            throw new Error(e.response.data?.error);
        }
    }

    public async moveToFree(inputData?: any) {
        try {
            return await this.axios.post<any>("/leads/move-to-free-leads", inputData, {
                headers: {
                    "Content-Type": "application/json",
                    "X-Api-Key": "",
                },
            });
        } catch (e: any) {
            throw new Error(e.response.data?.error);
        }
    }

    public async addFreeLead(post_data: any) {
        try {
            return await this.axios.post<any>("/leads/add-free-lead", post_data);
        } catch (e: any) {
            if (e.response?.status === 422) {
                const dataError = e.response?.data.error?.messages;

                if (Object.keys(dataError)?.length > 0) {
                    let errorText = "";
                    for (const key in dataError) {
                        console.log(key, dataError[key]);
                        const el = dataError[key];
                        errorText += el.msg + "\n";
                    }

                    throw new Error("Ideal Err: " + errorText);
                } else {
                    throw new Error("Ideal Err: " + e.response?.data.error?.messages);
                }
            } else if (e.response?.status === 500) {
                throw new Error("Ideal Err: " + e.response?.data.error?.messages);
            } else {
                throw new Error("Ideal Err: " + e.response?.data.error?.messages);
            }
        }
    }

    public async getWaConversation(params: { organizationCode: string, phoneNumber: string }) {
        const queries = {};
        try {
            return await this.axios.get<any>(`/leads/get-agent-conversation-with-leads?phoneNumber=${params.phoneNumber}&organization=${params.organizationCode}`, {
                params: {...queries},
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getPercentageContactBecomeLead(params: { agentCode: string }) {
        const queries = {};
        try {
            return await this.axios.get<any>(`/leads/get-percentage-contact-become-leads/${params.agentCode}`, {
                headers: {"X-Api-Key": xApiKeyAmartaVip},
                params: {...queries},
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }
}

export const leadService = new LeadService();
