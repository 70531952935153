import axios from "axios";
import {baseUrlAmartaVip, xApiKeyAmartaVip} from "../../config/apiConfig/apiConfig";

class TrackAgentService {
    private axios = axios.create({
        baseURL: baseUrlAmartaVip,
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    public async createMasterTrackAgent(inputData?: any) {
        try {
            return await this.axios.post<any>('/config/track-agent',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getMasterTrackAgent(params: any) {
        const queries = {
            ...(params?.companyCode && {
                company_code: params.companyCode,
            }),
        }

        try {
            return await this.axios.get<any>(`/config/track-agent`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }
}

export const trackAgentService = new TrackAgentService();