import React, {Component} from "react";
import {Table} from "antd";

class LeadNotes extends Component<any, any> {
    private readonly initState!: any;
    private tableColumn: any = [
        {
            title: 'Agent',
            dataIndex: 'agentCode',
            render: ((value: string, row: { agentName: string | null }) => {
                return <React.Fragment>
                    <span style={{color: "#da2f2f"}} className={`font-90`}>{value}</span><br/>
                    <span className={`gray-80`}>{row.agentName}</span>
                </React.Fragment>
            })
        },
        {
            title: 'Lead',
            dataIndex: 'phoneNumber',
            render: ((value: number, row: any) => {
                return <React.Fragment>
                    <span style={{color: "#da2f2f"}} className={`font-90`}>{value}</span><br/>
                    <span className={`gray-80`}>{row.firstName} {row.lastName}</span>
                </React.Fragment>
            })
        },
        {
            title: 'Event & Level',
            dataIndex: 'event',
            render: ((value: number, row: any) => {
                return <React.Fragment>
                    <span className={`gray-80`}>{value}</span><br/>
                    <span className={`gray-80`}>Level: {row.statusLevel}</span>
                </React.Fragment>
            })
        },
        {
            title: 'Counter Notes & Reactive',
            dataIndex: 'agentCode',
            render: ((value: number, row: any) => {
                return <React.Fragment>
                    <span className={`gray-80`}>Reactive: {row?.reactivate?.currentTotal ?? 0}</span><br/>
                    <span className={`gray-80`}>UpdateNote: {row?.totalUpdateNotes ?? 0}</span>
                </React.Fragment>
            })
        },
        {
            title: 'Date & Notes',
            dataIndex: 'updatedAt',
            render: ((value: number, row: any) => {
                return <React.Fragment>
                    <span className={`gray-80`}>{value}</span><br/>
                    <span className={`gray-80`}>{row.notes}</span>
                </React.Fragment>
            })
        }
    ];

    constructor(props: any) {
        super(props);
        this.initState = {
            loading: false,
        }
        this.state = {...this.initState}
    }


    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {

        console.log(this.props.data);

        return (
            <React.Fragment>
                <div className={`p-5`}>
                    <Table
                        rowKey={'code'}
                        size={'small'}
                        scroll={{x: 'calc(500px + 50%)'}}
                        columns={this.tableColumn}
                        dataSource={this.props.data}
                        pagination={{pageSize: 1000}}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default LeadNotes;
