import React, {Component} from "react";
import {Form, Button, Divider, Input, Select, Modal} from "antd";
import CurrencyInput from "react-currency-input-field";
import {trackAgentService} from "../../services/amartaVip/TrackAgentService";
import TextArea from "antd/es/input/TextArea";

class UpdateMasterTrack extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {
            organization: "",
            telegramId: "",
            minimumTarget: 0,
            fineAmount: 0,
            listTarget: [],
            loading: false,
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            organization: this.props.data.organization,
            loading: false,
            minimumTarget: this.props.data.minimum_target,
            fineAmount: this.props.data.fine,
            listTarget: this.props.data.list_agent,
            telegramId: this.props.data.telegram_id,
        });
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.time !== prevProps.time) || (this.props.chosenApplyCode !== prevProps.chosenApplyCode)) {
            await this.promisedSetState({
                organization: this.props.data.organization,
                loading: false,
                minimumTarget: this.props.data.minimum_target,
                fineAmount: this.props.data.fine,
                listTarget: this.props.data.list_agent,
                telegramId: this.props.data.telegram_id,
            });
        }
    }

    onCancel = async () => {
        this.props.onCloseModal({reload: false});
    }
    onSubmit = async () => {
        this.setState({
            loading: true,
        });

        const dataTrack = {
            type: this.props?.data?.type,
            company_code: this.props?.data?.company_code,
            company_name: this.props?.data?.company_name,
            list_agent: this.state.listTarget,
            schedule: this.props?.data?.schedule,
            aggregation: this.props?.data?.aggregation,
            minimum_target: this.state.minimumTarget,
            fine: this.state.fineAmount,
            telegram_id: this.state.telegramId,
            active: true
        }

        try {
            await trackAgentService.setToken(this.props.token);
            await trackAgentService.createMasterTrackAgent(
                dataTrack
            ).then(successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'data updated '
                });
                this.props.onCloseModal({reload: true});
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            await this.promisedSetState({
                loading: false,
            });
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "telegramId" | "minimumTarget" | "fineAmount" | "listTarget">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "telegramId":
                currentState.telegramId = (value) ? value : this.state.telegramId;
                break;
            case "minimumTarget":
                currentState.minimumTarget = (value) ? value : this.state.minimumTarget;
                break;
            case "fineAmount":
                currentState.fineAmount = (value) ? value : this.state.fineAmount;
                break;
            case "listTarget":
                currentState.listTarget = (value) ? value : this.state.listTarget;
                break;
        }
        this.setState({
            ...currentState,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={!this.state.processingFetch ? {} : {display: 'none'}}>

                    <Form.Item>
                        <i className={`small-text-grey`}>Organization:</i><br/>
                        <Input disabled={true} value={this.props?.data?.company_name} placeholder="Organization"/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>Type:</i><br/>
                        <Input disabled={true} value={this.props?.data?.type} placeholder="Type"/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>Schedule:</i><br/>
                        <Input disabled={true} value={this.props?.data?.schedule} placeholder="Type"/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>Aggregation:</i><br/>
                        <Input disabled={true} value={this.props?.data?.aggregation} placeholder="Type"/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>Minimum Target (Lead):</i><br/>
                        <CurrencyInput
                            className={'ant-input-css'}
                            allowDecimals={false}
                            groupSeparator={'.'}
                            decimalSeparator={','}
                            value={this.state.minimumTarget}
                            onValueChange={value => this.onFieldChange("minimumTarget", value ? parseFloat(value) : 0)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>Fine Amount:</i><br/>
                        <CurrencyInput
                            className={'ant-input-css'}
                            allowDecimals={false}
                            groupSeparator={'.'}
                            decimalSeparator={','}
                            prefix={'Rp. '}
                            value={this.state.fineAmount}
                            onValueChange={value => this.onFieldChange("fineAmount", value ? parseFloat(value) : 0)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>Telegram ID: (empty = no telegram webhook)</i><br/>
                        <Input value={this.state.telegramId} placeholder={`Empty = not send telegram message`} onChange={event => this.onFieldChange("telegramId", event.target.value)}/>
                    </Form.Item>
                    <Form.Item className={'padTop-5'}>
                        <i className={`small-text-grey`}>Target Agent Code</i>
                        <Select
                            mode="tags"
                            placeholder="Please select"
                            value={this.state.listTarget}
                            onChange={value => this.onFieldChange("listTarget", value)}
                            style={{width: '100%'}}
                            options={[]}
                        />
                    </Form.Item>
                    <Form.Item className={'padTop-5'}>
                        <i className={`small-text-grey`}>Target Agent Code (comma separator)</i>
                        <TextArea
                            showCount maxLength={1000}
                            value={this.state.listTarget.toString()}
                            onChange={event => this.onFieldChange("listTarget", event.target.value.split(','))}
                            placeholder="Target Agent Code (comma separator)"/>
                    </Form.Item>

                    <div style={{paddingTop: 20, textAlign: "right"}}>
                        <Divider/>
                        <Button onClick={event => this.onCancel()} style={{marginRight: 7}}>Cancel</Button>
                        <Button onClick={event => this.onSubmit()} loading={this.state.loading} type="primary" style={{marginRight: 7}}>Create</Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default UpdateMasterTrack;
