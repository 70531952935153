import React, {Component} from 'react';
import {HomeStates} from "./types/HomeTypes";
import withAuth from "../../hoc/withAuth";
import AppLayout from "../../component/layouts/AppLatout";

class Home extends Component<any, HomeStates> {
    private readonly initState!: HomeStates;

    constructor(props: any) {
        super(props);
        document.title = "Admin AmartaVip";

        this.initState = {
            fetching: true,
            dataListVacancy: [],
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {

    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout onClickClass={this.onAreaChange} breadcrumbPath={``} breadcrumbMenu={`Dashboard`} defaultSelectedKeys={['m-dashboard']}>
                    <h2 className="text-2xl font-semibold text-gray-800">Welcome Back!</h2>
                    <p className="mt-4 text-gray-600">
                        Amarta Vip Admin Panel
                    </p>
                </AppLayout>
            </React.Fragment>
        );
    }
}

export default withAuth(Home, 1);
