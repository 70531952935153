import React, {Component} from 'react';
import withAuth from "../../hoc/withAuth";
import {FormVenueActivityApplicationStates} from "./types/FormVenueActivityApplicationTypes";
import {allowedOrganization} from "../../config/appDataConfig/organizationCondig";
import {Col, Row, Form, Input, DatePicker, Select, Table, Button, Modal, Divider} from "antd";
import type {RangePickerProps} from 'antd/es/date-picker';
import dayjs from 'dayjs';
import Cookies from "universal-cookie";
import TextArea from "antd/es/input/TextArea";
import {PlusCircleOutlined} from "@ant-design/icons";
import CurrencyInput from "react-currency-input-field";
import VehicleModelSelect from "../../component/vehicle-copan/VehicleModelSelect";
import AppLayout from "../../component/layouts/AppLatout";
import {activityApiService} from "../../services/amartaVip/ActivityApiService";

export interface FormVenueActivityApplicationProps {
}

const disablediActivityDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current < dayjs().add(1, 'day').endOf('day');
};

class FormVenueActivityApplication extends Component<FormVenueActivityApplicationProps & any, FormVenueActivityApplicationStates> {
    private cookies = new Cookies();

    constructor(props: FormVenueActivityApplicationProps & any) {
        super(props);
        const {match} = this.props;
        const organizationCode = match?.params?.organizationCode ?? "AMARTACHERY";
        const pickedOrganization: any = allowedOrganization.find(o => o.companyCode === organizationCode.toUpperCase()) ?? {};

        if (!pickedOrganization.amartaVipCode) {
            window.location.href = "/";
        }

        this.state = {
            token: this.cookies.get('_vip-t') ?? "",
            pickedOrganization: pickedOrganization,
            processing: false,
            iActivityDate: dayjs(this.props?.match?.params.d).format('YYYY-MM-DD'),
            iGroup: "",
            iDealer: "",
            iActivity: "",
            iDuration: null,
            iVenue: "",
            iDisplay: "",
            iDescription: "",
            iTargetVisitor: "",
            iTargetTestDrive: "",
            iDataModelTable: [],
            iDataBudgetTable: [],
            iDataPicTable: [],
            iDataManTable: [],
            iDataGuestTable: [],

            showModalAddVehicle: false,
            tempModelName: null,
            tempModelUuid: "",
            tempModelTargetVisitor: 0,
            tempModelTargetSpk: 0,
            tempSpecialPromo: "",

            showModalAddBudget: false,
            tempBudgetDetail: "",
            tempBudgetAmount: 0,

            showModalAddPic: false,
            tempPicOutlet: "",
            tempPicName: "",

            showModalAddMan: false,
            tempManItem: "",
            tempManQty: 0,

            showModalAddGuest: false,
            tempGuestName: "",
        }
    }


    onFieldChange = <T extends keyof Pick<any, "iGroup" | "iDealer" | "iActivity" | "iActivityDate" | "iDuration" | "iVenue" | "iDisplay" | "iDescription" | "iTargetVisitor" | "iTargetTestDrive" | "tempModelTargetVisitor" | "tempModelTargetSpk" | "tempSpecialPromo" | "tempBudgetDetail" | "tempBudgetAmount" | "tempPicOutlet" | "tempPicName" | "tempManItem" | "tempManQty" | "tempGuestName">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "iGroup":
                currentState.iGroup = value;
                break;
            case "iDealer":
                currentState.iDealer = value;
                break;
            case "iActivity":
                currentState.iActivity = value;
                break;
            case "iActivityDate":
                currentState.iActivityDate = value;
                break;
            case "iDuration":
                currentState.iDuration = value;
                break;
            case "iVenue":
                currentState.iVenue = value;
                break;
            case "iDisplay":
                currentState.iDisplay = value;
                break;
            case "iDescription":
                currentState.iDescription = value;
                break;
            case "iTargetVisitor":
                currentState.iTargetVisitor = value;
                break;
            case "iTargetTestDrive":
                currentState.iTargetTestDrive = value;
                break;
            case "tempModelTargetVisitor":
                currentState.tempModelTargetVisitor = value;
                break;
            case "tempModelTargetSpk":
                currentState.tempModelTargetSpk = value;
                break;
            case "tempSpecialPromo":
                currentState.tempSpecialPromo = value;
                break;
            case "tempBudgetDetail":
                currentState.tempBudgetDetail = value;
                break;
            case "tempBudgetAmount":
                currentState.tempBudgetAmount = value;
                break;
            case "tempPicOutlet":
                currentState.tempPicOutlet = value;
                break;
            case "tempPicName":
                currentState.tempPicName = value;
                break;
            case "tempManItem":
                currentState.tempManItem = value;
                break;
            case "tempManQty":
                currentState.tempManQty = value;
                break;
            case "tempGuestName":
                currentState.tempGuestName = value;
                break;
        }

        this.setState({
            ...currentState,
        });
    }

    onVehicleModelChange = (data: any) => {
        this.setState({
            tempModelName: data.value,
            tempModelUuid: data.model_uuid,
        })
    }

    confirmAddModelData = async () => {
        const currentTableData = [...this.state.iDataModelTable];
        currentTableData.push({
            model_uuid: this.state.tempModelUuid,
            model_name: this.state.tempModelName,
            special_promo: this.state.tempSpecialPromo,
            target_visitor: this.state.tempModelTargetVisitor,
            target_spk: this.state.tempModelTargetSpk,
        });
        this.setState({
            iDataModelTable: currentTableData,
            showModalAddVehicle: false,
        });
    }

    confirmAddBudgetData = async () => {
        const currentTableData = [...this.state.iDataBudgetTable];
        currentTableData.push({
            budget_detail: this.state.tempBudgetDetail,
            budget_amount: this.state.tempBudgetAmount,
        });
        this.setState({
            iDataBudgetTable: currentTableData,
            showModalAddBudget: false,
        });
    }

    confirmAddPicData = async () => {
        const currentTableData = [...this.state.iDataPicTable];
        currentTableData.push({
            pic_outlet: this.state.tempPicOutlet,
            pic_name: this.state.tempPicName,
        });
        this.setState({
            iDataPicTable: currentTableData,
            showModalAddPic: false,
        });
    }

    confirmAddManData = async () => {
        const currentTableData = [...this.state.iDataManTable];
        currentTableData.push({
            man_item: this.state.tempManItem,
            man_quantity: this.state.tempManQty,
        });
        this.setState({
            iDataManTable: currentTableData,
            showModalAddMan: false,
        });
    }

    confirmAddGuestData = async () => {
        const currentTableData = [...this.state.iDataGuestTable];
        currentTableData.push({
            guest_name: this.state.tempGuestName,
        });
        this.setState({
            iDataGuestTable: currentTableData,
            showModalAddGuest: false,
        });
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    onSubmit = async () => {
        const activityData = {
            activity_type: 'APPLICATIONACTIVITY',
            company_code: this.state.pickedOrganization.amartaVipCode,
            activity_date: dayjs(this.state.iActivityDate).format("YYYY-MM-DD"),
            activity_time: "10:00",
            activity_name: this.state.iActivity,
            source: "admin.amarta.vip",
            data: {
                group: this.state.iGroup,
                dealer: this.state.iDealer,
                activity: this.state.iActivity,
                activity_date: dayjs(this.state.iActivityDate).format("YYYY-MM-DD"),
                duration: this.state.iDuration,
                venue: this.state.iVenue,
                display: this.state.iDisplay,
                target_visitor: this.state.iTargetVisitor,
                target_test_drive: this.state.iTargetTestDrive,
                description: this.state.iDescription,
            },
            data_target_vehicle_model: this.state.iDataModelTable ?? [],
            data_budget: this.state.iDataBudgetTable ?? [],
            data_pic: this.state.iDataPicTable ?? [],
            data_man_power: this.state.iDataManTable ?? [],
            guest_list: this.state.iDataGuestTable ?? []
        }

        this.setState({
            processing: true,
        });

        try {
            await activityApiService.setToken(this.state.token);
            await activityApiService.createActivity(
                activityData
            ).then(successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'Activity successfully scheduled '
                });

                this.setState({
                    processing: false,
                    iGroup: "",
                    iDealer: "",
                    iActivity: "",
                    iDuration: null,
                    iVenue: "",
                    iDisplay: "",
                    iDescription: "",
                    iTargetVisitor: "",
                    iTargetTestDrive: "",
                    iDataModelTable: [],
                    iDataBudgetTable: [],
                    iDataPicTable: [],
                    iDataManTable: [],
                    iDataGuestTable: [],
                    showModalAddVehicle: false,
                    tempModelName: null,
                    tempModelUuid: "",
                    tempModelTargetVisitor: 0,
                    tempModelTargetSpk: 0,
                    tempSpecialPromo: "",
                    showModalAddBudget: false,
                    tempBudgetDetail: "",
                    tempBudgetAmount: 0,
                    showModalAddPic: false,
                    tempPicOutlet: "",
                    tempPicName: "",
                    showModalAddMan: false,
                    tempManItem: "",
                    tempManQty: 0,
                    showModalAddGuest: false,
                    tempGuestName: "",
                });
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            this.setState({
                processing: false,
            });
        }
    }


    render() {
        const daysOption = [];
        for (let i = 1; i < 14; i++) {
            daysOption.push(<Select.Option key={i?.toString()} value={i?.toString()}>{i?.toString()}</Select.Option>);
        }
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`Activity / Approval Activity`} breadcrumbMenu={`Application Form`} defaultSelectedKeys={['m-form-app']} defaultOpenKeys={['sm-activity', 'sm-form-activity']}>
                    <Row style={{padding: 20, paddingTop: 10}}>
                        <Col xxl={{span: 18, offset: 4}} xl={{span: 18, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                            <div style={{textAlign: "center", fontSize: "170%", marginBottom: 7, fontWeight: "600"}}>Activity Application Form</div>
                            <br/>
                            ReceiveDate: {dayjs().format('YYYY-MM-DD')}
                            <div style={{textAlign: "left", fontSize: "125%", marginBottom: 7, marginTop: 20, fontWeight: "600"}}>I. Activity Information <i style={{textAlign: "center", fontSize: "60%", marginBottom: 20, color: "gray"}}>(filled by Dealer)</i></div>

                            <Row>
                                <Col style={{paddingLeft: 10, paddingRight: 10,}} lg={{span: 12}} md={{span: 24}} xs={{span: 24}}>
                                    <Form.Item>
                                        <i className={`text-small-grey-nopadding`}>Group *</i>
                                        <Input value={this.state.iGroup} onChange={event => this.onFieldChange("iGroup", event.target.value)} placeholder="Input your Group"/>
                                    </Form.Item>
                                    <Form.Item>
                                        <i className={`text-small-grey-nopadding`}>Dealer *</i>
                                        <Input value={this.state.iDealer} onChange={event => this.onFieldChange("iDealer", event.target.value)} placeholder="Input your Dealer"/>
                                    </Form.Item>
                                    <Form.Item>
                                        <i className={`text-small-grey-nopadding`}>Activity *</i>
                                        <Input value={this.state.iActivity} onChange={event => this.onFieldChange("iActivity", event.target.value)} placeholder="Describe your Activity"/>
                                    </Form.Item>
                                    <Form.Item>
                                        <i className={`text-small-grey-nopadding`}>Date of Activity *</i>
                                        <DatePicker
                                            name="iActivityDate"
                                            format={'YYYY-MM-DD'} style={{width: "100%"}}
                                            disabledDate={disablediActivityDate}
                                            defaultValue={dayjs(this.state.iActivityDate)}
                                            onChange={(date, dateString) => this.onFieldChange("iActivityDate", dateString)}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <i className={`text-small-grey-nopadding`}>Duration* <i style={{fontSize: "70%"}}>(days)</i> *</i>
                                        <Select onChange={value => this.onFieldChange("iDuration", value)} value={this.state.iDuration} placeholder={`Choose Duration`} style={{width: '100%'}}>
                                            {daysOption}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item>
                                        <i className={`text-small-grey-nopadding`}>Venue *</i>
                                        <TextArea value={this.state.iVenue} onChange={event => this.onFieldChange("iVenue", event.target.value)} rows={2} placeholder="Venue Description"/>
                                    </Form.Item>
                                    <Form.Item>
                                        <i className={`text-small-grey-nopadding`}>Display *</i>
                                        <TextArea value={this.state.iDisplay} onChange={event => this.onFieldChange("iDisplay", event.target.value)} rows={2} placeholder="Display"/>
                                    </Form.Item>
                                    <Form.Item>
                                        <i className={`text-small-grey-nopadding`}>Target Visitor *</i>
                                        <Input value={this.state.iTargetVisitor} onChange={event => this.onFieldChange("iTargetVisitor", event.target.value)} placeholder="Input Target Visitor"/>
                                    </Form.Item>

                                    <Form.Item>
                                        <i className={`text-small-grey-nopadding`}>Target Test Drive *</i>
                                        <Input value={this.state.iTargetTestDrive} onChange={event => this.onFieldChange("iTargetTestDrive", event.target.value)} placeholder="Input Target Test Drive"/>
                                    </Form.Item>
                                </Col>
                                <Col style={{paddingLeft: 10, paddingRight: 10,}} lg={{span: 12}} md={{span: 24}} xs={{span: 24}}>
                                    <i className={`text-small-grey-nopadding`}>Target Vehicle Model *</i><br/>
                                    <Button onClick={() => this.setState({showModalAddVehicle: true, tempModelName: null, tempModelUuid: "", tempSpecialPromo: "", tempModelTargetSpk: 0, tempModelTargetVisitor: 0})} size={"small"} type="primary" danger icon={<PlusCircleOutlined/>} style={{marginTop: 20}}>Add Vehicle</Button>
                                    <Table
                                        size="small"
                                        style={{marginTop: 10}}
                                        rowKey={`model_name`}
                                        dataSource={this.state.iDataModelTable}
                                        columns={[{title: 'Model', dataIndex: 'model_name', key: 'model_name'}, {title: 'Special Promo', dataIndex: 'special_promo', key: 'special_promo'}, {title: 'Target Visitor', dataIndex: 'target_visitor', key: 'target_visitor'}, {title: 'Target SPK', dataIndex: 'target_spk', key: 'target_spk'},]}/>
                                    <br/>
                                    <Form.Item>
                                        <i className={`text-small-grey-nopadding`}>Description *</i>
                                        <TextArea value={this.state.iDescription} onChange={event => this.onFieldChange("iDescription", event.target.value)} rows={2} placeholder="Description"/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <br/>
                            <div style={{textAlign: "left", fontSize: "125%", marginBottom: 20, marginTop: 20, fontWeight: "600"}}>II. Key Elements</div>
                            <Row>
                                <Col style={{paddingLeft: 10, paddingRight: 10,}} lg={{span: 12}} md={{span: 24}} xs={{span: 24}}>
                                    <i className={`text-small-grey-nopadding`}>Detail Budget *</i><br/>
                                    <Button onClick={() => this.setState({showModalAddBudget: true, tempBudgetDetail: "", tempBudgetAmount: 0})} size={"small"} type="primary" danger icon={<PlusCircleOutlined/>} style={{marginTop: 20}}>Add Budget</Button>
                                    <Table
                                        size="small"
                                        style={{marginTop: 10}}
                                        rowKey={`budget_detail`}
                                        dataSource={this.state.iDataBudgetTable}
                                        columns={[{title: 'Budget Detail', dataIndex: 'budget_detail', key: 'budget_detail'}, {title: 'Budget Amount ', dataIndex: 'budget_amount', key: 'budget_amount'},]}/>
                                    <br/>
                                </Col>
                                <Col style={{paddingLeft: 10, paddingRight: 10,}} lg={{span: 12}} md={{span: 24}} xs={{span: 24}}>
                                    <i className={`text-small-grey-nopadding`}>Person In Charge *</i><br/>
                                    <Button onClick={() => this.setState({showModalAddPic: true, tempPicOutlet: "", tempPicName: ""})} size={"small"} type="primary" danger icon={<PlusCircleOutlined/>} style={{marginTop: 20}}>Add PIC Outlet</Button>
                                    <Table
                                        size="small"
                                        style={{marginTop: 10}}
                                        rowKey={`budget_detail`}
                                        dataSource={this.state.iDataPicTable}
                                        columns={[{title: 'Outlet', dataIndex: 'pic_outlet', key: 'pic_outlet'}, {title: 'Name', dataIndex: 'pic_name', key: 'pic_name'},]}/>
                                    <br/>

                                    <i className={`text-small-grey-nopadding`}>Man Power *</i><br/>
                                    <Button onClick={() => this.setState({showModalAddMan: true, tempManItem: "", tempManQty: 0})} size={"small"} type="primary" danger icon={<PlusCircleOutlined/>} style={{marginTop: 20}}>Add PIC Outlet</Button>
                                    <Table
                                        size="small"
                                        style={{marginTop: 10}}
                                        rowKey={`man_item`}
                                        dataSource={this.state.iDataManTable}
                                        columns={[{title: 'Item', dataIndex: 'man_item', key: 'man_item'}, {title: 'Quantity', dataIndex: 'man_quantity', key: 'man_quantity'},]}/>
                                    <br/>
                                </Col>
                            </Row>

                            <br/>
                            <div style={{textAlign: "left", fontSize: "125%", marginBottom: 20, marginTop: 20, fontWeight: "600"}}>III. Additional Data</div>
                            <Row>
                                <Col style={{paddingLeft: 10, paddingRight: 10,}} lg={{span: 12}} md={{span: 24}} xs={{span: 24}}>
                                    <i className={`text-small-grey-nopadding`}>Guest List *</i><br/>
                                    <Button onClick={() => this.setState({showModalAddGuest: true, tempGuestName: ""})} size={"small"} type="primary" danger icon={<PlusCircleOutlined/>} style={{marginTop: 20}}>Add Guest List</Button>
                                    <Table
                                        size="small"
                                        style={{marginTop: 10}}
                                        rowKey={`guest_name`}
                                        dataSource={this.state.iDataGuestTable}
                                        columns={[{title: 'Name', dataIndex: 'guest_name', key: 'guest_name'}]}/>
                                    <br/>
                                </Col>
                            </Row>

                        </Col>
                        <Col xxl={{span: 18, offset: 4}} xl={{span: 18, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                            <Button onClick={() => this.onSubmit()} loading={this.state.processing} style={{backgroundColor: '#384967', borderColor: '#384967', color: '#fff'}} danger>Submit Data Application</Button>
                        </Col>
                    </Row>
                    <div style={{minHeight: 50}}/>
                </AppLayout>
                <Modal
                    open={this.state.showModalAddVehicle}
                    title="Add Vehicle Model Budget"
                    onCancel={() => this.setState({showModalAddVehicle: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button type={"primary"} key="1" onClick={this.confirmAddModelData}>
                            Add
                        </Button>,
                        <Button key="2" onClick={() => this.setState({showModalAddVehicle: false})}>
                            Close
                        </Button>,
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Model *</i>
                            <VehicleModelSelect onVehicleModelChangeProps={this.onVehicleModelChange} value={this.state.tempModelName} companyCode={this.state.pickedOrganization.copanCode} areaCode={this.state.pickedOrganization.copanAreaCode} brandCode={this.state.pickedOrganization.copanDefaultBrand} time={Math.floor(+new Date() / 1000)}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Special Promo *</i>
                            <Input value={this.state.tempSpecialPromo} onChange={event => this.onFieldChange("tempSpecialPromo", event.target.value)} placeholder="Input Special Promo"/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Target Visitor *</i>
                            <CurrencyInput
                                className={'ant-input-css'}
                                allowDecimals={false}
                                groupSeparator={','}
                                decimalSeparator={'.'}
                                value={this.state.tempModelTargetVisitor}
                                onValueChange={value => this.onFieldChange("tempModelTargetVisitor", value ? parseFloat(value) : 0)}
                            />
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Target SPK *</i>
                            <CurrencyInput
                                className={'ant-input-css'}
                                allowDecimals={false}
                                groupSeparator={','}
                                decimalSeparator={'.'}
                                value={this.state.tempModelTargetSpk}
                                onValueChange={value => this.onFieldChange("tempModelTargetSpk", value ? parseFloat(value) : 0)}
                            />
                        </Form.Item>
                    </React.Fragment>
                </Modal>

                <Modal
                    open={this.state.showModalAddBudget}
                    title="Add Detail Budget"
                    onCancel={() => this.setState({showModalAddBudget: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button type={"primary"} key="1" onClick={this.confirmAddBudgetData}>
                            Add
                        </Button>,
                        <Button key="2" onClick={() => this.setState({showModalAddBudget: false})}>
                            Close
                        </Button>,
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Detail Budget *</i>
                            <Input value={this.state.tempBudgetDetail} onChange={event => this.onFieldChange("tempBudgetDetail", event.target.value)} placeholder="Input Detail Budget"/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Amount Budget *</i>
                            <CurrencyInput
                                className={'ant-input-css'}
                                allowDecimals={false}
                                groupSeparator={','}
                                decimalSeparator={'.'}
                                value={this.state.tempBudgetAmount}
                                onValueChange={value => this.onFieldChange("tempBudgetAmount", value ? parseFloat(value) : 0)}
                            />
                        </Form.Item>
                    </React.Fragment>
                </Modal>

                <Modal
                    open={this.state.showModalAddPic}
                    title="Add Outlet PIC"
                    onCancel={() => this.setState({showModalAddPic: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button type={"primary"} key="1" onClick={this.confirmAddPicData}>
                            Add
                        </Button>,
                        <Button key="2" onClick={() => this.setState({showModalAddPic: false})}>
                            Close
                        </Button>,
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Outlet *</i>
                            <Input value={this.state.tempPicOutlet} onChange={event => this.onFieldChange("tempPicOutlet", event.target.value)} placeholder="Input Outlet"/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>PIC Name *</i>
                            <Input value={this.state.tempPicName} onChange={event => this.onFieldChange("tempPicName", event.target.value)} placeholder="Input PIC Name"/>
                        </Form.Item>
                    </React.Fragment>
                </Modal>

                <Modal
                    open={this.state.showModalAddMan}
                    title="Add Man Power"
                    onCancel={() => this.setState({showModalAddMan: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button type={"primary"} key="1" onClick={this.confirmAddManData}>
                            Add
                        </Button>,
                        <Button key="2" onClick={() => this.setState({showModalAddMan: false})}>
                            Close
                        </Button>,
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Item *</i>
                            <Input value={this.state.tempManItem} onChange={event => this.onFieldChange("tempManItem", event.target.value)} placeholder="Item"/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Quantity *</i>
                            <CurrencyInput
                                className={'ant-input-css'}
                                allowDecimals={false}
                                groupSeparator={','}
                                decimalSeparator={'.'}
                                value={this.state.tempManQty}
                                onValueChange={value => this.onFieldChange("tempManQty", value ? parseFloat(value) : 0)}
                            />
                        </Form.Item>
                    </React.Fragment>
                </Modal>

                <Modal
                    open={this.state.showModalAddGuest}
                    title="Add Guest"
                    onCancel={() => this.setState({showModalAddGuest: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button type={"primary"} key="1" onClick={this.confirmAddGuestData}>
                            Add
                        </Button>,
                        <Button key="2" onClick={() => this.setState({showModalAddGuest: false})}>
                            Close
                        </Button>,
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Name *</i>
                            <Input value={this.state.tempGuestName} onChange={event => this.onFieldChange("tempGuestName", event.target.value)} placeholder="Name"/>
                        </Form.Item>
                    </React.Fragment>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withAuth(FormVenueActivityApplication, 5);

