import axios from "axios";
import {baseUrlAmartaVip, xApiKeyAmartaVip} from "../../config/apiConfig/apiConfig";

class VacancyApiService {
    private axios = axios.create({
        baseURL: baseUrlAmartaVip,
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    public async updateApplicantStatus(inputData?: any) {
        try {
            return await this.axios.put<any>('/talent/organization-apply',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async createAssessment(inputData?: any) {
        try {
            return await this.axios.post<any>('/talent/organization-interview',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async updateAssessment(inputData?: any) {
        try {
            return await this.axios.put<any>('/talent/organization-interview',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getMasterVacancy(params: any) {
        try {
            return await this.axios.get<any>(`/talent/master/${params?.organization}`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                }
            });
        } catch (e: any) {
            console.log(e)
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getOrganizationApply(params: any) {
        const queries = {
            ...(params?.organization && {
                organization: params.organization,
            }),
            ...(params?.startDate && {
                start_date: params.startDate,
            }),
            ...(params?.endDate && {
                end_date: params.endDate,
            }),
            ...(params?.applyCode && {
                apply_code: params.applyCode,
            }),
            ...(params?.roleCode && {
                role_code: params.roleCode,
            }),
        }

        try {
            return await this.axios.get<any>(`/talent/organization-apply`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getOrganizationUser(params: any) {
        const queries = {
            ...(params?.userId && {
                user_id: params.userId,
            }),
            ...(params?.type && {
                type: params.type,
            }),
            ...(params?.organization && {
                organization: params.organization,
            }),
        }

        try {
            return await this.axios.get<any>(`/talent/organization-user`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getOrganizationInterview(params: any) {
        const queries = {
            ...(params?.organization && {
                organization: params.organization,
            }),
            ...(params?.startDate && {
                start_date: params.startDate,
            }),
            ...(params?.endDate && {
                end_date: params.endDate,
            }),
            ...(params?.assessmentCode && {
                assessment_code: params.assessmentCode,
            }),
            ...(params?.type && {
                type: params.type,
            }),
        }

        try {
            return await this.axios.get<any>(`/talent/organization-interview`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }
}

export const vacancyApiService = new VacancyApiService();
