import axios from "axios";
import {baseUrlB2b, xApiKeyB2b} from "../../config/apiConfig/apiConfig";

class FincoServices {
    private axios = axios.create({
        baseURL: baseUrlB2b,
    })

    public async getListLeasing() {
        const queries = {}
        try {
            return await this.axios.get<any>(`/public/available-leasing/public`, {
                headers: {
                    "X-Api-Key": xApiKeyB2b,
                },
                params: {
                    ...queries,
                }
            })
        } catch (e: any) {
            throw new Error(e)
        }
    }

    public async getmasterPricelist(params?: { leasingCode?: string, cityGroup?: string, modelName?: string, variantCode?: string }) {
        const queries = {
            ...(params?.leasingCode && {
                leasingCode: params.leasingCode,
            }),
            ...(params?.cityGroup && {
                cityGroup: params.cityGroup,
            }),
            ...(params?.modelName && {
                modelName: params.modelName,
            }),
            ...(params?.variantCode && {
                variantCode: params.variantCode,
            })
        }
        try {
            return await this.axios.get<any>(`price-list`, {
                params: {...queries}
            })
        } catch (e: any) {
            throw new Error(e.response.data.error.message)
        }
    }
}

export const fincoServices = new FincoServices()