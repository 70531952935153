import React, {Component} from 'react';
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import {ActivateAffiliateStates} from "./types/ActivateAffiliateTypes";
import {userServices} from "../../services/amartaVip/UserService";
import {AuditOutlined, DeleteOutlined, RollbackOutlined, SaveOutlined} from "@ant-design/icons";
import {Button, Col, Table, Tag, Tooltip, Form, Row, Divider, Modal} from "antd";
import TextArea from "antd/es/input/TextArea";

export interface ActivateAffiliateProps {
}

class ActivateAffiliate extends Component<ActivateAffiliateProps & any, ActivateAffiliateStates> {
    private cookies = new Cookies();
    private tableColumn: any = [
        {
            title: 'Kode Affiliate',
            dataIndex: 'affiliate_code',
            width: 160,
            render: ((value: number, row: any) => {
                return <React.Fragment>
                    <span className={`font-90`}>{value?.toString()}</span> <br/>
                    <span className={`text-small-grey-nopadding`}>{row.create_time?.slice(0, 16)?.replace("T", " ")}</span>
                </React.Fragment>
            })
        },
        {
            title: 'Nama | KTP',
            dataIndex: 'name',
            width: 160,
            render: ((value: number, row: any) => {
                return <React.Fragment>
                    <span className={`font-90`}>
                        {value} <Tag style={row.showroom_status ? {fontSize: "70%", display: "inline"} : {display: 'none'}} color="#108ee9">Showroom</Tag><br/>
                        <i style={{fontSize: "85%"}}>{row?.id_card?.id_card_number}</i>
                    </span>
                </React.Fragment>
            })
        },
        {
            title: 'bank',
            dataIndex: 'bank_code',
            width: 160,
            render: ((value: number, row: any) => {
                return <span className={`font-90`}>{row?.bank_code?.toUpperCase()}<br/><i style={{fontSize: "85%"}}>{row?.bank_account_number}</i></span>
            })
        },
        {
            title: 'Kode Agen',
            dataIndex: 'agent_code',
            width: 160,
            render: ((value: number, row: any) => {
                return <span className={`font-90`}>{value?.toString()}</span>
            })
        },
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 150,
            render: ((row: any) => {
                return <React.Fragment>
                    <span className={`font-90`}>
                        <Tooltip placement="topLeft" title="Lihat Data">
                            <Tag icon={<AuditOutlined/>} color="#55acee" onClick={event => this.editData(row)} style={{cursor: "pointer"}}> Acc / Reject Request </Tag>
                        </Tooltip>
                    </span>
                </React.Fragment>
            })
        },
    ];

    constructor(props: ActivateAffiliateProps & any) {
        super(props);

        this.state = {
            processing: false,
            processingFetchTableData: true,
            dataFilteredTable: [],
            pageSize: 100,
            dataRow: {},
            dataKk: {},
            submitting: false,
            showUpdateForm: false,
            modalConfirmReject: false,
            loadingConfirmReject: false,
            cancelReason: "",
            modalConfirmActive: false,
            loadingConfirmActive: false,
        }
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    async componentDidMount() {
        await this.fetchInactiveAffiliate();
    }

    fetchInactiveAffiliate = async () => {
        userServices.getInActiveAffiliate({}).then(async value => {
            const a = value.data.data ?? [];
            a.sort((item1: any, item2: any) => {
                const date1: any = new Date(item1.create_time);
                const date2: any = new Date(item2.create_time);
                return date2 - date1;
            });

            await this.promisedSetState({
                dataFilteredTable: a,
                processingFetchTableData: false,
            });
        }).catch(async reason => {
            await this.promisedSetState({
                dataFilteredTable: [],
                processingFetchTableData: false,
            });
        })
    }

    editData = async (data: any) => {
        console.log(data);
        await this.setState({
            dataRow: data,
            dataKk: data?.documents[0],
            showUpdateForm: true,
        });
    }

    onChangeTable = (pagination: any, filters: any, sorter: any) => {
        if (pagination?.pageSize) {
            this.setState({
                pageSize: pagination?.pageSize,
            });
        }
    }

    handleOkActiveData = async () => {
        this.setState({
            loadingConfirmActive: true
        });

        const dataUpdate = {
            type: "active",
            source: "copan.amartahonda.com",
            admin_id: "admin-copan",
            affiliate_codes: [
                {
                    code: this.state.dataRow?.affiliate_code,
                    reason: this.state.cancelReason
                }
            ]
        }

        try {
            await userServices.updateAffiliateStatus(
                dataUpdate
            ).then(() => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'affiliate berhasil diaktifkan'
                });
                setTimeout(function () {
                    window.location.reload();
                }, 2000);
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.setState({
                processing: false,
            });
        }
    }

    handleOkRejectData = async () => {
        if (!this.state.cancelReason) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'silakan isi alasan penolakan'
            });
            return;
        }

        this.setState({
            loadingConfirmReject: true
        });

        const dataUpdate = {
            type: "reject",
            source: "copan.amartahonda.com",
            admin_id: "admin-copan",
            affiliate_codes: [
                {
                    code: this.state.dataRow?.affiliate_code,
                    reason: this.state.cancelReason
                }
            ]
        }

        try {
            await userServices.updateAffiliateStatus(
                dataUpdate
            ).then(() => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'Affiliate berhasil direject'
                });
                setTimeout(function () {
                    window.location.reload();
                }, 2000);
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.setState({
                processing: false,
            });
        }
    }

    onReject = async () => {
        this.setState({
            cancelReason: "",
            modalConfirmReject: true,
            loadingConfirmReject: false
        });
    }

    onSubmit = async () => {
        this.setState({
            modalConfirmActive: true,
            loadingConfirmActive: false
        });
    }

    onCancel = async () => {
        await this.setState({
            showUpdateForm: false,
        });
    }

    handleCancelActiveData = async () => {
        await this.setState({
            modalConfirmActive: false,
        });
    }

    handleCancelRejectData = async () => {
        this.setState({
            modalConfirmReject: false,
            loadingConfirmReject: false
        });
    }

    onFieldChange = async <T extends keyof Pick<any, "cancelReason">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "cancelReason":
                currentState.cancelReason = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`UserVIP`} breadcrumbMenu={`Aktifasi Affiliate`} defaultSelectedKeys={['m-activateAffiliate']} defaultOpenKeys={['sm-uservip', 'sm-management-uservip']}>
                    <div style={this.state.showUpdateForm ? {display: 'none'} : {}}>
                        <Table
                            rowKey={'promo_code'}
                            size={'small'}
                            scroll={{x: 'calc(500px + 50%)'}}
                            loading={this.state.processingFetchTableData}
                            columns={this.tableColumn}
                            dataSource={this.state.dataFilteredTable}
                            pagination={{pageSize: this.state.pageSize}}
                            onChange={this.onChangeTable}
                        />
                    </div>
                    <div style={this.state.showUpdateForm ? {} : {display: 'none'}}>
                        <Row style={{padding: 20}}>
                            <Col xs={24} xl={10}>
                                <Divider orientation="left" plain>
                                    <span className={`divider-stock-purchase`}>Data Umum</span>
                                </Divider>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Kode Affiliate:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.affiliate_code}</Col>
                                </Row>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Kode Agen:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.agent_code}</Col>
                                </Row>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Nama:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.name?.toUpperCase()}</Col>
                                </Row>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Tanggal Pendaftaran:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.create_time?.replace("T", " ")}</Col>
                                </Row>
                                <Divider orientation="left" plain>
                                    <span className={`divider-stock-purchase`}>Data Bank</span>
                                </Divider>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Kode Bank:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.bank_code?.toUpperCase()}</Col>
                                </Row>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Nomor rekening Bank:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.bank_account_number}</Col>
                                </Row>

                                <div style={this.state.dataRow?.showroom_status ? {} : {display: 'none'}}>
                                    <Divider orientation="left" plain>
                                        <span className={`divider-stock-purchase`}>Data Showroom</span>
                                    </Divider>
                                    <Row className={`pt-2`}>
                                        <Col xs={24} xl={7} className={`col-grey`}>Nama Showroom:</Col>
                                        <Col xs={24} xl={17}>{this.state.dataRow?.showroom_data?.name?.toUpperCase()}</Col>
                                    </Row>
                                    <Row className={`pt-2`}>
                                        <Col xs={24} xl={7} className={`col-grey`}>Image Showroom:</Col>
                                        <Col xs={24} xl={17}><a target={`_blank`} href={this.state.dataRow?.showroom_data?.image}> Lihat Gambar </a></Col>
                                    </Row>
                                    <Row className={`pt-2`}>
                                        <Col xs={24} xl={7} className={`col-grey`}>Google URL:</Col>
                                        <Col xs={24} xl={17}><a target={`_blank`} href={this.state.dataRow?.showroom_data?.google_url}> {this.state.dataRow?.showroom_data?.google_url} </a></Col>
                                    </Row>
                                    <Row className={`pt-2`}>
                                        <Col xs={24} xl={7} className={`col-grey`}>Provinsi:</Col>
                                        <Col xs={24} xl={17}>{this.state.dataRow?.showroom_data?.address?.province_name?.toLowerCase()}</Col>
                                    </Row>
                                    <Row className={`pt-2`}>
                                        <Col xs={24} xl={7} className={`col-grey`}>Kota:</Col>
                                        <Col xs={24} xl={17}>{this.state.dataRow?.showroom_data?.address?.city_name?.toLowerCase()}</Col>
                                    </Row>
                                    <Row className={`pt-2`}>
                                        <Col xs={24} xl={7} className={`col-grey`}>Kecamatan:</Col>
                                        <Col xs={24} xl={17}>{this.state.dataRow?.showroom_data?.address?.district_name?.toLowerCase()}</Col>
                                    </Row>
                                    <Row className={`pt-2`}>
                                        <Col xs={24} xl={7} className={`col-grey`}>Kelurahan:</Col>
                                        <Col xs={24} xl={17}>{this.state.dataRow?.showroom_data?.address?.sub_district_name?.toLowerCase()}</Col>
                                    </Row>
                                    <Row className={`pt-2`}>
                                        <Col xs={24} xl={7} className={`col-grey`}>Kode Pos:</Col>
                                        <Col xs={24} xl={17}>{this.state.dataRow?.showroom_data?.address?.postal_code?.toLowerCase()}</Col>
                                    </Row>
                                    <Row className={`pt-2`}>
                                        <Col xs={24} xl={7} className={`col-grey`}>Alamat Lengkap:</Col>
                                        <Col xs={24} xl={17}>{this.state.dataRow?.showroom_data?.address?.full_address?.toLowerCase()}</Col>
                                    </Row>

                                </div>
                            </Col>
                            <Col xs={24} xl={2}/>
                            <Col xs={24} xl={10}>
                                <Divider orientation="left" plain>
                                    <span className={`divider-stock-purchase`}>KTP</span>
                                </Divider>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Nomor KTP:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.id_card?.id_card_number}</Col>
                                </Row>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Nama Lengkap:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.name?.toUpperCase()}</Col>
                                </Row>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Image KTP:</Col>
                                    <Col xs={24} xl={17}><a target={`_blank`} href={this.state.dataRow?.id_card?.id_card_image}> Lihat Gambar </a></Col>
                                </Row>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Tanggal Lahir:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.id_card?.birth_date?.replace("T", " ")}</Col>
                                </Row>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Tempat Lahir:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.id_card?.birth_place?.toLowerCase()}</Col>
                                </Row>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Status Pernikahan:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.id_card?.marital_status?.toLowerCase()}</Col>
                                </Row>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Pekerjaan:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.id_card?.occupation?.toLowerCase()}</Col>
                                </Row>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Jenis Kelamin:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.id_card?.sex?.toLowerCase()}</Col>
                                </Row>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Provinsi:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.address?.province_name?.toLowerCase()}</Col>
                                </Row>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Kota:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.address?.city_name?.toLowerCase()}</Col>
                                </Row>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Kecamatan:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.address?.district_name?.toLowerCase()}</Col>
                                </Row>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Kelurahan:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.address?.sub_district_name?.toLowerCase()}</Col>
                                </Row>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Kode Pos:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.address?.postal_code?.toLowerCase()}</Col>
                                </Row>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Alamat Lengkap:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataRow?.address?.full_address?.toLowerCase()}</Col>
                                </Row>

                                <Divider orientation="left" plain>
                                    <span className={`divider-stock-purchase`}>Data KK</span>
                                </Divider>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Nomor KK:</Col>
                                    <Col xs={24} xl={17}>{this.state.dataKk?.document_number}</Col>
                                </Row>
                                <Row className={`pt-2`}>
                                    <Col xs={24} xl={7} className={`col-grey`}>Image KK:</Col>
                                    <Col xs={24} xl={17}><a target={`_blank`} href={this.state.dataKk?.document_image}> Lihat Gambar </a></Col>
                                </Row>
                            </Col>
                        </Row>

                        <Col span={24} className={`pad-10`}>
                            <Form.Item>
                                <br/>
                                <Button type={'primary'} icon={<SaveOutlined/>} onClick={this.onSubmit} loading={this.state.submitting} style={{marginLeft: 10}}> Accept Affiliate </Button>
                                <Button danger type={'primary'} icon={<DeleteOutlined/>} onClick={this.onReject} loading={this.state.submitting} style={{marginLeft: 10}}> Reject Affiliate </Button>
                                <Button icon={<RollbackOutlined/>} onClick={this.onCancel} loading={this.state.submitting} style={{marginLeft: 10}}> Back</Button>
                            </Form.Item>
                        </Col>
                    </div>
                </AppLayout>

                <Modal
                    title="Konfirmasi Reject Affiliate"
                    visible={this.state.modalConfirmReject}
                    onOk={this.handleOkRejectData}
                    confirmLoading={this.state.loadingConfirmReject}
                    onCancel={this.handleCancelRejectData}
                >
                    <p>Anda yakin akan mereject affiliate ?</p> <br/>
                    Alasan Reject: <br/>
                    <TextArea value={this.state.cancelReason} onChange={event => this.onFieldChange("cancelReason", event.target.value)} rows={2} placeholder="Alasan ditolak"/>
                </Modal>

                <Modal
                    title="Konfirmasi Activate Affiliate"
                    visible={this.state.modalConfirmActive}
                    onOk={this.handleOkActiveData}
                    confirmLoading={this.state.loadingConfirmActive}
                    onCancel={this.handleCancelActiveData}
                >
                    <p>Anda yakin akan mengaktifkan affiliate tersebut ?</p> <br/>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withAuth(ActivateAffiliate, 16);
