import React, {Component} from "react";
import Cookies from "universal-cookie";
import {Button, Divider, Empty, Spin, Tag, Timeline} from "antd";
import {vacancyApiService} from "../../services/amartaVip/VacancyApiService";
import DetailAssessment from "../detailData/DetailAssessment";
import Modal from "antd/lib/modal";

class UserAssessmentHistory extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            processingFetch: false,
            dataAssessment: [],

            organization: "",
            chosenInterviewCode: "",
            chosenApplyCode: "",
            showModalDetail: false,
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.fetchHistoryData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.userUid !== prevProps.userUid) || (this.props.organization !== prevProps.organization)) {
            await this.fetchHistoryData();
        }
    }

    fetchHistoryData = async () => {

        console.log("asd");

        await this.promisedSetState({
            processingFetch: true,
            dataAssessment: [],
        });

        if (this.props.userUid && this.props.organization) {
            try {
                await vacancyApiService.setToken(this.state.token);
                const fetchResult = await vacancyApiService.getOrganizationUser({
                    organization: this.props.organization,
                    userId: this.props.userUid,
                    type: "assessment"
                });
                await this.promisedSetState({
                    processingFetch: false,
                    dataAssessment: fetchResult?.data?.data,
                });
            } catch (e) {
                await this.promisedSetState({
                    processingFetch: false,
                    dataAssessment: [],
                });
            }
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>

                <div style={this.state.processingFetch ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                    <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                </div>

                <Timeline className={`pt-10`}>
                    {this.state.dataAssessment.map((item: any, x: number) =>
                        <Timeline.Item key={x} color={`green`}>
                            <span style={{fontWeight: 500}}>{item?.type} {item?.category}</span> - <span>{item?.date}</span> <br/>
                            <span className={`text-small-grey-nopadding`}>{item?.organization} {item?.role_name} - {item?.apply_code}</span><br/>
                            <div style={item?.status === "OPEN" ? {} : {display: 'none'}}>
                                <Tag style={{fontSize: "70%", display: "inline", padding: 2, color: "#fff", backgroundColor: "#7c7d80"}}>Scheduled Interview </Tag>
                                <b style={{fontSize: "80%", color: "red"}}>{(this.props.checkSameApply) ? (this.props.checkSameApply !== item?.apply_code ? "*Apply Code yg Berbeda" : "") : ""}</b>
                            </div>
                            <div style={item?.status === "REJECTED" ? {} : {display: 'none'}}>
                                <Tag style={{fontSize: "70%", display: "inline", padding: 2, color: "#fff", backgroundColor: "#962929"}}>Rejected</Tag>
                                <b style={{fontSize: "80%", color: "red"}}>{(this.props.checkSameApply) ? (this.props.checkSameApply !== item?.apply_code ? "*Apply Code yg Berbeda" : "") : ""}</b>
                            </div>
                            <div style={item?.status === "ACCEPTED" ? {} : {display: 'none'}}>
                                <Tag style={{fontSize: "70%", display: "inline", padding: 2, color: "#fff", backgroundColor: "#353cc4"}}>Accepted</Tag>
                                <b style={{fontSize: "80%", color: "red"}}>{(this.props.checkSameApply) ? (this.props.checkSameApply !== item?.apply_code ? "*Apply Code yg Berbeda" : "") : ""}</b>
                            </div>

                            <Button style={{marginTop: 10}} size={"small"} onClick={() => this.setState({
                                organization: item?.organization,
                                chosenInterviewCode: item?.assessment_code,
                                chosenApplyCode: item?.apply_code,
                                showModalDetail: true,
                            })}>See Detail</Button>
                        </Timeline.Item>
                    )}
                </Timeline>

                <div style={this.state.dataAssessment?.length === 0 ? {} : {display: 'none'}}>
                    <Empty/>
                </div>

                <Modal
                    open={this.state.showModalDetail}
                    title={`Detail Assessment ` + this.state.chosenInterviewCode}
                    onCancel={() => this.setState({showModalDetail: false})}
                    cancelText={'Tutup'} okText={false}
                    width={`80%`}
                    footer={[
                        <Button key="back" type="default" onClick={() => this.setState({showModalDetail: false})}>
                            Close
                        </Button>,
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <DetailAssessment organization={this.state.organization} chosenInterviewCode={this.state.chosenInterviewCode} chosenApplyCode={this.state.chosenApplyCode} time={Math.floor(+new Date() / 1000)}/>
                    </React.Fragment>
                </Modal>
            </React.Fragment>
        );
    }
}

export default UserAssessmentHistory;
