import React, {Component} from "react";
import {catalogVehicleService} from "../../services/catalog/CatalogVehicleService";
// import currencyFormat from "../../helper/currencyFormat";
import {Select} from "antd";

class VehicleVariantSelect extends Component<any, any> {
    private initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            modelCode: "",
            brandCode: "",
            dataVehicleVariant: [],
            selectVehicleVariantValue: null,
        }
        this.state = {...this.initState}
    }

    fetchVehicleVariantData = async (modelCode: any) => {
        if (modelCode) {
            try {
                await catalogVehicleService.getCatalogVariant({
                    companyCode: this.props.companyCode,
                    areaCode: this.props.cityGroup,
                    modelName: modelCode,
                }).then(async (dataFetchModel: { data: { data: any[]; }; }) => {
                    const dataForState: any = dataFetchModel.data.data.map((variant: any) => ({
                        value: variant.variant_code,
                        name: variant.variant_name + " - " + variant.variant_code,
                        // name: variant.variant_name + " - " + variant.variant_code + " - " + currencyFormat(variant.price),
                        variant_uuid: variant.variant_uuid,
                        variant_name: variant.variant_name,
                    }));
                    this.setState({
                        modelCode: modelCode,
                        dataVehicleVariant: dataForState,
                    });
                });
            } catch (e: any) {
                this.setState({
                    modelCode: modelCode,
                    dataVehicleVariant: [],
                    selectVehicleVariantValue: null,
                });
            }
        } else {
            this.setState({
                modelCode: "",
                dataVehicleVariant: [],
                selectVehicleVariantValue: null,
            });
        }

    }

    onChange = (value: any, option: any) => {
        this.setState({
            selectVehicleVariantValue: value,
        });

        const picked = this.state.dataVehicleVariant.find((o: { value: any; }) => o.value === value);
        option.uuid = picked.variant_uuid;
        option.name = picked.variant_name;

        this.props.onVehicleVariantChangeProps(option);
    }

    async componentDidMount() {
        await this.promisedSetState({
            modelCode: this.props.modelCode
        });
        await this.fetchVehicleVariantData(this.props.modelCode);
        await this.onValueChange(this.props.value);
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.modelCode !== prevProps.modelCode) {
            this.fetchVehicleVariantData(this.props.modelCode);
        }
        if (this.props.brandCode !== prevProps.brandCode) {
            this.fetchVehicleVariantData("");
        }
        if (this.props.time !== prevProps.time) {
            this.onValueChange(this.props.value);
        }
    }

    onValueChange = (value: any) => {
        this.setState({
            selectVehicleVariantValue: value,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Select
                    showSearch
                    placeholder="Pilih Variant Kendaraan"
                    optionFilterProp="children"
                    onChange={this.onChange}
                    style={{width: "100%"}}
                    filterOption={(input, option) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    value={(this.props.value)}
                >
                    {
                        this.state.dataVehicleVariant.map((item: any, i: number) =>
                            <Select.Option key={item?.value} value={item?.value}>{item?.name}</Select.Option>
                        )
                    }
                </Select>
            </React.Fragment>
        );
    }
}

export default VehicleVariantSelect;
