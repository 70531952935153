import React, { Component } from "react";
import { Select } from "antd";

class SelectConfigArray extends Component<any, any> {
  private readonly initState!: any;

  constructor(props: any) {
    super(props);

    this.initState = {
      dataOption: this.props.dataOption ? this.props.dataOption : [],
      seletedValue: null,
    };
    this.state = { ...this.initState };
  }

  render() {
    return (
      <Select style={{ float: "left", minWidth: "100%" }} placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.onChange}>
        {this.state.dataOption.map((item: any) => (
          <Select.Option key={item} value={item}>
            {item.toUpperCase()}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export default SelectConfigArray;
