import React, {Component} from "react";
import Col from "antd/lib/col";
import {Button, Divider, Row, Select, Spin} from "antd";
import {vacancyApiService} from "../../services/amartaVip/VacancyApiService";
import Cookies from "universal-cookie";
import TextArea from "antd/es/input/TextArea";
import Modal from "antd/lib/modal";

class UpdateAssessmentStatus extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            organization: "",
            chosenInterviewCode: "",
            processingFetch: false,
            dataAssessment: {},
            interviewNotesForInternal: "",
            interviewNotes: "",
            interviewNotesForApplicant: "",
            applyStatus: "INTERVIEWED",
            assessmentStatus: null,
            loading: false,
        }

        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            organization: this.props.organization,
            chosenInterviewCode: this.props.chosenInterviewCode,
            interviewNotesForInternal: "",
            interviewNotes: "",
            interviewNotesForApplicant: "",
            applyStatus: "INTERVIEWED",
            assessmentStatus: null,
            loading: false,
        });
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.time !== prevProps.time) || (this.props.chosenInterviewCode !== prevProps.chosenInterviewCode)) {
            await this.promisedSetState({
                organization: this.props.organization,
                chosenInterviewCode: this.props.chosenInterviewCode,
                interviewNotesForInternal: "",
                interviewNotes: "",
                interviewNotesForApplicant: "",
                applyStatus: "INTERVIEWED",
                assessmentStatus: null,
                loading: false,
            });

            await this.fetchData();
        }
    }

    fetchData = async () => {
        await this.promisedSetState({
            processingFetch: true,
            dataAssessment: {},
        });

        try {
            await vacancyApiService.setToken(this.state.token);
            const fetchResult = await vacancyApiService.getOrganizationInterview({
                organization: this.state.organization,
                assessmentCode: this.state.chosenInterviewCode,
            });
            await this.promisedSetState({
                processingFetch: false,
                dataAssessment: fetchResult?.data?.data[0],
            });
        } catch (e) {
            await this.promisedSetState({
                processingFetch: false,
                dataAssessment: {},
            });
        }
    }


    onFieldChange = async <T extends keyof Pick<any, "interviewNotesForInternal" | "interviewNotes" | "interviewNotesForApplicant" | "applyStatus" | "assessmentStatus">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "interviewNotesForInternal":
                currentState.interviewNotesForInternal = value;
                break;
            case "interviewNotes":
                currentState.interviewNotes = value;
                break;
            case "interviewNotesForApplicant":
                currentState.interviewNotesForApplicant = value;
                break;
            case "applyStatus":
                currentState.applyStatus = value;
                break;
            case "assessmentStatus":
                currentState.assessmentStatus = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    onCancel = async () => {
        this.props.onCloseModal({reload: false});
    }
    onSubmit = async () => {
        if (!this.state.assessmentStatus) {
            Modal.error({
                title: 'Error',
                content: 'please complete the interview status '
            });
            return;
        }

        const dataInterview = {
            organization: this.state.organization,
            type: "interview",
            apply_code: this.state.dataAssessment?.apply_code,
            assessment_code: this.state.dataAssessment?.assessment_code,
            notes_for_internal: this.state.interviewNotesForInternal,
            notes: this.state.interviewNotes,
            notes_for_applicant: this.state.interviewNotesForApplicant,
            apply_status: this.state.applyStatus,
            assessment_status: this.state.assessmentStatus,
        }

        this.setState({
            loading: true,
        });

        try {
            await vacancyApiService.setToken(this.state.token);
            await vacancyApiService.updateAssessment(
                dataInterview
            ).then(successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'Interview successfully updated '
                });
                this.props.onCloseModal({reload: true});
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            await this.promisedSetState({
                loading: false,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.processingFetch ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={!this.state.processingFetch ? {} : {display: 'none'}}>
                    <Row>
                        <Col xs={24} xl={12} style={{padding: 20}}>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Apply Code:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.apply_code}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Assessment Code:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.assessment_code}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Category:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.category}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Organization:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.organization}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Vacancy:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.vacancy_code} ({this.state.dataAssessment?.vacancy_name})</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Role:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.role_name}</Col>
                            </Row>
                            <Divider/>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Type:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.type}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Category:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.category}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Interview Time:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.date?.slice(0, 16)?.replace("T", " ")}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Name:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.user_data?.full_name}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Phone:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.user_data?.phones}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Email:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.user_data?.email}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Location:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.location}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Notes:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.notes}</Col>
                            </Row>
                            <Row className={`pt-5`}>
                                <Col xs={24} xl={8} className={`col-grey`}>Notes For Applicant:</Col>
                                <Col xs={24} xl={14}>{this.state.dataAssessment?.notes_for_applicant}</Col>
                            </Row>
                        </Col>
                        <Col xs={24} xl={12} style={{padding: 20}}>
                            <Divider orientation="left">Assessment Result</Divider>

                            <div className={`gray-80 mt-20`}>Status Apply:</div>
                            <Select
                                placeholder="Apply Status"
                                showSearch
                                optionFilterProp="children"
                                value={this.state.applyStatus}
                                onChange={value => this.onFieldChange("applyStatus", value)}
                                style={{width: "100%"}}
                            >
                                <Select.Option value="OPEN">Open - Waiting For Process</Select.Option>
                                <Select.Option value="ACCEPTED">Accepted - Final Accepted</Select.Option>
                                <Select.Option value="REJECTED">Rejected - Final Rejected</Select.Option>
                                <Select.Option value="SCHEDULED_INTERVIEW">Scheduled Interview</Select.Option>
                                <Select.Option value="INTERVIEWED">Interviewed</Select.Option>
                                <Select.Option disabled value="SCHEDULED_TEST">Scheduled Test</Select.Option>
                                <Select.Option disabled value="TESTED">Tested</Select.Option>
                            </Select>

                            <div className={`gray-80 mt-20`}>Status Interview:</div>
                            <Select
                                placeholder="Interview Status"
                                showSearch
                                optionFilterProp="children"
                                value={this.state.assessmentStatus}
                                onChange={value => this.onFieldChange("assessmentStatus", value)}
                                style={{width: "100%"}}
                            >
                                <Select.Option value="OPEN">Open</Select.Option>
                                <Select.Option value="ACCEPTED">Accepted</Select.Option>
                                <Select.Option value="REJECTED">Rejected</Select.Option>
                            </Select>

                            <div className={`gray-80 mt-20`}>Catatan Internal:</div>
                            <TextArea showCount maxLength={200} value={this.state.interviewNotesForInternal} onChange={event => this.onFieldChange("interviewNotesForInternal", event.target.value)} placeholder="Catatan Internal"/>

                            <div className={`gray-80 mt-20`}>Catatan Interview:</div>
                            <TextArea showCount maxLength={200} value={this.state.interviewNotes} onChange={event => this.onFieldChange("interviewNotes", event.target.value)} placeholder="Catatan Interview"/>

                            <div className={`gray-80 mt-20`}>Catatan Untuk Applicant:</div>
                            <TextArea showCount maxLength={400} value={this.state.interviewNotesForApplicant} onChange={event => this.onFieldChange("interviewNotesForApplicant", event.target.value)} placeholder="Catatan Untuk Applicant"/>
                        </Col>
                    </Row>
                    <div style={{paddingTop: 20, textAlign: "right"}}>
                        <Divider/>
                        <Button onClick={event => this.onCancel()} style={{marginRight: 7}}>Cancel</Button>
                        <Button onClick={event => this.onSubmit()} loading={this.state.loading} type="primary" style={{marginRight: 7}}>Create</Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default UpdateAssessmentStatus;
