import axios from "axios";
import {baseUrlAmartaVip, xApiKeyAmartaVip} from "../../config/apiConfig/apiConfig";

class GroupServices {
    private axios = axios.create({
        baseURL: baseUrlAmartaVip,
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    public async updateGroup(data?: any) {
        try {
            return await this.axios.put<any>('/user/internal',
                data, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getGroup(params: { type: string }) {
        const queries = {
            ...(params?.type && {
                type: params.type,
            })
        }
        try {
            return await this.axios.get<any>(`/group`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                    "App-Build-Code": "dev",
                    "App-Code": "adminvip"
                },
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getTargetSchema(params: { code?: string }) {
        const queries = {
            ...(params?.code && {
                code: params.code,
            })
        }
        try {
            return await this.axios.get<any>(`/target-schema`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip
                },
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async updateTargetSchema(data?: any) {
        try {
            return await this.axios.put<any>('/target-schema',
                data, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async addTargetSchema(inputData?: any) {
        try {
            return await this.axios.post<any>('/target-schema',
                inputData, {
                    headers: {
                        "X-Api-Key": xApiKeyAmartaVip,
                        "Content-Type": "application/json"
                    }
                })
        } catch (e: any) {
            console.log(e.response.data.error.message)
            throw new Error(e.response.data.error.message)
        }
    }

    public async getNotes(params: { appCode?: string }) {
        const queries = {
            ...(params?.appCode && {
                app_code: params.appCode,
            })
        }
        try {
            return await this.axios.get<any>(`/app-notes`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip
                },
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async addNotes(inputData?: any) {
        try {
            return await this.axios.post<any>('/app-notes',
                inputData, {
                    headers: {
                        "X-Api-Key": xApiKeyAmartaVip,
                        "Content-Type": "application/json"
                    }
                })
        } catch (e: any) {
            console.log(e.response.data.error.message)
            throw new Error(e.response.data.error.message)
        }
    }

    public async deleteNotes(inputData?: any) {
        try {
            return await this.axios.delete<any>('app-notes', {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                    "Content-Type": "application/json"
                },
                data: inputData
            });
        } catch (e: any) {
            console.log(e.response.data.error.message);
            throw new Error(e.response.data.error.message);
        }
    }
}

export const groupServices = new GroupServices();
