import React, {Component} from 'react';
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import {Form, Input, Button, Select, Spin, Table, Dropdown, Menu} from "antd";
import {userServices} from "../../services/amartaVip/UserService";

export interface UserExportProps {
}

export interface UserExportStates {
    token: string;
    agentCodes: string;
    selectedColumns: string[];
    isProcessing: boolean;
    dataResult: any;
}

class UserExport extends Component<UserExportProps, UserExportStates> {
    private cookies = new Cookies();

    constructor(props: UserExportProps) {
        super(props);

        this.state = {
            token: this.cookies.get('_vip-t') ?? "",
            agentCodes: "999999,AFF888888,0506200709",
            selectedColumns: ["agent_code", "name", "bank_data"],
            isProcessing: false,
            dataResult: [],
        };
    }

    handleAgentCodeChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({agentCodes: event.target.value});
    };

    handleColumnsChange = (selectedColumns: string[]) => {
        this.setState({selectedColumns});
    };

    handleGet = async () => {
        const {agentCodes} = this.state;
        this.setState({isProcessing: true});
        try {
            const listData = await userServices.getMultiUserByAgentCOde({agentCodes: `${agentCodes?.replace(/ /g, '')}?bankonly=true`});
            const dataResult = listData.data.data;

            this.setState({
                isProcessing: false,
                dataResult: dataResult,
            });
        } catch (e: any) {
            this.setState({
                isProcessing: false,
                dataResult: [],
            });
        }
    };

    handleDownload = (fileType: 'json' | 'csv') => {
    const {dataResult, selectedColumns} = this.state;

    if (dataResult.length === 0) return;

    // Filter dan format data sesuai kolom yang dipilih dan ditampilkan
    const formattedData = dataResult.map((row: any) => {
        const filteredRow: any = {};

        selectedColumns.forEach((col) => {
            if (col === 'bank_data' && row.bank_accounts) {
                // Format data bank sesuai dengan object `bank_accounts`
                filteredRow['bank_code'] = row.bank_accounts.bank_code || '';
                filteredRow['bank_name'] = row.bank_accounts.bank_name || '';
                filteredRow['account_name'] = row.bank_accounts.account_name || '';
                filteredRow['account_number'] = row.bank_accounts.account_number || '';
            } else if (col === 'id_card' && row.id_card) {
                // Untuk data kartu identitas
                filteredRow['id_card_number'] = row.id_card.id_card_number || '';
            } else if (col === 'phone' && row.phone) {
                // Untuk data nomor telepon
                filteredRow['phone'] = row.phone.phone || '';
            } else {
                // Untuk flat fields lainnya
                filteredRow[col] = row[col] || '';
            }
        });

        return filteredRow;
    });

    let fileContent = '';
    let fileName = `user_export_${new Date().toISOString()}.${fileType}`;

    if (fileType === 'json') {
        // Konversi data ke JSON
        fileContent = JSON.stringify(formattedData, null, 2);
    } else if (fileType === 'csv') {
        // Konversi data ke CSV
        const csvHeaders = Object.keys(formattedData[0] || {}).join(',');
        const csvRows = formattedData
            .map((row: any) =>
                Object.values(row)
                    .map((value: any) => `"${value}"`) // Escape dengan tanda kutip
                    .join(',')
            )
            .join('\n');

        fileContent = `${csvHeaders}\n${csvRows}`;
    }

    // Membuat dan mengunduh file
    const blob = new Blob([fileContent], {type: fileType === 'json' ? 'application/json' : 'text/csv'});
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

    generateTableColumns = () => {
        const {selectedColumns} = this.state;

        const baseColumns = selectedColumns.map((col) => {
            if (col === 'bank_data') {
                return [
                    {
                        title: 'Bank Code',
                        dataIndex: ['bank_accounts', 'bank_code'],
                        key: 'bank_code',
                    }, {
                        title: 'Bank Name',
                        dataIndex: ['bank_accounts', 'bank_name'],
                        key: 'bank_name',
                    }, {
                        title: 'Account Name',
                        dataIndex: ['bank_accounts', 'account_name'],
                        key: 'account_name',
                    }, {
                        title: 'Account Number',
                        dataIndex: ['bank_accounts', 'account_number'],
                        key: 'account_number',
                    }
                ];
            }
            if (col === 'id_card') {
                return {
                    title: 'ID Card Number',
                    dataIndex: ['id_card', 'id_card_number'],
                    key: 'id_card_number',
                };
            }
            if (col === 'phone') {
                return {
                    title: 'Phone',
                    dataIndex: ['phone', 'phone'],
                    key: 'phone',
                };
            }
            return {
                title: col.replace('_', ' ').toUpperCase(),
                dataIndex: col,
                key: col,
            };
        });
        return baseColumns.flat();
    };

    generateTableData = () => {
        const {dataResult} = this.state;

        return dataResult.map((row: any, index: any) => ({
            key: index,
            ...row,
        }));
    };

    render() {
        const {agentCodes, selectedColumns, isProcessing, dataResult} = this.state;

        // Menu untuk opsi export
        const exportMenu = (
            <Menu>
                <Menu.Item key="json" onClick={() => this.handleDownload('json')}>
                    Download JSON
                </Menu.Item>
                <Menu.Item key="csv" onClick={() => this.handleDownload('csv')}>
                    Download CSV
                </Menu.Item>
            </Menu>
        );

        return (
            <React.Fragment>
                <AppLayout
                    noPadNoMar={true}
                    breadcrumbPath={`UserVIP`}
                    breadcrumbMenu={`User Export`}
                    defaultSelectedKeys={['m-exportuservip']}
                    defaultOpenKeys={['sm-uservip']}
                >
                    <div className="p-5">
                        <Form layout="vertical">
                            <Form.Item>
                                <i className={`small-text-grey`}>Kode Agen (pisahkan dengan koma)</i>
                                <Input.TextArea
                                    rows={4}
                                    placeholder="Masukkan kode agen, pisahkan dengan koma..."
                                    value={agentCodes}
                                    onChange={this.handleAgentCodeChange}
                                />
                            </Form.Item>

                            <i className={`small-text-grey`}>Pilih Kolom</i>
                            <Form.Item>
                                <Select
                                    mode="multiple"
                                    value={selectedColumns}
                                    onChange={this.handleColumnsChange}
                                    placeholder="Pilih kolom untuk di-export"
                                >
                                    <Select.Option value="agent_code">Kode Agen</Select.Option>
                                    <Select.Option value="name">Nama Agen</Select.Option>
                                    <Select.Option value="id_card">Nomor KTP</Select.Option>
                                    <Select.Option value="bank_data">Data Bank</Select.Option>
                                    <Select.Option value="uid">UID</Select.Option>
                                    <Select.Option value="phone">Nomor Telpon</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" onClick={this.handleGet} loading={isProcessing} className="mb-6">
                                    Get Data
                                </Button>
                            </Form.Item>

                            {isProcessing && (
                                <div className="spinCenter">
                                    <Spin size="large"/>
                                </div>
                            )}
                        </Form>

                        {!isProcessing && dataResult.length > 0 && (
                            <>
                                {/* Tombol Export */}
                                <Dropdown overlay={exportMenu} placement="bottom" arrow>
                                    <Button style={{marginBottom: '16px', float: 'right'}}>
                                        Export Data
                                    </Button>
                                </Dropdown>

                                {/* Tabel hasil data */}
                                <Table
                                    size={'small'}
                                    columns={this.generateTableColumns()}
                                    dataSource={this.generateTableData()}
                                    bordered
                                    pagination={false}
                                />
                            </>
                        )}
                    </div>
                </AppLayout>
            </React.Fragment>
        );
    }
}

export default withAuth(UserExport, 33);