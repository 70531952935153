import React, {Component} from "react";
import Cookies from "universal-cookie";
import {userServices} from "../../services/amartaVip/UserService"
import {Button, Col, Divider, Modal, Row, Form} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import AreaProvinceSelect from "../selects/AreaProvinceSelect";
import AreaCitySelect from "../selects/AreaCitySelect";

class UpdateCoverageArea extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            loading: false,
            showModalAdd: false,
            dataCoverage: [],
            tempProvinceCode: "",
            tempProvinceName: "",
            tempCityCode: "",
            tempCityName: "",
        }
        this.state = {...this.initState}
    }

    onProvinceChange = (data: any) => {
        this.setState({
            tempProvinceCode: data.value,
            tempProvinceName: data.children,
            tempCityCode: "",
            tempCityName: "",
        });
    };

    onCityChange = (data: any) => {
        this.setState({
            tempCityCode: data.value,
            tempCityName: data.children,
        });
    };

    async componentDidMount() {
        console.log(this.props.data.coverage_area)
        this.setState({dataCoverage: this.props.data.coverage_area})
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.data.agent_code !== prevProps.data.agent_code) || (this.props.time !== prevProps.time)) {
            this.setState({dataCoverage: this.props.data.coverage_area})
        }
    }

    onCancel = async () => {
        this.props.onCloseModal({reload: false});
    }

    onSubmit = async () => {
        await this.promisedSetState({loading: true})

        try {
            await userServices.setToken(this.state.token)
            await userServices.updateUserInternal({
                type: "update_coverage_area",
                uid: this.props.data.uid,
                user_type: this.props.data.user_type,
                coverage_area: this.state.dataCoverage
            }).then(async successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'successfully updated '
                })
                this.setState({
                    loading: false,
                    showModalAdd: false,
                })
                this.props.onCloseModal({reload: true});
            })
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            })
            this.setState({loading: false,})
        }


    }

    confirmDelete = async (id: any) => {
        const latestData = [...this.state.dataCoverage]
        latestData.splice(id, 1);
        this.setState({
            showModalAdd: false,
            tempProvinceCode: "",
            tempProvinceName: "",
            tempCityCode: "",
            tempCityName: "",
            dataCoverage: latestData
        })
    }

    confirmAdd = async () => {
        if (!this.state.tempProvinceCode) {
            Modal.error({
                title: 'error',
                content: 'Province Required',
            });
            return
        }

        const latestData = [...this.state.dataCoverage]
        latestData.push({
            province_code: this.state.tempProvinceCode,
            province_name: this.state.tempProvinceName,
            city_code: (this.state.tempCityCode) ? this.state.tempCityCode : null,
            city_name: (this.state.tempCityName) ? this.state.tempCityName : null,
        })

        this.setState({
            showModalAdd: false,
            tempProvinceCode: "",
            tempProvinceName: "",
            tempCityCode: "",
            tempCityName: "",
            dataCoverage: latestData
        })
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <>
                <Button size={"small"} style={{marginBottom: 15}} onClick={() => this.setState({showModalAdd: true})} type={"primary"}> Add New </Button>
                {this.state.dataCoverage?.map((item: any, x: number) =>
                    <Row key={x} style={{height: 40}}>
                        <Col span={20}>
                            {item?.province_name?.toString()} {item?.city_name?.toString()}
                        </Col>
                        <Col span={4}>
                            <DeleteOutlined onClick={() => this.confirmDelete(x)} style={{cursor: "pointer", color: "red", fontSize: "120%"}}/>
                        </Col>
                    </Row>
                )}

                <div style={{paddingTop: 20, textAlign: "right"}}>
                    <Divider/>
                    <Button loading={this.state.loading} onClick={event => this.onSubmit()} type="primary" style={{marginRight: 7}}>Update Data</Button>
                    <Button loading={this.state.loading} onClick={event => this.onCancel()} style={{marginRight: 7}}>Cancel</Button>
                </div>

                <Modal
                    open={this.state.showModalAdd}
                    title="Add Coverage Area"
                    onCancel={() => this.setState({showModalAdd: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button type={"primary"} key="1" onClick={this.confirmAdd}>Add</Button>,
                        <Button key="2" onClick={() => this.setState({showModalAdd: false})}>Close</Button>
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <Form.Item>
                            <div className={`gray-80 mt-20`}>Province*:</div>
                            <AreaProvinceSelect onAreaProvinceChangeProps={this.onProvinceChange} value={this.state.tempProvinceCode} country="idn"/>
                            <div className={`gray-80 mt-20`}>City*:</div>
                            <AreaCitySelect onAreaCityChangeProps={this.onCityChange} value={this.state.tempCityCode} provinceCode={this.state.tempProvinceCode}/>
                        </Form.Item>
                    </React.Fragment>
                </Modal>
            </>
        );
    }
}

export default UpdateCoverageArea;
