import React, {Component} from "react";
import {connect} from "react-redux";

class Test extends Component<any, any> {

    changeUserReduxNormal = () => {
        this.props.changeUserName();
    }

    changeUserReduxThunk = () => {
        this.props.changeUserNameThunk();
    }

    render() {
        return (
            <div>
                Doni Was Here {this.props.popupProps} <br/>
                using accoung {this.props.userName} <br/>
                <button onClick={this.changeUserReduxThunk}>Change Redux State User to Doni</button> <br/>
                <button onClick={this.changeUserReduxNormal}>Change Redux Thunk State User to Donny</button>
            </div>
        );
    }
}

const actionThunkUserName = () => {
    return (dispatch: any) => {
        setTimeout(() => {
            return dispatch({type: 'CHANGE_USER', value: 'Donny'})
        }, 1500)
    }
}

const actionNormalUserName = () => {
    return {type: 'CHANGE_USER', value: 'doni'}
}

const reduxState = (state: any) => ({
    popupProps: state.popup,
    userName: state.user
});

const reduxDispatch = (dispatch: any) => ({
    changeUserName: () => dispatch(actionThunkUserName()),
    changeUserNameThunk: () => dispatch(actionNormalUserName())
})

export default connect(reduxState, reduxDispatch)(Test);
