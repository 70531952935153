import React, { Component } from "react";
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from "universal-cookie";
import { Buffer } from "buffer";
import withAuth from "../../hoc/withAuth";
import Spreadsheet from "react-spreadsheet";
import { ImportWorksheetStates } from "./types/ImportWorksheetTypes";
import { allowedOrganization } from "../../config/appDataConfig/organizationCondig";
import SelectDealer from "../../component/selects/SelectDealer";
import { Button, DatePicker, Form, Modal, Select } from "antd";
import { worksheetApiService } from "../../services/amartaVip/WorksheetApiService";
import dayjs from "dayjs";

export interface ImportWorksheetProps {}

class ImportWorksheet extends Component<ImportWorksheetProps & any, ImportWorksheetStates> {
  private cookies = new Cookies();
  private columnLabels = ["Name", "Phone", "Address", "Description"];

  constructor(props: ImportWorksheetProps & any) {
    super(props);

    this.state = {
      token: this.cookies.get("_vip-t") ?? "",
      user: JSON.parse(Buffer.from(this.cookies.get("_vip-u"), "base64").toString()),
      startDate: dayjs(),
      loading: false,
      organizationCode: allowedOrganization[0].otodisCode,
      modalDetail: false,
      dealerCode: "",
      cityCode: "",
      cityName: "",
      provinceCode: "",
      provinceName: "",
      dataSheet: [
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }],
      ],
    };
  }

  onFieldChange = async <T extends keyof Pick<any, "organizationCode" | "startDate" | "dealerCode">>(target: T, value: any) => {
    const currentState: any = { ...this.state };
    switch (target) {
      case "organizationCode":
        currentState.organizationCode = value;
        currentState.dealerCode = "";
        currentState.cityCode = "";
        currentState.cityName = "";
        currentState.provinceCode = "";
        currentState.provinceName = "";
        break;
      case "startDate":
        currentState.startDate = value;
        break;
      case "dealerCode":
        currentState.dealerCode = value;
        break;
    }
    await this.promisedSetState({
      ...currentState,
    });
  };

  onChangeDealer = async (v: string, i: any) => {
    const [city_code, city_name, province_code, province_name] = i.add_text.split("#");
    // console.log("city_code", "city_name", "province_code", "province_name");
    // console.log(city_code, city_name, province_code, province_name);
    await this.promisedSetState({
      dealerCode: v,
      cityCode: city_code,
      cityName: city_name,
      provinceCode: province_code,
      provinceName: province_name,
    });
  };

  onAreaChange = async (data: any) => {
    console.log(data);
  };

  onSheetChange = (newData: any) => {
    // if (newData !== this.state.dataSheet)
    this.setState({ dataSheet: newData });
  };

  onResetSheet = () => {
    this.setState({
      dataSheet: [
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }, { value: "" }, { value: "" }],
      ],
    });
  };

  onImportData = async () => {
    const { startDate, organizationCode, dealerCode, dataSheet, user, cityCode, cityName, provinceCode, provinceName } = this.state;
    const filteredDataSheet = dataSheet
      .map((row: any) => {
        if (row[0]?.value !== "" && row[0]?.value !== undefined && row[1]?.value !== "" && row[1]?.value !== undefined && row[2]?.value !== "" && row[2]?.value !== undefined) {
          return {
            customer_name: row[0]?.value,
            customer_phone: row[1]?.value,
            customer_address: row[2]?.value,
            description: row[3]?.value ? row[3]?.value : "",
          };
        } else {
          return null;
        }
      })
      .filter((data: any) => data !== null);

    if (filteredDataSheet.length < 1) {
      Modal.error({
        title: "Error Validation",
        content: "Data sheet is Empty",
      });
      return false;
    }

    if (startDate === "") {
      Modal.error({
        title: "Error Validation",
        content: "Waktu Priode is required",
      });
      return false;
    }

    if (organizationCode === "") {
      Modal.error({
        title: "Error Validation",
        content: "Organization is required",
      });
      return false;
    }

    if (dealerCode === "") {
      Modal.error({
        title: "Error Validation",
        content: "Dealer Code is required",
      });
      return false;
    }

    const postData = {
      period_time: startDate,
      company_code: organizationCode,
      dealer_code: dealerCode,
      city_code: cityCode,
      city_name: cityName,
      province_code: provinceCode,
      province_name: provinceName,
      created_by: user,
      data: filteredDataSheet,
    };

    this.setState({ loading: true });

    try {
      worksheetApiService.setToken(this.state.token);
      const importData = await worksheetApiService.importWorksheet(postData);

      // const totalCreatedMessage = `<strong>Total data berhasil diinput = ${importData.data.total_created}</strong><br />`;
      // const failedDataMessages = importData.data.message.map((item: any) => `<p><strong>${item.phoneNumber}</strong>: ${item.reason}</p>`).join("");
      // const contentMessage = `${totalCreatedMessage}<p><strong>Data gagal diinput:</strong></p>${failedDataMessages}`;

      Modal.success({
        title: "Success",
        width: 600,
        content: (
          <div>
            <strong>Total data berhasil diinput : {importData.data.total_created}</strong>
            <br />
            <strong>Data Gagal Diinput : </strong>
            <ul>
              {importData.data.message.map((item: any) => (
                <li>
                  {item.phoneNumber} {item.reason}
                </li>
              ))}
            </ul>
          </div>
        ),
      });

      await this.promisedSetState({
        loading: false,
      });

      this.onResetSheet();
    } catch (e: any) {
      Modal.error({
        title: "Error Validation",
        content: e.toString(),
      });

      await this.promisedSetState({
        loading: false,
      });
    }
  };

  promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

  render() {
    return (
      <React.Fragment>
        <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`Worksheet`} breadcrumbMenu={`Import Worksheet`} defaultSelectedKeys={["m-import-worksheet"]} defaultOpenKeys={["sm-worksheet", "sm-other"]}>
          <div style={{ padding: 20 }}>
            <Form layout={`inline`} style={{ marginTop: -3 }}>
              <Form.Item>
                <i className={`small-text-grey`}>Organization:</i>
                <br />
                <Select style={{ float: "left", minWidth: "280px" }} showSearch placeholder="Choose organizationCode" optionFilterProp="children" onChange={(value) => this.onFieldChange("organizationCode", value)} value={this.state.organizationCode}>
                  {(allowedOrganization ?? []).map((item: any, i: number) => (
                    <Select.Option key={i} value={item?.otodisCode}>
                      {item?.companyCode}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item className={"padTop-10"} label="">
                <i className={`small-text-grey`}>Dealer</i>
                <br />
                <SelectDealer value={this.state.dealerCode} onChangeValue={this.onChangeDealer} organizationCode={this.state.organizationCode} />
              </Form.Item>
              <Form.Item className={"padTop-10"} label="">
                <i className={`small-text-grey`}>Period</i>
                <br />
                <DatePicker picker="month" defaultValue={dayjs(this.state.startDate, "YYYY-MM")} onChange={(event) => this.onFieldChange("startDate", event)} format={"YYYY-MM"} />
              </Form.Item>
              <Form.Item>
                <i className={`small-text-grey`}>&nbsp;</i>
                <br />
                <Button loading={this.state.loading} onClick={this.onImportData} type={"primary"}>
                  {" "}
                  Import Data{" "}
                </Button>
                &nbsp;
                <Button danger loading={this.state.loading} onClick={this.onResetSheet} type={"primary"}>
                  {" "}
                  Reset Sheet{" "}
                </Button>
              </Form.Item>
            </Form>
          </div>

          <div className={`p-5`}>
            <Spreadsheet data={this.state.dataSheet} columnLabels={this.columnLabels} onChange={this.onSheetChange} />
          </div>
        </AppLayout>
      </React.Fragment>
    );
  }
}

export default withAuth(ImportWorksheet, 21);
